import React, { useEffect, useState } from "react";
import { getDownloadIcon } from "./util";

function OtherReportTab(props) {
  const [data, setData] = useState();
  const cmsApi = props.dataapi;

  useEffect(() => {
    fetch(cmsApi)
      .then((res) => res.json())
      .then(
        (response) => setData(response.content.field_components),
        console.log(data)
      )
      .catch((err) => console.error(err));
  }, []);

  return (
    <>
      {data && (
        <div className="col-md-12 mt-2 mb-2">
          <a
            className="download-link"
            target="_blank"
            rel="noopener noreferrer"
            href={data[0].file_url}
            title={data[0].field_text}

          >
            {getDownloadIcon(
                          data[0].file_url,
                          data[0].file_url.substring(
                            data[0].file_url.lastIndexOf("/") + 1
                          )
                        )}
            {data[0].field_text}
          </a>
        </div>
      )}
    </>
  );
}

export default OtherReportTab;
