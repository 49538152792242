// import React from 'react';
import URL from 'url-parse';

// const SAFE_URL_PATTERN = /^(?:(?:https?|mailto):|[^&:/?#]*(?:[/?#]|$))/gi;

/** A pattern that matches safe data URLs. It only matches image, video, and audio types. */
//const DATA_URL_PATTERN = /^data:(?:image\/(?:bmp|gif|jpeg|jpg|png|tiff|webp)|video\/(?:mpeg|mp4|ogg|webm)|audio\/(?:mp3|oga|ogg|opus));base64,[a-z0-9+\/]+=*$/i;

// function _sanitizeUrl(url) {
//   url = String(url);
//   if (url === "null" || url.length === 0 || url === "about:blank") return "about:blank";
//   if (url.match(SAFE_URL_PATTERN)) return url;

//   return `unsafe:${url}`;
// }

// export const sanitizeUrl = (checkurl) => {
//   return _sanitizeUrl(String(checkurl).trim());
// }

function checkUrl(checkurl) {
    const url = URL(checkurl, {})
    if (url.protocol === 'http:') return true;
    if (url.protocol === 'https:') return true;
    return false;
}

export const sanitizeUrl = (checkurl) => {
    const url = checkUrl(checkurl) ? checkurl : null;
    return url;
}
