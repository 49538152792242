import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import Add from '@material-ui/icons/Add';
import Remove from '@material-ui/icons/Remove';


const Accordion = withStyles({
  root: {
    border: '1px solid rgba(0, 0, 0, .125)',
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },
  expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    // backgroundColor: '#EFECFE',
    
    // borderBottom: '1px solid rgba(0, 0, 0, .125)',
    marginBottom: -1,
    minHeight: 56,
    '&$expanded': {
      minHeight: 56,
    },
    // '& p.MuiTypography-root':{
    //   color:'#333333',
    //   fontSize:'14px',
    //   fontFamily:'Roboto-Regular',
    // }
  },
  content: {
    '&$expanded': {
      margin: '12px 0',
    },
  },
  expanded: {},
})(MuiAccordionSummary);

const CollapsibleAddIcon = withStyles({
    root: {
        float        : "right",
        border       :" 1px solid #333333",
        width        : "25px",
        height       : "25px",
        borderRadius: "50%",
        textAlign   : "center",
        color        : "#333333",
        marginTop   : "-3px",
        padding      :" 0px",
        fontSize    :" 18px",
     },
    })(Add);

  const CollapsibleRemoveIcon = withStyles({
    root: {
        float        : "right",
        border       :" 1px solid #C25414",
        width        : "25px",
        height       : "25px",
        borderRadius: "50%",
        textAlign   : "center",
        color        : "#C25414",
        marginTop   : "-3px",
        padding      :" 0px",
        fontSize    :" 18px",
      },
    })(Remove);

const AccordionDetails = withStyles((theme) => ({
  root: {
    // padding: theme.spacing(2),
  },
}))(MuiAccordionDetails);


class CollapsibleTabs extends React.Component{
    _isMounted = false;
      constructor(props) {
        super(props);
        this.state = {
            clpseTabs:props.clpseTabs,
            expanded: [],//3 //true false true
            expandAll: false,
            cardData:[],
          
        }
       // this.handleToggleAll=   this.handleToggleAll.bind(this);
      //  this.handleAll=   this.handleAll.bind(this);
        //this.handleChange=this.handleChange.bind(this);
    }
    componentDidMount() {
        try {
            this._isMounted = true;
            this.loadHomeBannerData();
            } catch (e) {
            console.log(e);
        }
 }
   
    loadHomeBannerData() {
       
        //data
        this.setState({cardData :this.state.clpseTabs});

        //data set to false
        var cData = this.state.clpseTabs.map((s,idx) => idx===0 ? true : false);
        this.setState({expanded: cData})
          
      }
        
      handleChange = (dataindex) => (event, newExpanded) => {
            var expandone = this.state.expanded;
            expandone[dataindex] = !expandone[dataindex];
            this.setState({expanded : expandone});
          };
        
      //expandall
      handleExpandAll = () => {
          var expandall = this.state.cardData.map(s => true);
          this.setState({
          expanded: expandall
      })   
            }

      //collapse all
      handleCollapseAll = () => {
           var expandall = this.state.cardData.map(s => false);
           this.setState({
                    expanded: expandall
                        })   
                       }

    render()
    {
      const rows = this.state.cardData.map((row, index) => { 
        return(
          <div className="collapsible-accordian" key={index} >
      <Accordion className="accordian-tab" square expanded={this.state.expanded[index]} onChange={this.handleChange(index)}>
     
            <AccordionSummary aria-controls={row.title.replace(/\s/g, '')} id={"accordian" + index }
            className={"accordian-tab-block " + (this.state.expanded[index] ? "accoridan-open" : "accoridan-close")}
             expandIcon={this.state.expanded[index] ? <CollapsibleRemoveIcon className="accordian-collapse-icon"/> : <CollapsibleAddIcon className="accordian-expand-icon"/>}>
            <Typography className="accordian-title">{row.title}</Typography>
            </AccordionSummary>
            <AccordionDetails className="accordian-body">
              <div className="container-fluid">
             <div className="row">{row.body}</div>
             </div>
            </AccordionDetails>
          </Accordion>
          </div>
        )})
        return (
            <div className="col-md-12">
            <div className="accordLink text-right mb-2">
            <button className="flat-button-orange link-button mr-2" onClick={this.handleExpandAll}>Expand All</button>
            <span>| </span>  
              <button className="flat-button-orange link-button" onClick={this.handleCollapseAll}>Collapse All</button>
           </div>
            {rows}
            </div>
        )
      }
}

export default CollapsibleTabs;