import React from 'react';
import moment from "moment";
import ReorderIcon from '@material-ui/icons/Reorder';
import AppsIcon from '@material-ui/icons/Apps';
import CircularCard from './components/circular-card';
import PaginationContainer from './components/pagination-container';
import CSVDownloader from './components/download-csv';
import Notes from './components/notes';
import { parseAsHtml } from './components/util'
import ConsolidatedFilters from './components/consollidated-filter';
import { Refresh } from './components/custom-toolbar';
import { getDownloadIcon, getTitleFileExtension } from './components/util';
import { sanitizeUrl } from './components/sanitize-url';

const momentDateFormat = "DD-MM-YYYY";
class Circulars extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            api: ``,
            cardData: [],
            currentView: 'listview',
            isGridview: false,
            fromdate: "01-01-2017",
            todate: moment().utc().utcOffset("+05:30").format(momentDateFormat),
            notesApi: `/api/contents/notes?url=/resources-circulars`, 
            notesData: [],
            cardpara: '',
            baseApi: `/api/consolidated-circulars-niccl-all?`,
            originalData: [],
            category: "",
            keyword: "",
        };
        this.resetFilters = this.resetFilters.bind(this);
        this.refresh = this.refresh.bind(this);
        this.setParameters = this.setParameters.bind(this);
        this.filterCirculars = this.filterCirculars.bind(this);
    }

    componentDidMount() {
        try {
            this._isMounted = true;
            this.setState({
                api: `${this.state.baseApi}fromdate=${this.state.fromdate}&todate=${this.state.todate}`
            }, () => {
                this.loadCircularData(this.state.api);
            })

            this.loadNotes(this.state.notesApi);
        } catch (e) {
            console.log(e);
        }
    }

    loadCircularData(api) {
        fetch(api)
            .then(resp => resp.json())
            .then((cards) => {
                const cardList = [];
                cards.data.forEach((item) => {
                    cardList.push({
                        TITLE: item.C_CIRC_TYPE,
                        SUBJECT: item.C_CIRC_SUBJECT,
                        DATE: item.C_CIRC_DATE,
                        LINK: item.CIRC_FILELINK,
                        CIRCULAR_NO: item.C_CIRC_NO
                    })
                })
                this.setState({ originalData: cardList });
                this.filterCirculars(cardList);
            })
            .catch((e) => { console.log("error in loading circulars data", e) })
    }

    loadNotes(api) {
        fetch(api)
            .then(resp => resp.json())
            .then((cards) => {
                let notedata = [];
                let cardpara = '';
                cards.content.field_note_components.forEach((item, idx) => {
                    idx === 1 ? cardpara = item : notedata.push(item)
                })
                this.setState({
                    notesData: notedata,
                    cardpara: cardpara.field_para
                })
            })
            .catch((e) => { console.log("error in notes of circulars", e) })
    }
  
    setParameters(fromdate, todate, category, keyword) {

        let from = moment(fromdate, "DD-MMM-YYYY").format(momentDateFormat);
        let to = moment(todate, "DD-MMM-YYYY").format(momentDateFormat);
        let api = `${this.state.baseApi}fromdate=${from}&todate=${to}`; //? added for testing

        if (category && category !== "") {
            api += `&type=${category}`
        }
        if (from !== this.state.fromdate || to !== this.state.todate || category !== this.state.category) {

            this.setState({
                fromdate: from,
                todate: to,
                api: api,
                category: category,
                keyword: keyword
            }, () => {
                this.loadCircularData(api);
            })
        }
        else {

            this.setState({ category, keyword }, () => {
                this.filterCirculars(this.state.originalData)
            })

        }
    }

    filterCirculars(carddata) {
        if (this.state.keyword !== "") {
            
            const filteredContent = carddata.filter(item => {
                return (item.CIRCULAR_NO?.toString().toLowerCase().search(this.state.keyword.toLowerCase()) !== -1
                    || item?.CIRC_SUBJECT?.toString().toLowerCase().search(this.state.keyword.toLowerCase()) !== -1);
            })
            this.setState({ cardData: filteredContent })
        }
        else {
            this.setState({ cardData: carddata })
        }
    }
    resetFilters() {
        this.setState({
            fromdate: moment(new Date()).subtract(7, 'days').format(momentDateFormat),
            todate: moment().utc().utcOffset("+05:30").format(momentDateFormat),
            keyword: '',
            category: ''
        }, () => {
            this.loadCircularData(`/api/consolidated-circulars-niccl-all?fromdate=${this.state.fromdate}&todate=${this.state.todate}`);
            this.setState({
                api: `/api/consolidated-circulars-niccl-all?fromdate=${this.state.fromdate}&todate=${this.state.todate}`
            })

        })
    }
    refresh() {
        if (this._isMounted) {
            this.setState({ cardData: [] });
            this.loadCircularData(this.state.api);
        }
    }

    render() {
        const circularComponent = []
        return (
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-12">
                        <div className="static-para circulars-para">{parseAsHtml(this.state.cardpara)}
                        </div>
                    </div>
                </div>

                {/* Circular Filters */}
                <div className="row">
                    <div className="col-md-12">
                        <ConsolidatedFilters resetcallback={this.resetFilters} callback={this.setParameters} />
                    </div>
                </div>

                <div className="row mb-3 mt-3">
                    <div className="col-lg-6">
                        <div className="d-flex align-items-center">
                            <div className="tag">
                                <p>Circulars for period {this.state.fromdate} to {this.state.todate}
                                </p>
                            </div>
                            <Refresh refreshcallback={this.refresh} />
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="d-flex justify-content-end align-items-center">
                            <div className="mr-2">
                                <CSVDownloader data={this.state.cardData} csvname={"circular-" + this.state.fromdate + "-to-" + this.state.todate} />
                            </div>
                            <div className="mr-2">
                                <div className="circle-icon"
                                    onClick={() => {
                                        if (this.state.currentView === 'gridview') {

                                            this.setState({ currentView: 'listview', isGridview: false })
                                        }
                                        else {
                                            this.setState({ currentView: 'gridview', isGridview: true })
                                        }

                                    }}
                                >
                                    {this.state.isGridview && <ReorderIcon />}
                                    {!this.state.isGridview && <AppsIcon />}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* cards */}
                {/* <div className={this.state.isGridview ? "row justify-content-center" : ""}>
                    {this.state.cardData.length > 0 && this.state.cardData.forEach((event, index) => {
                        circularComponent.push(<div className={this.state.isGridview ? "col-sm-4 mb-3" : "col-lg-12 mb-2"} key={index}>
                            <CircularCard alignCard={this.state.currentView} card={event} />
                        </div>)

                    })
                    }
                    {this.state.cardData.length > 0 ?
                     <div className={this.state.isGridview ? "container-fluid" : ""}>
                        <PaginationContainer pagination-component={circularComponent} itemsPerPage={6} />
                    </div>
                        : <p className="text-center mb-0">No records</p>}
                </div> */}
                  <div>
            {/* <section>
	<div class="container">
		<div class="panel panel-default main_header" style={{backgroundColor : '#8A231C', color:'#fff', fontSize:'18px'}}>
			<div class="panel-body">Circulars</div>
		</div>
	</div>
</section> */}
            <div class="container">
                <div class="table-responsive">
                <table className='MuiTable-root table customize-table'>
        <thead>
        <tr>
          <th style={{fontWeight:'bold'}}>Date</th>
          <th style={{fontWeight:'bold'}}>Subject</th>
          <th style={{fontWeight:'bold'}}>Category</th>
          <th style={{fontWeight:'bold'}}>Circular No.</th>
          <th style={{fontWeight:'bold'}}>Download</th>
        </tr>
        </thead>
        {this.state.originalData.map((val, key) => {
          return (
            <tr key={key}>
              <td>{val.DATE}</td>
              <td>{val.SUBJECT}</td>
              <td>{val.TITLE}</td>
              <td>{val.CIRCULAR_NO}</td>
              <td> <a target="_blank" className="download-link" href={val.LINK}>
                                        {getDownloadIcon(val.LINK,(val.CIRCULAR_NO))}</a></td>
            </tr>
          )
        })}
      </table>
      </div>
	  </div>
            </div>

                {/* Notes from CMS */}
                <div className="row mt-4" id="note">
                    <div className="col-md-12">
                        <Notes notesData={this.state.notesData} />
                    </div>
                </div>
            </div>
        )
    }
}

export default Circulars;