import React, { Component } from 'react';
// import TablePagination from '@material-ui/core/TablePagination';
import UsStocksFilters from './us-stock-filter';
import AttachmentTable from './attachment-table';


class UsStockCaTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            api: '/api/us-stocks-corp-announcement',
            columns: [],
            data: [],
            rowsPerPage: 10,
            page: 0,
            searchTable:'',
            searchCorpAnn:"",
            originalData:[]
        };
        this.handleChangePage = this.handleChangePage.bind(this);
        this.handleChangeRowsPerPage = this.handleChangeRowsPerPage.bind(this);
        this.setFilters = this.setFilters.bind(this);
        this.reset = this.reset.bind(this);
    };
    // componentDidUpdate(prevProps) {
    //     if (this.props.api !== prevProps.api) {
    //         this.setState({ api: this.props.api }, () => {
    //             this.loadData(this.state.api);
    //         })
    //     }
    // }
    componentDidMount() {
        try {
            if (this.state.api && this.state.api !== "") {
                this.loadData(this.state.api);
            }
        } catch (e) {
            console.log(e);
        }
    }
    loadData(api) {
        fetch(api)
            .then(resp => resp.json())
            .then((data) => {
                
                this.setState({
                    //data: data.data,
                    columns: data.columns,
                    //page: 0,
                });
                this.setState({ originalData: data.data });
                this.filterCorpAnn(data.data)
            })
            .catch((e) => { console.log("error in loading corporate announcement data", e) })

    }

    filterCorpAnn(tabledata) {
        if (this.state.searchCorpAnn !== "") {

            const filteredContent = tabledata.filter(item => {
                return (item.SYMBOL_CODE.toLowerCase()
                .search(this.state.searchCorpAnn.toLowerCase()) !== -1);
            })

            this.setState({ data: filteredContent,
                page: 0, })
        }
        else {
            this.setState({
                data: tabledata,
                page: 0,
            });
        }
    }
    
    handleChangePage = (newPage) => {
        this.setState({
            page: newPage,
        })
    }
    handleChangeRowsPerPage = (val) => {
        this.setState({
            rowsPerPage: +val,
            page: 0
        })
    }
    setFilters = (selectType,fromdate, 
        todate, searchCorpAnn) => {       
         
        let api = `/api/us-stocks-corp-announcement`;
        if(fromdate && todate && (fromdate !== this.state.fromdate || todate !== this.state.todate)){
        
        api += `?from=${fromdate}&to=${todate}`;
        this.setState({api,searchCorpAnn,fromdate,todate},()=>{
            this.loadData(api);
            })
        }
        else{
            this.setState({searchCorpAnn},()=>{
                this.filterCorpAnn(this.state.originalData)
                })
            
        }
        
    }
    reset = () => {        
        let api = `/api/us-stocks-corp-announcement`;
        
        this.setState({api,searchCorpAnn:""},()=>{
            this.loadData(api);
        })
    }
    render() {

        return (
               <>
               <UsStocksFilters callback={this.setFilters} resetcallback={this.reset} />
               <AttachmentTable data={this.state.data} columns={this.state.columns} />
               </>
                
        );
    }
}

export default UsStockCaTable;