import React, { Component } from 'react';
import moment from "moment";
import { Select, MenuItem } from "@material-ui/core";
import { MuiPickersUtilsProvider, KeyboardDatePicker, } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
//import env from '../../config';

const ButtonHeight = {
    height: '100%',
}

const dateFormat = "DD-MMM-YYYY";
class CorporateFilters extends Component {
    constructor(props) {
        super(props);
        this.state = {
            api: `/api/corporate-information?&from=${moment(new Date()).subtract(7, 'days').format(dateFormat)}&to=${moment().utc().utcOffset("+05:30").format(dateFormat)}`,
            api1: `/api/corporate-information`,
            selectIssuerName: [],
            selectIssuerDesc: [],
            issuerName: '',
            issuerDesc: '',
            fromDateOpen: false,
            toDateOpen: false,
            todate:  moment().utc().utcOffset("+05:30").format(dateFormat),
            fromdate: moment(new Date()).subtract(7, 'days').format(dateFormat),
            validationfrom: '',
            validationTo: '',
            maxFromDate: moment().utc().utcOffset("+05:30").format(dateFormat),
            callback: props.callback,
            resetcallback: props.resetcallback,
            linksarray: [
                {
                    id: "1D",
                },
                {
                    id: "1W",
                },
                {
                    id: "1M",
                },
                {
                    id: "3M",
                },
                {
                    id: "6M",
                },
                {
                    id: "1Y",
                },
                // {
                //     id: "Clear",
                // },
            ],
            activeLink: "1W",
        };
        this.handleClick = this.handleClick.bind(this);
        this.handleFromDateChange = this.handleFromDateChange.bind(this);
        this.handleToDateChange = this.handleToDateChange.bind(this);
        this.filter = this.filter.bind(this);
        this.reset = this.reset.bind(this);
        // this.state.callback(this.state.issuerName, this.state.issuerDesc,
        //     this.state.fromdate, this.state.todate);
        //this.state.resetcallback(this.state.api)

    };
    componentDidMount() {
        try {
            this._isMounted = true;
            this.loadIssuerName(this.state.api1 + '/disclosure-names');
            this.loadIssuerDesc(this.state.api1 + '/disclosure-description');
        } catch (e) {
            console.log(e);
        }
    }
    handleFromDateChange = (date) => {
        this.setState({
            fromdate: moment(date,dateFormat).format(dateFormat),
            fromDateOpen: false
        });

    }

    handleToDateChange = (date) => {
        this.setState({
            todate: moment(date,dateFormat).format(dateFormat),
            toDateOpen: false,

        }, () => {
            this.setState({ maxFromDate: this.state.todate })
        });
    }
    loadIssuerName(api) {
        fetch(api)
            .then(resp => resp.json())
            .then((resp) => {
                this.setState({ selectIssuerName: resp.data });
            })

            .catch((e) => { console.log("error in loading list of issuer name in corporate info", e) })
    }
    loadIssuerDesc(api) {
        fetch(api)
            .then(resp => resp.json())
            .then((resp) => {
                this.setState({ selectIssuerDesc: resp.data });
            })
            .catch((e) => { console.log("error in loading list of issuer desc in corporate info", e) })
    }
    handleClick(id) {
        this.setState({
            activeLink: id,
            todaysDate: new Date()
        }, function () {
            this.setState({
                maxFromDate: moment().utc().utcOffset("+05:30").format(dateFormat),
            })

            switch (id) {
                case "1D":
                    this.setState({
                        fromdate: moment(new Date()).subtract(1, 'days').format(dateFormat),
                        todate: moment().utc().utcOffset("+05:30").format(dateFormat),
                    });
                    break;
                case "1W":
                    this.setState({
                        fromdate: moment(new Date()).subtract(7, 'days').format(dateFormat),
                        todate: moment().utc().utcOffset("+05:30").format(dateFormat),
                    });
                    break;
                case "1M":
                    this.setState({
                        fromdate: moment(new Date()).subtract(1, 'months').format(dateFormat),
                        todate: moment().utc().utcOffset("+05:30").format(dateFormat),
                    });
                    break;
                case "3M":
                    this.setState({
                        fromdate: moment(new Date()).subtract(3, 'months').format(dateFormat),
                        todate: moment().utc().utcOffset("+05:30").format(dateFormat),
                    });
                    break;
                case "6M":
                    this.setState({
                        fromdate: moment(new Date()).subtract(6, 'months').format(dateFormat),
                        todate: moment().utc().utcOffset("+05:30").format(dateFormat),
                    });
                    break;
                case "1Y":
                    this.setState({
                        fromdate: moment(new Date()).subtract(1, 'year').format(dateFormat),
                        todate: moment().utc().utcOffset("+05:30").format(dateFormat),
                    });
                    break;
                case "Clear":
                    this.setState({ fromdate: null, todate: null,
                      
                    });
                    
                    break;

                default:
                    break;
            }


        })
    }


    filter() {
        if (this.state.fromdate && !this.state.todate) {
            this.setState({
                validationTo: "Please Select To Date"
            })
        }
        else if (!this.state.fromdate && this.state.todate) {
            this.setState({
                validationfrom: "Please Select From Date"
            })
        }
        else if (this.state.issuerName || this.state.issuerDesc || (this.state.fromdate && this.state.todate && moment(this.state.todate,dateFormat) >= moment(this.state.fromdate,dateFormat))) {
            this.setState({
                validationTo: '',
                validationfrom: '',
            })
            this.state.callback(this.state.issuerName, this.state.issuerDesc,
                this.state.fromdate, this.state.todate);

        }
        else if (this.state.fromdate === null && this.state.todate === null) {
            
            // this.state.callback(this.state.issuerName, this.state.issuerDesc, this.state.fromdate, this.state.todate
            // );
        }
        else { }
    }
    reset() {
        this.setState({
            issuerName: '',
            issuerDesc: '',
            todate: moment().utc().utcOffset("+05:30").format(dateFormat),
            fromdate:moment(new Date()).subtract(7, 'days').format(dateFormat),
            validationTo: '',
            validationfrom: '',
            activeLink: "1W",
            maxFromDate: moment().utc().utcOffset("+05:30").format(dateFormat),
        })
        this.state.resetcallback(this.state.api)
     }
    render() {
        return (
            <>

                <div className="row">
                    <div className="filterdata col-5">
                        {this.state.linksarray.map((links, index) => {
                            return <div key={index} data-val={links.id} onClick={() => this.handleClick(links.id)} className={(links.id === this.state.activeLink ? "days active" : "days")}>
                                {links.id}</div>
                        })}
                    </div>
                </div>
                <div className="row corporate-filter-fields">
                    <div className="col-md-3 cofilter-padding">
                        <Select className="customize-input-group quotes-select-filter"
                            onChange={(e) => { this.setState({ issuerName: e.target.value }) }} value={this.state.issuerName}
                            displayEmpty>

                            <MenuItem value="" disabled>Issuer Name</MenuItem>

                            {this.state.selectIssuerName.map((x, idx) => {
                                return <MenuItem key={x + idx} value={x}>
                                    {x}
                                </MenuItem>
                            })}
                        </Select>
                    </div>
                    <div className="col-md-3 cofilter-padding">
                        <Select className="customize-input-group quotes-select-filter"
                            onChange={(e) => { this.setState({ issuerDesc: e.target.value }) }} value={this.state.issuerDesc}
                            displayEmpty>
                            <MenuItem value="" disabled>Select Disclosure Type</MenuItem>
                            {this.state.selectIssuerDesc.map((x, idx) => {
                                return <MenuItem key={x + idx} value={x}>
                                    {x}
                                </MenuItem>
                            })}
                        </Select>
                    </div>

                    <div className="col-md-2 cofilter-padding">
                        <div className="customize-input-group-date customize-input-group">
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <KeyboardDatePicker placeholder="From" variant="inline" format="dd-MMM-yyyy" margin="normal"  views={["year", "month","date"]} openTo="year"
                                    id="fromDate" maxDate={this.state.maxFromDate} value={this.state.fromdate} onChange={this.handleFromDateChange}
                                    disableFuture={true}
                                    autoOk={true}
                                    KeyboardButtonProps={{ "aria-label":"from_date_button" }}
                                    helperText={
                                        moment(this.state.todate,dateFormat) < moment(this.state.fromdate,dateFormat) ?
                                        "From date should not be after to date"  : null
                                    }
                                    // KeyboardButtonProps={{
                                    //     onFocus: (e) => {
                                    //         this.setState({
                                    //             fromDateOpen: true
                                    //         });
                                    //     }
                                    // }}
                                    // PopoverProps={{
                                    //     disableRestoreFocus: true,
                                    //     onClose: () => {
                                    //         this.setState({
                                    //             fromDateOpen: false
                                    //         });
                                    //     }
                                    // }}
                                    // InputProps={{
                                    //     onFocus: () => {
                                    //         this.setState({
                                    //             fromDateOpen: true
                                    //         });
                                    //     }
                                    // }}
                                    // open={this.state.fromDateOpen} 
                                    />
                            </MuiPickersUtilsProvider>
                        </div>
                    </div>
                    <span className="red cofilter-validation-from">{this.state.validationfrom}</span>
                    <div className="col-md-2 cofilter-padding">
                        <div className="customize-input-group-date customize-input-group">
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <KeyboardDatePicker placeholder="To" variant="inline" format="dd-MMM-yyyy" margin="normal" views={["year", "month","date"]} openTo="year"
                                    id="toDate" maxDate={new Date()} value={this.state.todate} onChange={this.handleToDateChange}
                                    KeyboardButtonProps={{ "aria-label":"to_date_button" }}
                                    disableFuture={true}
                                    autoOk={true}
                                    // KeyboardButtonProps={{
                                    //     onFocus: (e) => {
                                    //         this.setState({
                                    //             toDateOpen: true
                                    //         });
                                    //     }
                                    // }}
                                    // PopoverProps={{
                                    //     disableRestoreFocus: true,
                                    //     onClose: () => {
                                    //         this.setState({
                                    //             toDateOpen: false
                                    //         });
                                    //     }
                                    // }}
                                    // InputProps={{
                                    //     onFocus: () => {
                                    //         this.setState({
                                    //             toDateOpen: true
                                    //         });
                                    //     }
                                    // }}
                                    // open={this.state.toDateOpen} 
                                    />
                            </MuiPickersUtilsProvider>
                        </div>

                    </div>
                    <span className="red cofilter-validation-to">{this.state.validationTo}</span>
                    <div className="col-md-1 cofilter-padding">
                        <button className="btn btn-orange full-width cofilter-btn" style={ButtonHeight} onClick={this.filter}>Go</button>
                    </div>
                    <div className="col-md-1 cofilter-padding">
                        <button className="btn btn-primary full-width cofilter-btn" style={ButtonHeight} onClick={this.reset}>Reset</button>
                    </div>
                </div>
            </>
        );
    }
}
export default CorporateFilters;