import React, { Component } from 'react';
import Checkbox from "@material-ui/core/Checkbox";
import StarIcon from '@material-ui/icons/Star';
//import env from '../config';;
import { getDownloadIcon, parseAsHtml } from './components/util';
import SearchIcon from '@material-ui/icons/Search';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import InfoIcon from '@material-ui/icons/Info';
import JSZipUtils from 'jszip-utils';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import { ReactComponent as MultipleDownload } from '../public/images/download-icon.svg';

class Favorites extends Component {
    constructor(props) {
        super(props);
        this.state = {
            api: `/api/daily-reports`,
            cardData: [],
            selectAll: false,
            data: [],
            checked: [],
            searchKey: '',
            multipleDownload: []
        }
        this.handleAllChange = this.handleAllChange.bind(this);
        this.handleSingleCheckboxChange = this.handleSingleCheckboxChange.bind(this);
    }

    componentDidMount() {
        try {
            this._isMounted = true;
            this.loadFavData(this.state.api);
        } catch (e) {
            console.log(e);
        }
    }

    loadFavData(api) {
        console.log("will fetch from", api)

        fetch(api)
            .then(resp => resp.json())
            .then((cards) => {
                // const cookies = new Cookies();
                var favData = localStorage.getItem('ifscFav') || 'no';
                if (favData === 'no') {
                    favData = []
                }
                else {
                    try {
                        favData = JSON.parse(favData);
                    }
                    catch(error){
                        favData = [];
                    }
                    
                    // favData = JSON.parse(favData);
                }
                let favArry = [];
                for (let favItem of favData) {
                
                let gotFile = false;
                if (!gotFile && cards.data[0]["Next Day"].length > 0) {
                    
                    let session1 = cards.data[0]["Next Day"][0].Session1;
                    let session2 = cards.data[0]["Next Day"][0].Session2;
                    for (let i = 0; i < session1.length; i++) {
                        let key = session1[i].key;
                        key = key.substr(0, key.length - 4);
                        
                        if (favItem === key) {
                            favArry.push(session1[i]);
                            gotFile = true;
                        }
                    }
                    //console.log(favData,favArry)
                    for (let j = 0; j < session2.length; j++) {
                        let key = session2[j].key;
                        key = key.substr(0, key.length - 4);
                        if (favItem === key) {
                            favArry.push(session2[j]);
                            gotFile = true;
                        }
                    }
                }
                if (!gotFile && cards.data[0]["Current Day"].length > 0) {
                    
                    let session1 = cards.data[0]["Current Day"][0].Session1;
                    let session2 = cards.data[0]["Current Day"][0].Session2;
                    for (let i = 0; i < session1.length; i++) {
                        let key = session1[i].key;
                        key = key.substr(0, key.length - 4);
                        
                        if  (favItem === key) {
                            favArry.push(session1[i]);
                            gotFile = true;
                        }
                    }
                    //console.log(favData,favArry)
                    for (let j = 0; j < session2.length; j++) {
                        let key = session2[j].key;
                        key = key.substr(0, key.length - 4);
                        if  (favItem === key) {
                            favArry.push(session2[j]);
                            gotFile = true;
                        }
                    }
                }
                if (!gotFile && cards.data[0]["Previous Day"].length > 0) {
                    
                    let session1 = cards.data[0]["Previous Day"][0].Session1;
                    let session2 = cards.data[0]["Previous Day"][0].Session2;
                    for (let i = 0; i < session1.length; i++) {
                        let key = session1[i].key;
                        key = key.substr(0, key.length - 4);
                        
                        if  (favItem === key) {
                            favArry.push(session1[i]);
                            gotFile = true;
                        }
                    }
                    //console.log(favData,favArry)
                    for (let j = 0; j < session2.length; j++) {
                        let key = session2[j].key;
                        key = key.substr(0, key.length - 4);
                        if  (favItem === key) {
                            favArry.push(session2[j]);
                            gotFile = true;
                        }
                    }
                }

                }
                
                // this.setState({
                //     cardData: favArry,
                // })
                var checkedCopy = [];
                var selectAll = this.state.selectAll;
                favArry.forEach(function (e, index) {
                    checkedCopy.push(selectAll);
                });

                this.setState({
                    data: this.state.cardData,
                    checked: checkedCopy,
                    selectAll: selectAll,
                    cardData: favArry,
                });

            })
            .catch((e) => { console.log("error in header", e) })
    }
    // star fill/unfill
    addToFav(key) {
        var favData = localStorage.getItem('ifscFav') || 'no';
        if (favData === 'no') {
            favData = []
        }
        else {
            try {
                favData = JSON.parse(favData);
            }
            catch(error){
                favData = [];
            }
            
            // favData = JSON.parse(favData);
        }
        var index = this.checkContains(key)
        if (index < 0) //If contin -->0 1 2  i.e index not -1
        {
            key = key.substr(0, key.length - 4);
            favData.push(key); //Added to array if -1
        } else {
            favData.splice(index, 1); //Remove from array
        }
        localStorage.setItem('ifscFav', JSON.stringify(favData)); //Updage array in localstorage
        this.loadFavData(this.state.api)
    }

    checkContains(key) {
        var favData = localStorage.getItem('ifscFav') || 'no';
        key = key.substr(0, key.length - 4);
        if (favData === 'no') {
            return -1;
        }
        else {
            try {
                favData = JSON.parse(favData);
            }
            catch(error){
                favData = [];
            }
            
        }
        for (var i = 0; i < favData.length; i++) {
            if (favData[i] === key) {
                return i;
            }
        }
        return -1;
    }

    handleAllChange = () => {
        var selectAll = !this.state.selectAll;
        this.setState({ selectAll: selectAll, multipleDownload: [] });
        var checkedCopy = [];
        var download = [];
        this.state.cardData.forEach(function (e, index) {
            checkedCopy.push(selectAll);
            download.push(e.fileurl);
        });
        this.setState({
            checked: checkedCopy,
            cardData: this.state.cardData,
            //   value: event.target.value

        });
        if (selectAll) {
            this.setState({
                multipleDownload: download
            });
        } else {
            this.setState({
                multipleDownload: []
            });
        }
    };

    handleSingleCheckboxChange = (index, item) => {


        var checkedCopy = this.state.checked;
        checkedCopy[index] = !this.state.checked[index];
        if (checkedCopy[index] === false) {
            this.setState({ selectAll: false });
        }
        this.setState({
            checked: checkedCopy
        });
        this.checkedITemUrl(item.fileurl, checkedCopy[index])
    };


    checkedITemUrl(url, toAdd) //To Get URL
    {
        if (toAdd === true) {
            var tetamparry = this.state.multipleDownload;
            var isadded = false;
            for (var i = 0; i < tetamparry.length; i++) {
                if (tetamparry[i] === url) {
                    isadded = true;
                    break;
                }
            }
            if (!isadded) {
                tetamparry.push(url);
            }
            this.setState({ multipleDownload: tetamparry });
        }
        else {
            var tempArry = this.state.multipleDownload;
            for (let i = 0; i < tempArry.length; i++) {
                if (tempArry[i] === url) {
                    tempArry.splice(i, 1);
                    break;
                }
            }
            this.setState({ multipleDownload: tempArry });
        }
    }

    handleDownloadAll() {
        var zip = new JSZip();
        var count = 0;
        var zipFilename = 'Multiple_Reports.zip';
        var len = this.state.multipleDownload.length;
        this.state.multipleDownload.forEach(function (url, index) {

            var urlsplt = url.split("/");
            var filename = urlsplt[urlsplt.length - 1];  //'file-' + index;
            // loading a file and add it in a zip file
            JSZipUtils.getBinaryContent(url, async function (err, data) {
                if (err) {
                    throw err; // or handle the error
                }
                zip.file(filename, data, { binary: true });
                count++;
                if (count === len) {
                    var zipFile = await zip.generateAsync({ type: 'blob' });
                    saveAs(zipFile, zipFilename);
                }
            });
        });
    }
    render() {
        return (<>

            <div className="col-lg-4 mt-3 mb-4">
                <div className="customize-input-group align-items-center" >

                    <input type="text" className="input-form-control" placeholder="Search Reports"
                        onChange={(e) => {
                            this.setState({ searchKey: e.target.value });
                        }}
                    />
                    <div className="input-group-append ml-2">
                        <SearchIcon />
                    </div>
                </div>
            </div>
            <div className="col-lg-8 mt-2 mb-4" >
                <div className="d-flex flex-wrap justify-content-end align-items-center">
                    <FormControlLabel className="mb-0"
                        control={<Checkbox className="selectCheckbox"
                            onChange={() => this.handleAllChange()}
                            // value={this.state.value}
                            checked={this.state.selectAll}
                        //   value={this.state.selectAll}
                        />}
                        label={"Select All"
                            // + this.state.multipleDownload.length
                        }
                    />
                    <button className="btn btn-orange pr-4 pl-4" onClick={() => {
                        this.handleDownloadAll();
                    }} >Multiple file download <MultipleDownload className="multiple-download-icon"/></button>
                    <div className="helpcol"><button className="btn btn-primary d-flex align-items-center">Help <InfoIcon className="info-icon" /></button>
                    </div>
                </div>
            </div>


            {this.state.cardData.length > 0 ? this.state.cardData.map((item, i) => {
                if (item.file.toLowerCase().includes(this.state.searchKey.toLowerCase())) {
                    return (<div className="col-xs-6 col-lg-6 mb-2" key={i}>
                        <div className="favcol">
                            <div className="col-xs-2 col-lg-2 px-0">
                                <Checkbox
                                    className="selectCheckbox"
                                    // value={this.state.checked[`${i}`]}
                                    //defaultChecked={false}
                                    checked={this.state.checked[`${i}`]}
                                    onChange={() => this.handleSingleCheckboxChange(`${i}`, item)}
                                /></div>
                            <div className="col-xs-6 col-lg-6 px-0"><p className="mb-0">{parseAsHtml(item.file)}</p></div>
                            <div className="col-xs-1 col-lg-1 px-0"><a href={(item.fileurl)}>{getDownloadIcon((item.fileurl))}</a></div>
                            <div className="col-xs-1 col-lg-1 px-0">
                                <div className="fillcolor-star d-block">
                                    <StarIcon className="favorite-icon" onClick={() => { this.addToFav(item.key); }} />
                                </div>
                            </div>
                        </div>
                    </div>)
                }
                else { return ""; }
            }) : <div className="col-lg-12"><p className="text-center">No Records</p></div>}

        </>
        )
    }
}
export default Favorites;