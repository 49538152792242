import React from 'react';
import NavTabs from './components/nav-tabs';
import CustomTable from './components/custom-table';
import { removePtag, parseAsHtml } from './components/util';

class Holidays extends React.Component {
    _isMounted = false;
    constructor(props) {
        super(props);
        this.state = {
            api: `/api/contents/page?url=/holiday_list-ifsc`,
            tradingHoursTable: [],
            tradingNote:[],
        }
    }

    componentDidMount() {
        try {
            this._isMounted = true;
            //Holiday table from CMS
            this.loadHolidayTradingData(this.state.api);
        } catch (e) {
            console.log(e);
        }

    }
    loadHolidayTradingData(api) {
        fetch(api)
            .then(resp => resp.json())
            .then((data) => {
                let tradingHoursTable = [];
                let tradingNote = []
                data.content.field_components.forEach((cont, idx) => {


                    Object.keys(cont).forEach((ke, jdx) => {
                        switch (ke) {
                            case "field_subtitle":
                                tradingHoursTable.push(<div className="col-md-12">
                                <h2 className="page-title pl-0" key={`${idx}${jdx}`}>{removePtag(cont[ke])}</h2>
                                </div>)
                                break;
                            case "field_table_contents":

                                tradingHoursTable.push(
                                    <div className="col-md-12" key={`sc${idx}${jdx}`} >
                                        <div key={`sc_${idx}${jdx}`} className="static-table" id="tradinghoursHoliday">
                                            {parseAsHtml(cont[ke])}
                                        </div></div>
                                    // <CustomTable tableid="tradinghoursHoliday"
                                    //     key={`sc${idx}${jdx}`}
                                    //     data-columns={cont[ke][0]}
                                    //     data-rows={cont[ke].slice(1, cont[ke].length - 1)}
                                    // />
                                )
                                break;
                            // case "field_text":
                            //     if (cont.file_url) {
                            //         tradingHoursTable.push(
                            //             <div className="col-md-12 mt-2 mb-2" key={`sc${idx}${jdx}`}><a className="download-link" target="_blank" rel="noopener noreferrer" href={cont.file_url} key={`sc${idx}${jdx}`} title={cont[ke]} id={"link" + cont.file_url.substring(cont.file_url.lastIndexOf('/') + 1)} aria-label={cont.file_url.substring(cont.file_url.lastIndexOf('/') + 1)}>{getDownloadIcon((cont.file_url), cont.file_url.substring(cont.file_url.lastIndexOf('/') + 1))}{cont[ke]}</a></div>
                            //         );
                            //     } else {
                            //         tradingHoursTable.push(<p key={`sc${idx}${jdx}`}>{cont[ke]}</p>);
                            //     }
                            //     break;
                            case "field_para":
                                if(idx === 0){
                                    tradingNote.push(<div className="col-md-12" key={`sc${idx}${jdx}`} ><div key={`sc_${idx}${jdx}`} className="static-para">{parseAsHtml(cont[ke])}</div></div>)
                                }
                                else{
                                    tradingHoursTable.push(<div className="col-md-12" key={`sc${idx}${jdx}`} ><div key={`sc_${idx}${jdx}`} className="static-para">{parseAsHtml(cont[ke])}</div></div>);

                                }
                                break;
                            case "field_event_image":
                                tradingHoursTable.push(<div
                                    key={`sc${idx}${jdx}`}
                                >
                                    <img className="img-fluid" src={cont[ke][0].url} alt={cont[ke][0].alt} />
                                </div>)
                                break;
                            case "field_text_list":
                                if (cont.file_url) {
                                    tradingHoursTable.push(
                                        <div className="col-md-12 circle-bullets" key={`sc${idx}${jdx}`}>
                                            <a target="_blank" rel="noopener noreferrer" href={cont.file_url} key={`sc${idx}${jdx}`} title={cont[ke]}>
                                                {cont[ke]}</a></div>
                                    );
                                } else {
                                    tradingHoursTable.push(<p key={`sc${idx}${jdx}`}>{cont[ke]}</p>);
                                }
                                break;
                           
                            default:
                                break;
                            // Do Nothing;
                        };
                    });

                });
                this.setState({ tradingHoursTable,tradingNote })
            })
            .catch((e) => {

                console.log("error in loading holidays", e)

            });
    }
    render() {
        return (

            <div className="container-fluid">
                <NavTabs tabs={[
                    {
                        title: "Trading Holidays", component:
                            <>
                            {this.state.tradingNote}
                                {/* Trading holiday table from Database */}
                                <CustomTable tableid="tradingholiday" data-api={`/api/trading-holidays`} />

                                {/* Trading hours table from CMS */}
                                {this.state.tradingHoursTable}
                            </>
                    },
                    //Clearing holiday table from Database
                    { title: "Clearing Holidays", component: <CustomTable tableid="clearingholiday" data-api={`/api/clearing-holidays`} /> }
                ]} />
            </div>
           
        );
    }
}

export default Holidays;