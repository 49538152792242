import React from "react";
import { Button } from "@material-ui/core";
import calendar from "../../public/images/calendar.png";
import { parseAsHtml, removePtag } from "./util";
import AlertDialog from "./alertDialog";

class NotificationContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: props.data,
      alert: props.data.field_alert_severity,
      warning: true,
      showComponent: false,
    };
    this._onButtonClick = this._onButtonClick.bind(this);
    this.setWarning = this.setWarning.bind(this);
    this.toggleShowHide = this.toggleShowHide.bind(this);
  }

  toggleShowHide = (boolean) => {
    this.setState({ showComponent: boolean });
  };

  _onButtonClick() {
    this.setState({
      showComponent: true,
    });
  }

  componentDidMount() {
    try {
      this.setWarning();
    } catch (e) {
      console.log(e);
    }
  }
  setWarning() {
    if (this.state.alert === "Low") {
      this.setState({ warning: false });
    }
  }

  render() {
    return (
      <div id="notification-container">
        <div className={this.state.warning ? "boxRed" : "boxGreen"}>
          {this.state.warning ? (
            <div className="contTxt">
              {parseAsHtml(this.state.data.field_alert_message_text)}
            </div>
          ) : (
            <div className="contTxt">
              {/* <p className="strong">Congratulations !!</p> */}
              {parseAsHtml(this.state.data.field_alert_message_text)}
            </div>
          )}
          <div className="row belowTextDiv">
            <div className="col-md-12 ">
              <Button
                className="viewMore"
                size="small"
                onClick={this._onButtonClick}
              >
                View More
              </Button>
              {this.state.showComponent ? (
                <AlertDialog
                  data={this.state.data.field_alert_message_text}
                  warning={this.state.warning}
                  toggle={this.toggleShowHide}
                  type={"menu"}
                />
              ) : null}

              <div className="notifytime">
                <img src={calendar} alt="" width="14" height="14" />{" "}
                <span className="font-italic">
                  {this.state.data.field_alert_message_date}{" "}
                </span>{" "}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default NotificationContainer;
