import React, { Component } from 'react'
import { removePtag, parseAsHtml, externalLinkDisclaimer } from './util';

class Notes extends Component {
    _isMounted = false;
    constructor(props) {
        super(props);
        this.state = {
            notesData: this.props.notesData,
            heading: '',
            content: '',
            url:[],
        };
    }

    componentDidMount() {
        try {
            this._isMounted = true;
             this.displayNotes(this.state.notesData);
        } catch (e) {
            console.log(e);
        }
    }
    componentDidUpdate(prevProps) {
        if (this.props.notesData !== prevProps.notesData) {
            this.setState({
                notesData: this.props.notesData
            }, () => {
                this.displayNotes(this.state.notesData);
            })
        }
    }
    displayNotes(notes) {
        
        let heading = '';
        let content = '';
        let url = [];
        notes.forEach((cont, idx) => {
            Object.keys(cont).forEach((ke, jdx) => {                
                switch (ke) {
                    case "field_title":
                        heading = cont[ke];
                        break;
                    case "field_para":
                        content = cont[ke];
                        break;
                    case "uri":
                        url.push(<a target="_blank" rel="noopener noreferrer" onClick={externalLinkDisclaimer}
                        href={cont[ke] || ""}> 
                        {cont.title || ""}</a>);
                        break;
                    default:
                        break;
                }
            })
        })
        this.setState({ content, heading, url });
    }
    render() {
        return (
            <div className="notes-section">
                {this.state.heading !=="" ? <h2 className="notes-title">{removePtag(this.state.heading)}</h2> : ""}
                {this.state.content !=="" ? <div className="static-para">{parseAsHtml(this.state.content)}
                {this.state.url}
                </div> : ""}
            </div>
        )
    }
}

export default Notes