import React from "react";
import CustomTable from "./components/custom-table";
//import env from "../config";
import LesFilters from "./components/les-filters";
import { parseAsHtml } from "./components/util";

class LiquidityEnhancementStatistics extends React.Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      api: `/api/contents/page?url=/markets/about-our-products/equity-derivatives/liquidity-enhancement-scheme-statistics`,
      filteredTable: [],
      filteredRow: [],
      nonfilteredTable: [],
      initialData: [],
      headerArray: [],
    };
    this.filterTable = this.filterTable.bind(this);
    this.getFilters = this.getFilters.bind(this);
    this.reset = this.reset.bind(this);
  }

  componentDidMount() {
    try {
      this._isMounted = true;
      this.loadTable(this.state.api);
    } catch (e) {
      console.log(e);
    }
  }
  loadColumnNames() {
    var headerArray = [];
    var thead = document.querySelector("table thead");
    var headers = thead.children;
    for (var k = 0; k < headers.length; k++) {
      var headerFields = headers[k].children;
      var singleColArray = [];
      for (var m = 0; m < headerFields.length; m++) {
        singleColArray.push(headerFields[m].innerHTML);
      }
      headerArray.push(singleColArray);
    }
    this.setState({ headerArray });
  }

  loadRows() {
    var rowArray = [];
    var tbody = document.querySelector("table tbody");
    var rows = tbody.children;
    for (var i = 0; i < rows.length; i++) {
      var fields = rows[i].children;
      var singlerowArray = [];
      for (var j = 0; j < fields.length; j++) {
        singlerowArray.push(fields[j].innerHTML);
      }
      rowArray.push(singlerowArray);
    }
    return rowArray;
  }

  loadTable(api) {
    fetch(api)
      .then((resp) => resp.json())
      .then((resp) => {
        const content = (resp.content && resp.content.field_components) || [];
        let data = [];
        let nonfilteredTable = [];
        content.forEach((cont, idx) => {
          Object.keys(cont).forEach((ke, jdx) => {
            if (ke === "field_table_contents") {
              nonfilteredTable = parseAsHtml(cont[ke]);
            }
          });
        });
        this.setState({ nonfilteredTable, filteredRow: data, filteredTable: [] }, () => {
          let initialData = this.loadRows();
          this.loadColumnNames();
          this.setState({ initialData });
        });
      })
      .catch((e) => {
        console.log("error in liquidity enhancement scheme", e);
      });
  }

  getFilters(currentMonth, currentYear) {
    let filteredRow = this.state.initialData;

    if (currentMonth !== "") {
      filteredRow = filteredRow.filter((item) => item[0].includes(currentMonth));
    }
    if (currentYear !== "") {
      filteredRow = filteredRow.filter((item) => item[0].includes(currentYear));
    }
    this.setState({ filteredRow }, () => {
      this.filterTable(filteredRow, this.state.headerArray[0]);
    });
  }

  filterTable(data, cols) {
    let filteredTable = [];
    filteredTable = [<CustomTable key={"lestable"} data-columns={cols} data-rows={data} />];
    this.setState({ filteredTable: filteredTable, nonfilteredTable: [] });
  }

  reset() {
    this.loadTable(this.state.api);
  }

  render() {
    return (
      <div className="container-fluid les-section static-table" id="lestable">
        <LesFilters callback={this.getFilters} resetcallback={this.reset} />
        <div className="row">
          {this.state.nonfilteredTable !== [] ? (
            <div className="col-md-12 m-0">{this.state.nonfilteredTable}</div>
          ) : (
            <></>
          )}
          {this.state.filteredTable !== [] ? this.state.filteredTable : <></>}
        </div>
      </div>
    );
  }
}

export default LiquidityEnhancementStatistics;
