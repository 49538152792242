import React from 'react';
import { CarouselProvider, Slider, Slide, ButtonBack, ButtonNext, DotGroup } from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import CircularCard from './circular-card';
import { withStyles } from "@material-ui/core/styles";

const styles = theme => ({

    mobilecircularslider: {
        [theme.breakpoints.down('xs')]: {
            "& button:nth-last-child(-n+3)": {
                display: `inline-block !important`,
            }
        },
    },
    circularslider: {
        "& button:nth-last-child(-n+3)": {
            display: `none`,
        }
    },
});

class HomeCircularCards extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            cardData: [],
            CircData: props.CircData,
            // visibleSlide: window.innerWidth <=576 ? 1 : 4,
            ariaproperties: "",
            visibleSlide: 4,

        }
    };
    componentDidMount() {
        try {
            this._isMounted = true;
            this.loadHomeCirculars();
            window.addEventListener("resize", this.resize.bind(this));
            this.resize();
        } catch (e) {
            console.log(e);
        }
    }
    resize() {

        let currentmobileScreen = window.innerWidth;
        let visibleSlide = 4;
        if (currentmobileScreen <= 576) {
            visibleSlide = 1;

        }
        else if(currentmobileScreen  <=768) {
            visibleSlide = 2;
        } 
        else if(currentmobileScreen <=800) {
            visibleSlide = 4;
        }
        else if(currentmobileScreen <=1024) {
            visibleSlide = 4;
        } 
        else if(currentmobileScreen <=1366) {
            visibleSlide = 4;
        }
        this.setState({ visibleSlide });
    }
    componentWillUnmount() {
        window.removeEventListener("resize", this.resize.bind(this));
    }
    loadHomeCirculars() {

        fetch(`/api/circulars-niccl`)
            .then(resp => resp.json())
            .then((data) => {
                let cardList = [];
                let cards = [];
                let ariaproperties = "";
                //show latest 5 circulars on homepage
                cards = data.length > 0 ? data.splice(0, 5) : data
                cards.data.forEach((item, idx) => {

                    //accessibility - adding aria property dynamically using index
                    ariaproperties += "circular-slider-" + idx + " ";
                    cardList.push({
                        TITLE: item.CIRC_COMPANY,
                        SUBJECT: item.CIRC_SUBJECT,
                        DATE: item.CIRC_DATE,
                        LINK: item.CIRC_FILELINK,
                        CIRCULAR_NO: item.CIRC_NO
                    })
                })
                this.setState({
                    cardData: cardList,
                    ariaproperties: ariaproperties
                });
            })
            .catch((e) => { console.log("error in loading home circulars", e) })

    }

    render() {
        const { classes } = this.props;
        console.log("cardData",this.state.cardData)
        return (
            <div className="container-fluid pt-4 pb-4 light-blue-bg">

                <div className="title-row">
                    <span className="title"><h2>Circulars
                        </h2></span>
                    <span className="btn-row">
                        <a target="_blank" rel="noopener noreferrer" href="/downloads/circulars" aria-label="View All Circulars"
                        className="btn btn-orange">View all</a>
                    </span>
                    <div className="clear"></div>
                </div>
                {/* <div className="row align-items-center">
                    <div className="col-lg-3">
                        <h2>Circulars
                        </h2>
                    </div>
                    <div className="col-lg-9">
                        <a target="_blank" rel="noopener noreferrer" href="/resources/circulars" aria-label="View All Circulars" 
                        className="btn btn-orange pr-4 pl-4 float-right">View all</a>
                    </div>
                </div> */}
                <div className="row align-items-center">
                    <div className="col-lg-12">
                        <section id="slider3" className="pb-4">

                            <div className="large-12 mt-3 columns p-0">
                                {<CarouselProvider
                                    naturalSlideWidth={100}
                                    naturalSlideHeight={83}
                                    totalSlides={this.state.cardData.length}
                                    visibleSlides={this.state.visibleSlide}
                                >
                                    <Slider aria-label="latest-circulars" className="mb-3" aria-owns={this.state.ariaproperties}>
                                        {this.state.cardData.length > 0 && this.state.cardData.map((event, index) => {
                                            return <Slide id={"circular-slider-" + index} className="item" index={index} key={index}>
                                                <CircularCard alignCard="gridview" card={event}></CircularCard>
                                            </Slide>
                                        })
                                        }
                                    </Slider>
                                    <ButtonBack className="arrow-btn"><ArrowBackIosIcon /></ButtonBack>
                                    <ButtonNext className="arrow-btn"><ArrowForwardIosIcon /></ButtonNext>
                                    <DotGroup className={"mr-4 " + (this.state.visibleSlide === 4 ? classes.circularslider : classes.mobilecircularslider)} showAsSelectedForCurrentSlideOnly={true} />
                                </CarouselProvider>
                                }
                            </div>

                        </section>
                    </div>
                </div>
            </div>

        );
    }
}
export default withStyles(styles, { withTheme: true })(HomeCircularCards);
