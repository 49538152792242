import React, { useState, useEffect } from "react";
import Pagination from "@material-ui/lab/Pagination";
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import TableRow from '@material-ui/core/TableRow';
import { TableFooter, TablePagination } from "@material-ui/core";

TablePaginationNav.propTypes = {
    count: PropTypes.number.isRequired,
    onChangePage: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
};
const useStyles1 = makeStyles(theme => ({
    root: {
        flexShrink: 0,
        position: 'absolute',
        right: '0',
    },
}));
function TablePaginationNav(props) {
    const classes = useStyles1();
    const { count, page, rowsPerPage, onChangePage } = props;
    const totalcount = Math.ceil(count / rowsPerPage);
    const [currPage, setCurrPage] = useState(page + 1);

    useEffect(() => {
        setCurrPage(page+1)
      }, [page]);
    
    function handleChange(event, value) {
        if (value < currPage) {
            onChangePage(event, page - (currPage - value));
            setCurrPage(currPage - (currPage - value));
        }
        else {
            onChangePage(event, page + (value - currPage));
            setCurrPage(currPage + (value - currPage));
        }
    }

    return (
        <div className={classes.root + " pagination-container"}>
            <Pagination page={currPage} color="primary" count={totalcount} onChange={handleChange} variant="outlined" />
        </div>
    );
}
function CustomMuiTablePagination(props) {
    
    const { 
        count,
        page,
        rowsPerPage,
        changeRowsPerPage,
        changePage
     } = props;

    return (
        <TableFooter className="tfoot-pagination">
        <TableRow>
            <TablePagination className="datatable-pagination"
                count={count}
                rowsPerPage={rowsPerPage}
                page={page}
                labelDisplayedRows={({ from, to, count }) => `Showing entries ${from} to ${to} of ${count} entries`}
                onChangePage={(_, page) => changePage(page)}
                onChangeRowsPerPage={event => changeRowsPerPage(event.target.value)}
                rowsPerPageOptions={[10, 20, 30]}
                labelRowsPerPage={''}
                ActionsComponent={TablePaginationNav}
            />
        </TableRow>
    </TableFooter>
    );
}
export default CustomMuiTablePagination;

