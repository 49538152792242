import React, { useState, useEffect } from 'react';

function FlashButton() {

    const [settlementFile, setSettlementFile] = useState(null);
    const [indexedCombinedOIFile, setindexedCombinedOIFile] = useState(null);
    const dailySettlementApi = 'api/daily-settlement-prices';
    const indexedCombinedOIApi = 'api/index-combined-oi';


    useEffect(() => {
        fetch_daily_settlement_data();
        fetch_index_combineOI_data();
    }, []);

    const fetch_daily_settlement_data = async () => {
        try {
            const result = await fetch(dailySettlementApi);
            const resp = await result.json();
            setSettlementFile(resp);
        } catch (error) {
            console.log(error);
        }
    };

    const fetch_index_combineOI_data = async () => {
        try {
            const result1 = await fetch(indexedCombinedOIApi);
            const resp1 = await result1.json();
            setindexedCombinedOIFile(resp1);
        } catch (error) {
            console.log(error);
        }
    };

    return (

        <div className='row'>
            <div className='settlement-btns d-flex flex-row justify-content-center'>
                <a className='btn flash-buttons col-4' href={settlementFile ? settlementFile[0].FILE_NAME : '#'}>
                    Daily Settlement Price INDEX
                    <div className='small text-align-center'>
                        Published on {settlementFile ? settlementFile[0].FILE_DISPLAY_NAME : '#'}
                    </div>
                </a>
                <div className='col-1'></div>
                <a className='btn flash-buttons col-4' href={indexedCombinedOIFile ? indexedCombinedOIFile[0].FILE_NAME : '#'} >
                    Index Combined OI
                    <div className='small text-align-center'>
                        Published on {indexedCombinedOIFile ? indexedCombinedOIFile[0].FILE_DISPLAY_NAME : '#'}
                    </div>
                </a>
            </div>
        </div>
    )
}

export default FlashButton;
