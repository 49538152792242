import React from 'react';
import MUIDataTable from "mui-datatables";
import NavTabs from './nav-tabs';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { ReactComponent as DownloadIcon } from '../../public/images/csv-icon.svg';
import CustomToolbar, { Refresh } from "./custom-toolbar";
//import env from '../../config';
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TradeInformation from './trade-information';
import Graph from './graph';
import QuoteFilters from './quote-filters';
import { convertPrice, convertNumberPrecision } from './util';
import Tooltip from '@material-ui/core/Tooltip';
// import img from '../../public/images/expand-plus.svg'
import CustomMuiTablePagination from './custom-muitable-pagination';

class AccordianDataTable extends React.Component {
    constructor(props) {
        super(props);
        this.wrapper = React.createRef();
        this.state = {
            api: props["data-api"],
            timestampApi: `/api/getquotes/timestamp`,
            tabletimestamp: '',
            symbol: props.symbol,
            otherCol: [],
            otherData: [],
            columns: [],
            data: [],
            underlyingval: [],
            expiryval: [],
            optiontypeval: [],
            strikepriceval: [],
            instrumentval: [],
            instrumselected: "All",
            selected: "All",
            options: {
                filter: false,
                selectableRows: 'none',
                expandableRowsHeader: false,
                // filter: false,
                print: false,
                search: false,
                download: false,
                viewColumns: false,

                rowsExpanded: [],
                elevation: 0,
                responsive: 'standard',
                rowsPerPage: 10,
                // tableBodyMaxHeight: '500px',
                customToolbar: () => {
                    return (
                        <CustomToolbar callback={this.refresh} />
                    );
                },
                expandableRows: true,
                renderExpandableRow: (rowData, rowMeta) => {
                    const colSpan = rowData.length + 1;
                    return <TableRow className="expandable-rows">
                        <TableCell colSpan={colSpan} className="expandable-cell">
                            <div className="container-fluid">
                                <NavTabs tabs={[
                                    {
                                        title: "Trade Information", component:
                                            <div className="container-fluid">
                                                <div className="row  mt-3 mb-3">
                                                    <div className="col-md-2">
                                                        <div className="contract-param-card">
                                                            <div className="row">
                                                                <div className="col-md-12 pb-1"> <span>Symbol</span></div>
                                                                <div className="col-md-12">  <b>{this.state.symbol}</b></div>
                                                            </div>

                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="contract-param-card">
                                                            <div className="row">
                                                                <div className="col-md-12 pb-1"> <span>Underlying Value</span></div>
                                                                {/* {console.log('ttttttt',this.state.underlyingval[rowMeta.rowIndex],this.state.underlyingval,typeof this.state.underlyingval[rowMeta.rowIndex] !== "undefined")  } */}
                                                                <div className="col-md-12">  <b>{ typeof this.state.underlyingval[rowMeta.rowIndex] !== "undefined" ?  convertNumberPrecision(this.state.underlyingval[rowMeta.rowIndex]) :''}</b></div>
                                                            </div>

                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="contract-param-card">
                                                            <div className="row">
                                                                <div className="col-md-12 pb-1"> <span>Volume Freeze Quantity</span></div>
                                                                <div className="col-md-12">  <b>{rowData[13]}</b></div>
                                                            </div>

                                                        </div>
                                                    </div>
                                                    <div className="col-md-2">
                                                        <div className="contract-param-card">
                                                            <div className="row">
                                                                <div className="col-md-12 pb-1"> <span>Change</span></div>
                                                              {
                                                                 
                                                                 rowData[10].props.className.includes("green") ?  <div className="col-md-12 green "><b>{rowData[10]} ({rowData[11]}%)</b></div>
                                                              :<div className="col-md-12  red"><b>{ rowData[10]} ({rowData[11]}%)</b></div>}
                                                               
                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="mt-0 col-md-12 static_content">
                                                    <h2 className="get-quotes">Market Depth- Order Book</h2>
                                                    </div>
                                                </div>
                                                <div className="row">

                                                    <div className="col-md-4">
                                                        {/*   <TradeInformation type={"order"} data-api={this.state.api + '&instrumenttype=' + rowData[0].split(' ').join('%20') + '&type=order'} */}
                                                        <TradeInformation type={"order"} rowIndex={rowMeta.rowIndex} data-api={this.state.api + '&type=order'}
                                                        // data-array={[
                                                        //     { qty: 25, bid: 530, ask: 500, qtys: 25 },
                                                        //     { qty: 25, bid: 530, ask: 500, qtys: 25 },
                                                        //     { qty: 25, bid: 530, ask: 500, qtys: 25 },
                                                        //     { qty: 25, bid: 530, ask: 500, qtys: 25 },
                                                        //     { qty: 25, bid: 530, ask: 500, qtys: 25 },
                                                        //     { qty: 25, bid: 530, ask: 500, qtys: 25 },
                                                        // ]}

                                                        />
                                                    </div>

                                                    <div className="col-md-4">
                                                        {/* <TradeInformation type={"trade"} data-api={this.state.api + '&instrumenttype=' + rowData[0].split(' ').join('%20') + '&type=trade'} /> */}
                                                        <TradeInformation type={"trade"} rowIndex={rowMeta.rowIndex} data-api={this.state.api + '&type=trade'} />

                                                    </div>
                                                    <div className="col-md-4">
                                                        <TradeInformation type={"coc"} rowIndex={rowMeta.rowIndex} data-api={this.state.api + '&type=coc'} />
                                                    </div>
                                                </div>
                                            </div>
                                    },
                                    {
                                        title: "Intraday Chart", component:
                                            <div className="container ">
                                                <div className="row">
                                                    <div className="col-md-12">

                                                        <Graph points={2} graphName="Intra Day" chartType="line" 
                                                        api={`/api/getquotes/intraday?instrument=${this.state.instrumentval[rowMeta.rowIndex]}`+
                                                        `&symbol=${this.state.symbol}&expiry=${this.state.expiryval[rowMeta.rowIndex]}`+
                                                        `&strikeprice=${this.state.strikepriceval.length && this.state.strikepriceval[rowMeta.rowIndex]
                                                            > 0 ? this.state.strikepriceval[rowMeta.rowIndex] : 
                                                        (this.state.strikepriceval[rowMeta.rowIndex]==="-" ? 0 : this.state.strikepriceval[rowMeta.rowIndex])}`+
                                                        `&optiontype=${this.state.optiontypeval.length > 0 && this.state.optiontypeval[rowMeta.rowIndex]
                                                        ? this.state.optiontypeval[rowMeta.rowIndex].replace("-", "XX") : "-"}`} />
                                                    </div>
                                                </div>
                                            </div>
                                    },
                                    // {
                                    //     title: "Volume and OI", component:
                                    //         <div className="container">
                                    //             <div className="row">
                                    //                 <div className="col-md-12">
                                    //                     <Graph points={3} graphName="Time" api={`/api/broad-market-indice/graph?symbol=NIFTY%2050`}
                                    //                         data1={{ type: "line", name: 'Vol', color: '#4edafe', data: [0, 2, 10, 6, 2, 10, 14], zIndex: 1 }}
                                    //                         xaxis={['19 Mar', '20 Mar', '21 Mar', '22 Mar', '23 Mar', '24 Mar', '25 Mar']}
                                    //                         data2={{ type: "bar", name: 'OI', color: '#2f7ed8', data: [20, 50, 60, 10, 80, 90, 110], yAxis: 1 }}
                                    //                     />
                                    //                 </div>
                                    //             </div>
                                    //         </div>
                                    // },
                                ]} />
                            </div>
                        </TableCell>
                    </TableRow>
                },
            },
            components: {
                icons: {
                    DownloadIcon,
                }
            },
        };

        this.refresh = this.refresh.bind(this);
        this.refreshData = this.refreshData.bind(this);
    };



    getMuiTheme = () => createMuiTheme({
        overrides: {
            MUIDataTableToolbar: {
                root: {
                    textAlign: "left",
                },
                actions: {
                    display: "flex",
                    flex: "initial",
                    // move all icons to the right
                    // "& > span, & > button": {
                    //   order: 99
                    // },
                    // target the custom toolbar icon
                    "& > span:last-child, & > button:last-child": {
                        order: 1
                    },
                    // target any icon
                    "& > span:nth-child(1), & > button:nth-child(1)": {
                        order: 3,
                        border: '1px solid #e86e25',
                        borderRadius: '50%',
                        padding: '5px',
                        height: '40px',
                        width: '40px',
                        marginTop: '2%',
                    },
                    "& > span:nth-child(2), & > button:nth-child(2)": {
                        order: 2
                    }
                }
            },
            MuiTypography: {
                h6: {
                    fontSize: "14px",

                }
            },
            MuiToolbar: {
                root: {
                    paddingLeft: "0.5rem !important",
                    paddingRight: "0.5rem !important",
                }
            },
            MUIDataTable: {
                responsiveStacked: {
                    boxShadow: "0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)",
                },
            },
            TablePaginationActions: {
                order: 1
            },
            MUIDataTableHeadCell: {
                contentWrapper:{
                    display:`unset`
                },
                data:
                {
                    width: "max-content",
                },
                sortLabelRoot:
                {
                    display:"none",
                }
            },
            MuiTableCell:{
                alignLeft:{
                    "& button div:first-child": {
                        textAlign:`left !important`
                    }
                },
                alignRight:{
                    "& button div:first-child": {
                        textAlign:`right !important`
                    }
                }
            },
            MUIDataTableSelectCell:{
                headerCell:{
                    width:`2%`,
                }
            }
            // MUIDataTableHeadRow:{
            //     root:{
            //         "& rightAlignedHeader":{
            //             fontSize:`20px`,
            //         }
            //     }
            // }

        }
    })

    componentDidMount() {
        try {
            this._isMounted = true
            this.loadData(this.state.api);
            this.loadTimestamp(this.state.timestampApi);
        } catch (e) {
            console.log(e);
        }
    }

    loadTimestamp(api) {
        fetch(api)
            .then(resp => resp.json())
            .then((item) => {
                this.setState({
                    tabletimestamp: item.data,
                })
            })
            .catch((e) => { console.log("error in loading timestamp in get quotes", e) })
    }

    loadData(api) {
       
        //const offsetWidth = this.wrapper.current.offsetWidth || 1100;
        fetch(api)
            .then(resp => resp.json())
            .then((data) => {
                let tempUnderly = [];
                for (let i = 0; i < data.data.length; i++) {
                    tempUnderly.push(data.data[i].UNDERLYINGVAL);
                }
                this.setState({ underlyingval: tempUnderly });

                let tempinsttype = [];
                for (let i = 0; i < data.data.length; i++) {
                    tempinsttype.push(data.data[i].INSTRUMENTTYPE);
                }
                
                this.setState({ instrumentval: tempinsttype });

                let tempexpiry = [];
                for (let i = 0; i < data.data.length; i++) {
                    tempexpiry.push(data.data[i].EXPIRY);
                }
                
                this.setState({ expiryval: tempexpiry });

                let tempstrikeprice = [];
                for (let i = 0; i < data.data.length; i++) {
                    tempstrikeprice.push(data.data[i].STRIKEPRICE);
                }
               
                this.setState({ strikepriceval: tempstrikeprice });

                let tempoptiontypeval = [];
                for (let i = 0; i < data.data.length; i++) {
                    tempoptiontypeval.push(data.data[i].OPTIONTYPE);
                }
               
                this.setState({ optiontypeval: tempoptiontypeval });

                data.api = api
                data.columns = data.columns.map((col) => {
                    //col.width = offsetWidth * (col.width / 100);
                    col = {
                        label: col.tooltip || col.headerName,
                        name: col.field,
                        width:col.width || 'auto',
                        headerName: col.headerName,
                        colorIndicator: col.colorIndicator,
                        bold: col.bold,
                        type: col.type,
                        subHeader: col.subHeader,
                        precision:col.precision,
                        decimal:col.decimal,
                        options: {
                            filterList: [],
                            customHeadRender: ({ index, ...column }, sortColumn, sortOrder) => {
                                
                                return (
                                    <Tooltip key={index} title={col.label} placement="bottom">
                                        <TableCell className={
                                            "quotes-table-head " + (col.name === sortOrder.name && column.sortDirection !== null ? "sorted-col" : "unsorted-col")}
                                            align={col.type === "number" ? "right" : (col.type === "dateTime" ? "center" : "left")}
                                            style={col.name==="OPTIONTYPE" ? {paddingLeft:`10px`,width: col.width} : {width: col.width}}
                                            // style={{ width: col.width, col.name==="OPTIONTYPE" ? paddingLeft:`10px` : ""   }}
                                            onClick={() => sortColumn(index)} key={index}>
                                            <span className={"preline-span " + (col.name === sortOrder.name && column.sortDirection !== null ? "sortactive" : "")}>
                                                {col.headerName.split(" ")[0]}<br />
                                                {col.headerName.split(" ")[1]}
                                                {col.subHeader ? <span className="dg-table-sub-header" >
                                                    {col.subHeader && ` ${col.subHeader}`}
                                                </span> : ""}
                                            </span>
                                            <span className="sorting-icons" aria-label={"Sort By " + col.label}>
                                                {col.name === sortOrder.name && column.sortDirection !== null ?
                                                    (sortOrder.direction === "asc" ? <i className="fa fa-arrow-up"></i>
                                                        : <i className="fa fa-arrow-down"></i>)
                                                    :
                                                    <i className="fa fa-sort"></i>
                                                }
                                            </span>
        
                                        </TableCell>
                                    </Tooltip>
                                );
                            },
                            // customHeadLabelRender: (params) => {
                            // return (<>
                                
                            //         <span className="preline-span">
                            //          {/* {col.headerName} */}
                                    
                            //             {col.headerName.split(" ")[0]}<br/>
                            //             {col.headerName.split(" ")[1]}
                            //           {/* <br /> */}
                            //             {col.subHeader ? <span className="dg-table-sub-header">
                            //         {col.subHeader && ` ${col.subHeader}`}
                            //     </span> : ""}
                                
                            //         </span>
                                  
                            //       </>
                            //     )
                            // },
                            customBodyRender: (data, dataIndex, rowIndex) => {
                                let precision = 2;
                                if (col.name === "TURNOVER") {
                                   
                                        data = data / 1000;
                                }
                                if(col.precision){
                                    // console.log("yes", dataIndex.rowData[0].includes("CUR"))
                                    if(this.state.symbol === "SILVER"){
                                        
                                        precision=3;
                                    }
                                    else if((dataIndex.rowData[0]).includes("Cur") && this.state.symbol!== "INRUSD")
                                    {
                                        
                                        precision=4;
                                    }
                                    else {
                                        precision=2;
                                    }
                                }
                                else {
                                    precision=2;
                                }
        
        
                                return (
                                    <span className={col.colorIndicator ? data >= 0 ? "green bold-columns" : "red bold-columns" : col.bold ? "bold-columns" : ""}>
                                        {/* {typeof (data) === "number" ? convertPrice(data) : data} */}
                                        {typeof (data) === "number" ? (col.decimal ? convertNumberPrecision(data,precision) : convertPrice(data)) : data}
                                    </span>
                                )
                            },
                            setCellProps: () => ({
                                align: col.type === "number" ? "right" :(col.type === "dateTime" ? "center"  : "left"),
                                style: col.name==="OPTIONTYPE" ? {paddingLeft:`10px`} : {}  
                            }),
                            // setCellHeaderProps: () => ({ align: col.type === "number" ? "right" :(col.type === "dateTime" ? "center"  : "left"),
                            //     style:col.name==="OPTIONTYPE" ? {paddingLeft:`19px`,width:col.width}:
                            //      {paddingLeft:`4px`,width:col.width}  })
                        },
                    }
                    return col;
                });
                this.setState(data);

            })
            .catch((e) => { console.log("error in loading get quotes data", e) })
    }

    refresh() {
        if (this._isMounted) {
            this.setState({ data: [] });
            this.loadData(this.state.api);
        }
    }

    refreshData(x, y, z, a) {
        
        if (x.includes('Active Contracts') ) {
            //let insttype = (x && !x.includes("null")) ? x.substr(0, 3) : '';
            let insttype = '';
            //console.log("strikeprice val",x, "nulll", insttype)
            let expdate = (y && y!=="null") ? y : '';
            // let optType = (z && z!=="null") ? z : '';
            // let stkPrice = (a && a!=="null") ? a : '';

            var api = `/api/getquotes?symbol=${this.state.symbol}&instrumenttype=${insttype}&expiry=${expdate}`
            this.setState({ api: api });
            this.loadData(api);
        }
        // else if (x.includes("Select Instrument")){
        //     alert('akdsmf')
        //     var api = `/api/getquotes?symbol=${this.state.symbol}&instrumenttype=${x.substr(0,3)}`
        //     console.log('abcde',api)
        //             this.setState({api: api});
        //     this.loadData(api);
        // }
        else if(x.includes('Futures')){
            let insttype = (x && x!=="null") ? x : '';
            let expdate = (y && y!=="null") ? y : '';
            //console.log("strikeprice val",expdate, y === "null", y)
            let api = `/api/getquotes?symbol=${this.state.symbol}&instrumenttype=${insttype}&expiry=${expdate}&inst=${insttype}`;
            
            this.setState({ api: api });
            this.loadData(api);
        }
        else {
            
            let insttype = (x && x!=="null") ? x : '';
            let expdate = (y && y!=="null") ? y : '';
            let optType = (z && z!=="null") ? z : '';
            let stkPrice = (a && a!=="null") ? a : '';
            //console.log("strikeprice val",expdate, y === "null", y)
            let api = `/api/getquotes?symbol=${this.state.symbol}&instrumenttype=${insttype}&expiry=${expdate}&optiontype=${optType}&strikeprice=${stkPrice}&inst=${insttype}`;
            
            this.setState({ api: api });
            this.loadData(api);
        }
    }
    render() {
        // const { classes } = this.props;
        return (
            <div ref={this.wrapper} className="col-md-12 px-0 deriv-mui-data-table  contrast-table">
                <QuoteFilters data={this.state.data} columns={this.state.columns} symbol={this.state.symbol}
                    refresh={this.refreshData}
                />
                <MuiThemeProvider theme={this.getMuiTheme()}>
                    <MUIDataTable className=" accordian-table"
                    
                        title={<div className="muidatatable-timestamp"> As on {this.state.tabletimestamp} IST (All price values in)
                    <Refresh refreshcallback={this.refresh} /></div>}
                        // title={`As on dd-mmm-yyy hh:mm:ss IST`}
                        columns={this.state.columns}
                        data={this.state.data}
                        options={{...this.state.options,
                            // onChangeRowsPerPage: (numberOfRows) => {
                            //     console.log("numberOfRows",numberOfRows)
                            //     this.setState({
                            //         rowsPerPage: numberOfRows
                            //     })
                            // },
                            page:0,
                            customFooter: (count,
                                page,
                                rowsPerPage,
                                changeRowsPerPage,
                                changePage) => {
                                return <CustomMuiTablePagination
                                    count={count} page={page}
                                    rowsPerPage={rowsPerPage}
                                    changeRowsPerPage={changeRowsPerPage}
                                    changePage={changePage} />
                            },}}
                        components={this.state.components}
                    />
                </MuiThemeProvider>
            </div>
        );
    };
}

export default AccordianDataTable;