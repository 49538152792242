import React from 'react';
import IssuerTable from './issuer-details-table';

class IssuerDetails extends React.Component {
    _isMounted = false;
    constructor(props) {
        super(props);
        this.state = {
            headerRef: props.headerRef,
            api: '/api/issuer-details',
        };
    }

    componentDidMount() {
        try {
            this._isMounted = true;

        } catch (e) {
            console.log(e);
        }
    }

    render() {
        return (
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-12 mt-3">
                        <IssuerTable api={this.state.api} />
                    </div>
                </div>
            </div>
        );
    }
}

export default IssuerDetails;