import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Collapse from "@material-ui/core/Collapse";
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import RemoveIcon from '@material-ui/icons/Remove';
import AddIcon from '@material-ui/icons/Add';

const styles = theme => ({
  selectedTab:{
    textAlign: `left`,
    '& .selected-tab-text':{
      '& span':{
        fontFamily: `Roboto-Regular`,
        color: `#C25414`,
      fontSize: `14px`,
      }
    },
		
  },
  listChild: {
    padding: `5px 12px 7px 12px;`
  },
  listComp: {
    padding: `0`,
    margin: `-2px`
  },
  listText: {
    paddingLeft:0,
    '& span':{
    padding: `3px 10px`,
    color: `#333333`,
    fontFamily: 'Roboto-Regular',
    fontSize:'14px'
    },
    marginTop: '0px',
    marginBottom: '0px',
  },
  listMainText: {
    fontSize: `0.8rem`,
    padding: `0 15px`,
    color: `#fff`,
    fontFamily: 'Roboto-Regular',
    marginTop: '0px',
    marginBottom: '0px',
  },
  // expand: {
  //   color: `#fff`,
  //   fontSize: `25px`,
  //   lineHeight: `1.2`,
  // },
  collapse: {
    color: `#fff`,
    fontSize: `25px`,
    lineHeight: `1.2`,
  },
  expandAdd: {
    color: `#E86E25`,
    fontSize: `25px`,
    lineHeight: `1.2`,
  },
  collapseRemove: {
    color: `#E86E25`,
    fontSize: `25px`,
    lineHeight: `1.2`,
  },
});

class LeftNavigationPane extends Component {

  constructor(props) {
    super(props);
    this.state = {
      ...props.menudata,
      open: {},
      submenuOpen: {},
      activeIndex: 0,
      cardHeaderClass: "card-header"
    };
  };

  static getUpdatedMenuState = (menus, pagekey) => {
    const open = {};
    const submenuOpen = {};
    if (menus && menus.length > 0 && pagekey) {
      pagekey = pagekey.toUpperCase();
      menus.forEach((mainMenu) => {
        const subCateg = mainMenu.items;
        subCateg.forEach((submenus, idx) => {
          if (submenus["items"]) {
            submenus["items"].forEach((submenuitem) => {
              if (submenuitem["key"].toUpperCase() === pagekey) {
                open[submenus["key"]] = true
              }
              submenuitem.items.forEach((subsubmenu)=>{
                if(subsubmenu["key"].toUpperCase() === pagekey)
                {
                  open[submenus["key"]] = true
                  open[submenuitem["key"]]=true
                 submenuOpen[submenuitem["key"]]=true
                }
                subsubmenu.items.forEach((level4menu)=>{
                  if(level4menu["key"].toUpperCase() === pagekey)
                  {
                    open[submenus["key"]] = true
                    open[submenuitem["key"]]=true
                    open[subsubmenu["key"]]=true
                   submenuOpen[submenuitem["key"]]=true
                   submenuOpen[subsubmenu["key"]]=true
                  }
                })
              })
            });
          }
        });
      });
    }
    return { open, submenuOpen };
  }

  handleClickSubMenu = (subVal) => {
    this.setState(state => ({ submenuOpen: { 
      ...this.state.submenuOpen,
      [subVal]: !state.submenuOpen[subVal] 
    } }));
  };

  handleCollapse = (val) => {
    this.setState(state => ({
      open: { [val]: !state.open[val] },
      cardHeaderClass: {
        [val]: state.open[val] ? "card-header" : "card-header card-header-after"
      }
    }))
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    // Do after state updated.
  }

  static getDerivedStateFromProps(props, state) {
    if (state.menus.length > 0) {
      return state;
    } else if (props.menudata && props.menudata.menus && props.menudata.menus.length > 0) {
      return {
        ...props.menudata,
        ...LeftNavigationPane.getUpdatedMenuState(props.menudata.menus, props.menudata.pagekey)
      }
    }
     else {
      return null;
    }
  }

  render() {
    const { classes } = this.props;

    const CollapsibleMenu = ({ item }) => {
      const selected = (item["key"] && item["key"].toUpperCase() === this.props.menudata.pagekey.toUpperCase());
      return <div className={` collapsed-tab ${(selected || this.state.submenuOpen[item["key"]] ) ? (classes.selectedTab +" collapsed-tab-selected submenu-open") : ""}`}>
        <List component="ul" disablePadding>
        <ListItem className={classes.submenu}>
          <ListItemText className={classes.listText + (selected ? " selected-tab-text" : "")}>
            <a className="collapsible-tab-link" href={item["url"]} title= {item["name"]}>
          {item["name"]}
          </a>
            </ListItemText>

          {this.state.submenuOpen[item["key"]] ?
            <RemoveIcon onClick={() => this.handleClickSubMenu(item["key"])} className={"sublevel-collapsible-menu "+classes.collapseRemove} /> :
            <AddIcon onClick={() => this.handleClickSubMenu(item["key"])} className={"sublevel-collapsible-menu "+classes.expandAdd} />}

        </ListItem>
        <Collapse className={classes.listChild} in={this.state.submenuOpen[item["key"]]} timeout="auto" unmountOnExit>
          {item["items"] !== undefined ?
            item["items"].map((menu, key) => { return <HasSubMenu key={`submenus${key}`} item={menu}/> }) : ""}
        </Collapse>
      </List>
      </div>
    };

    const SingleLevelMenu = ({ item, menukey }) => {
      const selected = (item["key"] && item["key"].toUpperCase() === this.props.menudata.pagekey.toUpperCase());
      return <div className={`collapsed-tab ${selected ? (classes.selectedTab + " collapsed-tab-selected") : ""}`}>
        <List component="div" disablePadding>
          <ListItem button className={classes.submenu} href={item["url"]} component="a" title={item["name"]} id={"left-nav "+ item["name"]} aria-label={"left-nav "+ item["name"]}>
            <ListItemText className={classes.listText + (selected ? " selected-tab-text" : "")} inset primary={item["name"]} />
          </ListItem>
        </List></div>
    };

    const HasSubMenu = ({ item, menukey }) => {
      //console.log("is fav displayed",item["items"])
      const component = item["items"].length === 0 ?
        <SingleLevelMenu item={item} menukey={menukey}/> :
        <CollapsibleMenu item={item} menukey={menukey}/>
      return component;
    }

    return (
      <div>
        {this.state.menus.map((mainMenu, idx) => {
          const subCateg = mainMenu.items;
          return subCateg.map((submenus, idx) => {
            return submenus.items !== [] && submenus.items.length > 0 ? (
              <div className={this.state.open[submenus["key"]] ? "card mb-3 pane-open" : "card mb-3 pane-close"} key={`pagemenu${idx}`}>
                <List aria-label={this.state.open[submenus["key"]] ?"Active Left Menu" : "Closed Left Menu"} key={`pagemenu${idx}`} className={classes.listComp} component="nav">
                  <div className={this.state.cardHeaderClass[submenus["key"]] || "card-header"} id={submenus["key"]}>
                    <ListItem className={!this.state.open[submenus["key"]] ? classes.listClosed : classes.listOpened} button
                      onClick={() => this.handleCollapse(submenus["key"])}>

                      <ListItemText className={classes.listMainText} inset primary={submenus["name"]} />

                      {this.state.open[submenus["key"]] ?
                        <KeyboardArrowUpIcon className={classes.collapse} /> :
                        <KeyboardArrowDownIcon className="expand" />}
                    </ListItem>
                  </div>
                  <Collapse className={classes.listChild}
                    in={this.state.open[submenus["key"]]} timeout="auto" unmountOnExit>
                    {submenus["items"] !== undefined ? submenus["items"].map((menu, key) => { return <HasSubMenu key={`submenus${idx}${key}`} item={menu} menukey={submenus["key"]} /> }) : ""}
                  </Collapse>
                </List>
              </div>
            ) : <></>
          })

        })}
      </div>
    );
  }
}
export default withStyles(styles, { withTheme: true })(LeftNavigationPane);