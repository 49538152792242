import React from 'react';
import { removePtag, parseAsHtml } from './util';

class ContactUsCard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
           
            PhoneNMailViewData: props.PhoneNMailViewData,
            AddressViewData: props.AddressViewData,
            
        };

    };
    componentDidMount() {
        try {
            this._isMounted = true;
        } catch (e) {
            console.log(e);
        }
    }
    componentDidUpdate(prevProps) {
       
        if (prevProps.AddressViewData !== this.props.AddressViewData || prevProps.PhoneNMailViewData !== this.props.PhoneNMailViewData) {
            this.setState(
                { AddressViewData: this.props.AddressViewData, PhoneNMailViewData: this.props.PhoneNMailViewData }, () => {
                });
        }
        if (prevProps.heading !== this.props.heading) {
            this.setState(
                { heading: this.props.heading,
                });
        }
    }

    render() {
        return (<>{this.state.AddressViewData ? this.state.AddressViewData.map((event, index) => {
                        return <div className="col-lg-5 static-contactus-card" key={index}>
                            <div className="d-flex">
                                <div className="white-circle">
                                    <img alt={event.field_contact_us_view[0].alt || ""} src={event.field_contact_us_view[0].url || ""} />
                                </div>
                                <div className="contact-info">
                                    <h3 title={removePtag(event.field_contact_us_view[1].field_title || "")}>{removePtag(event.field_contact_us_view[1].field_title || "")}</h3>
                                    {parseAsHtml(event.field_contact_us_view[2].field_para || "")}

                                </div>
                            </div>
                        </div>
                    }) : ""}
                    <div className="col-lg-7 static-contactus-card">
                        <div className="row">
                            {this.state.PhoneNMailViewData ? this.state.PhoneNMailViewData.map((event, index) => {
                                return <div className="col-lg-6" key={index}>
                                    <div className="d-flex">
                                        <div className="white-circle">
                                            <img alt={event.field_contact_us_view[0].alt || ""} src={event.field_contact_us_view[0].url || ""} />
                                        </div>
                                        <div className="contact-info">
                                            <h3 title={removePtag(event.field_contact_us_view[1].field_title || "")}>{removePtag(event.field_contact_us_view[1].field_title || "")}</h3>
                                            {(event.field_contact_us_view[2].field_para || "").includes("@") ? 
                                            <a href={"mailto:"+removePtag(event.field_contact_us_view[2].field_para || "")+"?subject=Contact US&amp;body=ContactUS"} 
                                            className="download-link">{removePtag(event.field_contact_us_view[2].field_para || "")}</a> 
                                            : <p>{removePtag(event.field_contact_us_view[2].field_para || "")}</p>} 
                                        </div>
                                    </div>
                                </div>
                            }) : ""}
                        </div>
                    </div>
               </>
           
        )
    }
}
export default ContactUsCard;