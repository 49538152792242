import React from "react";
import NavTabs from "./components/nav-tabs";
// import Notes from "./components/notes";
import moment from "moment";
// import HistDataTable from "./components/historical-data-table";
import TradeInformation from "./components/trade-information";
import { Refresh } from "./components/custom-toolbar";
import USContainer from "./components/depository-container-us";
// import Graph from "./components/graph";
// import HistoricalDataFilter from "./components/historical-data-filter";

class UsStocksQuotes extends React.Component {
  constructor(props) {
    super(props);
    const queryparams = new URLSearchParams(props.location.search);
    const usSymbolType = props.match.params.type || "";
    const pageType = usSymbolType.includes("etf") ? "usetfs" : "usstocks";
    this.state = {
      queryparams,
      headerRef: props.headerRef,
      symbol: `${queryparams.get("symbol") || "-"}`,
      quotesApi: `/api/us-quotes?symbol=${
        queryparams.get("symbol") || "-"
      }`,
      recentTabs: [],
      localStorageAcess: props.location.pathname,
      pageURL: props.location,
      storageKey: localStorage.getItem(pageType),
      stockType: pageType,
      histApi: ``,
      fromdate: moment(new Date()).subtract(1, "months").format("DD-MMM-YYYY"),
      todate: moment().utc().utcOffset("+05:30").format("DD-MMM-YYYY"),
      filename: "Historical Data",
      usContainerInfo: [],
      fundDescription: '',
      timeStamp: '',
      tradeInfo:[]
    };
    this.recieveDateParams = this.recieveDateParams.bind(this);
    this.reset = this.reset.bind(this);
    this.refresh = this.refresh.bind(this);
    //  this.loadQuotesData = this.loadQuotesData.bind(this);
  }
  refresh() {
    if (this._isMounted) {
      this.setState({ 
        usContainerInfo: [], 
        fundDescription: [], 
        timeStamp: [],
        tradeInfo:[],
      });
      this.loadQuotesData(this.state.quotesApi);
    }
  }
  loadQuotesData(api) {
    fetch(api)
      .then((resp) => resp.json())
      .then((respo) => {
        if(respo.data[0]) {
          this.setState({ 
            usContainerInfo: respo.data[0], 
            fundDescription: respo.data[0].symbolDetail, 
            timeStamp: respo.data[0].timeStamp,
            tradeInfo:respo.data[0].tradeInfo,
          });
        }
        else {
          this.setState({ 
            usContainerInfo: [], 
            fundDescription: "", 
            timeStamp: "",
            tradeInfo:[],
          });
        }
        
      })
      .catch((e) => {
        console.log("error in fetching Us Stock Quotes Data", e);
      });
  }

  componentDidMount() {
    try {
      
      this._isMounted = true;
      this.state.headerRef.current.updateTitle(
        "Depository Receipts",
        "Depository Receipts",
        [
          {
            field_breadcrumb: [
              { uri: "/markets/derivatives-watch", title: "Markets" },
              { uri: "/markets/derivatives-watch", title: "Live Watch" },
              { uri: this.props.location.search, title: "Depository Receipts" },
            ],
          },
        ]
      );
      
      this.loadQuotesData(this.state.quotesApi);
      // this.loadNotes(this.state.notesApi);
      this.setState({
        histApi: `/api/historical-derivative?fromdate=${this.state.fromdate}&todate=${this.state.todate}&symbol=${this.state.symbol}`,
        filename:
          "Historical_Data_" +
          this.state.symbol +
          "_" +
          moment(this.state.fromdate, "DD-MMM-YYYY").format("DD-MM-YYYY") +
          "_" +
          moment(this.state.todate, "DD-MMM-YYYY").format("DD-MM-YYYY"),
      });

      //start logic for latest 5 tabs symbols (nav tabs)
      if (this.state.storageKey === null) {
        var temp = [];
        temp.push({
          title: this.state.symbol,
          redirectUrl: this.state.pageURL.pathname + this.state.pageURL.search,
          time: new Date(),
        });

        var d = JSON.stringify(temp);
        localStorage.setItem(this.state.stockType, d);
      } else {
        var recover = JSON.parse(this.state.storageKey);
        if (recover.length > 0) {
          let reverse = [];
          if (recover[recover.length - 1].title === this.state.symbol) {
            for (let i = recover.length - 1; i >= 0; i--) {
              if (recover[i].title === this.state.symbol) {
                continue;
              }
              reverse.push(recover[i]);
            }
            reverse.sort(function (a, b) {
              if (a.time < b.time) return 1;
              if (a.time > b.time) return -1;
              return 0;
            });
            this.setState({ recentTabs: reverse });
          } else {
            let reverse = [];
            //var isRemoved = false;
            for (let i = recover.length - 1; i >= 0; i--) {
              if (recover[i].title === this.state.symbol) {
                //isRemoved = true;
                continue;
              }
              reverse.push(recover[i]);
            }
            reverse.sort(function (a, b) {
              if (a.time < b.time) return 1;
              if (a.time > b.time) return -1;
              return 0;
            });
            if (reverse.length > 4) {
              reverse.pop();
            }
            this.setState({ recentTabs: reverse });

            //if (isRemoved == true)
            //{
            recover = [];
            recover = JSON.parse(JSON.stringify(reverse));

            //if true then add
            recover.push({
              title: this.state.symbol,
              redirectUrl:
                this.state.pageURL.pathname + this.state.pageURL.search,
              time: new Date(),
            });
            //}
            recover.sort(function (a, b) {
              if (a.time < b.time) return -1;
              if (a.time > b.time) return 1;
              return 0;
            });
            if (recover.length > 5) {
              recover.shift();
            }
            let d = JSON.stringify(recover);
            localStorage.setItem(this.state.stockType, d);
          }
        }
      }
      //end logic for nav tabs
    } catch (e) {
      console.log(e);
    }
  }

  
  reset() {
    let api = `/api/historical-derivative?fromdate=${this.state.fromdate}&todate=${this.state.todate}&symbol=${this.state.symbol}`;
    this.setState({ histApi: api });
  }

  recieveDateParams = (
    instrumenttype,symbol,expiry,optiontype,strikeprice,fromdate, todate
  ) => {
    let api = `/api/historical-derivative?`;
    if (fromdate && todate) {
      
    
      api += `&fromdate=${fromdate}&todate=${todate}&symbol=${this.state.symbol}`;
    }

    this.setState({
      histApi: api,
    });
  };
  render() {
    return (
      <div className="usquotes static_content container-fluid mt-4 mb-5">
        <div className="row">
          <div className="col-md-12">
            <NavTabs
              color="secondary"
              tabs={[
                {
                  title: this.state.symbol,
                  component: (
                      <div className="container-fluid">
                        <div className="row mt-3">
                          <div className="col-md-12 mt-0 ">
                            <h2 className="get-quotes">Global Stocks</h2>
                          </div>
                        </div>
                        <NavTabs
                          color="primary"
                          tabStyleBlock={false}
                          tabs={[
                            {
                              title: this.state.stockType.includes("etf")
                                ? "US ETFS"
                                : "US STOCKS",
                              component: (
                                <div className="container-fluid">
                                  <div className="row">
                                    <div className="col-md-6">
                                      <h2 className="timestamp-heading">
                                        As on {this.state.timeStamp} IST (All
                                        price values in)
                                        <Refresh
                                          refreshcallback={this.refresh}
                                        />
                                      </h2>
                                    </div>
                                  </div>
                                  
                                  <USContainer
                                    symbol={this.state.symbol}
                                    usContainerInfo={this.state.usContainerInfo}
                                  />
                                  <NavTabs
                                    color="primary"
                                    tabStyleBlock={false}
                                    tabs={[
                                      {
                                        title: "TRADE INFORMATION",
                                        component: (
                                          <div className="container-fluid">
                                            <div className="row mt-3">
                                              <div className="col-md-12 mt-0 static_content">
                                                <h2 className="get-quotes">
                                                  Market Depth - Order Book
                                                </h2>
                                              </div>
                                              {this.state.tradeInfo[0]  ? 
                                             <>

                                              <div className="col-md-4">
                                                <TradeInformation
                                                  type={"order"}
                                                  data={this.state.tradeInfo[0].order.data}
                                                  columns={this.state.tradeInfo[0].order.cols}
                                                  summary={this.state.tradeInfo[0].order.summary[0]}
                                                />
                                              </div>

                                              <div className="col-md-4">
                                                <TradeInformation
                                                  type={"trade"}
                                                  data={this.state.tradeInfo[0].trade.data}
                                                  columns={this.state.tradeInfo[0].trade.cols}
                                                />
                                              </div>

                                              {/* For ETF - 3rd table - data unavailable */}
                                              {/* <div className="col-md-4">
                                                <TradeInformation
                                                  type={"margin"}
                                                  data={this.state.tradeInfo[0].margin.data}
                                                  columns={this.state.tradeInfo[0].margin.cols}
                                                />
                                              </div> */}
                                              <div className="col-md-12 mt-3" id="fundDesc">
                                                
                                                <h3>Fund Description:</h3>
                                                    <span>{this.state.fundDescription}</span>
                                              </div>
                                              </>
                                              : 
                                              <div className="col-md-12 mt-0 static_content">
                                              <p className="text-center">No data</p>
                                              </div>
                                              }

                                              
                                            </div>
                                          </div>
                                        ),
                                      },
                                      
                                    //Commented - not applicable as of now
                                    //   {
                                    //     title: "INTRA-DAY CHART",
                                    //     component: (
                                    //       <div className="container ">
                                    //         <div className="row">
                                    //           <div className="col-md-12">
                                    //             <Graph
                                    //               points={2}
                                    //               graphName="Time"
                                    //               chartType="area"
                                    //               api={`/api/us-quotes/intraday?&symbol=${this.state.symbol}`}
                                    //             />
                                    //           </div>
                                    //         </div>
                                    //       </div>
                                    //     ),
                                    //   },
                                    //   {
                                    //     title: "CORPORATE INFORMATION",
                                    //     component: (
                                    //       <div className="col-md-12">
                                           
                                    //       </div>
                                    //     ),
                                    //   },
                                      // {
                                      //   title: "HISTORICAL DATA",
                                      //   component: (
                                      //     <>
                                      //       <div className="mt-4 mb-4">
                                      //         <HistoricalDataFilter
                                      //           quoteType={true}
                                      //           resetcallback={this.reset}
                                      //           callback={
                                      //             this.recieveDateParams
                                      //           }
                                      //         />
                                      //       </div>
                                      //       <HistDataTable
                                      //         quoteType={true}
                                      //         api={this.state.histApi}
                                      //       />
                                      //     </>
                                      //   ),
                                      // },
                                    ]}
                                  />{" "}
                                </div>
                              ),
                            },
                          ]}
                        />
                      </div>
                   
                  ),
                },
              ]}
              containsAdditionTab={true}
              addToTabs={this.state.recentTabs}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default UsStocksQuotes;
