import React from 'react';
import MUIDataTable from "mui-datatables";
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
//import { ReactComponent as DownloadIcon } from '../../public/images/csv-icon.svg';
// import CustomToolbar from "./custom-toolbar";
import { DownloadIconButton as DownloadIcon,State3toggle } from "./custom-toolbar";
// import { convertNumberPrecision, convertPrice } from './util';
import { TableCell } from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';
// import TableRow from '@material-ui/core/TableRow';
// import { TableFooter, TablePagination } from "@material-ui/core";
// import CustomTablePagination from './custom-muitable-pagination';
import CustomMuiTablePagination from './custom-muitable-pagination';
import { getToggleHeader, getToggleVal } from './util';
import createBreakpoints from '@material-ui/core/styles/createBreakpoints'

const breakpoints = createBreakpoints({})


class HistDataTable extends React.Component {
    constructor(props) {
        super(props);
        this.wrapper = React.createRef();
        this.state = {
            api: props.api,
            State3toggle: "Thousands",
            columns: [],
            data: [],
            noMatchLabel: "Loading Data...",
            milliontoggle: true,
            rowsPerPage:10,
            filename:props.filename,
            // quoteType:props.quoteType || false,
            // mobileScreen:false,
            options: {
                viewColumns:false,
                // downloadOptions: {
                //     filename: props.filename + '.csv',
                //     // filterOptions: {
                //     //     useDisplayedColumnsOnly: true,
                //     //     useDisplayedRowsOnly: true,
                //     // }
                // },
                sort:true,
                selectableRows: "none",
                elevation: 0,
                filter: false,
                print: false,
                search: false,
                responsive: 'vertical',
                //tableBodyMaxHeight: '500px',
                // download:props.quoteType ? false : true,
                customToolbar: () => {
                    return (
                        //this.state.quoteType ? "" :
                        <State3toggle toggleCallback={this.toggle3Switch} />
                    );
                },
            },
            components: {
                icons: {
                    // SearchIcon,
                    // PrintIcon,
                    DownloadIcon,
                    // ViewColumnIcon,
                    // FilterIcon,
                }
            },
            fromDate: props.fromDate,
            toDate: props.toDate,
            inst: props.inst,
            symb: props.symb,
        };
        this.refresh = this.refresh.bind(this);
        // this.toggleSwitch = this.toggleSwitch.bind(this);
        this.toggle3Switch = this.toggle3Switch.bind(this);
        //this.checkDownload = this.checkDownload.bind(this);
    };
//     toggleSwitch(checked) {
//         // console.log("toggle", checked)
//         this.setState({ milliontoggle: checked })/* () => {
//             this.updateColumnsTable(this.state.displayedColumns);
//         })*/
//         this.loadData(this.state.api,checked);
// }

toggle3Switch(checked) {
    this.setState({ State3toggle: checked })
    // this.loadData(this.state.api, checked);
}
    getMuiTheme = () => createMuiTheme({
        overrides: {
            MUIDataTableToolbar: {
                root: {
                    textAlign: "left",
                },
                actions: {
                    display: "flex",
                    flex: "initial",
                   
                    "& > span:last-child, & > button": {
                        order: 1,
                    },
                    [breakpoints.down('xs')]: {
                        display: 'flex',
                        textAlign: `left !important`,
                        flexWrap:'wrap',
                        marginTop:'10px',
                    }
                }
            },
            MuiTypography: {
                h6: {
                    fontSize: "14px",

                }
            },
            MuiToolbar: {
                root: {
                    paddingLeft: "0.5rem !important",
                    paddingRight: "0.5rem !important",
                }
            },
            MUIDataTable: {
                responsiveStacked: {
                    boxShadow: "0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)",
                }
            },
            MUIDataTableHeadCell :{
                fixedHeader:{
                    zIndex:"10"}
            },
            MuiTooltip: {
                tooltip: {
                    fontSize: "12px",
                }
            },
            TablePaginationActions: {
                order: 1
            },
        }
    })

    componentDidMount() {
        try {
            this._isMounted = true;
            this.loadData(this.state.api);
            // window.addEventListener("resize", this.resize.bind(this));
            // this.resize();
        } catch (e) {
            console.log(e);
        }
    }
    // resize() {
    //     let currentmobileScreen = (window.innerWidth <= 640);
    //     if (currentmobileScreen !== this.state.mobileScreen) {
    //         this.setState({mobileScreen: currentmobileScreen});
    //     }
    // }
    // componentWillUnmount() {
    //     window.removeEventListener("resize", this.resize.bind(this));
    // }
    // componentWillReceiveProps(nextProps) {
    //     this.setState({ api: nextProps["data-api"] },
    //         () => {
    //             this.loadData(this.state.api);
    //         });

    // }
    componentDidUpdate(prevProps) {
        if (this.props.api !== prevProps.api) {
            this.setState({ api: this.props.api }, 
            ()=> {this.loadData(this.state.api, this.state.State3toggle);
            });
        }
        if (this.props.fromDate !== prevProps.fromDate) {
            this.setState({ fromDate: this.props.fromDate }, 
           );
        }
        if (this.props.toDate !== prevProps.toDate) {
            this.setState({ toDate: this.props.toDate }, 
           );
        }
        if (this.props.inst !== prevProps.inst) {
            this.setState({ inst: this.props.inst }, 
           );
        }
        if (this.props.symb !== prevProps.symb) {
            this.setState({ symb: this.props.symb }, 
           );
        }
        if (this.props.filename !== prevProps.filename) {
            this.setState({ filename: this.props.filename }, 
           );
        }
    }
    loadData(api) {
        //const offsetWidth = this.wrapper.current.offsetWidth || 1100;
        fetch(api)
            .then(resp => resp.json())
            .then((data) => {
                data.api = api

                data.columns = data.columns.map((col) => {
                    //col.width = offsetWidth * (col.width / 100);
                    col = {
                        label: col.tooltip || col.headerName,
                        name: col.field,
                        millions: col.millions,
                        headerName: col.headerName,
                        subHeader: col.subHeader,
                        type:col.type,
                        width:col.width,
                        decimal: col.decimal,
                        precision: col.precision,
                        options: {
                            customHeadRender: ({ index, ...column }, sortColumn, sortOrder) => {
                                if (col.name === "TURNOVER") {
                                    col.subHeader = getToggleHeader(this.state.State3toggle);
                                    // if (this.state.State3toggle === "left") {
                                    //     col.subHeader = "($ Thousands)";
                                    // }
                                    // else if (this.state.State3toggle === "center") {
                                    //     col.subHeader =  "($ Millions)";
                                    // }
                                    // else {
                                    //     col.subHeader = "($ Billions)";
                                    // }
                                }
                                return (
                                    <Tooltip title={col.label} placement="bottom" key={col.name}>
                                        <TableCell className={col.name === sortOrder.name && column.sortDirection !== null ? "sorted-col" : "unsorted-col"}
                                            align={col.type === "number" ? "right" : (col.type === "dateTime" ? "center" : "left")}
                                            style={{ width: col.width }}
                                            onClick={() => sortColumn(index)} key={index}>
                                            <span className={"preline-span " + (col.name === sortOrder.name && column.sortDirection !== null ? "sortactive" : "")}>
                                                
                                                {col.name === "CONTRACTSTRADED" ? 
                                                <>{col.headerName.split(" ")[0]}{" "}{col.headerName.split(" ")[1]} <br />
                                                {col.headerName.split(" ")[2]}</>
                                                : <>{col.headerName.split(" ")[0]}<br />
                                                {col.headerName.split(" ")[1]}{" "}{col.headerName.split(" ")[2]} </>}
                                                
                                                {col.subHeader ? 
                                                 <span className="dg-table-sub-header">
                                                 {col.subHeader && ` ${col.subHeader}`}
                                                </span> : ""}
        
        
                                            </span>
                                            <span className="sorting-icons" aria-label={"Sort By " + col.label}>
                                                {col.name === sortOrder.name && column.sortDirection !== null ?
                                                    (sortOrder.direction === "asc" ? <i className="fa fa-arrow-up"></i>
                                                        : <i className="fa fa-arrow-down"></i>)
                                                    :
                                                    <i className="fa fa-sort"></i>
                                                }
                                            </span>
        
                                        </TableCell>
                                    </Tooltip>
                                );
                            },
                            // customHeadLabelRender: (params) => {
                            //    if (col.name === "turnover") {
                            //     if (state3tog === "left") {
                            //         col.subHeader = "($ Thousands)";
                            //     }
                            //     else if (state3tog === "center") {
                            //         col.subHeader =  "($ Millions)";
                            //     }
                            //     else {
                            //         col.subHeader = "($ Billions)";
                            //     }
                            //     }
                            //     if(col.name === "contractTraded"){
                            //         return (
                            //             <span className="preline-span">
                            //                 {col.headerName.split(" ")[0]}{" "}{col.headerName.split(" ")[1]}
                            //                 <br />
                            //                 {col.headerName.split(" ")[2]}
                            //                 <span className="dg-table-sub-header">
                            //                     {col.subHeader && ` ${col.subHeader}`}
                            //                 </span>
                            //             </span>
                            //         )
                            //     }
                            //     else {
                            //     return (
                            //         <span className="preline-span">
                            //             {col.headerName.split(" ")[0]}<br />
                            //             {/* <i className="fa fa-sort"></i><br/> */}
                            //             {col.headerName.split(" ")[1]}{" "}{col.headerName.split(" ")[2]}
                            //             <span className="dg-table-sub-header">
                            //                 {col.subHeader && ` ${col.subHeader}`}
                            //             </span>
                            //         </span>
                            //     )
                            //     }
                            // },
                            customBodyRender: (data, dataIndex, rowIndex) => {
                                //let precision = 2;
                                if (col.name === "TURNOVER") {
                                    data = Number(data.replace(/,/g, ''));
                                    data = getToggleVal(data,this.state.State3toggle);
                                    // if (this.state.State3toggle === "left") {
                                    //     data = data /1000;
                                    // }
                                    // else if (this.state.State3toggle === "center") {
                                    //     data = data / 1000000;
                                    // }
                                    // else {
                                    //     data = data / 1000000000;
                                    // }
                                    data = data.toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2});
                                }
                                // if (col.precision) {
                                //     if (dataIndex.rowData[2] === "SILVER") {
        
                                //         precision = 3;
                                //     }
                                //     else if ((dataIndex.rowData[2].includes("USD") || (dataIndex.rowData[2].includes("INR"))) && dataIndex.rowData[2] !== "INRUSD") {
                                //         precision = 4;
                                //     }
                                //     else {
                                //         precision = 2;
                                //     }
                                // }
                                // else {
                                //     precision = 2;
                                // }
                                // let result = typeof (data) === "number" ? 
                                // (col.decimal ? convertNumberPrecision(data, precision) 
                                // : convertPrice(data)) : data;
                                // sample.push(result);
                                return (
                                    <span>
                                        {data}
                                    {/* {typeof (data) === "number" ? 
                                    (col.decimal ? convertNumberPrecision(data, precision) 
                                    : convertPrice(data)) : data} */}
                                    </span>
                                )

                        },
                        sortCompare: (order) => {
                            if (col.type === "dateTime") {
                                return (obj1, obj2) => {
                                    const dateA = new Date(obj1.data).getTime();
                                    const dateB = new Date(obj2.data).getTime();
                                    return (dateA < dateB ? -1 : 1) * (order === "asc" ? 1 : -1);
    
                                }
                            } 
                            else if (col.type === "number"){
                                return (obj1, obj2) => {
                                    // let val1 = obj1.data;
                                    // let val2 = obj2.data;
                                    let val1 = obj1.data !== "-" &&
                                     obj1.data !== null && obj1.data ? Number(obj1.data.replace(/,/g, '')) : 0;
                                    let val2 =  obj2.data !== "-" &&
                                    obj2.data !== null && obj2.data ? Number(obj2.data.replace(/,/g, '')) : 0;
                                    return (val1 < val2 ? -1 : 1) * (order === 'asc' ? 1 : -1);
                                };
                            }
                            else {
                                return (obj1, obj2) => {
                                    let val1 = obj1.data !== "-" ? obj1.data : 0;
                                    let val2 = obj2.data !== "-" ? obj2.data : 0;
                                    return (val1 < val2 ? -1 : 1) * (order === 'asc' ? 1 : -1);
                                };
                            }
    
                        },
                        setCellProps: () => ({
                            align: col.type === "number" ? "right" :(col.type === "dateTime" ? "center"  : "left"),
                        }),
                        // setCellHeaderProps: () => ({ 
                        //     align: col.type === "number" ? "right" :(col.type === "dateTime" ? "center"  : "left"),
                        //     style: {width:col.width}
                        // })
                    }}
                    return col;
                });
                this.setState(data);
                if (data.data.length === 0) {
                    this.setState({ noMatchLabel: "No Records Found" })
                }
                else {
                    this.setState({ noMatchLabel: "" })
                }
            })
            .catch((e) => { console.log("error in loading historical data", e) })

    }

    refresh() {
        console.log("hit refresh", this._isMounted);
        if (this._isMounted) {
            this.setState({ data: [] });
            this.loadData(this.state.api);
        }
    }

    render() {
        return (
            // <div ref={this.wrapper} 
            // className={"col-md-12 historical-data contrast-table " 
            // + (this.state.mobileScreen ? "responsive-mui-table" : "deriv-mui-data-table ")}
            // // id="histDataTableResp"
            // >
            <div ref={this.wrapper} 
            className={(this.state.noMatchLabel ? "no-records " : "" )+
            "col-md-12 responsive-muidatatable historical-data deriv-mui-data-table contrast-table"} 
            // className="col-md-12 historical-data deriv-mui-data-table contrast-table" 
            id="histDataTableResp">
                <MuiThemeProvider theme={this.getMuiTheme()}>
                    {
                        this.state.data ?
                            <MUIDataTable
                                title={this.state.fromDate !=null && this.state.toDate !=null ?
                                     `Data from `+ this.state.fromDate + ` to `+ this.state.toDate : ``}
                                
                                columns={this.state.columns}
                                data={this.state.data}
                                options={{
                                    ...this.state.options,
                                    textLabels: {
                                        body: {
                                            noMatch: this.state.noMatchLabel
                                        }
                                    },
                                    // responsive: !this.state.mobileScreen ? 'standard' : 'vertical',
                                    // tableBodyMaxHeight:!this.state.mobileScreen ? '500px' : 'initial',
                                    downloadOptions: {
                                        filename: this.state.filename + '.csv',
                                    },
                                    onDownload: (buildHead, buildBody, columns, data) => {
                                        
                                        
                                        let turnoverCol = columns.findIndex(col => col.name === "TURNOVER");
                                        // let turnoverCol = 0;
                                        // columns.forEach((col,idx)=>{
                                        //     if(col.name === "TURNOVER"){
                                        //         turnoverCol = idx;
                                        //     }
                                        // })
                                        // if(this.checkDownload()){
                                          data = data.map((cell)=>{
                                            cell.data = cell.data.map((val,jdx)=>{
                                                    if(jdx === turnoverCol){
                                                        val = Number(val.replace(/,/g, ''));
                                                        val = getToggleVal(this.state.total[0].TURNOVER,this.state.State3toggle);
                                                        // if (this.state.State3toggle === "left") {
                                                        //     val = val /1000;
                                                        // }
                                                        // else if (this.state.State3toggle === "center") {
                                                        //     val = val / 1000000;
                                                        // }
                                                        // else {
                                                        //     val = val / 1000000000;
                                                        // }
                                                        return val.toFixed(2);
                                                    }
                                                    else if(val === "-"){
                                                        return " - ";
                                                    }
                                                    return val;
                                        })
                                        return cell;
                                    })
                                    return "\uFEFF" + buildHead(columns) + buildBody(data); 
                                    },
                                    // })
                                    // if(this.checkDownload()){
                                       // console.log("downlaod cell",cell.data)
                                        
                                    //}
                                        
                                       
                                    // // }
                                    // //  return false;
                                    // //    console.log("downlaod data",data)
                                    // //    data = data.map((cell)=>{
                                    // //     cell.data.map((abcd)=>{
                                    // //         console.log("downlaod cell",cell.data[abcd])
                                    // //     })
                                    // //     //cell.data = cell.data === "-" ? 0 : cell.data
                                        
                                    // //     return cell
                                    // //    })
                                       
                                    // },
                                    // onChangeRowsPerPage : (numberOfRows) => {
                                    //     this.setState({
                                    //         rowsPerPage:numberOfRows
                                    // })},
                                    page:0,
                                    customFooter: (count,
                                        page,
                                        rowsPerPage,
                                        changeRowsPerPage,
                                        changePage) => {
                                        return <CustomMuiTablePagination 
                                        count={count} page={page}
                                        rowsPerPage={rowsPerPage} 
                                        changeRowsPerPage={changeRowsPerPage} 
                                        changePage={changePage} />
                                    },
                                }
                                }
                                components={this.state.components}
                            /> :
                            <span>loading</span>
                    }
                </MuiThemeProvider>
            </div>
        );
    };
}

export default HistDataTable;