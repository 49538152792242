import $ from 'jquery';

export const incfont = () => {
    var increaseCount = localStorage.getItem('NSEIfscFontCounter') || 0;
    if (parseInt(increaseCount, 10) < 2) {
        ChangeTagFont(1);
        increaseCount++;
        localStorage.setItem('NSEIfscFontCounter', increaseCount);
    }
}

export const decfont = () => {
    var decreaseCount = localStorage.getItem('NSEIfscFontCounter') || 0;
    if (parseInt(decreaseCount, 10) > -2) {
        ChangeTagFont(-1);
        decreaseCount--;
        localStorage.setItem('NSEIfscFontCounter', decreaseCount);
    }
}

export const orgfont = () => {
    var orgCount = localStorage.getItem('NSEIfscFontCounter') || 0;
    if (parseInt(orgCount, 10) > 0) {
        while (parseInt(orgCount, 10) > 0) {
            ChangeTagFont(-1);
            orgCount--;
        }
    }
    else {
        while (parseInt(orgCount, 10) < 0) {
            ChangeTagFont(1);
            orgCount++;
        }
    }
    localStorage.setItem('NSEIfscFontCounter', 0);
}

var ChangeTagFont = (amount) => {
    var self = $("html,a,body,h1,h2,h3,h4,h5,h6,.container-fluid p,ul,li,ol,input");;
    var changeFont = function (element, amount) {
        var baseFontSize = parseInt(element.css('font-size'), 10);
        element.css('font-size', (baseFontSize + parseInt(amount, 10)) + 'px');
    };

    self.each(function (index, element) {
        changeFont($(element), amount);
    });
}