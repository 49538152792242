import React, { Component } from 'react';
import SearchResult from './SearchResult'
import { withRouter} from 'react-router-dom';
import {ReactComponent as SearchIcon } from '../../public/images/loupe.svg';


// const headerRef = React.createRef();
class GlobalSearch extends Component {
    
    constructor(props) {
        super(props)
        this.state = {
            searchKey: '',
            searchResult: {},
            checkDiv: false
        }
        this.searchKeyword = this.searchKeyword.bind(this);
        this.searchAll = this.searchAll.bind(this);
        this.handleClick = this.handleClick.bind(this);
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.state.searchKey !== prevState.searchKey && this.state.searchKey.length >= 3) {
            fetch(`/api/search/autocomplete?q=${this.state.searchKey}`)
                .then(resp => resp.json())
                .then((data) => {
                    let totalResult = 7;
                    const symbols = data.symbols.slice(0, totalResult);
                    totalResult -= symbols.length;
                    const sitemap = data.sitemap.slice(0, totalResult);
                    totalResult -= sitemap.length;
                    const contents = data.search_content.slice(0, totalResult);

                    this.setState({
                        searchResult: {
                            symbols,
                            sitemap,
                            contents,
                            
                        },
                        checkDiv: true
                    })
                })
        }
    }
    searchKeyword(e) {
        this.setState({
            searchKey: e.target.value
        })
    }
    searchAll(e){
        if(e.key==='Enter' && this.state.searchKey !==''){
        window.location.href = `/search?q=`+this.state.searchKey;
        // this.props.history.push(`/search?q=`+this.state.searchKey)
        }
    }
    componentDidMount() {
        document.addEventListener('click', this.handleClick);
      }
      componentWillUnmount() {
        document.removeEventListener('click', this.handleClick);
      }
      handleClick(e) {
        if (this.node.contains(e.target)) {
            this.setState({ checkDiv: true })
        } else {
            this.setState({ checkDiv: false })
        }
      }
    render() {
        return (
            <div className="input-group searchbar" 
            onFocus={(e) => {
                this.setState({ checkDiv: true })
            }}
            // onBlur={(e) => {
            //     this.setState({ checkDiv: true })
            // }}
            ref={node => this.node = node}
            >
                <input type="text" className="form-control "
                    value={this.state.searchKey}
                    onChange={this.searchKeyword} autoComplete="off"
                   
                    placeholder="Search"
                    onKeyPress={this.searchAll}
                />
                {
                    this.state.searchKey.length >= 3 && this.state.checkDiv ?
                        <SearchResult results={this.state.searchResult} highlightword={this.state.searchKey} />
                        : null
                }
                <div className="input-group-append">
                    <div className="searchgroupicon"><SearchIcon /></div>
                </div>
            </div>
        );
    }
}

export default withRouter(GlobalSearch);