import React from 'react';
import MUIDataTable from "mui-datatables";
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { DownloadIconButton as DownloadIcon, Refresh, State3toggle } from "./custom-toolbar";
import { convertNumberPrecision, convertPrice,getToggleHeader, getToggleVal } from './util';
import Tooltip from '@material-ui/core/Tooltip';
import { TableCell } from '@material-ui/core';
import CustomMuiTablePagination from './custom-muitable-pagination';
import createBreakpoints from '@material-ui/core/styles/createBreakpoints'

const breakpoints = createBreakpoints({})

class MostDataTable extends React.Component {
    constructor(props) {
        super(props);
        this.wrapper = React.createRef();
        this.state = {
            api: props["api"],
            statusApi: `/api/derivatives-market-status`,
            updateDateStatus: ``,
            columns: [],
            data: [],
            tempData: [],
            State3toggle: 'Thousands',
            noMatchLabel: "Loading Data...",
            sortedCol: "CONTRACTSTRADED",
            sortDir: "desc",
            // rowsPerPage:10,
            options: {
                filter: false,
                print: false,
                search: false,
                viewColumns: false,
                selectableRows: "none",
                elevation: 0,
                responsive: 'vertical',
                // tableBodyMaxHeight: '500px',
                customToolbar: () => {
                    return (<>
                        <State3toggle toggleCallback={this.toggle3Switch} />
                    </>
                    );
                },
            },
            components: {
                icons: {
                    // SearchIcon,
                    // PrintIcon,
                    DownloadIcon,
                    // ViewColumnIcon,
                    // FilterIcon,
                }
            },
        };
        this.refresh = this.refresh.bind(this);
        this.toggle3Switch = this.toggle3Switch.bind(this);
    };

    toggle3Switch(checked) {
        this.setState({ State3toggle: checked })
        // this.loadData(this.state.api, checked);
    }

    getMuiTheme = () => createMuiTheme({
        overrides: {
            MUIDataTableToolbar: {
                root: {
                    textAlign: "left",
                    paddingLeft: "0 ",
                    paddingRight: "0",
                },
                actions: {
                    display: "flex",
                    flex: "initial",

                    "& > span:last-child, & > button": {
                        order: 1
                    },
                    [breakpoints.down('xs')]: {
                        display: 'flex',
                        textAlign: `left !important`,
                        flexWrap:'wrap',
                        marginTop:'10px',
                    }
                }
            },
            MuiTableSortLabel: {
                root: {
                    '&$active': {
                        color: 'red',
                        '&& $icon': {
                            color: 'red'
                        },
                        '&& $iconDirectionDesc': {
                            color: 'green'
                        },
                    },
                },
            },
            MuiTypography: {
                h6: {
                    fontSize: "14px",

                }
            },
            // MuiToolbar: {
            //     root: {
            //         paddingLeft: "0.5rem !important",
            //         paddingRight: "0.5rem !important",
            //     }
            // },
            MUIDataTable: {
                responsiveStacked: {
                    boxShadow: "0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)",
                }
            },
            MUIDataTableHeadCell: {
                contentWrapper: {
                    display: `unset`
                },
                data:
                {
                    width: "max-content",
                },
                sortLabelRoot:
                {
                    display:"none"
                }
            },
            MuiTableCell: {
                alignLeft: {
                    "& button div:first-child": {
                        textAlign: `left !important`
                    }
                },
                alignRight: {
                    "& button div:first-child": {
                        textAlign: `right !important`
                    }
                }
            },
        }
    })

    componentDidMount() {
        try {
            this._isMounted = true
            this.loadData(this.state.api);
            this.loadMarketStatus(this.state.statusApi);
        } catch (e) {
            console.log(e);
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.api !== prevProps.api) {

            this.setState({ api: this.props.api },
                () => {
                    this.loadData(this.state.api);
                });
        }

    }

    loadMarketStatus(api) {

        fetch(api)
            .then(resp => resp.json())
            .then((data) => {
                this.setState({
                    timestamp: data.marketstatus,
                })
                // console.log("as/on date",this.state.timestamp)
            })

    }
    loadData(api) {
        //const offsetWidth = this.wrapper.current.offsetWidth || 1100;
        if(api) {
            fetch(api)
            .then(resp => resp.json())
            .then((data) => {
                data.api = api
                data.columns = data.columns.map((col) => {
                    //col.width = offsetWidth * (col.width / 100);
                    col = {
                        label: col.tooltip || col.headerName,
                        name: col.field,
                        headerName: col.headerName,
                        subHeader: col.subHeader,
                        decimal: col.decimal,
                        type: col.type,
                        precision: col.precision,
                        width:col.width || `auto`,
                        options: {
                            customHeadRender: ({ index, ...column }, sortColumn, sortOrder) => {
                                // console.log("col dir",sortOrder)
                                if (col.name === "VALUE") {
                                    col.subHeader = getToggleHeader(this.state.State3toggle);
                                    // if (this.state.State3toggle === "left") {
                                    //     col.subHeader = "($ Thousands)";
                                    // }
                                    // else if (this.state.State3toggle === "center") {
                                    //     col.subHeader =   "($ Millions)";
                                    // }
                                    // else {
                                    //     col.subHeader = "($ Billions)";
                                    // }
                                }
                                return (
                                    <Tooltip title={col.label} placement="bottom"  key={index}>
                                        <TableCell className={col.name === sortOrder.name && column.sortDirection !== null ? "sorted-col" : "unsorted-col"}
                                            align={col.type === "number" ? "right" : (col.type === "dateTime" ? "center" : "left")}
                                            style={{ width: col.width }}
                                            onClick={() => sortColumn(index)}>
                                            <span className={"preline-span " + (col.name === sortOrder.name && column.sortDirection !== null ? "sortactive" : "")}>
                                                {col.headerName.split(" ")[0]}<br />
                                                {col.headerName.split(" ")[1]}
                                                {col.subHeader ? <span className="dg-table-sub-header" >
                                                    {col.subHeader && ` ${col.subHeader}`}
                                                </span> : ""}
                                            </span>
                                            <span className="sorting-icons" aria-label={"Sort By " + col.label}>
                                                {col.name === sortOrder.name && column.sortDirection !== null ?
                                                    (sortOrder.direction === "asc" ? <i className="fa fa-arrow-up"></i>
                                                        : <i className="fa fa-arrow-down"></i>)
                                                    :
                                                    <i className="fa fa-sort"></i>
                                                }
                                            </span>
        
                                        </TableCell>
                                    </Tooltip>
                                );
                            },
                            sortCompare: (order) => {

                                if (col.type === "dateTime") {
                                    return (obj1, obj2) => {
                                        const dateA = new Date(obj1.data).getTime();
                                        const dateB = new Date(obj2.data).getTime();
                                        return (dateA < dateB ? -1 : 1) * (order === "asc" ? 1 : -1);
        
                                    }
                                } 
                                else if (col.type === "number"){
                                    return (obj1, obj2) => {
                                        // let val1 = obj1.data;
                                        // let val2 = obj2.data;
                                        let val1 = obj1.data !== "-" ? obj1.data : 0;
                                        let val2 = obj2.data !== "-" ? obj2.data : 0;
                                        return (val1 < val2 ? -1 : 1) * (order === 'asc' ? 1 : -1);
                                    };
                                }
                                else {
                                    return (obj1, obj2) => {
                                        let val1 = obj1.data;
                                        let val2 = obj2.data;
                                        return (val1 < val2 ? -1 : 1) * (order === 'asc' ? 1 : -1);;
                                    };
                                }
        
                            },
                            customBodyRender: (data, dataIndex, rowIndex) => {
                                let precision = 2;
                                if (col.name === "VALUE") {
                                    data = getToggleVal(data,this.state.State3toggle);
                                    // if (this.state.State3toggle === "left") {
                                    //     data = data / 1000;
                                    // }
                                    // else if (this.state.State3toggle === "center") {
                                    //     data = data / 1000000;
                                    // }
                                    // else {
                                    //     data = data / 1000000000;
                                    // }
                                }
                                if (col.precision) {
                                    if (dataIndex.rowData[1] === "SILVER") {

                                        precision = 3;
                                    }
                                    else if (dataIndex.rowData[0].includes("Currency") && dataIndex.rowData[1] !== "INRUSD") {
                                        precision = 4;
                                    }
                                    else {
                                        precision = 2;
                                    }
                                }
                                else {
                                    precision = 2;
                                }
                                return (
                                    <span> {typeof data === "number" ?
                                        (col.decimal ? convertNumberPrecision(data, precision) : convertPrice(data))
                                        : data} </span>
                                )
                            },
                            setCellProps: () => ({
                                align: col.type === "number" ? "right" : (col.type === "dateTime" ? "center" : "left"),
                            }),
                        },
                    }
                    return col;
                });

                this.setState(data);
                if (data.data.length === 0) {
                    this.setState({ noMatchLabel: "No Records Found" })
                }
                else {
                    this.setState({noMatchLabel:""})
                }
            })
            .catch((e) => { console.log("error in loading most active data", e) })
        }
        
    }

    refresh() {
        
        if (this._isMounted) {
            this.setState({ data: [], tempData: [] });
            this.loadData(this.state.api);
            this.loadMarketStatus(this.state.statusApi);
        }
    }

    render() {
        return (
            <div ref={this.wrapper} 
            // className="col-md-12 mui-data-table deriv-mui-data-table mstactive-mui-data-table contrast-table" 
            className={(this.state.noMatchLabel ? "no-records " : "" )+
            "col-md-12 responsive-muidatatable deriv-mui-data-table contrast-table"} 
            id="mostActiveResponsive">
                <MuiThemeProvider theme={this.getMuiTheme()}>
                    {
                        this.state.data ?
                            <MUIDataTable
                                title={<div className="muidatatable-timestamp"> As on {this.state.timestamp} IST
                                <Refresh refreshcallback={this.refresh} /></div>}
                                columns={this.state.columns}
                                data={this.state.data}
                                options={{
                                    ...this.state.options,
                                    textLabels: {
                                        body: {
                                            noMatch: this.state.noMatchLabel,
                                            columnHeaderTooltip: col => `${col.label}`
                                        }
                                    },
                                    sortOrder: {
                                        name: this.state.sortedCol,
                                        direction: this.state.sortDir,
                                    },
                                    page:0,
                                    customFooter: (count,
                                        page,
                                        rowsPerPage,
                                        changeRowsPerPage,
                                        changePage) => {
                                        return <CustomMuiTablePagination 
                                        count={count} page={page}
                                        rowsPerPage={rowsPerPage} 
                                        changeRowsPerPage={changeRowsPerPage} 
                                        changePage={changePage} />
                                    },

                                }
                                }
                                components={this.state.components}
                            /> :
                            <span>loading</span>
                    }
                </MuiThemeProvider>
            </div>
        );
    };
}

export default MostDataTable;