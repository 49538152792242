import React from 'react';
//import env from '../config';;
import HistoricalNltFilter from './components/historical-nlt-filter';
import moment from "moment";
import MarketTable from './components/market-table';

class HistoricalNlt extends React.Component {
    _isMounted = false;
    constructor(props) {
        super(props);
        this.state = {
            headerRef: props.headerRef,
            fromdate: null,
            todate: null,
            expiry: null,
            strikeprice: null,
            optiontype: null,
            symbol: null,
            instrumenttype: null,
            api: `/api/historical-nlt`,
            notesApi: `/api/contents/notes?url=/resources-historical-data`,
            notesData: [],
            filename: 'Historical_Data'
        }
        this.recieveDateParams = this.recieveDateParams.bind(this);
        this.reset = this.reset.bind(this)
    }

    componentDidMount() {
        try {
            this._isMounted = true;
            this.loadNotes(this.state.notesApi);
            //this.state.headerRef.current.updateTitle("Historical Derivative", "Historical Derivative");
        } catch (e) {
            console.log(e);
        }

    }

    loadNotes(api) {
        fetch(api)
            .then(resp => resp.json())
            .then((cards) => {
                this.setState({
                    notesData: cards.content.field_note_components,
                })
            })
            .catch((e) => { console.log("error in notes", e) })
    }


    reset() {
        let api = `/api/historical-nlt-all`;
        this.setState({ api });
    }
    recieveDateParams = (instrumenttype, symbol, fromdate, todate) => {
        let api = `/api/historical-nlt?`;
        // alert(instrumenttype);
        if (fromdate && todate) {
            api += `&fromdate=${fromdate}&todate=${todate}`;
        }
        if (symbol) {
            api += `&symbol=${symbol}`;
        }
        if (instrumenttype) {
            // alert('fff');
            api += `&instrumenttype=${instrumenttype}`;
        }

        this.setState({
            api, instrumenttype, symbol, fromdate, todate,
            filename: 'Historical_Data_' + instrumenttype + '_' + symbol + '_'
                + moment(fromdate, "DD-MMM-YYYY").format("DD-MM-YYYY") + '_' + moment(todate, "DD-MMM-YYYY").format("DD-MM-YYYY")
        });
    }
    render() {
        return (
            <>
                <HistoricalNltFilter resetcallback={this.reset} callback={this.recieveDateParams} />
                <div className='col-md-12 home-row'>
                    <MarketTable
                        data-api={this.state.api}
                        pageName="NLTHistorical"
                        pageTitle=""
                        notesApi="/api/contents/notes?url=/market/nlt"
                    />
                </div>
                

            </>
        );
    }
}

export default HistoricalNlt;