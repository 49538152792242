import React from 'react';
import { removePtag, parseAsHtml } from './util';
import ContactUsCard from './contactus-card';

class ContactUs extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            ContactUsCard: props.ContactUsCard,
            PhoneNMailViewData: props.PhoneNMailViewData,
            AddressViewData: props.AddressViewData,
            heading: props.heading,
        };

    };
    componentDidMount() {
        try {
            this._isMounted = true;
        } catch (e) {
            console.log(e);
        }
    }
    componentDidUpdate(prevProps) {
        if (prevProps.ContactUsCard !== this.props.ContactUsCard) {
            this.setState({ ContactUsCard: this.props.ContactUsCard });
        }
        if (prevProps.AddressViewData !== this.props.AddressViewData || prevProps.PhoneNMailViewData !== this.props.PhoneNMailViewData) {
            this.setState(
                { AddressViewData: this.props.AddressViewData, PhoneNMailViewData: this.props.PhoneNMailViewData }, () => {
                });
        }
        if (prevProps.heading !== this.props.heading) {
            this.setState(
                { heading: this.props.heading,
                });
        }
    }

    render() {
        return (
            <div className="container-fluid contact light-blue-bg pt-4">
                <h2 title={removePtag(this.state.heading)}>{removePtag(this.state.heading)}</h2>
                <div className="row pb-3 mt-3">
                    {this.state.ContactUsCard.map((event, index) => {
                        return <div className="col-lg-3 border-right-grey" key={index}>
                            <h3 title={parseAsHtml(removePtag(event.field_contact_us_card[0].field_title))}>
                                {parseAsHtml(removePtag(event.field_contact_us_card[0].field_title))}</h3>
                            {parseAsHtml(event.field_contact_us_card[1].field_para)}
                            {/* {(event.field_contact_us_card[1].field_para).includes("@") ? <a href="mailto:?subject=Contact US&amp;body=ContactUS" className="download-link">{parseAsHtml(removePtag(event.field_contact_us_card[1].field_para))}</a> : <p>{parseAsHtml(event.field_contact_us_card[1].field_para)}</p>}  */}

                            {/* <a href={event.field_contact_us_card[2].uri} className="btn btn-orange">
                                {event.field_contact_us_card[2].title}
                            </a> */}
                        </div>
                    })}
                </div>
                <div className="row row pt-4 pb-5">
                <ContactUsCard AddressViewData={this.state.AddressViewData}
                PhoneNMailViewData={this.state.PhoneNMailViewData}/>
                </div>
                
            </div>
        )
    }
}
export default ContactUs;