import React from 'react';

class FooterContainer extends React.Component {
    _isMounted = false;
    constructor(props) {
        super(props);
        this.state = {
            meta: props['menu-meta'],
            items: props['menu-items'],
        }
    }

    render() {
        return (
           !this.state.meta.url ?
                <div className="col-md-3">
                    <h4>{this.state.meta.name}</h4>
                    {this.state.items.map((menu, idx) => {
                        return (menu.items !== [] && menu.items.length > 0) ?
                            <div key={menu.key}>
                                <ul className="footer">
                                <li className="submenu-heading">{menu.name}</li>
                                    {menu.items.map((item) => {
                                        return <li className="level2-menu" key={item.key} >
                                            <a href={item.url} title={item.name}>{item.name}</a>
                                            <ul className="level3-menu pl-4">
                                            {item.items.map((subitem) => {
                                                return <li key={subitem.key}>
                                                    <a href={subitem.url} title={subitem.name}>{subitem.name}</a>
                                                    </li>
                                            })}
                                            </ul>
                                        </li>
                                    })}</ul>
                            </div> : ""
                    })}
                </div> : ""


        );
    };
}

export default FooterContainer;