import React from 'react';
import { CarouselProvider, Slider, Slide, ButtonBack, ButtonNext, DotGroup } from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import { removePtag } from './util'

import { withStyles } from "@material-ui/core/styles";

const styles = theme => ({

    // mobilenewslider: {
    //     [theme.breakpoints.down('xs')]: {
    //         "& button:nth-last-child(-n+1)" : {
    //             display: `inline-block !important`,
    //         }
    //     },
    // },
    newslider: {
            "& button:nth-last-child(-n+1)" : {
                display: `none`,
            }
    },
});
class NewsCards extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            cardData: [],
            newsData: props.newsData,
            heading: "",
            visibleSlide: window.innerWidth < 768 ? 1 : 2,
            ariaproperties:"",
        }
    };
    componentDidMount() {
        try {
            this._isMounted = true;
            this.loadHomeNewsData(this.state.newsData);
        } catch (e) {
            console.log(e);
        }
    }
    loadHomeNewsData(newsData) {

        let ariaproperties= "";
        let heading = "";
        let news = [];
        Object.keys(newsData).forEach(item => {

            if (newsData[item].field_whats_new_view[0].field_subtitle) {
                heading = newsData[item].field_whats_new_view[0].field_subtitle;
            }
            else {
                news.push(newsData[item]);
            }
        });

        //accessibility - adding aria property dynamically using index
        news.map((row, index) => {
            return ariaproperties += "news-slider-"+index+" "
        })
        this.setState({
            cardData: news,
            heading: heading,
            ariaproperties:ariaproperties
        });
    }
    componentDidUpdate(prevProps) {
        if (prevProps.newsData !== this.props.newsData) {
            this.loadHomeNewsData(this.props.newsData);
        }
    }
    render() {
        const { classes } = this.props;
        return (
            <div className="container-fluid mt-4 mb-4">
                <div className="row">
                    <div className="col-md-12">
                        <h2>{removePtag(this.state.heading || "")}</h2>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <section id="slider2" className="news-section">

                            <div className="large-12 mt-3 columns p-0">
                                {<CarouselProvider
                                    naturalSlideWidth={100}
                                    naturalSlideHeight={25}
                                    totalSlides={4}
                                    visibleSlides={this.state.visibleSlide}
                                >
                                    <Slider aria-label="latest-news" aria-owns={this.state.ariaproperties}>
                                        {this.state.cardData.map((event, index) => {
                                            return <Slide id={"news-slider-"+index} 
                                            style={{ backgroundImage: `url(${event.field_whats_new_view[0].url || ""})` }} 
                                            className="item" key={index}>
                                                <div className="row">
                                                    <div className="col-lg-8">
                                                        <h3>{removePtag(event.field_whats_new_view[1].field_title || "")}</h3>
                                                        <p>{removePtag(event.field_whats_new_view[2].field_para || "")}</p>
                                                    </div>
                                                </div>
                                            </Slide>
                                        })
                                        }
                                    </Slider>
                                    <ButtonBack className="arrow-btn"><ArrowBackIosIcon /></ButtonBack>
                                    <ButtonNext className="arrow-btn"><ArrowForwardIosIcon /></ButtonNext>
                                    <DotGroup className={this.state.visibleSlide === 2 ? classes.newslider : classes.mobilenewslider} showAsSelectedForCurrentSlideOnly={true}/>
                                </CarouselProvider>
                                }
                            </div>

                        </section>
                    </div>
                </div>
            </div>
        )
    }
}

export default withStyles(styles, { withTheme: true })(NewsCards);