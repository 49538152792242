import React from "react";
// import AnnouncementTable from './announcement-table';
import AttachmentTable from "./attachment-table";
import NseCorporateFilter from "./nse-corporate-announcement-filter";

class NseCorporateAnnouncement extends React.Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      headerRef: props.headerRef,
      fromdate: null,
      todate: null,
      companyName: null,
      subjectType:null,
      api: "/api/nse-corporate-announcement",
    };

    this.reset = this.reset.bind(this);
  }

  reset() {
    let api = `/api/nse-corporate-announcement`;
    this.setState({ api });
  }

  recieveDateParams = (companyName, subjectType,fromdate, todate) => {
    let api = `/api/nse-corporate-announcement?`;
    // alert(instrumenttype);
    if (fromdate && todate) {
      api += `&fromdate=${fromdate}&todate=${todate}`;
    }
    if (companyName) {
      api += `&companyName=${companyName}`;
    }
    if(subjectType){
        api += `&subjectType=${subjectType}`;
    }

    this.setState({
      api,
      companyName,
      fromdate,
      todate,
    });
  };

  componentDidMount() {
    try {
      this._isMounted = true;
    } catch (e) {
      console.log(e);
    }
  }

  render() {
    return (
      <div className="container-fluid">
        <div className="row">
          <NseCorporateFilter
            resetcallback={this.reset}
            callback={this.recieveDateParams}
          />
          <div className="col-md-12 mt-3">
            <AttachmentTable
              api={this.state.api}
              pageType="nseCorporateAction"
            />
          </div>
        </div>
      </div>
    );
  }
}

export default NseCorporateAnnouncement;
