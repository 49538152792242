import React, { Component } from 'react'
import Highlighter from "react-highlight-words";

class SearchCard extends Component {
    render() {
        return (
            <a href={this.props.type === 'content'
            && (this.props.search["result_sub_type"] && this.props.search["result_sub_type"] === 'content' )
            ? this.props.search["url"]+"?q="+this.props.highlight
        :this.props.search["url"]} className="search-link">
                <div className="autocompleteList tt-suggestion tt-selectable" id="search-results">
                    <div className="line1">
                        <span className="lt">
                            <Highlighter
                                highlightClassName="highlight-key"
                                searchWords={[this.props.highlight]}
                                autoEscape={true}
                                textToHighlight={
                                    this.props.type === 'symbol'
                                        ? this.props.search["symbol_info"]
                                        : (
                                            this.props.type === 'content'
                                                ? this.props.search["title"]
                                                : this.props.search["site_sub_module"]
                                        )
                                } />
                        </span>
                        <span>
                            <p className="tt-highlight">
                                <Highlighter
                                    highlightClassName="highlight-key"
                                    searchWords={[this.props.highlight]}
                                    autoEscape={true}
                                    textToHighlight={this.props.search["symbol"] || ""} />
                            </p>
                        </span>
                    </div>
                    <p className="line2">
                        <Highlighter
                            highlightClassName="highlight-key"
                            searchWords={[this.props.highlight]}
                            autoEscape={true}
                            textToHighlight={'in ' + this.props.search["result_sub_type"]} /></p>
                </div>
            </a>
        )
    }
}

export default SearchCard