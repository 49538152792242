import React from 'react';
import { CarouselProvider, Slider, Slide, ButtonBack, ButtonNext, ButtonPlay } from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { ReactComponent as PlayIcon } from '../../public/images/play.svg';
import { ReactComponent as PauseIcon } from '../../public/images/pause.svg';
import { UsMarkettoggle } from './custom-toolbar';

class MarketBanner extends React.Component {
    constructor(props) {
        super(props);
        this.ustickerSwitch= this.ustickerSwitch.bind(this);
        this.state = 
        {
            api:props["api-marketrate"],
            cardData: [],
            // marketData: props.marketData,
            // visibleSlide:  window.innerWidth < 400 ? 1:window.innerWidth <=768 ? 2:window.innerWidth <=1024 ? 5:window.innerWidth <=1366 ? 7:7,
            visibleSlide: 7,
            ariaproperties:""
        }
    };
    componentDidMount() {
        try {
            //console.log("market data", this.state);
            this._isMounted = true;
            this.MarketData(this.state.api);
            window.addEventListener("resize", this.resize.bind(this));
            this.resize();
        } catch (e) {
            console.log(e);
        }
    }

    ustickerSwitch(toggleVal) {
        if(toggleVal === "depository"){
            this.MarketData("/api/market-rate?type=depository")
        }else{
            this.MarketData("/api/market-rate?type=derivative")
        }
    }
    resize() {
        
        let currentmobileScreen = window.innerWidth;
        let visibleSlide = 7;
        if (currentmobileScreen < 400) {
            visibleSlide = 1;
            
        }
        else if(currentmobileScreen  <=768) {
            visibleSlide = 2;
        } 
        else if(currentmobileScreen <=800) {
            visibleSlide = 4;
        }
        else if(currentmobileScreen <=1024) {
            visibleSlide = 5;
        } 
        else if(currentmobileScreen <=1366) {
            visibleSlide = 7;
        }
        this.setState({visibleSlide});
    }
    componentWillUnmount() {
        window.removeEventListener("resize", this.resize.bind(this));
    }
    MarketData(api) {
        fetch(api)
        .then(resp => resp.json())
            .then((data) => {
                let ariaproperties= "";
                data.data.map((row, index) => {
                    return ariaproperties += "market-slider-"+index+" "
                })
                this.setState({
                    cardData: data.data, ariaproperties
                });
        })
        .catch((e)=> { console.log("error in market data",e)})
        
    }
    render() {
        return (
            <>
         
            <div className="container-fluid light-grey-bg">
                <section id="slider1" className="market-banner-slides">
                
                    <div className="row">
                        <div className="large-12 columns mt-5 mb-4">
                   
                            {<CarouselProvider  className="rating-slider" 
                                naturalSlideWidth={100}
                                naturalSlideHeight={30}
                                totalSlides={this.state.cardData.length}
                                visibleSlides={this.state.visibleSlide}
                                isPlaying={this.state.cardData.length > this.state.visibleSlide ? true : false}
                                interval={5000}
                            >
                                <Slider aria-label="market-ticker" aria-owns={this.state.ariaproperties} >
                                    {this.state.cardData.length > 0 && this.state.cardData.map((event, index) => {
                                        return <Slide id={"market-slider-"+index} key={index}>
                                            <div className={event.PERCHANGE < 0 ? "item down-rate mr-2" : "item up-rate mr-2"}>
                                                <div className="d-flex justify-content-between ">
                                                    <div className="instrument-type">
                                                        <span className="insttype">{event.INSTRUMENTTYPE}</span> {event.EXPIRYDATE || ""} {event.OPTIONTYPE || ""}
                                                        <span className="stkprice">{event.STRIKEPRICE || ""}</span>
                                                        </div>
                                                    <div className={event.PERCHANGE < 0 ? "red-color" : "green-color"}>
                                                        {event.PERCHANGE < 0 ? <ArrowDropDownIcon className="rate-up-down"/> : <ArrowDropUpIcon className="rate-up-down"/>}
                                                    </div>
                                                </div>
                                                <div className="d-flex justify-content-between">
                                                    <div className="grid-display">
                                                        <span className="normal-font">{event.SYMBOL}</span>
                                                        <span className="small-font">{event.DAYCHANGE}</span>
                                                    </div>
                                                    <div className="pr-2">
                                                        <p className="normal-font">{event.LASTPRICE}</p>
                                                        <p className={event.PERCHANGE < 0 ? "small-font red-color perc-margin-left mb-0" : "small-font green-color perc-margin-left mb-0"}>{event.PERCHANGE}%</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </Slide>
                                    })}
                                </Slider>
                                <div className="usmarkettoggle">
                                <UsMarkettoggle tickerCallback={this.ustickerSwitch} /></div>
                                <div className="slider-control-buttons">
                                <ButtonBack className="prev arrow-btn-slider"><ArrowBackIosIcon className="next-prev"/></ButtonBack>
                                <ButtonPlay className="play-pause arrow-btn-slider" childrenPaused={<PlayIcon className="next-prev"/>} childrenPlaying={<PauseIcon className="next-prev"/>} />
                                <ButtonNext className="next arrow-btn-slider"><ArrowForwardIosIcon className="next-prev"/></ButtonNext>
                                </div>
                            </CarouselProvider>}
                        </div>
                    </div>
                </section>
            </div>
            </>
        );
    }
}

export default MarketBanner;