import React, { Component } from 'react';
import { withStyles } from "@material-ui/core/styles";
import Table from '@material-ui/core/Table';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import HighChartGraph from './highchart-graph';
//import env from '../../config';
// import niftyitlogo from '../../public/images/NSEIFSC-Logo3.png';
import nifty50logo from '../../public/images/nifty50-graph.jpg';
import niftybanklogo from '../../public/images/niftybank-graph.jpg';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';

const styles = theme => ({
    redCell: {
        color: `red` 
    },
    greenCell: {
       color: `green`
    },
});
class UnderLyingInfo extends Component {
    constructor(props) {
        super(props);
        this.state = {
            api: props["data-api"],
            columns: [],
            data: [],
            latestIST: '',
            currentSymbol: "NIFTY 50",
            selectedRow: [],
            graphColour: ``
        };
        this.changeIndex = this.changeIndex.bind(this);
        this.changeLogo = this.changeLogo.bind(this);
    };

    componentDidMount() {
        try {
            //console.log("state", this.state);
            if (this.state.api && this.state.api !== "") {
                this.loadData(this.state.api);
            }
        } catch (e) {
            console.log(e);
        }
    }
    loadData(api) {
        fetch(api)
            .then(resp => resp.json())
            .then((data) => {
                this.setState({
                    data: data.data,
                    columns: data.columns,
                    latestIST: data.timestampIST,
                    graphColour: data.data[0].change,
                    selectedRow: data.data[0],
                    currentSymbol: data.data[0].index,
                    currDate:data.currDate,
                });
            })
            .catch((e) => { console.log("error in underlying info", e) })

    }
    changeIndex = (symbol, currentIndice, graphColor) => {

        this.setState({
            currentSymbol: symbol.split(' ').join('%20'),
            selectedRow: currentIndice,
            graphColour: graphColor
        })
    }

    changeLogo = (index) => {
        switch (index) {
            // case "NIFTY IT":
            //     return niftyitlogo;
            case "NIFTY 50":
                return nifty50logo;
            case "NIFTY BANK":
                return niftybanklogo;
            default:
                return "";
        }
    }

    render() {
        const { classes } = this.props;
        return (
            <div className="row underlying-info"> <div className="col-md-7 m-0">
                <div className="graph-logo">
                    {this.changeLogo(this.state.selectedRow.index) && 
                    <img className="img-responsive" alt="niftylogo" src={this.changeLogo(this.state.selectedRow.index)} />}
                </div>

                <div className="rate-wrap">
                    <span className={this.state.selectedRow.change > 0 ? "current-rate green" : "current-rate red"} >{this.state.selectedRow.currentValue}</span>
                    <span className="rate-change rate-down">{this.state.selectedRow.change}</span>
                    {this.state.selectedRow.change > 0 ? <ArrowDropUpIcon className="perchange-icon green" /> : <ArrowDropDownIcon className="perchange-icon red" />}
                    <span className="percent-rate-change">{this.state.selectedRow.perChange} %</span>
                </div>
                <div className="mt-2">
                <HighChartGraph currDate={this.state.currDate} graphColor={this.state.graphColour} details={this.state.selectedRow} api={`/api/broad-market-indice/graph?symbol=` + this.state.currentSymbol} />
                </div>
            </div>
                <div className="col-md-5 m-0">
                    <div className="table-responsive">
                        <TableContainer>
                        <h3 className="timestamp-heading float-right">As On {this.state.latestIST} IST</h3>
                            <Table className="table underlying-graph">
                            <caption className="table-caption">Broad Market Indices</caption>
                                <TableHead>
                                    <TableRow>
                                        {this.state.columns.map((col,idx) => {
                                            return <th key={col} scope="col" className={idx===0 ? "" : "text-right"}>
                                                {col}</th>

                                        })}
                                    </TableRow>
                                </TableHead>
                                <tbody>
                                    
                                    {this.state.data.map((td, i) => {
                                        return <React.Fragment  key={`${td.index}${i}`}><TableRow onClick={() => this.changeIndex(td.index, td, td.change)} scope="col" >
                                            <td>{td.index}</td>
                                            <td className={(td.change > 0 ? classes.greenCell : classes.redCell) + " text-right"}>{td.currentValue}</td>
                                            <td className={(td.change > 0 ? classes.greenCell : classes.redCell) + " text-right"}>{td.change}</td>
                                            <td className={(td.change > 0 ? classes.greenCell : classes.redCell) + " text-right"}>{td.perChange}</td>
                                        </TableRow>

                                         </React.Fragment>
                                    })}
                                </tbody>
                            </Table>
                        </TableContainer>
                    </div>
                </div></div>
        );
    }
}

export default withStyles(styles, { withTheme: true })(UnderLyingInfo);