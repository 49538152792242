import React from 'react'

class NotFound extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            headerRef: props.headerRef,
        };
    }

    componentDidMount() {
        try {
            this._isMounted = true;

            //If page not found - hide breadcrumb section
            this.props.headerRef.current.hideBreadCrum();

            this.props.headerRef.current.showHeaderWrapBackground();
        } catch (e) {
            console.log(e);
        }
    }

    render() {
        return (
            <div className="col-md-12 text-center">
                <h2 className="page-title">Page Not Found</h2>
                <a className="download-link" href="/">
                    Go To homePage
                </a>
            </div>
        );
    }
}

export default NotFound;