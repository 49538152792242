import React from 'react';
import { CarouselProvider, Slider, Slide, ButtonBack, ButtonNext, DotGroup } from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';
import PressReleaseCard from './press-release-card';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
class HomePressRelease extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            api:props["api-pr"],
            cards: [],
            pages: [],
            perpage: window.innerWidth <=576 ? 1: 2,
            sliceCards: window.innerWidth <=576 ? 1: 2,
            ariaproperties:"",
        }
    };
    componentDidMount() {
        try {
            //console.log("home press-release", this.state);
            this._isMounted = true;
            // window.addEventListener("resize", this.resize.bind(this));
            this.PressReleaseData(this.state.api);
        } catch (e) {
            console.log(e);
        }
    }
    // resize() {
    //     let currentHideNav = (window.innerWidth <= 500);
    //     if (currentHideNav !== this.state.sliceCards) {
    //         this.setState({sliceCards: currentHideNav},()=>{
    //             this.PressReleaseData(this.state.api);
    //         });
    //     }
    // }
    PressReleaseData(api) {
        fetch(api)
            .then(resp => resp.json())
            .then((cards) => {
                let pages = [];
                let pressReleasecard = cards;
                while (pressReleasecard.length > 0) {
                    pages.push(pressReleasecard.splice(0, this.state.sliceCards));
                }
                this.setState({ pages },()=>{
                    let ariaproperties= "";
                    this.state.pages.map((row, index) => {
                        return ariaproperties += "pressrelease-slider-"+index+" "
                    })
                    this.setState({
                        ariaproperties
                    })
                });
            })
        .catch((e) => { console.log("error in loading home press release", e) })
        // Fetch PressRelease data.
        
    }
    render() {
        return (
            <div className="col-lg-6">
                  <div className="title-row">
                    <span className="title"><h2>Press Release
                        </h2></span>
                    <span className="btn-row">
                    <a target="_blank" rel="noopener noreferrer" href="/resources/press-release" aria-label="View All Press Release"
                         className="btn btn-orange">View all</a>
                    </span>
                    <div className="clear"></div>
                </div>
                {/* <div className="row">
                    <div className="col-lg-12">
                        <h2>Press Release<a target="_blank" rel="noopener noreferrer" href="/resources/press-release" aria-label="View All Press Release"
                         className="btn btn-orange pr-4 pl-4 float-right">View all</a>
                        </h2>
                    </div>
                </div> */}
                <div className="row">
                    <div className="col-lg-12">
                <section id="slider4">
                    <div className="d-flex">
                        <div className="large-12  mt-4  columns p-0">
                            {
                                <CarouselProvider
                                    naturalSlideWidth={100}
                                    naturalSlideHeight={50}
                                    totalSlides={this.state.pages.length || 1}
                                >
                                    <Slider aria-label="latest-press-release" aria-owns={this.state.ariaproperties}>
                                        {
                                            this.state.pages.map((page, idx) => {
                                                return <Slide id={"pressrelease-slider-"+idx} key={`slide${idx}`} index={idx}>
                                                    {
                                                        page.map((card, jdx) => {
                                                            return <PressReleaseCard cardkey={`card${idx}${jdx}`} key={`card${idx}${jdx}`} card={card}></PressReleaseCard>
                                                        })
                                                    }
                                                </Slide>
                                            })
                                        }
                                    </Slider>
                                    <ButtonBack className="arrow-btn"><ArrowBackIosIcon /></ButtonBack>
                                    <ButtonNext className="arrow-btn"><ArrowForwardIosIcon /></ButtonNext>
                                    <DotGroup />
                                </CarouselProvider>
                            }
                        </div>
                    </div>
                </section>
                </div>
                </div>
            </div>
        )
    }
}

export default HomePressRelease;