import React from 'react';
// import { withStyles } from "@material-ui/core/styles";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { convertPrice } from './util';

class TradeInformation extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            api: props["data-api"] || "",
            columns: props.columns || [],
            data: props.data || [],
            type: props["type"] || "",
            summary: props.summary || [],
            rowIndex: props.rowIndex || 0,
        }
    }

    componentDidMount() {
        try {
            this._isMounted = true;
            if (this.state.api && this.state.api !== "") {
                this.loadTradeInfo(this.state.api);
            }

        } catch (e) {
            console.log(e);
        }

    }
    // componentDidUpdate(prevProps) {
    //     console.log("console.log",this.props.summary)
    //     if (this.props.data !== prevProps.data || 
    //         this.props.columns !== prevProps.columns || 
    //         this.props.summary !== prevProps.summary) {

    //         this.setState({ 
    //             data: this.props.data, 
    //             columns: this.props.columns,
    //             summary: this.props.summary });
    //     }
    // }
    loadTradeInfo(api) {

        fetch(api)
            .then(resp => resp.json())
            .then((response) => {
                this.setState({
                    columns: response.tradeinfocols,
                    data: response.data[this.state.rowIndex],
                    summary: response.summary ?
                        response.summary[this.state.rowIndex] : [],
                })
                // switch (this.state.type) {
                //     case "trade":
                //         this.setState({
                //             columns: response.tradecols,
                //             data: response.data[this.state.rowIndex],
                //         }
                //         )
                //         break;
                //     case "coc":
                //         this.setState({
                //             columns: response.coccols,
                //             data: response.data[this.state.rowIndex],

                //         })
                //         break;
                //     case "order":
                //         this.setState({
                //             columns: response.ordercols,
                //             data: response.data[this.state.rowIndex],
                //             summary: response.summary[this.state.rowIndex][0],
                //         })
                //         break;
                //     default:
                //         break;
                // }
            })
            .catch((e) => { console.log("error in trade info", e) })
    }

    render() {

        return (
            <>
                <TableContainer>
                    <Table id={this.state.type + "InfoTable"} className="hover-shadow-table quotes-sub-table" aria-label="simple table">
                        {this.state.type === "coc" ? <caption className="table-caption">Cost of Carry</caption> : <></>}
                        <TableHead>
                            <TableRow>
                                {this.state.columns.map((row, idx) => {
                                    return <TableCell key={idx}
                                    //align={(this.state.type==="trade" || this.state.type==="coc") && idx!==0 ? "right" : "left"}
                                    >{row}</TableCell>
                                })}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {this.state.data.map((row, kdx) => {
                                return <TableRow key={kdx}>
                                    {Object.keys(row).map((item, jdx) => {
                                        return <TableCell key={jdx}
                                        // align={typeof row[item] === "number" ? "right" : "left"}
                                        // align={(this.state.type==="trade" || this.state.type==="coc") &&  jdx!==0 ? "right" : "left"}
                                        >
                                            {typeof row[item] === "number" ? convertPrice(row[item]) : row[item]}
                                            {/* {row[item]} */}
                                        </TableCell>
                                    })}
                                </TableRow>
                            })}

                        </TableBody>

                    </Table>
                </TableContainer>

                {this.state.type === 'order' && this.state.summary &&
                    <div className="row contract-table-row">
                        <div className="col-lg-6">
                            <b>Buy</b> (Total Quantity)<br />{this.state.summary.buy}
                        </div>
                        <div className="col-lg-6">
                            <b>Sell</b> (Total Quantity)<br />{this.state.summary.sell}
                        </div>

                    </div>}
            </>
        )
    }
}

export default TradeInformation;