import React from "react";
import { Button } from "@material-ui/core";


class ScrollTerms extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isVisible: true,
    };
  }


scrollToNotes=()=> {
    var elmnt = document.getElementById("terms");
    elmnt.scrollIntoView({behavior: "smooth"});
  }


  render() {
    return (
      <div className='termsOfUse'>
        <Button onClick={this.scrollToNotes} style={{color:'#333333', textTransform:"none", outline:'none', fontFamily:'Roboto-Medium', fontSize:'16px'}}>
          Terms of Use
        </Button>
      </div>
    );
  }
}

export default ScrollTerms;
