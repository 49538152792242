import React, { Component } from 'react';
import { getDownloadIcon, removePtag, parseAsHtml, externalLinkDisclaimer } from './util';

class InfoCard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            content: "",
            proddata: props.prodcarddata,
        }
    };
    componentDidMount() {
        try {
            console.log("state", this.state);
            this._isMounted = true;
            this.ProductInfoData();
        } catch (e) {
            console.log(e);
        }
    }
    ProductInfoData() {
        //data variable
        const data = this.state.proddata;
        const components = [];

        data.field_cards.forEach((dt, idx) => {
            components.push(<div key={`pdata${idx}`} className="col-md-4 mt-3">
                {
                    this.getViews(dt, idx)
                }
            </div>);
        });

        // console.log("components", components);
        this.setState({ content: components });
        // return components;
    }

    getViews(dt, idx) {
        const views = dt.field_card_view.map((cont, jdx) => {
            const key = Object.keys(cont)[0];
            switch (key) {
                case "url":
                    if (cont.alt && cont.height && cont.width) {
                        return <a href={cont.uri || "/#"} key={`img${idx}${jdx}`}>
                            <div className="col-12 p-0 info-card-image">
                                <img src={cont.url} alt={cont.alt}
                                    width={"100%"} />
                                {/* <br /> */}
                                {/* <h5>{cont.field_title || cont.title}</h5>
                                <h6>{cont.field_para}</h6> */}
                            </div>
                        </a>
                    }
                    break;
                case "uri":
                    return <p className="col-12 pt-1 pb-1 mb-0 text-left" key={`link${idx}${jdx}`}>
                        <a target="_blank" rel="noopener noreferrer" onClick={externalLinkDisclaimer} href={cont.uri} title={cont.title}>{cont.title}</a>
                    </p>
                case "field_title":
                    return <div key={`title${idx}${jdx}`} className="col-12">
                        <h3
                            //key={`sc_${idx}${jdx}`}
                            className="static-para"
                            >{parseAsHtml(removePtag(cont.field_title))}</h3>
                    </div>
                case "field_para":
                    return <div className="col-12" key={`para${idx}${jdx}`}>
                        <div
                            //key={`sc_${idx}${jdx}`}
                            className="static-para">{parseAsHtml(cont.field_para)}</div>
                    </div>
                case "field_text":
                    if (cont.file_url) {
                        return <div key={`download${idx}${jdx}`} className="col-md-12 mt-2 mb-2"><a className="download-link" href={cont.file_url} key={`sc${idx}${jdx}`} title={cont.file_url.substring(cont.file_url.lastIndexOf('/') + 1)} aria-label={"link"+cont.file_url.substring(cont.file_url.lastIndexOf('/') + 1)}>
                            {getDownloadIcon((cont.file_url),cont.file_url.substring(cont.file_url.lastIndexOf('/') + 1))}{cont.field_text}</a></div>

                    } else {
                        return <p key={`sc${idx}${jdx}`}>{cont.field_text}</p>
                    }
                default:
                    break;
            }
            return "";
        })
        return <div className="product-block-wrap h-100 pt-2 pb-2">{views}</div>;
    }

    render() {
        return (
            <div className="container-fluid">
                <div className="mb-3 row">
                    {
                        this.state.content
                    }
                </div>
            </div>
        )
    }
}

export default InfoCard;