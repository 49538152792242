import React from 'react';
import {removePtag, externalLinkDisclaimer} from './util';

class HomeAdBanner extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            cardData: [],
            AdBannerCard: props.AdBannerCard,
        }
    };
    componentDidMount() {
        try {
            //console.log("ad banner", this.state);
            this._isMounted = true;
            this.AdBannerData();
        } catch (e) {
            console.log(e);
        }
    }
    componentDidUpdate(prevProps){
        if(prevProps.AdBannerCard !== this.props.AdBannerCard){
            this.setState(
                {cardData: this.props.AdBannerCard});
        }
    }
    AdBannerData() {
        this.setState({
            cardData: this.state.AdBannerCard
        });
    }
    render() {
        return (
            <div className="container-fluid">
            <div className="row">
            
               
                    {this.state.cardData.map((event, index) => {
                        return <div key={index} className="ad-banner" style={{backgroundImage: `url(${event.field_homepage_banner_view[0].url || ""})`}}>
                        <div className="adbanner-container">
                        <div className="col-lg-8" key={index}>
                            <h2>{removePtag(event.field_homepage_banner_view[1].field_title || "")}</h2>
                            <p>{removePtag(event.field_homepage_banner_view[2].field_para || "")}</p>
                            <a onClick={externalLinkDisclaimer} target="_blank" rel="noopener noreferrer" href={event.field_homepage_banner_view[3].uri} className="btn btn-secondary">
                            {event.field_homepage_banner_view[3].title}</a>
                        </div>
                        </div>
                        </div>
                    })}
                </div>
           
        </div>
        )
    }
}

export default HomeAdBanner;