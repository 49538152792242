import React, { Component } from "react";
import LeftNavigationPane from './LeftNavigationPane';

class LeftNavigationContainer extends Component {
  _isMenuLoading = false;
  constructor(props) {
    super(props);
    this.state = {
      page: {
        menus: [],
        menukey: props.menukey,
        pagekey: props.pagekey
      },
    }
  }
  componentDidMount() {
    try {
      this._isMounted = true;
    } catch (e) {
      console.log(e);
    }
  }

  updatekeys({ pagekey, menukey }) {
    this.loadMenu(menukey, pagekey);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.menukey !== this.state.page.menukey) {
      try {
        this._isMounted = true;
        this.loadMenu(prevProps.menukey, prevProps.pagekey);
      } catch (e) {
        console.log(e);
      }
    }
  }

  //Load menu details of active left pane of Page
  loadMenu(menukey, pagekey) {
    if (!this._isMenuLoading && menukey) {
      this._isMenuLoading = true;
      const menuUrl = `/api/contents/menu?key=${menukey}`;
      fetch(menuUrl)
        .then(resp => resp.json())
        .then((data) => {
          //data[0].items.pop();
          this.setState({
            page: { menus: data, menukey, pagekey },
          }, () => {
            this._isMenuLoading = false;
          })
        })
        .catch((e) => {
          console.log("error in fetching menu bar content", e)
        });
    }
  }

  render() {
    return (
      <LeftNavigationPane menudata={this.state.page} />
    );
  }
}
export default LeftNavigationContainer;