import React, { Component } from 'react';
import moment from "moment";
import { Select, MenuItem } from "@material-ui/core";
import { MuiPickersUtilsProvider, KeyboardDatePicker, } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';

const ButtonHeight = {
    height: '100%',
}
const dateFormat = "DD-MMM-YYYY";
class PressReleaseFilters extends Component {
    constructor(props) {
        super(props);
        this.state = {
            prType: '',
            customtodate: null,
            customfromdate: null,
            fromdate: moment(new Date()).subtract(3, 'months').format(dateFormat),
            todate: moment().utc().utcOffset("+05:30").format(dateFormat),
            validationfrom: '',
            validationTo: '',
            maxFromDate: moment().utc().utcOffset("+05:30").format(dateFormat),
            resetcallback: props.resetcallback,
            callback:props.callback,
            filterFromDt:moment(new Date()).subtract(3, 'months').format(dateFormat),
            filterToDt:moment().utc().utcOffset("+05:30").format(dateFormat),
            linksarray: [
                {
                    id: "1D",
                },
                {
                    id: "1W",
                },
                {
                    id: "1M",
                },
                {
                    id: "3M",
                },
                {
                    id: "6M",
                },
                {
                    id: "1Y",
                },
                {
                    id: "Clear",
                },

            ],
            prCategories:[],
            activeLink: "3M",
            searchPrKey:'',
        };
        this.handleClick = this.handleClick.bind(this);
        this.handleFromDateChange = this.handleFromDateChange.bind(this);
        this.handleToDateChange = this.handleToDateChange.bind(this);
        this.filter = this.filter.bind(this);
        this.customDateFilter = this.customDateFilter.bind(this);
        this.reset = this.reset.bind(this);
        this.searchPressRelease = this.searchPressRelease.bind(this);
        this.validateFromToDate = this.validateFromToDate.bind(this);
        this.onSelectCategory = this.onSelectCategory.bind(this);

    };
    componentDidMount() {
        try {
            this._isMounted = true;

                this.loadPressReleaseCategory();
            
            
        } catch (e) {
            console.log(e);
        }
    }
    handleFromDateChange = (date) => {
        this.setState({
            customfromdate: moment(date,dateFormat).format(dateFormat),
            // fromDateOpen: false
        },()=>{
            this.validateFromToDate();
        });
    }

    loadPressReleaseCategory() {
          const categoryUrl = `/api/contents/press-release-category`;
          fetch(categoryUrl)
            .then(resp => resp.json())
            .then((data) => {
              this.setState({
                prCategories:data[0].items
              })
            })
            .catch((e) => {
              console.log("error in fetching press release categories", e)
            });
    }

    //set validation helper message
    validateFromToDate = () => {
        if(moment(this.state.customtodate,dateFormat) < moment(this.state.customfromdate,dateFormat)){
            this.setState({validationfrom:"From date should not be after to date"})
          }
          else {
              this.setState({validationfrom:""}) 
        }
    }
    handleToDateChange = (date) => {
        this.setState({
            customtodate: moment(date,dateFormat).format(dateFormat),
        }, () => {
            this.validateFromToDate();
            this.setState({ maxFromDate: this.state.customtodate })
        });
    }
    
    handleClick(id) {
        this.setState({
            activeLink: id,
        }, function () {
            this.setState({
                maxFromDate: moment().utc().utcOffset("+05:30").format(dateFormat),
            })

            switch (id) {
                case "1D":
                    this.setState({
                        fromdate: moment(new Date()).subtract(1, 'days').format(dateFormat),
                    },()=>{
                        this.filter();
                    });
                   
                    break;
                case "1W":
                    this.setState({
                        fromdate: moment(new Date()).subtract(7, 'days').format(dateFormat),
                    },()=>{
                        this.filter();
                    });
                    break;
                case "1M":
                    this.setState({
                        fromdate: moment(new Date()).subtract(1, 'months').format(dateFormat),
                    },()=>{
                        this.filter();
                    });
                    break;
                case "3M":
                    this.setState({
                        fromdate: moment(new Date()).subtract(3, 'months').format(dateFormat),
                    },()=>{
                        this.filter();
                    });
                    break;
                case "6M":
                    this.setState({
                        fromdate: moment(new Date()).subtract(6, 'months').format(dateFormat),
                    },()=>{
                        this.filter();
                    });
                    break;
                case "1Y":
                    this.setState({
                        fromdate: moment(new Date()).subtract(1, 'year').format(dateFormat),
                    },()=>{
                        this.filter();
                    });
                    break;
                case "Clear":
                    // this.setState({ fromdate: null, todate: null });
                    this.reset();
                    break;

                default:
                    break;
            }
        })
    }

    filter() {
        this.setState({
            todate: moment().utc().utcOffset("+05:30").format(dateFormat),
            customtodate:null,
            customfromdate:null,
            filterFromDt:this.state.fromdate,
            filterToDt:this.state.todate
        },()=>{
            this.state.callback(this.state.filterFromDt, this.state.filterToDt,
                this.state.prType,this.state.searchPrKey);
        });        
    }
    customDateFilter() {
        if (this.state.customfromdate && !this.state.customtodate) {
            this.setState({
                validationTo: "Please Select To Date"
            })
        }
        else if (!this.state.customfromdate && this.state.customtodate) {
            this.setState({
                validationfrom: "Please Select From Date"
            })
        }
        else if (this.state.customfromdate && this.state.customtodate && moment(this.state.customtodate,dateFormat) >= moment(this.state.customfromdate,dateFormat)) {
            this.setState({
                validationTo: '',
                validationfrom: '',                
                activeLink: "",
                filterFromDt:this.state.customfromdate,
                filterToDt:this.state.customtodate
            },()=>{
                this.state.callback(this.state.filterFromDt, this.state.filterToDt,
                    this.state.prType,this.state.searchPrKey);
            });            
        }
       
        else { }
    }
    reset() {
        this.setState({
            fromdate: moment(new Date()).subtract(3, 'months').format(dateFormat),
            todate: moment().utc().utcOffset("+05:30").format(dateFormat),
            customtodate:null,
            customfromdate:null,
            validationTo: '',
            validationfrom: '',
            activeLink: "3M",
            searchPrKey:'',
            prType:'',
            maxFromDate: moment().utc().utcOffset("+05:30").format(dateFormat),
            filterFromDt:moment(new Date()).subtract(3, 'months').format(dateFormat),
            filterToDt:moment().utc().utcOffset("+05:30").format(dateFormat),
        })
        this.state.resetcallback();
    }
    searchPressRelease(e){
        this.setState({
            searchPrKey: e.target.value
        },()=>{
            this.state.callback(this.state.filterFromDt, this.state.filterToDt,
                this.state.prType,this.state.searchPrKey);
        })
    }
    onSelectCategory(e){
        this.setState({ prType: e.target.value },()=>{
            this.state.callback(this.state.filterFromDt, this.state.filterToDt,
                this.state.prType,this.state.searchPrKey);
        })
    }
    render() {
        return (
            <>
            <div className="row">
                    <div className="filterdata col-5">
                        {this.state.linksarray.map((links, index) => {
                            return <div key={index} data-val={links.id} onClick={() => this.handleClick(links.id)} className={(links.id === this.state.activeLink ? "days active" : "days")}>
                                {links.id}</div>
                        })}
                    </div>
                </div>
                <div className="row corporate-filter-fields">
                  
                    <div className="col-md-3 cofilter-padding">
                        <Select className="customize-input-group quotes-select-filter"
                            onChange={this.onSelectCategory} value={this.state.prType}
                            displayEmpty>

                            <MenuItem value="" disabled>Category</MenuItem>

                            {this.state.prCategories.map((x, idx) => {
                                return <MenuItem key={x.key} value={x.name}>
                                    {x.name}
                                </MenuItem>
                            })}
                        </Select>
                    </div>
                    <div className="col-md-3 cofilter-padding">
                          <div className="input-group colorsearchbar">

                                    <input type="text" className="form-control" placeholder="Search by Keywords"
                                     value={this.state.searchPrKey}
                                     onChange={this.searchPressRelease} autoComplete="off"/>
                                    <div className="input-group-append">

                                        <span className="searchgroupicon"><i className="fa fa-search" aria-hidden="true"></i>
                    </span>
                                    </div>
                                </div>
                       
                    </div>

                <div className="col-md-2 cofilter-padding">
                        <div className="customize-input-group-date customize-input-group">
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <KeyboardDatePicker placeholder="From" variant="inline" format="dd-MMM-yyyy" margin="normal" views={["year", "month","date"]} openTo="year"
                                    id="fromDate" disableFuture={true} value={this.state.customfromdate} onChange={this.handleFromDateChange}
                                    autoOk={true}
                                    KeyboardButtonProps={{ "aria-label":"from_date_button" }}
                                    helperText={
                                        this.state.validationfrom !== "" ?
                                        this.state.validationfrom  : null
                                    }
                                    maxDate={this.state.maxFromDate}
                                    />
                            </MuiPickersUtilsProvider>
                        </div>
                    </div>
                    
                    <div className="col-md-2 cofilter-padding">
                        <div className="customize-input-group-date customize-input-group">
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <KeyboardDatePicker placeholder="To" variant="inline" format="dd-MMM-yyyy" margin="normal" views={["year", "month","date"]} openTo="year"
                                    id="toDate" maxDate={new Date()} value={this.state.customtodate} onChange={this.handleToDateChange}
                                    KeyboardButtonProps={{ "aria-label":"to_date_button" }}
                                    autoOk={true}
                                    disableFuture={true}
                                    helperText={
                                        this.state.validationTo !== "" ?
                                        this.state.validationTo  : null
                                    }
                                    />
                            </MuiPickersUtilsProvider>
                            
                        </div>

                    </div>
                   
                    <div className="col-md-1 cofilter-padding">
                        <button className="btn btn-orange full-width cofilter-btn" style={ButtonHeight} onClick={this.customDateFilter}>Go</button>
                    </div>
                   
                </div>
            </>
        );
    }
}
export default PressReleaseFilters;