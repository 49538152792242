import React from 'react';
import { removePtag } from './util';
class ActiveTabs extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            contents: props.contents || [],
            colClass: `col-md-${Math.round(12 / (props.tabCount || 1))}`
        }
    }

    render() {
        return (
            <div className="row col-12 active-tabs mb-2">
                {
                    this.state.contents.map((content, idx) => {
                        return <div key={idx} className={this.state.colClass}>
                            <h2 className={`tab-like-heading ` + (idx % 2 === 0 ? 'blue' : `peach`)}>
                                <span> {removePtag(content.title)}</span>
                            </h2>
                            <div className="my-3">{content.body}</div>
                        </div>
                    })
                }
            </div>
        );
    }
}

export default ActiveTabs;