import React from 'react';
// import NavigationIcon from '@material-ui/icons/Navigation';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import IconButton from "@material-ui/core/IconButton";

class ScrollTop extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

            isVisible: false,
        };
    }
    componentDidMount() {
        try {
            var scrollComponent = this;
            window.addEventListener("scroll", function (e) {
                scrollComponent.toggleVisibility();
            });


        } catch (e) {
            console.log(e);
        }

    }
    componentWillUnmount() {
        var scrollComponent = this;
        window.removeEventListener("scroll", function (e) {
            scrollComponent.toggleVisibility();
        });
    }
    
    toggleVisibility = () => {

        if (window.pageYOffset >= 768) {
            this.setState({ isVisible: true })
        } else {
            this.setState({ isVisible: false })
        }

    };

    scrollToTop = () => {
        console.log("smooth scroll");
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    };
    render() {
        return (
            <div className="scroll-to-top">
                {this.state.isVisible ?
                 <IconButton onClick={this.scrollToTop} aria-label="scroll-top">
                 <ExpandLessIcon className="expandFooter" /><span className="hide">Scroll Top</span>
                 </IconButton>
                    // <IconButton onClick={this.scrollToTop} >
                    //     <NavigationIcon />
                    // </IconButton>

                    : ""}
            </div>
        );
    }
}

export default ScrollTop;
