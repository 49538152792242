import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { withRouter} from 'react-router-dom';

const AntTabs = withStyles({
  indicator: {
    backgroundColor: 'transparent',
  },

})(Tabs);

const AntTab = withStyles((theme) => ({
  root: {
    color: '#333333',
    fontSize: '16px',
    fontFamily: 'Roboto-Regular',
    lineHeight: '26px',
    border: '1px solid #D9D9D9',
    marginRight: '10px',
    boxShadow: '0px 2px 4px 2px rgb(0 0 0 / 10%)',
    textTransform: 'none',
    opacity: 1,
    padding: "3px 0px 0px 0px",
    height: `100%`,
    '&:hover': {
      backgroundClip: "content-box",
      backgroundColor: '#fff',
      color: '#C25414',
      borderTop: "3px solid #C25414",
      '& .hometabs-icons svg': {
        fill: '#C25414',
      }
    },
    '&$selected': {
      backgroundClip: "content-box",
      color: ' #fff ',
      backgroundColor: '#C25414 ',
      borderTop: "3px solid #C25414"
    },
    '&:focus': {
      backgroundClip: "content-box",
      color: ' #fff',
      outline: '0px',
      backgroundColor: '#C25414',
      borderTop: "3px solid #C25414"
    },
  },
  selected: {},
}))((props) => <div className="navtabs"><Tab disableRipple {...props} /></div>);


const AntTab1 = withStyles((theme) => ({
  root: {
    '& span': {
      padding: "0px 10px",
      fontFamily: 'Roboto-Regular',
      fontSize: '16px',
    },
    padding: "3px 0px 0px 0px",
    borderTop: "3px solid #ffffff",
    // '.quotes .primary': {
    //   color: '#4A4A4A',
    //   textTransform: 'none',
    //   opacity: 1,
    //   // padding: '0px 8px',
    //   // margin: '0px',

    // },
    color: '#4A4A4A',

    textTransform: 'none',
    opacity: 1,
    // padding: '0px 8px',
    // margin: '0px',
    // padding: "4px 0px 0px 0px",
    // '.quotes .quotes-tab': {
    //   backgroundClip: "content-box",
    //   backgroundColor: '#fff',
    //   color: '#392D7D',
    //   borderTop: "3px solid #392D7D"
    // },
    // '.quotes .primary &:hover': {
    //   backgroundClip: "content-box",
    //   backgroundColor: '#fff',
    //   color: '#C25414',
    //   borderTop: "3px solid #C25414"
    // },
    '&:hover': {
      backgroundClip: "content-box",
      backgroundColor: '#fff',
      color: '#C25414',
      borderTop: "3px solid #C25414"
    },
    '&$selected': {
      backgroundClip: "content-box",
      color: ' #C25414',
      borderTop: "3px solid #C25414"
    },
    // '.quotes .primary &$selected': {
    //   backgroundClip: "content-box",
    //   backgroundColor: `#fff`,
    //   color: ' #C25414',
    //   borderTop: "3px solid #C25414"
    // },
    '.quotes .quotes-tab &$selected': {
      backgroundClip: "content-box",
      backgroundColor: `#392D7D`,
      color: ' #fff',
      borderTop: "3px solid #392D7D"
    },
    '.quotes .quotes-tab &$selected:hover': {
      backgroundClip: "content-box",
      backgroundColor: `#392D7D`,
      color: ' #fff',
      borderTop: "3px solid #392D7D"
    },
    '.quotes .quotes-tab &:hover': {
      backgroundClip: "content-box",
      backgroundColor: '#fff',
      color: '#392D7D',
      borderTop: "3px solid #392D7D",
      // marginLeft: '5px !important'
    },
    // '&:focus': {
    //   backgroundClip: "content-box",
    //   backgroundColor: '#392D7D',
    //   color: '#fff',
    //   fontSize: '14px',
    //   fontFamily: 'Montserrat Regular',
    //   padding: "4px 0px 0px 0px",
    //   borderTop: "3px solid #fff"
    // },
    '&:active': {
      //  backgroundColor: 'red !important',
    },
    '&:focus': {
      backgroundClip: "content-box",
      outline: '0px',
      color: '#C25414',
      borderTop: "3px solid #C25414"
    },
  },
  selected: {},
}))((props) => <div className={"mt-3 navtabs1 "+ (props.selected ? "symboltabs" : "")}>
  <Tab disableRipple {...props} /></div>);




class TabPanel extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      pageLoaded:false
    }
  }

  componentDidMount() {
    this.setState({pageLoaded:true})
  }
  render() {
    const { children, value, index, color, staticpage,title, ...other } = this.props;
    let tabtitle = title.replace(/\s/g, '');
    return ( this.state.pageLoaded ? 
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${tabtitle || "" }`}
        aria-labelledby={`simple-tab-${tabtitle || ""}`}
        {...other}
      >
        {value === index && (
          // <div className="tabpanel-body" style={color !== "secondary" ? { padding: '16px'} : { paddingTop: '0px', paddingBottom: '16px' }}>
          <div className="tabpanel-body">
            <div className={staticpage ? "row m-0" : ""}>
              {children}
            </div>
          </div>
        )}
      </div> : null 
    );
  }
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const useStyles = (theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
});



class NavTabs extends React.Component {
  constructor(props) {
    super(props);
    const tabId = new URLSearchParams(props.location.hash);
    this.state = {
      value: 0,
      tabs: props.tabs,
      tabStyleBlock: props.tabStyleBlock,
      color: props.color,
      staticpage: props.staticpage,
      additionalTabs: typeof props.addToTabs !== 'undefined' ? props.addToTabs : [],
      currentTab:`${tabId || ''}`,
    }
  }

  componentDidUpdate(prevProps) {

    if (this.props.containsAdditionTab) { //for get quotes

      if (this.props.tabStyleBlock !== prevProps.tabStyleBlock || this.props.tabs !== prevProps.tabs || this.props.addToTabs !== prevProps.addToTabs) {
        this.setState({ tabStyleBlock: this.props.tabStyleBlock, additionalTabs: this.props.addToTabs })

        var tmp = this.props.tabs;
        for (var i = 0; i < this.props.addToTabs.length; i++) {
          tmp.push(this.props.addToTabs[i]);
        }
        this.setState({ tabs: tmp })


      }
    }
    else {
      if (this.props.tabStyleBlock !== prevProps.tabStyleBlock || this.props.tabs !== prevProps.tabs) {
        this.setState({ tabStyleBlock: this.props.tabStyleBlock, tabs: this.props.tabs })
      }
    }
  }


  componentDidMount() {

    var tmp = this.state.tabs; //for get quotes
    for (var i = 0; i < this.state.additionalTabs.length; i++) {
      tmp.push(this.state.additionalTabs[i]);
    }
    this.setState({ tabs: tmp })
    this.state.tabs.forEach((item,idx)=>{
      if(item.activeTabName){
        // console.log("activeTabName",item.activeTabName)
        this.setState({ value: idx })
      }
    })
    let jdx = 0;
    this.state.tabs.forEach((item,kdx)=>{
      
      if(this.state.currentTab && this.state.currentTab !== "" 
      && this.state.currentTab.includes(item.title.replace(/\s/g, '').toLowerCase())){
       
        jdx = kdx;
        this.handleChange([], jdx);
      }
    })
    
  }

  handleChange = (event, newValue) => {
    this.setState({ value: newValue })
  };

  a11yProps = (index,title) => {
    title = title.replace(/\s/g, '');
    return {
      id: `simple-tab-${title || ""}`,
      'aria-controls': `simple-tabpanel-${title || ""}`,
    };
  }
  labelIcon = (tab) => {
    return <span className="label-icon">{tab.icon ? tab.icon : ""}{tab.title}</span>
  }
  render() {
    const { classes } = this.props
    return (

      <div className={this.state.color === "secondary" ? (classes.root + " quotes nav-tabs-body") 
      // : this.state.color === "primary" ? classes.root + " primary nav-tabs-body" 
      :( classes.root + "  nav-tabs-body")}>

        <AntTabs variant="scrollable"
         value={this.state.value} onChange={this.handleChange} aria-label="tabs"
        className={this.state.color === "secondary" ? "quotes-tab" : "" }
        >
          {this.state.tabs.map((tab, idx) => {
            //for get quotes
            if (typeof tab.redirectUrl !== "undefined" && tab.redirectUrl !== '') {

              if (this.state.tabStyleBlock) {
                return <AntTab wrapped key={`tab${idx}`} onClick={() => {
                }} label={this.labelIcon(tab)} {...this.a11yProps(idx,tab.title)} />
              }
              else {
                //get quotes
                return <a key={`tab${idx}`} className="quote-link" href={tab.redirectUrl}> 
                <AntTab1 wrapped key={`tab${idx}`} onClick={() => {

                }} label={tab.title} {...this.a11yProps(idx,tab.title)} /> </a>
              }

            } else {

              if (this.state.tabStyleBlock) {
                return <AntTab wrapped key={`tab${idx}`} label={this.labelIcon(tab)} {...this.a11yProps(idx,tab.title)} />
              }
              else {

                return <AntTab1 wrapped key={`tab${idx}`} label={tab.title} {...this.a11yProps(idx,tab.title)} />
              }
            }
          })}
        </AntTabs>

        {this.state.tabs.map((tab, idx) => {

          return (
            <TabPanel title={tab.title} staticpage={this.state.staticpage} value={this.state.value} index={idx} key={`tabpanel${idx}`} color={this.state.color}>
              {tab.component}
            </TabPanel>
          )
        })}
      </div>

    );
  }
}

export default withStyles(useStyles, { withTheme: true })(withRouter(NavTabs));