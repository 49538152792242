import React from 'react';
//import env from '../config';;
import NavTabs from './components/nav-tabs';
import AllResultsTab from './components/all-results-tab';

class AllSearchResults extends React.Component {
    _isMounted = false;
    constructor(props) {
        super(props);
        const queryparams = new URLSearchParams(props.location.search);
        this.state = {
            headerRef: props.headerRef,
            queryparams,
            api: `/api/search?q=${queryparams.get("q") || ''}`,

        }
    }
  
    componentDidMount() {
        try {
            this._isMounted = true;
            this.state.headerRef.current.updateTitle("Search", "Search",[]);
            this.state.headerRef.current.showHeaderWrapBackground();
        } catch (e) {
            console.log(e);
        }
    }

    render() {
        return (

            <div className="container-fluid search-results-section mb-4">
                <div className="row">
                    <div className="col-md-12">
                        <NavTabs tabs={[
                            {
                                title: "All", component:<AllResultsTab type='all' page={1} api={this.state.api} />
                            },
                            {
                                title: "Markets", component: <AllResultsTab page={1} type='quotes' api={this.state.api} />
                            },
                            {
                                title: "Circulars", component: <AllResultsTab page={1} type='circulars' api={this.state.api} />
                            },
                            
                            {
                                title: "Content", component: <AllResultsTab page={1} type='content' api={this.state.api} />
                            },
                        ]} />
                    </div>
                </div>
            </div>

        );
    }
}

export default AllSearchResults;