import React, { Component } from 'react';
import moment from "moment";
import { Select, MenuItem } from "@material-ui/core";
import { MuiPickersUtilsProvider, KeyboardDatePicker, } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
//import env from '../../config';

const ButtonHeight = {
    height: '100%',
}

const dateFormat = "DD-MMM-YYYY";
class UsStocksFilters extends Component {
    constructor(props) {
        super(props);
        this.state = {
            // api: `/api/corporate-information?&from=${moment(new Date()).subtract(7, 'days').format(dateFormat)}&to=${moment().utc().utcOffset("+05:30").format(dateFormat)}`,
            // api1: `/api/corporate-information`,
            // selectIssuerName: [],
            selectUsType: ["Us Stocks"],
            selectType: "Us Stocks",
            fromDateOpen: false,
            toDateOpen: false,
            todate:  null,
            fromdate: null,
            validationfrom: '',
            validationTo: '',
            maxFromDate: moment().utc().utcOffset("+05:30").format(dateFormat),
            searchCorpAnn: '',
            
            callback: props.callback,
            resetcallback: props.resetcallback,
            
        };
        //this.handleClick = this.handleClick.bind(this);
        this.handleFromDateChange = this.handleFromDateChange.bind(this);
        this.handleToDateChange = this.handleToDateChange.bind(this);
        this.filter = this.filter.bind(this);
        this.reset = this.reset.bind(this);
        this.searchCorpAnnFunc = this.searchCorpAnnFunc.bind(this);
        // this.state.callback(this.state.issuerName, this.state.selectType,
        //     this.state.fromdate, this.state.todate);
        //this.state.resetcallback(this.state.api)

    };
    componentDidMount() {
        try {
            this._isMounted = true;
            // this.loadIssuerName(this.state.api1 + '/disclosure-names');
            // this.loadselectType(this.state.api1 + '/disclosure-description');
        } catch (e) {
            console.log(e);
        }
    }
    handleFromDateChange = (date) => {
        this.setState({
            fromdate: moment(date,dateFormat).format(dateFormat),
            fromDateOpen: false
        });

    }

    handleToDateChange = (date) => {
        this.setState({
            todate: moment(date,dateFormat).format(dateFormat),
            toDateOpen: false,

        }, () => {
            this.setState({ maxFromDate: this.state.todate })
        });
    }

    filter() {
        if (this.state.fromdate && !this.state.todate) {
            this.setState({
                validationTo: "Please Select To Date"
            })
        }
        else if (!this.state.fromdate && this.state.todate) {
            this.setState({
                validationfrom: "Please Select From Date"
            })
        }
        else if (this.state.selectType || 
            (this.state.fromdate && this.state.todate && 
            moment(this.state.todate,dateFormat) >= moment(this.state.fromdate,dateFormat))) {
                
            this.setState({
                validationTo: '',
                validationfrom: '',
            })
            this.state.callback(this.state.selectType,
                this.state.fromdate, this.state.todate,this.state.searchCorpAnn);

        }
        else if (this.state.fromdate === null && this.state.todate === null) {
            
            // this.state.callback(this.state.issuerName, this.state.selectType, this.state.fromdate, this.state.todate
            // );
        }
        else { }
    }
    reset() {
        this.setState({
            selectType: 'Us Stocks',
            todate: null,
            fromdate: null,
            validationTo: '',
            validationfrom: '',
            maxFromDate: moment().utc().utcOffset("+05:30").format(dateFormat),
            searchCorpAnn:''
        })
        this.state.resetcallback()
     }
     searchCorpAnnFunc(e) {
        this.setState({
            searchCorpAnn: e.target.value
        })
        this.state.callback(this.state.selectType,
        this.state.fromdate, this.state.todate,
        e.target.value);
        
    }
    render() {
        return (
            <>

               
                <div className="row corporate-filter-fields">
                    
                    <div className="col-md-3 cofilter-padding">
                        <Select className="customize-input-group quotes-select-filter"
                            onChange={(e) => { this.setState({ selectType: e.target.value }) }} value={this.state.selectType}
                            displayEmpty>
                            <MenuItem value="Us Stocks" disabled>Select Type</MenuItem>
                            {this.state.selectUsType.map((x, idx) => {
                                return <MenuItem key={x + idx} value={x}>
                                    {x}
                                </MenuItem>
                            })}
                        </Select>
                    </div>
                    <div className="col-md-3 cofilter-padding">
                        <div className="input-group colorsearchbar">

                            <input title="Symbol" type="text" className="pr-0 circular-search form-control" placeholder="Symbol"
                                value={this.state.searchCorpAnn}
                                onChange={this.searchCorpAnnFunc} autoComplete="off" />
                            <div className="input-group-append">

                                <span className="searchgroupicon"><i className="fa fa-search" aria-hidden="true"></i>
                                </span>
                            </div>
                        </div>

                    </div>
                    <div className="col-md-2 cofilter-padding">
                        <div className="customize-input-group-date customize-input-group">
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <KeyboardDatePicker placeholder="From" variant="inline" format="dd-MMM-yyyy" margin="normal"  views={["year", "month","date"]} openTo="year"
                                    id="fromDate" maxDate={this.state.maxFromDate} value={this.state.fromdate} onChange={this.handleFromDateChange}
                                    disableFuture={true}
                                    autoOk={true}
                                    KeyboardButtonProps={{ "aria-label":"from_date_button" }}
                                    helperText={
                                        moment(this.state.todate,dateFormat) < moment(this.state.fromdate,dateFormat) ?
                                        "From date should not be after to date"  : null
                                    }
                                    />
                            </MuiPickersUtilsProvider>
                        </div>
                    </div>
                    <span className="red cofilter-validation-from">{this.state.validationfrom}</span>
                    <div className="col-md-2 cofilter-padding">
                        <div className="customize-input-group-date customize-input-group">
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <KeyboardDatePicker placeholder="To" variant="inline" format="dd-MMM-yyyy" margin="normal" views={["year", "month","date"]} openTo="year"
                                    id="toDate" maxDate={new Date()} value={this.state.todate} onChange={this.handleToDateChange}
                                    KeyboardButtonProps={{ "aria-label":"to_date_button" }}
                                    disableFuture={true}
                                    autoOk={true}
                                   
                                    />
                            </MuiPickersUtilsProvider>
                        </div>

                    </div>
                    <span className="red cofilter-validation-to">{this.state.validationTo}</span>
                    <div className="col-md-1 cofilter-padding">
                        <button className="btn btn-orange full-width cofilter-btn" style={ButtonHeight} onClick={this.filter}>Go</button>
                    </div>
                    <div className="col-md-1 cofilter-padding">
                        <button className="btn btn-primary full-width cofilter-btn" style={ButtonHeight} onClick={this.reset}>Reset</button>
                    </div>
                </div>
            </>
        );
    }
}
export default UsStocksFilters;