import React from 'react';
import { getDownloadIcon, removePtag, getTitleFileExtension } from './util';
import { sanitizeUrl } from './sanitize-url';
import Tooltip from '@material-ui/core/Tooltip';

class MediaCoverageCard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            card: props.card,
            alignCard: props.alignCard,
            cardkey:props.cardkey || "",
        };
       
    }
    componentDidUpdate(prevProps) {
        if (this.props.alignCard !== prevProps.alignCard) {
            this.setState({ alignCard: this.props.alignCard })
        }
        if (this.props.card !== prevProps.card) {
            this.setState({ card: this.props.card })
        }
    }
    render() {
        let title = removePtag(this.state.card.title);
        const listview = (card) => {
        return <><div className="image">
        <img className="image" alt={card.image.alt} src={card.image.url} />
        </div>
        
        <div className="mediacovgcont">
            <p className="date"><strong>{card.date}</strong></p>
            <h3 title={removePtag(card.title)}>{removePtag(card.title)}</h3>
            {/* <p className="subtitle">
            {removePtag(card.para)}
            </p> */}
            {/* <p  className="subtitle">{removePtag(card.desc)}</p> */}
            <Tooltip title={"Download " +getTitleFileExtension(card.download)} placement="right">
            <a target="_blank" rel="noopener noreferrer" 
             aria-label={"View Media Coverage File for "+title} className="download-link" href={sanitizeUrl(card.download)}>{getDownloadIcon(card.download, this.state.cardkey)}Download</a>
            </Tooltip>
            {/* <div><a href={card.viewMore}>View More
            </a></div> */}
        </div></>;
        }

       if (this.state.alignCard.toUpperCase() === "LISTVIEW") {
            return (
                // list view
                <div className="mediacovg">
                    {this.state.card.viewMore ?
                    <a target="_blank" rel="noopener noreferrer" href={this.state.card.viewMore} className="d-flex media-card-link">
                       {listview(this.state.card)}
                    </a>
                    :
                    <div className="d-flex media-card-link">
                       {listview(this.state.card)}
                    </div>}
                </div>)
        }
        else if (this.state.alignCard.toUpperCase() === "GRIDVIEW") {
            return (
                //grid view
                // <div className="col-lg-4">
                    <div className="block-wrap">
                       <div className="img-media"> <img src={this.state.card.image.url} alt={this.state.card.image.alt}/></div>
                        <div className="content-block">
                            <h3 title={removePtag(this.state.card.title)}>{removePtag(this.state.card.title)}</h3>
                            <button className="btn btn-secondary align-btn">{this.state.card.date}</button>
                            {/* <p  className="subtitle">
                            {removePtag(this.state.card.para)}
                            </p> */}
                            <div className="row align-items-center mt-3">
                                <div className="col-lg-6">
                                    {this.state.card.viewMore ? 
                                    <a target="_blank" rel="noopener noreferrer" className="btn btn-primary" title="View More" href={this.state.card.viewMore} aria-label={"View More "+removePtag(this.state.card.title)}>View More                             
                                    </a> : <></>}
                                </div>
                                <div className="col-lg-6">
                                    <div className="d-flex align-items-center blue-color justify-content-end">
                                    <Tooltip title={"Download " +getTitleFileExtension(this.state.card.download)} placement="right">
                                    <a target="_blank" rel="noopener noreferrer" 
                                     className="download-link" href={sanitizeUrl(this.state.card.download)} title={this.state.card.download.substring(this.state.card.download.lastIndexOf('/') + 1)} aria-label={"Download"+removePtag(this.state.card.title)}>{getDownloadIcon(this.state.card.download, this.state.cardkey)}Download</a>
                                    </Tooltip>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                // </div>
            )
        }
        else {
            return ('')
        }
    }
}

export default MediaCoverageCard;