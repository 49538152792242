import React from 'react';
import MUIDataTable from "mui-datatables";
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { TableCell } from '@material-ui/core';
import { DownloadIconButton as DownloadIcon, Refresh, State3toggle} from "./custom-toolbar";
 import { CustomSearchBar } from "./custom-toolbar";
import { convertPrice, convertNumberPrecision, getToggleVal } from './util';
import Tooltip from '@material-ui/core/Tooltip';
import CustomMuiTablePagination from './custom-muitable-pagination';
// import TableRow from '@material-ui/core/TableRow';
// import { TableFooter } from "@material-ui/core";
import UsStocksFilters from './us-stock-filter';
import createBreakpoints from '@material-ui/core/styles/createBreakpoints'
const breakpoints = createBreakpoints({})

class UsStockDataTable extends React.Component {
    constructor(props) {
        super(props);
        this.wrapper = React.createRef();
        this.state = {
            api: props.api,
            statusApi: `/api/us-market-status`,
            marketOpen: '',
            insttype: props.insttype,
            columns: [],
            data: [],
            noMatchLabel: "No Records",
            state3tog: 'Thousands',
            total: [],
            sortedCol: "CONTRACTSTRADED",
            sortDir: "desc",
            rowsPerPage: 10,
            muitable:props.muitable,
            options: {
                selectableRows: "none",
                viewColumns: false,
                download: props.csv ? true : false,
                filter: false,
                print: false,
                search: false,
                searchOpen:props.search,
                searchAlwaysOpen:props.search,
                elevation: 0,
                // responsive: 'standard',
                responsive: 'vertical',
                // tableBodyMaxHeight: '500px',
                downloadOptions: {
                    filename: props.filename + '.csv'
                },
                customToolbar: () => {
                    if(props.muitable){
                        return (<><State3toggle toggleCallback={this.toggle3Switch} />
                            {props.viewbutton ? <a target="_blank" rel="noopener noreferrer" aria-label="View US Stock Page" 
                            href={"/markets/global-stocks"} style={{ order: `3` }} 
                            className="ml-2 btn btn-orange pr-4 pl-4">View All</a> : ""}
                        </>);
                    }
                    else if(props.filters){
                        return <UsStocksFilters />
                    }
                    
                },
                customSearchRender: (searchText, handleSearch, hideSearch, options) => {
                    return (<>{!props.page ? <CustomSearchBar searchText={searchText}
                        //searchPlaceholder={props.searchPlaceholder}
                        onSearch={handleSearch}
                        onHide={hideSearch}
                        options={options} /> : ''}
                        { props.muitable ? <div className="muidatatable-timestamp">{this.state.marketOpen} IST
                            <Refresh refreshcallback={this.refresh} /></div> : ""}</>);
                },
            },
            components: {
                icons: {
                    DownloadIcon,
                }
            },
        };
        this.refresh = this.refresh.bind(this);
        this.toggle3Switch = this.toggle3Switch.bind(this);
        this.updateColumnsTable = this.updateColumnsTable.bind(this);
    };
    toggle3Switch(checked) {
        this.setState({ state3tog: checked });
    }
    getToggleTurnover = (val,toggleState) => {
        if(typeof val === "number"){
        if (toggleState === "Thousands") {
            return val / 1000;
        }
        else if (toggleState === "Millions") {
            return val / 1000000;
        }
        else {
            return val / 1000000000;
        }
    }
    return val;
    }

    getToggleHeader = (toggleState) => {
        if (toggleState === "Thousands") {
            return "(In USD Thousands)";
        }
        else if (toggleState === "Millions") {
            return "(In USD Millions)";
        }
        else {
            return "(In USD Billions)";
        }
    }

    getMuiTheme = () => createMuiTheme({
        overrides: {
             MUIDataTableToolbar: {
                root: {
                    textAlign: "left",
                    paddingLeft: "0 ",
                    paddingRight: "0",
                },
                actions: {
                    display: "flex",
                    flex: "initial",
                    alignItems: `center`,
                    "& > span:last-child, & > button": {
                        order: 1
                    },
                    [breakpoints.down('xs')]: {
                        display: 'flex',
                        textAlign: `left !important`,
                        flexWrap:'wrap',
                        marginTop:'10px',
                    }
                }
            },
            // MuiTypography: {
            //     h6: {
            //         fontSize: "14px",

            //     }
            // },
            // MuiToolbar: {
            //     root: {
            //         paddingLeft: "0.5rem !important",
            //         paddingRight: "0.5rem !important",
            //     }
            // },
            // MUIDataTable: {

            //     responsiveStacked: {
            //         boxShadow: "0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)",
            //     },
            // },
            // TablePaginationActions: {
            //     order: 1
            // },
            // MUIDataTableHeadCell: {
            //     contentWrapper: {
            //         display: `unset`
            //     },
            //     data:
            //     {
            //         width: "max-content",
            //     },
            //     sortLabelRoot: {
            //         display: "none"
            //     }
            // },
            // MuiTableCell: {
            //     alignLeft: {
            //         "& button div:first-child": {
            //             textAlign: `left !important`
            //         }
            //     },
            //     alignRight: {
            //         "& button div:first-child": {
            //             textAlign: `right !important`
            //         }
            //     }
            // },
            MuiTooltip: {
                tooltip: {
                    fontSize: "12px",
                }
            },

        }
    })


    componentDidMount() {
        try {
            this._isMounted = true;
            this.loadData(this.state.api);
            this.loadMarketStatus(this.state.statusApi);
        } catch (e) {
            console.log(e);
        }
    }
    loadMarketStatus(api) {

        fetch(api)
            .then(resp => resp.json())
            .then((data) => {
                this.setState({
                    marketOpen: data.marketstatus,
                })
            })

    }

    componentDidUpdate(prevProps) {
        if (this.props.api !== prevProps.api) {
            this.setState({ api: this.props.api },
                () => {
                    this.loadData(this.state.api);
                });
        }
    }
    updateColumnsTable(cols) {

        //const offsetWidth = this.wrapper.current.offsetWidth || 1100;
        let colsList = [];
        colsList = cols.map((col) => {
            //col.width = offsetWidth * (col.width / 100);
            col = {

                label: col.tooltip || col.headerName,
                name: col.field,
                colorIndicator: col.colorIndicator,
                headerName: col.headerName,
                subHeader: col.subHeader,
                bold: col.bold,
                type: col.type,
                decimal: col.decimal,
                precision: col.precision,
                width: col.width || `auto`,
                padding:col.padding,
                currency:col.currencySymbol,
                searchable:col.searchable || false,
                asterisk:col.asterisk,
                linktext:col.linktext || "",
                options:
                {
                    customHeadRender: ({ index, ...column }, sortColumn, sortOrder) => {

                        if (col.name === "TURNOVER") {
                            col.subHeader = this.getToggleHeader(this.state.state3tog);
                            // if (this.state.state3tog === "left") {
                            //     col.subHeader = "($ Thousands)";
                            // }
                            // else if (this.state.state3tog === "center") {
                            //     col.subHeader = "($ Millions)";
                            // }
                            // else {
                            //     col.subHeader = "($ Billions)";
                            // }
                        }
                        return (
                            <Tooltip title={col.label} placement="bottom" key={col.name+index}>
                                <TableCell className={col.name === sortOrder.name && column.sortDirection !== null ? "sorted-col" : "unsorted-col"}
                                    align={col.type === "number" ? "right" : (col.type === "dateTime" ? "center" : "left")}
                                    style={col.padding ? {paddingRight:`1%`,width: col.width} : { width: col.width }}
                                    onClick={() => sortColumn(index)}>
                                    <span className={"preline-span " + (col.name === sortOrder.name && column.sortDirection !== null ? "sortactive" : "")}>
                                        {/* {col.headerName.split(" ")[0]}{" "}
                                        {col.headerName.split(" ")[1]}<br />
                                        {col.headerName.split(" ")[2]} */}
                                        {col.headerName}
                                        {col.asterisk ? <span className="red"> *</span> : ""}
                                        <br />
                                        {col.subHeader ? <span className="dg-table-sub-header" >
                                            {col.subHeader && ` ${col.subHeader}`}
                                        </span> : ""}
                                        

                                    </span>
                                    <span className="sorting-icons" aria-label={"Sort By " + col.label}>
                                        {col.name === sortOrder.name && column.sortDirection !== null ?
                                            (sortOrder.direction === "asc" ? <i className="fa fa-arrow-up"></i>
                                                : <i className="fa fa-arrow-down"></i>)
                                            :
                                            <i className="fa fa-sort"></i>
                                        }
                                    </span>
                                </TableCell>
                            </Tooltip>
                        );
                    },
                    
                    customBodyRender: (data, dataIndex, rowIndex) => {
                        let precision = 2;
                        let className = "";
                        if (col.name === "TURNOVER") {
                            data = this.getToggleTurnover(data,this.state.state3tog);
                            // if (this.state.state3tog === "left") {
                            //     data = data / 1000;
                            // }
                            // else if (this.state.state3tog === "center") {
                            //     data = data / 1000000;
                            // }
                            // else {
                            //     data = data / 1000000000;
                            // }
                        }
                        if (col.name === "SYMBOL") {
                            let symboltype = "";
                            switch (this.state.insttype) {
                                case "stocks":
                                    symboltype = "/us-quotes/stocks"
                                    break;
                                case "etfs":
                                    symboltype = "/us-quotes/etfs"
                                    break;
                                default:
                                    break;
                            }
                            // console.log("####",data)
                            // let identifier = '&usprice=' + dataIndex.rowData[2];
                            data = <a target="_blank" rel="noopener noreferrer" 
                            aria-label={"View US Stocks for" + data} 
                            href={symboltype + "?symbol=" + data} title={data}>
                                {data}</a>
                        }
                        if(col.type === "link"){
                            let popupLink = data;
                            data = <a href={popupLink}
                            onClick={()=> {
                                return !window.open(popupLink, '', 'width=500,height=500')}}
                            target="_blank"  rel="noopener noreferrer">
                                {col.linktext}</a>
                        }
                        // if (col.precision) {
                        //     if (dataIndex.rowData[1] === "SILVER") {

                        //         precision = 3;
                        //     }
                        //     else if (this.state.insttype === "cur" && dataIndex.rowData[1] !== "INRUSD") {
                        //         precision = 4;
                        //     }
                        //     else {
                        //         precision = 2;
                        //     }
                        // }
                        // else {
                        //     precision = 2;
                        // }
                        if(col.type === "number" && col.decimal){
                            data = convertNumberPrecision(data, precision);
                        }
                        else if(col.type === "number" && !col.decimal) {
                            data = convertPrice(data)
                        }
                        
                        if(col.colorIndicator && data !== " - "){
                            if(data >= 0) {
                                className = "green bold-columns"
                            }
                            else {
                                className ="red bold-columns"
                            }
                        }
                        else if(col.bold){                            
                                className ="bold-columns";                            
                        }
                        return (
                            <span className={className}>
                              {col.currency && data !==" - " ? "$ "+ data : data}
                            </span>
                        )
                    },
                    searchable: col.searchable,
                    setCellProps: () => ({
                        align: col.type === "number" ? "right" : (col.type === "dateTime" ? "center" : "left"),
                        style: col.padding ? {paddingRight:`3%`} : {}
                    }),
                    sortCompare: (order) => {

                        if (col.type === "dateTime") {
                            return (obj1, obj2) => {
                                const dateA = new Date(obj1.data).getTime();
                                const dateB = new Date(obj2.data).getTime();
                                return (dateA < dateB ? -1 : 1) * (order === "asc" ? 1 : -1);

                            }
                        } 
                        else if (col.type === "number"){
                            return (obj1, obj2) => {
                                // let val1 = obj1.data;
                                // let val2 = obj2.data;
                                //console.log("val",obj1.data,obj2.data)
                                let val1 = obj1.data !== " - " ? obj1.data : 0;
                                let val2 = obj2.data !== " - " ? obj2.data : 0;
                                return (val1 < val2 ? -1 : 1) * (order === 'asc' ? 1 : -1);
                            };
                        }
                        else {
                            return (obj1, obj2) => {
                                let val1 = obj1.data;
                                let val2 = obj2.data;
                                return (val1 < val2 ? -1 : 1) * (order === 'asc' ? 1 : -1);;
                            };
                        }

                    },
                },
            }
            return col;
        });
        this.setState({ columns: colsList })
    }
    loadData(api) {
        fetch(api)
            .then(resp => resp.json())
            .then((data) => {
                data.api = api;
                this.setState({ data: data.data, total: data.total });
                this.updateColumnsTable(data.columns);
                if (data.data.length === 0) {
                    this.setState({ noMatchLabel: "No Records Found" })
                }
                else {
                    this.setState({ noMatchLabel: "" })
                }
            })
            .catch((e) => { console.log("error in loading us stocks data table", e) })
    }

    refresh() {

        if (this._isMounted) {
            this.setState({
                data: [],
                marketOpen: '',
                sortedCol: "CONTRACTSTRADED",
                sortDir: "desc"
            });
            this.loadData(this.state.api);
            this.loadMarketStatus(this.state.statusApi);
        }
    }

    render() {
        const totalVolume = () => {
            return convertPrice(this.state.total[0].VOLUME);
        }
        
        const totalValue = () => {
            return convertNumberPrecision(getToggleVal(this.state.total[0].TURNOVER,this.state.state3tog));
            // return this.state.state3tog === "center" ? convertNumberPrecision((this.state.total[0].TURNOVER) / 1000000)
            //     : (this.state.state3tog === "left" ? convertNumberPrecision((this.state.total[0].TURNOVER) / 1000) :
            //         convertNumberPrecision((this.state.total[0].TURNOVER / 1000000000)));

        };
        let muitable = this.state.muitable
        return (
            <div ref={this.wrapper} 
            className={(this.state.noMatchLabel ? "no-records " : "" )+"col-md-12 us-stocks-table responsive-muidatatable deriv-mui-data-table contrast-table"} 
            //id="responsiveTable"
            >

                <MuiThemeProvider theme={this.getMuiTheme()}>
                    {
                        this.state.data ?
                            <MUIDataTable
                                // title={<div className="muidatatable-timestamp">{this.state.marketOpen} IST
                                // <Refresh refreshcallback={this.refresh} /></div>}
                                columns={this.state.columns}
                                data={this.state.data}
                                options={{
                                    ...this.state.options,
                                    sortOrder: {
                                        name: this.state.sortedCol,
                                        direction: this.state.sortDir,
                                    },
                                    textLabels: {
                                        body: {
                                            noMatch: this.state.noMatchLabel,
                                            columnHeaderTooltip: col => `${col.label}`
                                        }
                                    },
                                    // customTableBodyFooterRender: function (opts) {
                                    //     return muitable ? (
                                    //         <TableFooter className="mui-total-row">

                                    //             <TableRow>
                                    //                 {opts.selectableRows !== 'none' ? <TableCell /> : null}
                                    //                 {opts.columns.map((col, index) => {
                                    //                     if (col.display === 'true') {
                                    //                         if (index === 0) {
                                    //                             return (
                                    //                                 <TableCell key={index} >
                                    //                                     Total
                                    //                                 </TableCell>
                                    //                             );
                                    //                         }
                                    //                         if (col.name === 'CONTRACTSTRADED') {
                                    //                             return (
                                    //                                 <TableCell className="text-right" key={index}>
                                    //                                     {totalVolume()}
                                    //                                 </TableCell>
                                    //                             );
                                    //                         } else if (col.name === 'TURNOVER') {
                                    //                             return (
                                    //                                 <TableCell className="text-right" key={index} >
                                    //                                     {totalValue()}
                                    //                                 </TableCell>
                                    //                             );
                                    //                         } else {
                                    //                             return <TableCell key={index} />;
                                    //                         }
                                    //                     }
                                    //                     return null;
                                    //                 })}
                                    //             </TableRow>
                                    //         </TableFooter>
                                    //     ): <></>
                                        
                                    // },
                                    onChangeRowsPerPage: (numberOfRows) => {
                                        this.setState({
                                            rowsPerPage: numberOfRows
                                        })
                                    },
                                    customFooter: (count,
                                        page,
                                        rowsPerPage,
                                        changeRowsPerPage,
                                        changePage) => {
                                        return <CustomMuiTablePagination
                                            count={count} page={page}
                                            rowsPerPage={rowsPerPage}
                                            changeRowsPerPage={changeRowsPerPage}
                                            changePage={changePage} />
                                    },
                                    
                                    onColumnSortChange: (newColumnOrder, columnIndex, newPosition) => {
                                        this.setState({ sortedCol: newColumnOrder, sortDir: columnIndex });
                                    },
                                }
                                }
                                components={{ ...this.state.components}}
                            /> :
                            <span>loading</span>
                    }
                </MuiThemeProvider>
            </div>
        );
    };
}

export default UsStockDataTable;