import React from 'react';
import ReorderIcon from '@material-ui/icons/Reorder';
import AppsIcon from '@material-ui/icons/Apps';
import CircularCard from './components/circular-card';
import PaginationContainer from './components/pagination-container';
import moment from "moment";
import { Refresh } from './components/custom-toolbar';
import PressReleaseFilters from './components/press-release-filters';
//import CSVDownloader from './components/download-csv';
import { removePtag } from './components/util';
import DownloadExcel from './components/download-excel';

const momentDateFormat = "DD-MMM-YYYY";
class PressRelease extends React.Component {
    _isMounted = false;
    constructor(props) {
        super(props);
        this.state = {
            fromdate: moment(new Date()).subtract(3, 'months').format(momentDateFormat),
            todate: moment().utc().utcOffset("+05:30").format(momentDateFormat),
            baseApi: `/api/contents/press-release?`,
            api: ``,
            cardData: [],
            currentView: 'listview',
            isGridview: false,
            drawerOpen: props.drawerOpen,
            dateFormat: "YYYY-MM-DD",
            category: "",
            keyword: "",
            originalData: [],
            csvcolumns: ["CATEGORY", "SUBJECT", "DATE", "FILE LINK"],
        };
        this.refresh = this.refresh.bind(this);
        this.setParameters = this.setParameters.bind(this);
        this.resetFilters = this.resetFilters.bind(this);
        this.filterPressRelease = this.filterPressRelease.bind(this);
    }

    componentDidMount() {
        try {
            this._isMounted = true;

            this.setState({
                api: `${this.state.baseApi}fromDate=${moment(this.state.fromdate, momentDateFormat).format("YYYY-MM-DD")}&toDate=${moment(this.state.todate, momentDateFormat).format("YYYY-MM-DD")}`
            }, () => {
                this.loadPressReleaseData(this.state.api);
            })

        } catch (e) {
            console.log(e);
        }

    }

    componentDidUpdate(prevProps) {
        if (prevProps.drawerOpen !== this.props.drawerOpen) {
            this.setState({
                drawerOpen: this.props.drawerOpen,
            })
        }
    }

    loadPressReleaseData(api) {
        fetch(api)
            .then(resp => resp.json())
            .then((cards) => {
                const cardList = [];
                cards.forEach((item) => {
                    cardList.push({
                        // TITLE: removePtag(item.title),
                        CATEGORY: item.category,
                        SUBJECT: removePtag(item.para),
                        DATE: item.date,
                        //removes version id param coming from aws for downloaded csv
                        LINK: (item.url).includes("?VersionId") ?
                            item.url.substring(0, item.url.indexOf("?VersionId"))
                            : item.url,

                    })
                })
                this.setState({ originalData: cardList })
                this.filterPressRelease(cardList)
            })
            .catch((e) => { console.log("error in loading press release", e) })
    }

    refresh() {
        if (this._isMounted) {
            this.setState({ cardData: [] }, () => {
                this.loadPressReleaseData(this.state.api);
            });

        }
    }
    setParameters(fromdate, todate, category, keyword) {

        let from = moment(fromdate, momentDateFormat).format(this.state.dateFormat);
        let to = moment(todate, momentDateFormat).format(this.state.dateFormat);
        let api = `${this.state.baseApi}fromDate=${from}&toDate=${to}`;
        if (fromdate !== this.state.fromdate || todate !== this.state.todate) {

            this.setState({
                fromdate: fromdate,
                todate: todate,
                api: api,
                category: category,
                keyword: keyword
            }, () => {
                this.loadPressReleaseData(api);
            })
        }
        else {

            this.setState({ category, keyword }, () => {
                this.filterPressRelease(this.state.originalData)
            })

        }
    }

    filterPressRelease(carddata) {
        if (this.state.category !== "" && this.state.keyword !== "") {

            const filteredContent = carddata.filter(item => {
                return (item.CATEGORY.toLowerCase().search(this.state.category.toLowerCase()) !== -1
                    && item.SUBJECT.toLowerCase().search(this.state.keyword.toLowerCase()) !== -1);
            })

            this.setState({ cardData: filteredContent })
        }
        else if (this.state.category === "" && this.state.keyword !== "") {

            const filteredContent = carddata.filter(item => {
                return (item.SUBJECT.toLowerCase().search(this.state.keyword.toLowerCase()) !== -1);
            })

            this.setState({ cardData: filteredContent })
        }
        else if (this.state.category !== "" && this.state.keyword === "") {

            const filteredContent = carddata.filter(item => {
                return (item.CATEGORY.toLowerCase().search(this.state.category.toLowerCase()) !== -1);
            })
            this.setState({ cardData: filteredContent })
        }
        else {
            this.setState({ cardData: carddata })
        }
    }
    resetFilters() {
        this.setState({
            fromdate: moment(new Date()).subtract(3, 'months').format(momentDateFormat),
            todate: moment().utc().utcOffset("+05:30").format(momentDateFormat),
            keyword: "",
            category: "",
            cardData: [],
            originalData: [],
        }, () => {
            this.loadPressReleaseData(`${this.state.baseApi}fromDate=${moment(this.state.fromdate, momentDateFormat).format("YYYY-MM-DD")}&toDate=${moment(this.state.todate, momentDateFormat).format("YYYY-MM-DD")}`);
            this.setState({
                api: `${this.state.baseApi}fromDate=${moment(this.state.fromdate, momentDateFormat).format("YYYY-MM-DD")}&toDate=${moment(this.state.todate, momentDateFormat).format("YYYY-MM-DD")}`
            })

        })
    }
    render() {
        const pressreleaseComp = [];
        return (
            <div className="container-fluid">
                {/* Press release filters */}
                <div className="row">
                    <div className="col-md-12">
                        <PressReleaseFilters resetcallback={this.resetFilters} callback={this.setParameters} />
                    </div>
                </div>

                <div className="row mt-3 mb-3">
                    <div className="col-lg-6">
                        <div className="d-flex align-items-center">
                            <div className="tag">
                                <p>Press Release for period {moment(this.state.fromdate, momentDateFormat).format("DD-MM-YYYY")} to {moment(this.state.todate, momentDateFormat).format("DD-MM-YYYY")}
                                </p>
                            </div>
                            <Refresh refreshcallback={this.refresh} />
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="d-flex justify-content-end align-items-center">
                            <div className="mr-2">
                                <DownloadExcel data={this.state.cardData} columns={this.state.csvcolumns}
                                    csvname={"press-releases-" + moment(this.state.fromdate, momentDateFormat).format("DD-MM-YYYY") + "-to-" + moment(this.state.todate, momentDateFormat).format("DD-MM-YYYY")} />
                                {/* <CSVDownloader data={this.state.cardData} csvname={"press-releases-" + moment(this.state.fromdate, momentDateFormat).format("DD-MM-YYYY") + "-to-" + moment(this.state.todate, momentDateFormat).format("DD-MM-YYYY")} /> */}
                            </div>
                            <div className="mr-2">
                                <div className="circle-icon"
                                    onClick={() => {
                                        if (this.state.currentView === 'gridview') {

                                            this.setState({ currentView: 'listview', isGridview: false })
                                        }
                                        else {
                                            this.setState({ currentView: 'gridview', isGridview: true })
                                        }

                                    }}
                                >
                                    {this.state.isGridview && <ReorderIcon />}
                                    {!this.state.isGridview && <AppsIcon />}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* cards */}
                <div className={this.state.isGridview ? "row justify-content-center" : ""}>
                    {(this.state.cardData && this.state.cardData.length > 0) && this.state.cardData.forEach((event, index) => {
                        pressreleaseComp.push(<div className={this.state.isGridview ? (this.state.drawerOpen ? "col-sm-4 mb-3" : "col-sm-3 mb-3") : "col-lg-12"} key={index}>
                            <CircularCard alignCard={this.state.currentView} card={event} cardkey={"pr" + index} />
                        </div>)
                    })
                    }
                    {/* Pagination Component- passing press release cards */}
                    {(this.state.cardData && this.state.cardData.length > 0) ?
                    <div className={this.state.isGridview ? "container-fluid" : ""}>
                        <PaginationContainer pagination-component={pressreleaseComp} itemsPerPage={this.state.drawerOpen ? 6 : 8} />
                    </div>
                        : <p className="text-center mb-0">No records</p>}
                </div>

            </div>
        )
    }
}

export default PressRelease;