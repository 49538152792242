import React from 'react';
import {
    CarouselProvider, Slider, Slide,ButtonBack, ButtonNext, DotGroup,
    // ButtonPlay
} from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';
// import PlayCircleFilledIcon from '@material-ui/icons/PlayCircleFilled';
// import PauseCircleFilledIcon from '@material-ui/icons/PauseCircleFilled';
import { removePtag, removeStrongtag, parseAsHtml, externalLinkDisclaimer } from './util';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';

class HomeBanner extends React.Component {
    _isMounted = false;
    constructor(props) {
        super(props);
        this.state = {
            cardData: [],
            hbData: props.hbData,
            // slideWidth: window.innerWidth < 480 ? 80 : 100,
            // slideHeight: window.innerWidth < 480 ? 50 : 1,
            slideWidth: 100,
            slideHeight:1,
            bannerContent: [],
        }
    }
    componentDidMount() {
        try {
            this._isMounted = true;
            this.loadHomeBannerData(this.state.hbData);
            window.addEventListener("resize", this.resize.bind(this));
        } catch (e) {
            console.log(e);
        }

    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.resize.bind(this));
    }

    resize() {
        
        let currentmobileScreen = window.innerWidth;
        let slideWidth = 1;
        let slideHeight = 100;
        if (currentmobileScreen  < 480) {
            slideWidth = 80;
            slideHeight = 50;
            
        }
        this.setState({slideWidth,slideHeight});
    }

    componentDidUpdate(prevProps) {
        if (prevProps.hbData !== this.props.hbData) {
            this.setState({hbData:this.props.hbData})
            this.loadHomeBannerData(this.props.hbData);
        }
    }
    
    loadHomeBannerData(hbData) {
        let ariaproperties = "";
        let bannerData = hbData.map((row, index) => {
            ariaproperties += "home-slider-" + index + " ";
            return (
                <Slide id={"home-slider-" + index} className="homeBanner" key={index}>
                    <ul className="ws_images">
                        <li>
                            {this.getBannerContent(row, index)}
                        </li>
                    </ul>
                </Slide>
            )
        });
        let bannerContent = <CarouselProvider
            naturalSlideWidth={this.state.slideWidth}
            naturalSlideHeight={this.state.slideHeight}
            totalSlides={hbData.length || 1}
            visibleSlides={1}
            isPlaying={true} lockOnWindowScroll={false}
            interval={5000}
        >
            <Slider aria-label="home-banner" aria-owns={ariaproperties}>
                {bannerData}
            </Slider>
            <ButtonBack className='carousel-button carousel-button-back'>
            <ArrowBackIosIcon/>
            </ButtonBack>
            <ButtonNext className='carousel-button carousel-button-next'>
            <ArrowForwardIosIcon/>
            </ButtonNext>
            <div className="ws_bullets">
                <DotGroup />
            </div>
        </CarouselProvider>
        this.setState({ bannerContent: bannerContent });
    }

    getBannerContent(bannerData, index) {
        let bannerContent = [];
        let bannerImg = "";
        let bannerTitle = "";
        let bannerPara = "";
        let bannerButton = "";
        let title = ""
        Object.keys(bannerData["field_homepage_banner_view"]).forEach((ke, idx) => {

            bannerData["field_homepage_banner_view"].forEach((banner, jdx) => {
                let fieldname = Object.keys(banner)[0];
                switch (fieldname) {
                    case "url":
                        bannerImg = <img src={banner.url} key={`img${idx}${jdx}`}
                            alt={banner.alt} title={banner.title} />;
                        break;
                    case "field_title":
                        bannerTitle = <li className="mainheadingList" key={`title${idx}${jdx}`}>
                            {parseAsHtml(removePtag(banner.field_title))}</li>;
                        title = removeStrongtag(removePtag(banner.field_title));
                        break;
                    case "field_para":
                        bannerPara = parseAsHtml(banner.field_para);
                        break;
                    case "field_text":
                        bannerButton = <li className="banner-button" key={`button${idx}${jdx}`}>
                            <a  target="_blank" rel="noopener noreferrer" aria-label={"View file for " + removeStrongtag(removePtag(title))}
                                href={banner.file_url || ""} className="btn btn-orange btn-hmbanner">
                                    {banner.field_text || "Know More"}
                            </a></li>;
                        break;
                    case "uri":
                        bannerButton = <li className="banner-button" key={`button${idx}${jdx}`}>
                            <a onClick={externalLinkDisclaimer} target="_blank" rel="noopener noreferrer" aria-label={"Read more for " + removeStrongtag(removePtag(title))}
                                href={banner.uri || ""} className="btn btn-orange btn-hmbanner">
                                    {banner.title || "Know More"}
                            </a></li>;
                        break;
                    default:
                        break;
                }
            })
        })
        bannerContent.push(bannerImg, <ul key={`banner${index}`} className="banner-width">{bannerTitle}{bannerPara}{bannerButton}</ul>)
        return bannerContent;
    }

    render() {
        
        return (
            <div id="wowslider-container1">
                {this.state.bannerContent}  
            </div>
        );
    }
}
export default HomeBanner;