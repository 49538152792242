import React, { Component } from 'react';
import { getDownloadIcon, parseAsHtml } from './util';
import Table from '@material-ui/core/Table';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import Checkbox from "@material-ui/core/Checkbox";
// import StarBorderIcon from '@material-ui/icons/StarBorder';
// import StarIcon from '@material-ui/icons/Star';

class ReportsTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: props["data"] || [],
            selected: [],
           tab: props["tab"] || "",
            selectAll: props.selectAll,
            isChecked: false,
            data1: [],
            checked: [],
            callback: props.callback,
            singleCheck: false
        };
        this.handleSingleCheckboxChange = this.handleSingleCheckboxChange.bind(this);
    };

    componentDidMount() {
        //  select all -multiple download
        var checkedCopy = [];
        var selectAll = this.state.selectAll;
        this.state.data.forEach(function (e, index) {
            checkedCopy.push(selectAll);
        });

        this.setState({
            data1: this.state.data,
            checked: checkedCopy,
            selectAll: selectAll
        }, () => {
            // console.log("check array", this.state.checked)
        });
    }

    componentDidUpdate(prevProps) {
        if (this.props.data !== prevProps.data) {
            this.setState({ data: this.props.data, tab: this.props.tab })
        }

         //  select all -multiple download
        if (this.props.selectAll !== prevProps.selectAll) {
            if (this.props.selectAll !== 'DoNot') {
                // console.log("check array update", this.props.selectAll)
                var checkedCopy = [];
                var selectAll = this.props.selectAll;
                this.state.data.forEach(function (e, index) {
                    checkedCopy.push(selectAll);
                });
                this.setState({
                    data1: this.state.data,
                    checked: checkedCopy,
                    selectAll: this.props.selectAll
                });
                this.checkedRowFiles(this.props.selectAll, this.state.data);
            }
        }
    }

    checkedRowFiles = (chekcedVal, data1) => {
        if (chekcedVal) {
            // console.log('treyueeee');
            for (var i = 0; i < data1.length; i++) {
                this.props.addToDownload(data1[i].fileurl, true);
            }
        } else {
            // console.log(':OOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOO');
        }
    }

 

    handleSingleCheckboxChange = (index, item) => {
        // console.log(index);
        var checkedCopy = this.state.checked;
        checkedCopy[index] = !this.state.checked[index];
        if (checkedCopy[index] === false) {
            this.setState({ selectAll: false });
        }
        this.setState({
            checked: checkedCopy,
            singleCheck: checkedCopy[index],
        }, () => {
         this.state.callback(this.state.singleCheck);
        //  console.log("bbbbbbbbbbbb", this.state.checked)
        }
        );
    this.props.addToDownload(item.fileurl, checkedCopy[index]);
    };

    render() {
        const TableRow = ({ item, i, isSelected, tabkey, isChecked }) => {
            const tablerow =
                <tr key={`${item.file}${i}${tabkey}`}>
                    <td className="MuiTableCell-body text-align-left">
                        <Checkbox inputProps={{ 'aria-label': 'Checkbox A' }}
                            className="selectCheckbox"
                            //defaultChecked={this.state.checked[`${i}`]}
                            checked={this.state.checked[`${i}`]}
                            onChange={() => this.handleSingleCheckboxChange(`${i}`, item)}
                        />
                    </td>
                    <td className="MuiTableCell-body text-align-left">
                        {parseAsHtml(item.file)}
                    </td>
                    <td className="MuiTableCell-body text-center">
                        <a target="_blank" rel="noopener noreferrer" aria-label="Download" href={(item.fileurl)}>{getDownloadIcon((item.fileurl),`${item.file}${i}${tabkey}`)}</a>
                    </td>
                </tr>;
            return tablerow;
        }

        return (
            // <div className="col-md-12 m-0">
               <div className="table-responsive">
                    <TableContainer>
                        <Table className="table customize-table reports-checkbox">
                            <TableHead>
                                <tr>
                                    <th className="MuiTableCell-head preline-span text-align-left" ><span className="hide">Sr</span> </th>
                                    <th className="MuiTableCell-head preline-span text-align-left" >{this.props.heading || "File"}</th>
                                    <th className="MuiTableCell-head preline-span text-center" >Download</th>
                                </tr>
                            </TableHead>
                            <tbody>
                                {this.state.data.length > 0 ?
                                    this.state.data.map((item, i) => {
                                        return <TableRow tabkey={this.state.tab} item={item} i={i} isChecked={this.state.isChecked} key={i} />
                                    }) : <tr><td className="MuiTableCell-body text-center p-2" colSpan="4">No Records</td></tr>}
                            </tbody>
                        </Table>
                    </TableContainer>
                </div>
            // </div>
        );
    }
}

export default ReportsTable;