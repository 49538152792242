import React, { Component } from 'react';
import Highcharts from 'highcharts'
import exporting from "highcharts/modules/exporting";
import HighchartsReact from 'highcharts-react-official';

exporting(Highcharts);

class HighChartGraph extends Component {
    constructor(props) {
        super(props);
        this.state = {
            api: props.api,
            details: props.details,
            data: [],
            options: {},
            positiveGraph: props.graphColor,
            threshold:0,
            currDate:props.currDate || ""

        }
    };
    componentDidUpdate(prevProps) {
        if (this.props.api !== prevProps.api) {
            this.setState({
                api: this.props.api,
                positiveGraph: this.props.graphColor,
                details: this.props.details
                
            }, () => {
                    this.loadData(this.state.api);
                })
        }
        if (this.props.details !== prevProps.details) {
            this.setState({ details: this.props.details })
        }
        if (this.props.currDate !== prevProps.currDate) {
            this.setState({ currDate: this.props.currDate })
        }
    }
    componentDidMount() {
        try {
            if (this.state.api && this.state.api !== "") {
                this.loadData(this.state.api);
            }
        } catch (e) {
            console.log(e);
        }
    }
    loadData(api) {
        fetch(api)
            .then(resp => resp.json())
            .then((data) => {
                this.setState({
                    options: {
                        exporting: {
                            filename: 'Nifty Underlying Chart as on '+this.state.currDate
                        },
                        title: {
                            text:""
                        },
                        yAxis:{
                            title:"",
                            plotLines: [{
                                
                                color: 'darkgreen',
                                width: 2,
                                value: data.previousVal,
                                dashStyle: 'ShortDash'
                            }],
                        },
                        xAxis:{
                            type:"datetime"
                        },
                        series: [{
                            
                            data: data.data,
                            name: this.state.details.index,
                            threshold:data.previousVal,
                            negativeColor: 'red',
                            color: 'green',
                            tooltip:{
                                valueDecimals:2
                            }
                            //color: this.state.positiveGraph > 0 ? "green" : "red",
                        }],                        
                    },
                })
            })
            .catch((e) => { console.log("error in header", e) })
    }

    render() {

        return ( <><HighchartsReact highcharts={Highcharts} options={this.state.options} /></> )
    }
}

export default HighChartGraph;