import React, { Component } from "react";
import { Select, MenuItem } from "@material-ui/core";
//import env from '../../config';

const ButtonHeight = {
  height: "100%",
};

class EsgFilters extends Component {
  constructor(props) {
    super(props);
    this.state = {
      api1: `/api/esg-bonds/filters`,
      api2: '/api/esg-bonds',
      selectName: [],
      selectBondType: [],
      selectBondStandard: [],
      name: "",
      bondType: "",
      bondStandard: "",
      callback: props.callback,
      resetcallback: props.resetcallback,
    };
    this.filter = this.filter.bind(this);
    this.reset = this.reset.bind(this);
    this.loadFilters = this.loadFilters.bind(this);
  }
  componentDidMount() {
    try {
        this.loadFilters(this.state.api1)
      this._isMounted = true;
    } catch (e) {
      console.log(e);
    }
  }

  loadFilters(api) {
    fetch(api)
        .then(resp => resp.json())
        .then((resp) => {
            this.setState({ selectName: resp.names, selectBondType:resp.bondType, selectBondStandard: resp.bondStandard });
        })
        .catch((e) => { console.log("error in loading list of filters in esg bonds", e) })
}

  filter() {
    if (this.state.name || this.state.bondType || this.state.bondStandard) {
      this.state.callback(this.state.name, this.state.bondType, this.state.bondStandard);
    } else {
    }
  }
  reset() {
    this.setState({
      name: "",
      bondType: "",
      bondStandard: "",
    });
    this.state.resetcallback(this.state.api2);
  }

  render() {
    return (
      <>
        <div className="mr-5 row corporate-filter-fields">
          <div className="col-md-3 pr-0 cofilter-padding">
            <Select
              className="customize-input-group quotes-select-filter"
              onChange={(e) => {
                this.setState({ name: e.target.value });
              }}
              value={this.state.name}
              displayEmpty
            >
              <MenuItem value="" disabled>
                Issuer
              </MenuItem>
              {this.state.selectName.map((x, idx) => {
                return (
                  <MenuItem key={x + idx} value={x}>
                    {x}
                  </MenuItem>
                );
              })}
            </Select>
          </div>
          <div className="col-md-3 pr-0 cofilter-padding">
            <Select
              className="customize-input-group quotes-select-filter"
              onChange={(e) => {
                this.setState({ bondType: e.target.value });
              }}
              value={this.state.bondType}
              displayEmpty
            >
              <MenuItem value="" disabled>
                Select Bond Type
              </MenuItem>
              {this.state.selectBondType.map((x, idx) => {
                return (
                  <MenuItem key={x + idx} value={x}>
                    {x}
                  </MenuItem>
                );
              })}
            </Select>
          </div>

          <div className="col-md-3 pr-0 cofilter-padding">
            <Select
              className="customize-input-group quotes-select-filter"
              onChange={(e) => {
                this.setState({ bondStandard: e.target.value });
              }}
              value={this.state.bondStandard}
              displayEmpty
            >
              <MenuItem value="" disabled>
                Select Bond Standard
              </MenuItem>
              {this.state.selectBondStandard.map((x, idx) => {
                return (
                  <MenuItem key={x + idx} value={x}>
                    {x}
                  </MenuItem>
                );
              })}
            </Select>
          </div>

          <span className="red cofilter-validation-to">
            {this.state.validationTo}
          </span>
          <div className="col-md-1 pr-0 cofilter-padding">
            <button
              className="btn esg-btn full-width cofilter-btn"
              style={ButtonHeight}
              onClick={this.filter}
            >
              Go
            </button>
          </div>
          <div className="col-md-1 pr-0 cofilter-padding">
                <button className="btn btn-primary full-width cofilter-btn" style={ButtonHeight} onClick={this.reset}>Reset</button>
          </div>
        </div>
      </>
    );
  }
}
export default EsgFilters;
