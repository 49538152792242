const columns =  [
    {
    "name": "chart",
    "heading": "",
    "subHead": "",
    "width": "2.34%",
    "sort": false,
    "chkVal": false,
    "OPTIONTYPE": "CE"
    },
    {
    "name": "OI",
    "heading": "OI",
    "subHead": "",
    "legend": "Open Interest in contracts",
    "width": "5.14%",
    "sort": false,
    "chkVal": false,
    "OPTIONTYPE": "CE",
    "type": "quantity"
    },
    {
    "name": "OICHANGE",
    "heading": "Chng in OI",
    "subHead": "",
    "legend": "Change in Open Interest (Contracts)",
    "width": "3.0%",
    "sort": false,
    "chkVal": false,
    "OPTIONTYPE": "CE",
    "type": "quantity"
    },
    {
    "name": "CONTRACTSTRADED",
    "heading": "Volume",
    "subHead": "",
    "legend": "Volume in Contracts",
    "width": "5.54%",
    "sort": false,
    "chkVal": false,
    "OPTIONTYPE": "CE",
    "type": "quantity"
    },
    {
    "name": "IMPLIEDVOLATILITY",
    "heading": "IV",
    "subHead": "",
    "legend": "Implied Volatility",
    "width": "3.0%",
    "sort": false,
    "chkVal": false,
    "OPTIONTYPE": "CE",
    "type": "number"
    },
    {
    "name": "LASTPRICE",
    "heading": "LTP",
    "subHead": "",
    "legend": "Last Traded Price",
    "width": "6%",
    "sort": true,
    "chkVal": false,
    "OPTIONTYPE": "CE",
    "type": "number"
    },
    {
    "name": "DAYCHANGE",
    "heading": "Chng",
    "subHead": "",
    "legend": "Change w.r.t to Previous Close",
    "width": "3.0%",
    "sort": false,
    "chkVal": true,
    "OPTIONTYPE": "CE",
    "type": "number"
    },
    {
    "name": "BIDQTY",
    "heading": "Bid Qty",
    "subHead": "",
    "legend": "Best Bid/Buy Qty",
    "width": "4.34%",
    "sort": false,
    "chkVal": false,
    "OPTIONTYPE": "CE",
    "type": "quantity"
    },
    {
    "name": "BIDPRICE",
    "heading": "Bid Price",
    "subHead": "",
    "legend": "Best Bid/Buy Price",
    "width": "4.34%",
    "sort": false,
    "chkVal": false,
    "OPTIONTYPE": "CE",
    "type": "number"
    },
    {
    "name": "ASKPRICE",
    "heading": "Ask Price",
    "subHead": "",
    "legend": "Best Ask/Sell Price",
    "width": "4.34%",
    "sort": false,
    "chkVal": false,
    "OPTIONTYPE": "CE",
    "type": "number"
    },
    {
    "name": "ASKQTY",
    "heading": "Ask Qty",
    "subHead": "",
    "legend": "Best Ask/Sell Qty",
    "width": "4.34%",
    "sort": false,
    "chkVal": false,
    "OPTIONTYPE": "CE",
    "type": "quantity"
    },
    {
    "name": "EXPIRY",
    "heading": "Expiry Date",
    "subHead": "",
    "width": "7.34%",
    "sort": false,
    "chkVal": false,
    "OPTIONTYPE": ""
    },
    {
    "name": "BIDQTY",
    "heading": "Bid Qty",
    "subHead": "",
    "legend": "Best Bid/Buy Qty",
    "width": "4.34%",
    "sort": false,
    "chkVal": false,
    "OPTIONTYPE": "PE",
    "type": "quantity"
    },
    {
    "name": "BIDPRICE",
    "heading": "Bid Price",
    "subHead": "",
    "legend": "Best Bid/Buy Price",
    "width": "4.34%",
    "sort": false,
    "chkVal": false,
    "OPTIONTYPE": "PE",
    "type": "number"
    },
    {
    "name": "ASKPRICE",
    "heading": "Ask Price",
    "subHead": "",
    "legend": "Best Ask/Sell Price",
    "width": "4.34%",
    "sort": false,
    "chkVal": false,
    "OPTIONTYPE": "PE",
    "type": "number"
    },
    {
    "name": "ASKQTY",
    "heading": "Ask Qty",
    "subHead": "",
    "legend": "Best Ask/Sell Qty",
    "width": "4.34%",
    "sort": false,
    "chkVal": false,
    "OPTIONTYPE": "PE",
    "type": "quantity"
    },
    {
    "name": "DAYCHANGE",
    "heading": "Chng",
    "subHead": "",
    "legend": "Change w.r.t to Previous Close",
    "width": "3.0%",
    "sort": false,
    "chkVal": true,
    "OPTIONTYPE": "PE",
    "type": "number"
    },
    {
    "name": "LASTPRICE",
    "heading": "LTP",
    "subHead": "",
    "legend": "Last Traded Price",
    "width": "6%",
    "sort": true,
    "chkVal": false,
    "OPTIONTYPE": "PE",
    "type": "number"
    },
    {
    "name": "IMPLIEDVOLATILITY",
    "heading": "IV",
    "subHead": "",
    "legend": "Implied Volatility",
    "width": "3.0%",
    "sort": false,
    "chkVal": false,
    "OPTIONTYPE": "PE",
    "type": "number"
    },
    {
    "name": "CONTRACTSTRADED",
    "heading": "Volume",
    "subHead": "",
    "legend": "Volume in Contracts",
    "width": "5.54%",
    "sort": false,
    "chkVal": false,
    "OPTIONTYPE": "PE",
    "type": "quantity"
    },
    {
    "name": "OICHANGE",
    "heading": "Chng in OI",
    "subHead": "",
    "legend": "Change in Open Interest (Contracts)",
    "width":"3.0%",
    "sort": false,
    "chkVal": false,
    "OPTIONTYPE": "PE",
    "type": "quantity"
    },
    {
    "name": "OI",
    "heading": "OI",
    "subHead": "",
    "legend": "Open Interest in contracts",
    "width": "5.14%",
    "sort": false,
    "chkVal": false,
    "OPTIONTYPE": "PE",
    "type": "quantity"
    },
    {
    "name": "chart",
    "heading": "",
    "subHead": "",
    "width": "2.34%",
    "sort": false,
    "chkVal": false,
    "OPTIONTYPE": "PE"
    }
]
   
export default columns;