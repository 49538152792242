import React from 'react';
import moment from "moment";
import { MuiPickersUtilsProvider, KeyboardDatePicker, } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';

class LesFilters extends React.Component {

    _isMounted = false;
    constructor(props) {
        super(props);
        this.state = {
            yearSelected: null,
            monthSelected: null,
            monthSelectedOpen: false,
            yearSelectedOpen: false,
            maxMonth: null,
            currentMonth:'' , 
            currentYear: '',
            callback: props.callback,
            resetcallback: props.resetcallback,
        };
        this.handlemonthSelectedChange = this.handlemonthSelectedChange.bind(this);
        this.handleyearSelectedChange = this.handleyearSelectedChange.bind(this);
        this.submitDateRange = this.submitDateRange.bind(this);
        this.resetDateRange = this.resetDateRange.bind(this);
    }

    componentDidMount() {
        try {
            this._isMounted = true;
            
        } catch (e) {
            console.log(e);
        }
    }
handlemonthSelectedChange = (date) => {
        this.setState({
            monthSelected: date,
            monthSelectedOpen: false
        });
        
    }
    handleyearSelectedChange = (date) => {
        this.setState({
            yearSelected: date,
            yearSelectedOpen: false,
        }
        , () => {
            this.setState({
                maxMonth: moment(this.state.yearSelected).format("yyyy") === moment(new Date()).format("yyyy") ?
                    new Date() : moment(this.state.yearSelected).format("MMM"),
            })
        }
        );
    }
    submitDateRange() {
        
        let currentMonth = this.state.monthSelected ? moment(this.state.monthSelected).format("MMM") : '';
        let currentYear = this.state.yearSelected ? moment(this.state.yearSelected).format("yyyy") : '';
        this.setState({currentMonth,currentYear})
      
        if(moment(currentMonth+"-"+currentYear,"MMM-YYYY") <= moment(new Date(), "MMM-YYYY") || (currentYear === '' && currentMonth !== '')) { 
        
        this.state.callback(currentMonth,currentYear);
        }

    }
   
    resetDateRange() {
        this.setState({
            monthSelected: null, yearSelected: null, maxMonth: null,
        }, () => {
            this.state.resetcallback();
        })
    }
    render() {
        return (
           
                <div className="row mt-3 mb-4">
                    <div className="col-3">
                        <div className="customize-input-group">
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <KeyboardDatePicker disableToolbar placeholder="Select Month" variant="inline" format="MMM" views={["month"]}
                                    margin="normal" id="monthSelected" maxDate={this.state.maxMonth} value={this.state.monthSelected} onChange={this.handlemonthSelectedChange}
 
                                    KeyboardButtonProps={{
                                        onFocus: (e) => {
                                            this.setState({
                                                monthSelectedOpen: true
                                            });
                                        },
                                        "aria-label":"month_button" 
                                    }}
                                    PopoverProps={{
                                        disableRestoreFocus: true,
                                        onClose: () => {
                                            this.setState({
                                                monthSelectedOpen: false
                                            });
                                        }
                                    }}
                                    InputProps={{
                                        onFocus: () => {
                                            this.setState({
                                                monthSelectedOpen: true
                                            });
                                        }
                                    }}
                                    open={this.state.monthSelectedOpen} />
                            </MuiPickersUtilsProvider>
                        </div>
                    </div>
                    
                    <div className="col-3 pl-0">
                        <div className="customize-input-group">
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <KeyboardDatePicker placeholder="Select Year" disableToolbar variant="inline" format="yyyy" views={["year"]} margin="normal"
                                    id="yearSelected" maxDate={new Date()} value={this.state.yearSelected} onChange={this.handleyearSelectedChange}
                                  
                                    KeyboardButtonProps={{
                                        onFocus: (e) => {
                                            this.setState({
                                                yearSelectedOpen: true
                                            });
                                        },
                                        "aria-label":"year_button" 
                                    }}
                                    PopoverProps={{
                                        disableRestoreFocus: true,
                                        onClose: () => {
                                            this.setState({
                                                yearSelectedOpen: false
                                            });
                                        }
                                    }}
                                    InputProps={{
                                        onFocus: () => {
                                            this.setState({
                                                yearSelectedOpen: true
                                            });
                                        }
                                    }}
                                    open={this.state.yearSelectedOpen} />
                            </MuiPickersUtilsProvider>
                        </div>
                    </div>
                    <div className="col-2 pl-0">
                        <button className="btn btn-orange full-width submit-button" onClick={this.submitDateRange}>Submit</button>
                    </div>
                    <div className="col-2 pl-0">
                        <button className="btn btn-primary full-width reset-button" onClick={this.resetDateRange}>Reset</button>
                    </div>
                </div>
              
        );
    }
}

export default LesFilters;