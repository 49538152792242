import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
//import env from '../../config';
import { State3toggle } from './custom-toolbar';
import CSVDownloader from './download-csv';
import { convertPrice, convertNumberPrecision,getToggleHeader, getToggleVal } from './util';
import LinearProgress from '@material-ui/core/LinearProgress'

class TbgTableRevised extends React.Component {
    constructor(props) {
        super(props);
        this.wrapper = React.createRef();
        this.state = {
            api: props["data-api"],
            relApi: `/api/tbgr/monthly?`,
            currentMonth: props["current-month"],
            currentYear: props["current-year"],
            timestamp:``,
            timestampAPi:`/api/tbg/getTimestamp`,
            //param: props.param,
            columns: [],
            data: [],
            currentTime: "Yearly",
            milliontoggle: true,
            state3tog:'Thousands',
            records:'',
            csvdata:[],
            inProgress:true
        };
        this.dateRedirect = this.dateRedirect.bind(this);
        this.dailyData = this.dailyData.bind(this);
        this.monthlyData = this.monthlyData.bind(this);
        this.yearlyData = this.yearlyData.bind(this);
        this.timeLink = this.timeLink.bind(this);
        // this.toggleSwitch = this.toggleSwitch.bind(this);
        this.toggle3Switch = this.toggle3Switch.bind(this);
    };


    // toggleSwitch(checked) {
    //     this.setState({ milliontoggle: checked })
    // }
    toggle3Switch(checked) {
        this.setState({ state3tog: checked })
        
    }
    componentDidMount() {
        try {
            this.loadTimestamp(this.state.timestampAPi);
            if (this.state.api && this.state.api !== "") {
                this.loadData(this.state.api);
            }
        } catch (e) {
            console.log(e);
        }
    }

    dateRedirect = (param) => {
        // console.log("label clicked",param,this.state.currentTime)
        var timeParam = param.split("-");
        var from = timeParam[0];
        var to = timeParam[1];
        switch (this.state.currentTime) {
            case "Monthly":
                this.dailyData(from, to)
                break;
            case "Yearly":
                this.monthlyData(from, to)
                break;
            default:
                break;
        }
    }

    dailyData = (from, to) => {
        console.log("daily,",from,to);
        this.setState({ currentTime: "Daily", currentYear: to, currentMonth: from })
        const dailyApi = `/api/tbgr/monthly?month=${from}&year=${to}`
        this.loadData(dailyApi)
    }

    monthlyData = (from, to) => {
        console.log("monthly,",from,to);
        var fromyr = '';
        var toyr = ''
        if (from && to) {
            fromyr = from;
            toyr = to;
        }
        else {
            fromyr = this.state.currentYear;
            toyr = parseInt(this.state.currentYear) + 1
        }

        this.setState({ currentTime: "Monthly" })
        const monthlyApi = `/api/tbgr/yearly?fromyear=${fromyr}&toyear=${toyr}`;
        this.loadData(monthlyApi);
    }

    yearlyData = () => {
        console.log("yealy,");
        this.setState({ currentTime: "Yearly" })
        const yearlyApi = `/api/tbgr/all`
        this.loadData(yearlyApi)
    }
    loadTimestamp(api) {
        fetch(api)
            .then(resp => resp.json())
            .then((data) => {
                this.setState({timestamp:data.timestamp})
            })
            .catch((e) => { console.log("error in header", e) })
    }
    loadData(api) {
        this.setState({inProgress : true});
        fetch(api)
            .then(resp => resp.json())
            .then((data) => {
                this.setState({inProgress : false});
                if (Object.keys(data.data).length !== 0) {

                    this.setState({
                        columns: data.columns,
                        data: data.data,
                        records:true,
                    },()=>{
                        const sample = this.state.data
                        const csvdata = Object.keys(sample).map((item,id)=>sample[item]);
                        
                        this.setState({csvdata})
                        
                    })
                }
                else {
                    this.setState({
                        columns: data.columns,
                        records:false,
                    })
                    
                }
            })
            .catch((e) => { this.setState({inProgress : false});console.log("error in loading business growth data", e) })
    }


    // Link to be displayed on table        
    timeLink = () => {
        // console.log("link clicked",this.state.columns[0])
        
        if (this.state.columns[0]) {
            switch (this.state.columns[0].label) {
                case "Date":
                    return <button className="navigate-table" onClick={this.monthlyData}><NavigateBeforeIcon />Monthly</button>
                case "Month":
                    return <button className="navigate-table" onClick={this.yearlyData}><NavigateBeforeIcon />Yearly</button>
                default:
                    break;
            }
        }
    }
    render() {
        const cols = this.state.columns;
        const tableBody = this.state.data
        const returnCellVal = (col, toggleState, cellVal) =>{
            if(col.includes("Value")){
                return convertNumberPrecision(getToggleVal(cellVal,toggleState));
            }
            // if(col.includes("Value") && toggleState  === 'center'){
            //     return convertNumberPrecision((cellVal) / 1000000);
            // }
            // else if(col.includes("Value") && toggleState  === 'right'){
            //     return convertNumberPrecision((cellVal) / 100000000);
            // }
            // else if(col.includes("Value") && toggleState  === 'left'){
            //     return convertNumberPrecision((cellVal) / 1000);
            // }
            else {
                return convertPrice(cellVal);
            }
        }
        // console.log("business",this.timeLink())
        return (
        <>

            <div className="row">
                <div className="col-md-6 m-0">
                <div className="ml-3 mt-3 mb-3 row">
                <h3 className="timestamp-heading">As on {this.state.timestamp} IST
                </h3>
                </div>
                </div>

                <div className="col-md-6">

                    <div className="mr-3 mt-3 mb-3
                         row justify-content-end align-items-center">
                        {/* <Toggle toggleState={this.state.milliontoggle} toggleCallback={this.toggleSwitch} /> */}
                        <State3toggle toggleCallback={this.toggle3Switch} />
                        {/* <DownloadIconButton /> */}
                        <span><CSVDownloader data={this.state.csvdata} csvname={"business-growth"} /></span>
                    </div>
                </div>
            </div>
            {this.state.inProgress ? <LinearProgress/> : <>
            <div className="col-md-12 tbgtbl">
                
                {this.timeLink()}
                {/* <TableContainer> */}
                <div className="table-responsive tbgtable">
                    <Table className="table lefthd-table">
                        <TableHead>
                            <TableRow key="time">
                                {cols.map((item, idx) => {
                                    return item.label.columns ?
                                        <th className="MuiTableCell-head" key={`${idx}${item.label.label}`} colSpan="2">{item.label.label}</th> :
                                        <th className="MuiTableCell-head" key={`${idx}${item.label}`} rowSpan="2">{item.label}</th>
                                })}
                            </TableRow>
                            <TableRow key="data">
                                {cols.map((item, idx) => {
                                    return item.label.columns ? Object.keys(item.label.columns).map((subitem, colidx) => {
                                        return <th className={"MuiTableCell-head w-9 text-right"} key={`${idx}${colidx}`}>
                                            {(item.label.columns[subitem]).includes("Val") ? 
                                            (item.label.columns[subitem] +  getToggleHeader(this.state.state3tog) 
                                            // (this.state.state3tog  === 'center' ? "($ Millions)" : 
                                            // (this.state.state3tog  === 'left' ? "($ Thousands)" : "($ Billions)"))
                                            )
                                            : item.label.columns[subitem]}
                                            </th>
                                    }) : <React.Fragment key={`${idx}`}></React.Fragment>
                                })}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <tr className="tbg-no-hover"><td className="border-none-padding0"></td></tr>
                            {this.state.records===true ? Object.keys(tableBody).map((row, idx) => {
                                return <React.Fragment key={row}>
                                    <TableRow >
                                    {/* {cols[0].label !== "Date" ?
                                        <TableCell key={`${idx}${row}`} component="td" scope="row">
                                            <a onClick={() => this.dateRedirect(row)} href="#">{row}</a></TableCell> :
                                        <TableCell key={`${idx}${row}`} component="td" scope="row">{row}</TableCell>
                                    } */}

                                    {Object.keys(tableBody[row]).map((cell, jdx) => {
                                        return <React.Fragment key={jdx}>
                                            <TableCell className={jdx === 0 ? "text-left" : "text-right"} key={`${jdx}${idx}`} component="td" scope="row">
                                            {cols[0].label !== "Date" && jdx === 0 ? 
                                            <button className="tbg-time-link"  onClick={() => this.dateRedirect(tableBody[row][cell])}>{tableBody[row][cell]}</button> 
                                            : returnCellVal(cell, this.state.state3tog,tableBody[row][cell])
                                            // this.state.milliontoggle && cell.includes("Value") ? convertPrice((tableBody[row][cell])/1000000) : convertPrice(tableBody[row][cell])
                                            }
                                            </TableCell>
                                            {/* <TableCell key={`${jdx}${idx}+val`} component="td" scope="row">{this.state.milliontoggle ? ((tableBody[row][cell].TURN_VALUE)/100).toFixed(2) : (tableBody[row][cell].TURN_VALUE).toFixed(2)}</TableCell> */}
                                        </React.Fragment>
                                    })}
                                
                                </TableRow>
                                    <tr className="tbg-no-hover"><td className="border-none-padding0"></td></tr>
                            </React.Fragment>
                            }) : this.state.records===false ?<tr><td colSpan={12}>No Records</td></tr> : <></>}

                        </TableBody>
                    </Table>
                </div>
            </div>

            </>}
            {/* </TableContainer> */}
        </>
        );
    };
}

export default TbgTableRevised;