import React from "react";
import { ReactComponent as CsvIcon } from '../../public/images/csv-icon.svg';

class CSVDownloader extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
           arr:this.props.data || [],
           csvname:this.props.csvname,
        };
        this.downloadCSV = this.downloadCSV.bind(this);
        this.getCSVString = this.getCSVString.bind(this);
        this.getCSVBlob = this.getCSVBlob.bind(this);
        this.serveCSVBlob = this.serveCSVBlob.bind(this);
    }
    componentDidMount() {
        try {
            this._isMounted = true;
            
        } catch (e) {
            console.log(e);
        }
    }

    componentDidUpdate(prevProps){
        if(this.props.data !== prevProps.data){
            this.setState({arr:this.props.data || []})
        }
        if(this.props.csvname !== prevProps.csvname){
            this.setState({csvname:this.props.csvname})
        }
    }

    downloadCSV() {
        
        const arr = this.state.arr;
        const csvString = arr.length > 0 ? this.getCSVString(arr) : "";
        const csvBlob = this.getCSVBlob(csvString);
        this.serveCSVBlob(csvBlob);
    };

    getCSVString(arr) {
        const csvArray = [Object.keys(arr[0])].concat(arr);
        return csvArray.map(row => {
            let rowArray =  [];
            Object.values(row).forEach(item=> {
                rowArray.push('"' + item + '"');
            });
            return rowArray;
            })
            .join("\n") || [];
            
    };

    getCSVBlob(csvString) {
        return new Blob([csvString], { type: "text/csv" });
    };

    serveCSVBlob(csvBlob) {
        const url = URL.createObjectURL(csvBlob);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", this.state.csvname+".csv");
        document.body.appendChild(link);
        link.click();
    };

    render() {
        
        return (
            
                <button onClick={this.downloadCSV} className="btn btn-white btn-csv-download d-flex align-items-center">
                    <CsvIcon className="icon-color mr-1" />Download (.CSV)
                </button>
            
        );
    }
}

export default CSVDownloader;
