import * as React from "react";
import { parseAsHtml, removePtag } from "./util";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import alert from "../../public/images/alert.svg";
//import checked from "../../public/images/checked.svg";
import notBell from "../../public/images/ringing-bell.png"

const AlertDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },

  "& .MuiDialog-paper": {
    height: "400px",
    width: "830px",
    borderRadius: "0px",
    maxWidth: "none",
    marginTop: "10%",
  },
}));

const AlertDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle
      sx={{ m: 0, p: 2 }}
      {...other}
      style={{
        paddingBottom: "5px",
        fontSize: "24px",
        fontFamily: "Roboto-Medium",
      }}
    >
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[800],
            outline: 'none !important'
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

export default function CustomizedDialogs(props) {
  const [open, setOpen] = React.useState(true);
  
 function handleClose () {
    setOpen(false);
   if (props.type === "menu") 
   { props.toggle(false) }
   else{}
  
  };

  return (
    <div>
      <AlertDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        {props.warning ? (
          <AlertDialogTitle
            id="customized-dialog-title"
            onClose={handleClose}
            className="dialogTitleRed"
          >
            Alert
          </AlertDialogTitle>
        ) : (
          <AlertDialogTitle
            id="customized-dialog-title"
            onClose={handleClose}
            // className="dialogTitleGreen"
          >
            Notification
          </AlertDialogTitle>
        )}
        <DialogContent>
          <div className="alertImgAlign">
            {props.warning ? (
              <img src={alert} alt="alert icon" width="73" height="73"></img>
            ) : (
              <img src={notBell} alt="alert icon" width="73" height="73"></img>
            )}
          </div>
          {props.warning ? (
            <span className="alertTitleRed">Alert Message</span>
          ) : (
            <span className="alertTitleGreen">Notification</span>
          )}

          <div className="alertText">
            <Typography
              style={{
                color: "#333333",
                padding: "10px 70px 70px 70px",
                textAlign: "left",
              }}
            >
              {parseAsHtml(props.data)}
            </Typography>
          </div>
        </DialogContent>
      </AlertDialog>
    </div>
  );
}
