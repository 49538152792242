import React from 'react';
import AttachmentTable from './components/attachment-table';
import CorporateFilters from './components/corporate-filters';
import moment from "moment";

class CorporateInformation extends React.Component {
    _isMounted = false;
    constructor(props) {
        super(props);
        this.state = {
            
            api: `/api/corporate-information?&from=${moment(new Date()).subtract(7, 'days').format("DD-MMM-YYYY")}&to=${moment().utc().utcOffset("+05:30").format('DD-MMM-YYYY')}`,         
        };
        this.filterParams = this.filterParams.bind(this);
        this.resetTable = this.resetTable.bind(this);
    }

    componentDidMount() {
        try {
            this._isMounted = true;

          
           
        } catch (e) {
            console.log(e);
        }
    }
  
    filterParams(issuerName,issuerDesc,fromdate,todate) {
        let api = `/api/corporate-information?`;
        if (issuerName !== "") {
            issuerName = issuerName.split(' ').join('%20');
            api += '&name=' + issuerName.split(`'`).join('%27%27');
        }
        if (issuerDesc !== "") {
            api += '&description=' + issuerDesc.split(' ').join('%20');
        }
        if (fromdate && todate) {
            api += `&from=${fromdate}&to=${todate}`;
        }
       
        this.setState({ api })
    }

    resetTable(api){
        this.setState({ api })
    }

    render() {
        return (
            <div className="container-fluid corp-info-section">
                <CorporateFilters resetcallback={this.resetTable} callback={this.filterParams}/>
                <div className="row">
                    <div className="col-md-12 mt-3">
                        <AttachmentTable api={this.state.api} />
                    </div>
                </div>
            </div>
        );
    }
}

export default CorporateInformation;