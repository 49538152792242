import React from 'react';
import { CarouselProvider, Slider, Slide, ButtonBack, ButtonNext, ButtonPlay } from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import { ReactComponent as PlayIcon } from '../../public/images/play.svg';
import { ReactComponent as PauseIcon } from '../../public/images/pause.svg';

class IssuerImageSlider extends React.Component {
    constructor(props) {
        super(props);
        this.state = 
        {
            cardData: props.data,
            visibleSlide: 4,
            ariaproperties:""
        }
    };
    componentDidMount() {
        try {
            this._isMounted = true;
            window.addEventListener("resize", this.resize.bind(this));
            this.resize();
        } catch (e) {
            console.log(e);
        }
    }
    resize() {
        
        let currentmobileScreen = window.innerWidth;
        let visibleSlide = 7;
        if (currentmobileScreen < 400) {
            visibleSlide = 1;
            
        }
        else if(currentmobileScreen  <=768) {
            visibleSlide = 2;
        } 
        else if(currentmobileScreen <=800) {
            visibleSlide = 4;
        }
        else if(currentmobileScreen <=1024) {
            visibleSlide = 5;
        } 
        else if(currentmobileScreen <=1366) {
            visibleSlide = 7;
        }
        this.setState({visibleSlide});
    }
    componentWillUnmount() {
        window.removeEventListener("resize", this.resize.bind(this));
    }
    render() {
        return (
            <div className="container-fluid light-grey-bg">
                <section id="IssuerImageSlider" className="market-banner-slides">
                    <div className="row">
                        <div className="columns">
                            {<CarouselProvider  className="rating-slider" 
                                naturalSlideWidth={100}
                                naturalSlideHeight={30}
                                totalSlides={10}
                                visibleSlides={6}
                                isPlaying={this.state.cardData.length > this.state.visibleSlide ? true : false}
                                interval={3000}
                            >
                                <Slider aria-label="market-ticker" aria-owns={this.state.ariaproperties} className="imgSlider">
                                    {this.state.cardData.length > 0 && this.state.cardData.map((event, index) => {
                                        return <Slide id={"market-slider-"+index} key={index} width="auto" className='mr-1 ml-1'>
                                          <img id={event.alt} src={event.url} alt={event.alt} height='80px' width="auto" className="imgSlide"/>
                                        </Slide>
                                    })}
                                </Slider>
                                <div className="slider-control-buttons">
                                <ButtonBack className="prev arrow-btn-slider"><ArrowBackIosIcon className="next-prev"/></ButtonBack>
                                <ButtonPlay className="play-pause arrow-btn-slider" childrenPaused={<PlayIcon className="next-prev"/>} childrenPlaying={<PauseIcon className="next-prev"/>} />
                                <ButtonNext className="next arrow-btn-slider"><ArrowForwardIosIcon className="next-prev"/></ButtonNext>
                                </div>
                            </CarouselProvider>}
                        </div>
                    </div>
                </section>
            </div>
        );
    }
}

export default IssuerImageSlider;