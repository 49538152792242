import React from "react";
import { IconButton } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import AddIcon from '@material-ui/icons/Add';
import FormControl from '@material-ui/core/FormControl';
import NativeSelect from "@material-ui/core/NativeSelect";
// import InputLabel from "@material-ui/core/InputLabel";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

class DerivativesDropdownTab extends React.Component {
    constructor(props) {
        super(props);
        const defaultValue = props.selects.find((sel) => sel.label).label;
        const selected = props.tabs.map(() => defaultValue);
        this.state = {
            selects: props.selects || [],
            activetabs: props.tabs,
            activeId: 0,
            selected,
            sendSelectedCallback: props.sendSelectedCallback,
            //paramSelected:props.paramSelected,
        };
        this.state.sendSelectedCallback(this.state.selected[0]);
        this.onClickActiveTab = this.onClickActiveTab.bind(this)
    };

    handleOpen(id) {
        //console.log("select opened")
        if (this.state.activeId !== id) {
            this.setState({ activeId: id });
        }
        this.state.sendSelectedCallback(this.state.selected[id]);
    };
    componentDidUpdate(prevProps){
        // if(prevProps.paramSelected !== this.props.paramSelected){
        //     this.setState({paramSelected:this.props.paramSelected,
                
        //     })
        // }
        // if(prevProps.tabs !== this.props.tabs){
        //     console.log("tabs changed active", this.props.tabs)
        //     this.setState({
        //         activetabs:this.props.tabs
        //     })
        // }
    }
    handleAdd(event) {
        const newTabObject = {
            closeable: true,
            //component: this.state.activetabs[this.state.activeId].component,
            selected: this.state.selected
        };
        
        const selected = this.state.selected;
        selected.push(selected[this.state.activeId]);
        const newList = this.state.activetabs.concat(newTabObject);
        this.setState({ activetabs: newList, selected });
    };

    handleClose(event, deleteIdx) {
        event.stopPropagation();
        const activetabs = this.state.activetabs;
        activetabs.splice(deleteIdx, 1);
        const selected = this.state.selected;
        selected.splice(deleteIdx, 1);
        const activeId = this.state.activeId;
        this.setState({
            activetabs,
            selected,
            activeId: activeId > deleteIdx ? activeId - 1 : activeId
        });
    };

    handleChange(event, idx) {
        event.stopPropagation();
        const selected = this.state.selected;
        selected[idx] = event.target.value;
        this.setState({ selected });
        // const newTabObject = {
        //     closeable: false,
        //     component: this.state.activetabs[this.state.activeId].component,
        //     selected: this.state.selected
        // };
        this.state.sendSelectedCallback(this.state.selected[idx]);
            // this.setState({
            //     activetabs:this.props.tabs
            // })
        
    };
    onClickActiveTab(id){
        this.setState({activeId:id})
        this.state.sendSelectedCallback(this.state.selected[id]);
    }

    render() {
        
        return (
            <div className="col-xs-12 col-lg-12 mt-1">
                <div className="deriv-dropdowntabs MuiTabs-flexContainer">
                    {this.state.activetabs.map((tab, idx) => {
                       return <FormControl className={`mr-1 ${idx !== this.state.activeId ? "formcontrol close-bar" : "formcontrol active"}`} key={idx}
                            value={idx} >
                            <NativeSelect
                                IconComponent={ExpandMoreIcon}
                                onClick={() => this.handleOpen(idx)}
                                onChange={(event) => this.handleChange(event, idx)}
                                value={this.state.selected[idx] ?? ""}
                                id="grouped-select" 
                                inputProps={{ 'aria-label': 'derivative watch filters' }}
                             >  
                             {
                                    this.state.selects.map((opt, jdx) => {
                                        if (opt.sublabel) {
                                            return <optgroup key={`select${jdx}`} label={opt.sublabel} ></optgroup>;
                                        } else {
                                            return <option key={`select${jdx}`} value={opt.label} >{opt.label}</option>;
                                        }
                                    })
                                }
                                
                            </NativeSelect>
                           
                            {tab.closeable && this.state.activetabs.length > 1 && idx !== this.state.activeId && (
                                <>
                                <IconButton
                                    className="col-2 close-selectbox"
                                    component="div"
                                    onClick={event => this.handleClose(event, idx)}>
                                    <CloseIcon />
                                </IconButton>
                                <span className="activeTab" onClick={()=>this.onClickActiveTab(idx)}></span>
                                </>
                            )}
                        </FormControl>
                        
                        
    }
                        
                        )}
                    {this.state.activetabs.length <= 4 ?

                        <div className="plusbtn" onClick={event => this.handleAdd(event)}>
                            <AddIcon />
                        </div>
                        : ""}
                </div>
                {/* {this.state.activetabs.map((tab, idx) => {
                    return (
                        <TabPanel value={this.state.activeId} index={idx} key={`tabpanel${idx}`}>
                            {tab.component}
                        </TabPanel>
                    )
                })} */}
            </div>
        );
    }
}

export default DerivativesDropdownTab;
