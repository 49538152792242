import React from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';

class ImageSlider extends React.Component {

    _isMounted = false;
    constructor(props) {
        super(props);
        this.state = {
            mydata: [],
        }
    }

    componentDidMount() {
        try {
            this._isMounted = true;
            this.setState({ mydata: this.props.characterData });
        } catch (e) {
            console.log(e);
        }
    }

    render() {
        return (
            <Carousel className="image-gallery">
                {this.state.mydata.map((row, index) => { return <div key={index}><img alt={row.alt} src={row.mImage} /></div> })}
            </Carousel>
        );
    }
}

export default ImageSlider;