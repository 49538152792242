import React from 'react';
import OptionChainTable from "./components/option-chain-table";
import NavTabs from './components/nav-tabs';

class OptionChain extends React.Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {

    }
  }

  componentDidMount() {
    try {
      this._isMounted = true;
    } catch (e) {
      console.log(e);
    }
  }

  // closeFullScreen() {
  //   var elmnt1 = document.getElementById("bestView");
  //   elmnt1.className = ""
  //   var elmnt2 = document.getElementById("LogoAllow")
  //   elmnt2.className = "hide"
  // }


  render() {
    return (
      <div id="optionChain-page" className="container-fluid">
        <div className="row">
          <div className="col-md-12">
            <NavTabs
              color="secondary"
              tabs={[
                {
                  title: "Equity Stock",
                  component: (
                    <>
                      <div id='bestView'>
                        {/* <div id="LogoAllow" className='hide'>
                          <img src={Logo3} width="107" height='62' alt='IFSC International Exchange' />
                          <button className='bestViewClose' onClick={this.closeFullScreen}> <img src={close} width="15" height='15' alt='close' />be</button>
                        </div> */}
                        <h2 className="page-title pl-3">Option Chain (Equity Derivatives)</h2>
                        <OptionChainTable type="equity" />
                      </div>
                    </>
                  ),
                },
                {
                  title: "Currency",
                  component: (
                    <>
                      <div id='bestView'>
                        {/* <div id="LogoAllow" className='hide'>
                          <img src={Logo3} width="107" height='62' alt='IFSC International Exchange' />
                          <button className='bestViewClose' onClick={this.closeFullScreen}> <img src={close} width="15" height='15' alt='close' /></button>
                        </div> */}
                        <h2 className="page-title  pl-3">Option Chain (Currency Derivatives)</h2>
                        <OptionChainTable type="currency" />
                      </div>
                      {/* <OptionChainDataFilter /> */}
                      {/* <div className="container-fluid">
                        <div className="row"> */}

                      {/* <div className="col-md-12">
                          <OptionChainTable ltp={this.state.ltp} timestamp={this.state.timestamp} filterCallback={this.setExpiry} 
                              type="equity" data={this.state.data} expiry={this.state.tableselectedexpiry} strikeprice={this.state.tableselectedsp}
                              filtered={this.state.filtered} />
                          </div>
                          <div id='terms' className="col-md-12">
                            <Notes notesData={this.state.notesData} />
                          </div> */}
                      {/* </div>

                      </div> */}
                    </>
                  ),
                },
                // {
                //   title: "Commodities",
                //   component: (
                //     <>
                //       <div id='bestView'>
                //         <div id="LogoAllow" className='hide'>
                //           <img src={Logo3} width="107" height='62' alt='IFSC International Exchange' />
                //           <button className='bestViewClose' onClick={this.closeFullScreen}> <img src={close} width="15" height='15' alt='close' /></button>
                //         </div>
                //         <h2 className="page-title ">Option Chain (Commodities Derivatives)</h2>
                //         <OptionChainTable type="commodity" dataapi={`/api/option-chain?symbol=GOLD`} />
                //       </div>
                //     </>
                //   ),
                // },
              ]}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default OptionChain;