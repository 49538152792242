import React from 'react';
//import NavTabs from './components/nav-tabs';
import CustomTable from './components/custom-table';
import Notes from './components/notes';
// import { getDownloadIcon, removePtag, parseAsHtml } from './components/util';

class ListOfUnderlying extends React.Component {

    _isMounted = false;
    constructor(props) {
        super(props);
        this.state = {
            card: props.card,
            headerRef: props.headerRef,
            api: `/api/contents/page?url=/markets/about-our-products/list-of-underlying/equity-derivatives`,
            notesApi: `/api/contents/notes?url=/product-list-underlying`,
            data: [],
            notesData: [],
            downloadlink: '',
        }
    }

    componentDidMount() {
        try {
            this._isMounted = true;
            this.loadNotes(this.state.notesApi);
            this.state.headerRef.current.updateTitle("Equity Derivatives", 
            "Equity Derivatives", [{
                "field_breadcrumb": [
                { "uri": "/products-ifsc", "title": "Markets" },
                { "uri": "/products-ifsc", "title": "All Products" },
                { "uri": "/products-ifsc", "title": "About our Products" },
                { "uri": "/products-ifsc", "title": "List Of Underlying" },
                { "uri": "/markets/about-our-products/list-of-underlying/equity-derivatives", "title": "Equity Derivatives" },
            ]
            }]);
        } catch (e) {
            console.log(e);
        }

    }
    loadNotes(api) {
        fetch(api)
            .then(resp => resp.json())
            .then((cards) => {
                this.setState({
                    notesData: cards.content.field_note_components,
                }, () => {
                   // this.loadStaticData(this.state.api);
                })
            })
            .catch((e) => { console.log("error in header", e) })

    }
    // loadStaticData(api) {


    //     fetch(api)
    //         .then(resp => resp.json())
    //         .then((resp) => {
    //             const content = (resp.content && resp.content.field_components) || []
    //             let title = "";
    //             const data = {};
    //             content.forEach((cont) => {
    //                 if (cont.field_title) {
    //                     title = cont.field_title;
    //                      console.log(title)
    //                 }
    //                 if (cont.field_tabs_content) {
    //                     cont.field_tabs_content.forEach((tab) => {
    //                         if (tab.field_tab_view && tab.field_tab_view.length > 1) {
    //                             if (
    //                                 tab.field_tab_view[0].field_title &&
    //                                 tab.field_tab_view[1].field_table_contents
    //                             ) {
    //                                 data[tab.field_tab_view[0].field_title] = tab.field_tab_view[1].field_table_contents
    //                             }
    //                         }
    //                     })
    //                 }
    //                 if (content[3].file_url) {
    //                     this.setState({
    //                         downloadlink: content[3].file_url
    //                     })
    //                 }
    //             });
    //             const contents = this.createComponents(data, content)
    //             //  this.state.headerRef.current.updateTitle("List Of Underlying", "List Of Underlying");
    //             this.setState({ contents });

    //         })
    //         .catch((e) => { console.log("error in list of underlying", e) })
    // };


    // createComponents(data, content) {

    //     const contents = <NavTabs tabStyleBlock={false} tabs={[
    //         {
    //             title: "Equity Derivative", component:
    //                 <React.Fragment>
    //                     <div className="col-md-12 m-0">
    //                         <div className="col-md-12 m-0">
    //                             <p className="lst_of_underlyng_title">Index Derivatives</p>
    //                         </div>
    //                         {/* urlTable attribute set to true - for setting links on symbols for redirecting to Get Quotes Page */}
    //                         <div className="lst_of_underlyng_setwidth">
    //                             <CustomTable tableid="louIdxDer" data-api={`/api/index-derivatives`} urlTable={true} />
    //                         </div>
    //                         <div className="col-md-12 m-0">
    //                             <p className="lst_of_underlyng_title">Stock Derivatives - Indian.</p>
    //                         </div>
    //                         <CustomTable tableid="louStockDer" data-api={`/api/stock-derivatives`} urlTable={true} />

    //                     </div>

    //                     <div className="col-md-12">
    //                         <Notes notesData={this.state.notesData} />
    //                         <a  target="_blank" rel="noopener noreferrer" onClick={externalLinkDisclaimer} href={content[7].uri || ""}> {content[7].title || ""}</a>
    //                     </div>

    //                 </React.Fragment>
    //         },
    //         ...Object.keys(data).map((key) => {
    //             return {
    //                 title: removePtag(key),
    //                 component: <div className="static-table col-md-12 m-0">
    //                     {parseAsHtml(data[key])}
    //                     {/* <CustomTable tableid={"loulistofunderlying"}
    //                         data-columns={data[key][0]}
    //                         data-rows={data[key].slice(1, data[key].length - 1)} urlTable={true}
    //                     /> */}
    //                 </div>
    //             }
    //         })
    //     ]} />
    //     return contents;
    // };

    render() {
        return (<div className=" static_content">
        <div className="col-md-12 m-0">
        <a target="_blank" rel="noopener noreferrer" href="/markets/about-our-products/contract-specification/equity-derivatives" className="btn btn-orange pr-4 pl-4">Contract Specifications</a>
        </div>
            <div className="col-md-12 m-0">
            
<p className="lst_of_underlyng_title">Index Derivatives</p>
</div>
{/* urlTable attribute set to true - for setting links on symbols for redirecting to Get Quotes Page */}
{/* <div className="lst_of_underlyng_setwidth"> */}
<CustomTable tableid="louIdxDer" data-api={`/api/index-derivatives`} urlTable={true} />
{/* </div> */}
<div className="col-md-12 m-0">
<p className="lst_of_underlyng_title">Stock Derivatives - Indian.</p>
</div>
<CustomTable tableid="louStockDer" data-api={`/api/stock-derivatives`} urlTable={true} />

<div className="col-md-12">
<Notes notesData={this.state.notesData} />
{/* {this.state.notesData[0] ? <a  target="_blank" rel="noopener noreferrer" 
onClick={externalLinkDisclaimer} 
href={this.state.notesData[0].uri || ""}> 
{this.state.notesData[0].title || ""}</a> : "" } */}
</div>
       </div> );
    }
}

export default ListOfUnderlying;