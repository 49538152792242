import React, { Component } from 'react';
//import Highcharts from 'highcharts'
import exporting from "highcharts/modules/exporting";
import HighchartsReact from 'highcharts-react-official';
//import OCgraph from './OCgraph.json'
import Highcharts from 'highcharts/highstock'

// const data1 = OCgraph.graphData3;
// const data2= OCgraph.graphData4;

exporting(Highcharts);

class HighChartOc extends Component {
    constructor(props) {        
        super(props);
        this.state = {
            api: props.api,
            options: {},
        }
    };
    componentDidUpdate(prevProps) {
        if (this.props.api !== prevProps.api) {
            this.setState({
                api: this.props.api,    
            }, () => {
                    this.loadData(this.state.api);
                })
        }
    }
    componentDidMount() {
        try {
            if (this.state.api && this.state.api !== "") {
                this.loadData(this.state.api);
            }
        } catch (e) {
            console.log(e);
        }
    }
    loadData(api) {
        fetch(api)
            .then(resp => resp.json())
            .then((data) => {
                this.setState({
                  options: {
                    chart: {
                      height: "100%",
                      width: "100%",
                    },
                    title: {
                      text: null,
                    },
                    rangeSelector: {
                      enabled: false,
                      selected: 2,
                      inputEnabled: false,
                    },
                    credits: {
                      enabled: false,
                    },
                    navigator: {
                      enabled: false,
                    },
                    scrollbar: {
                      enabled: false,
                    },
                    yAxis: {
                      opposite: false,
                      plotLines: [
                        {
                          value: 0,
                          width: 2,
                          color: "silver",
                        },
                      ],
                    },
                    legend: {
                      enabled: false,
                    },
                    plotOptions: {
                      series: {
                        compare: "percent",
                        showInNavigator: true,
                        states: {
                          inactive: {
                            opacity: 1,
                          },
                        },
                      },
                    },
                    tooltip: {
                      valueDecimals: 2,
                      split: true,
                    },
                    xAxis: {
                      type: "datetime",
                      crosshair: {
                        width: 1,
                        color: "grey",
                      },
                    },
                    series: [
                      {
                        data: data.callData,
                        name: data.symbol,
                        tooltip: {
                          pointFormat:
                            '<span style="color:{series.color}">{series.name}</span>: <b>{point.y}</b> ({point.change}%)<br/>',
                        },
                      },
                      {
                        data: data.putData,
                        name: data.symbol,
                        tooltip: {
                          pointFormat:
                            '<span style="color:{series.color}">{series.name}</span>: <b>{point.y}</b> ({point.change}%)<br/>',
                        },
                      },
                    ],
                  },
                });
            })
            .catch((e) => { console.log("error in header", e) })
    }

    render() {
        return ( <><HighchartsReact highcharts={Highcharts} constructorType={"chart"} options={this.state.options} /></> )
    }
}

export default HighChartOc;