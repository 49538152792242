import React, { Component } from 'react';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

class Menu extends Component {
    _isMounted = false;
    constructor(props) {
        super(props);

        this.state = {
            meta: props['menu-meta'],
            items: props['menu-items'],
            openMenu: false,
            activeMenu:props.activeMenu || "Home",
            activePage:props.activePage || "",
        }
        this.subMenuToggle = this.subMenuToggle.bind(this);
    }

    componentDidMount() {
        try {
            this._isMounted = true;
        } catch (e) {
            console.log(e);
        }
    }
    subMenuToggle() {
        this.setState({ openMenu: !this.state.openMenu })
    }
    componentDidUpdate(prevProps) {
        if(prevProps.activeMenu !== this.props.activeMenu){
            this.setState({activeMenu:this.props.activeMenu || "Home"})
        }
        if(prevProps.activePage !== this.props.activePage){
            this.setState({activePage:this.props.activePage || "Home"})
        }
     }
    
    displaySubMenu = (level1menu) => {
        if(level1menu.key === "AboutourProducts" || level1menu.key === "ListingBond"){            
            let submenuArray = []
            level1menu.items.forEach(submenu => {
            submenuArray.push(<li key={submenu.key}>
                <a className={(submenu.key === this.state.activePage ? " activepage" : "")} href={submenu.url}>{submenu.name}
                </a></li>)
            });
        return  <ul className="level3-submenu">{submenuArray}</ul>
        }
    }
    render() {

        return (
            <li className="nav-item dropdown has-megamenu dmenu">
                <span onClick={this.subMenuToggle}>
                    {/* Added active class based on whether menu is active/to be highlighted */}  
                    <a className={this.state.meta.url ? ("nav-link pr-2" + ((this.state.activeMenu).toLowerCase() === (this.state.meta.key).toLowerCase() ? " active" : "")): 
                    ("nav-link arrow-menu dropdown-toggle " + ((this.state.activeMenu).toLowerCase() === (this.state.meta.key).toLowerCase() ? " active" : ""))} href={this.state.meta.url || "#"} title={this.state.meta.name} aria-label={this.state.meta.name}>{this.state.meta.name}
                        {this.state.meta.url ? <></> : <ExpandMoreIcon className="menu-expand-icon" />}
                    </a>
                </span>
                {this.state.meta.url ? "" :
                    <div className={this.state.openMenu ? "dropdown-menu megamenu sm-menu" : "menu-close dropdown-menu megamenu sm-menu"}>
                        <div className="container-fluid">
                            <div className="row">
                                {this.state.items.map((menu, idx) => {
                                    /*Check if its Overview section of Menu */
                                    if (menu.isOverview) {
                                        return <div className="col-lg-5 col-md-5" key={`${menu.name}${idx}`}>
                                           <div className="row"><h4>{menu.name}</h4></div>
                                            {menu.items.map((item,oid) => {
                                                return <div className="row" key={`${menu.title}${oid}`}>
                                                    <div className="col-lg-5 mb-4 pl-0">
                                                        <img src={item.image} className="img-responsive" alt="overview"/>
                                                    </div>
                                                    <div className="col-lg-7">
                                                        <div className="d-block">
                                                            <h5>{item.title}</h5>
                                                            <p>{item.description.replace('<p>', '').replace('</p>', '')}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            })}
                                        </div>
                                    }
                                    else {
                                        return <div className={idx===0 ? "col-lg-12" : "col-lg-3 col-md-3"} key={`${menu.key}${idx}`} >
                                            <div className="nopadding">
                                            <h4>{menu.name}</h4>
                                            {menu.items.map((item) => {
                                                return <React.Fragment key={item.key} ><a className={"dropdown-item " + 
                                                (item.key === this.state.activePage ? " activepage" : "")} 
                                                href={item.url} title={item.name}>
                                                
                                                {item.name}
                                                
                                                    </a>
                                                    {this.displaySubMenu(item)}
                                                    </React.Fragment>
                                            })}
                                            </div>
                                        </div>
                                    }
                                })}
                            </div></div>
                    </div>}
            </li>
        )
    }
}

export default Menu
