import React from 'react';
import { parseAsHtml, removePtag, getTitleFileExtension, getDownloadIcon } from './util';
// import { ReactComponent as MultipleDownload } from '../../public/images/download-icon.svg';
import { sanitizeUrl } from './sanitize-url';
import Tooltip from '@material-ui/core/Tooltip';

class PressReleaseCard extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            card: props.card,
            id:props.cardkey,
        };
    }

    render() {
        let title = removePtag(this.state.card.title);
        return <div className="pressrbox mx-1" >
            <div className="d-flex">
                <div className="grey-circle">
                    <h3>{this.state.card.day}</h3>
                    <h4>{this.state.card.month}</h4>
                </div>
                <div className="press-rcont" title={removePtag(this.state.card.para)}>
                   {parseAsHtml(this.state.card.para)}
                </div>
                <div className="pressrdownload">
                    
                         <Tooltip title={"Download " +getTitleFileExtension(this.state.card.url)} placement="left">
                         <a target="_blank" rel="noopener noreferrer"
                     aria-label={"View Press Release File for "+title} href={sanitizeUrl(this.state.card.url)}>
                         {getDownloadIcon(this.state.card.url,"pr-download"+this.state.id)}
                    {/* <MultipleDownload id={"pr-download"+this.state.id} className="press-download-icon"/> */}
                    </a>
                    </Tooltip>
                </div>
            </div>
        </div>
    }
}

export default PressReleaseCard;