import React, { Component } from 'react';
import { getDownloadIcon, parseAsHtml } from './util';
import Table from '@material-ui/core/Table';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import Checkbox from "@material-ui/core/Checkbox";
import StarBorderIcon from '@material-ui/icons/StarBorder';
import StarIcon from '@material-ui/icons/Star';

class DailyReportsTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: props["data"] || [],
            selected: [],
            favorites: [],
            tab: props["tab"] || "",
            selectAll: props.selectAll,
            isChecked: false,
            data1: [],
            checked: [],
            callback: props.callback,
            singleCheck: false,
            searchKey: ''
        };
        this.handleSingleCheckboxChange = this.handleSingleCheckboxChange.bind(this);
    };

    componentDidMount() {
        var favData = localStorage.getItem('ifscFav') || 'no';
        if (favData === 'no') {
            favData = []
        } else {
            try {
                favData = JSON.parse(favData);
            }
            catch(error){
                favData = [];
            }
            // favData = JSON.parse(favData);
        }
        this.setState({ favorites: favData });

        //  select all -multiple download
        var checkedCopy = [];
        var selectAll = this.state.selectAll;
        this.state.data.forEach(function (e, index) {
            checkedCopy.push(selectAll);
        });

        this.setState({
            data1: this.state.data,
            checked: checkedCopy,
            selectAll: selectAll
        }, () => {
            // console.log("check array", this.state.checked)
        });
    }

    componentDidUpdate(prevProps) {
        if (this.props.data !== prevProps.data) {
            this.setState({ data: this.props.data, tab: this.props.tab })
        }

        if (this.props.searchKey !== prevProps.searchKey) {
            this.setState({ searchKey: this.props.searchKey });
            if (this.props.selectAll !== 'DoNot') {
                this.checkedRowFiles(this.props.selectAll, this.state.data, this.props.searchKey);
            }
        }

        if (this.props.updateTable !== prevProps.updateTable) {
            var favData = localStorage.getItem('ifscFav') || 'no';
            if (favData === 'no') {
                favData = []
            } else {
                try {
                    favData = JSON.parse(favData);
                }
                catch(error){
                    favData = [];
                }
                
            }
            this.setState({ favorites: favData });

        }
        //  select all -multiple download
        if (this.props.selectAll !== prevProps.selectAll) {
            if (this.props.selectAll !== 'DoNot') {
                // console.log("check array update", this.props.selectAll)
                var checkedCopy = [];
                var selectAll = this.props.selectAll;
                this.state.data.forEach(function (e, index) {
                    checkedCopy.push(selectAll);
                });

                this.setState({
                    data1: this.state.data,
                    checked: checkedCopy,
                    selectAll: this.props.selectAll
                });
                this.checkedRowFiles(this.props.selectAll, this.state.data, this.state.searchKey);
            }
        }
    }

    checkedRowFiles = (chekcedVal, data1, searchKey) => {

        var checked = this.state.checked;
        if (chekcedVal) {
            // console.log('treyueeee');
            for (var i = 0; i < data1.length; i++) {
                if (data1[i].file.toLowerCase().includes(searchKey.toLowerCase())) {
                    checked[i] = true;
                    this.props.addToDownload(data1[i].fileurl, true);
                } else {
                    checked[i] = false;
                    this.props.addToDownload(data1[i].fileurl, false);
                }
            }
            this.setState({ checked: checked })
        } else {
            //  console.log(':OOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOO');
        }
    }

    addToFav(key) {
        var favData = localStorage.getItem('ifscFav') || 'no';
        if (favData === 'no') {
            favData = []
        }
        else {
            try {
                favData = JSON.parse(favData);
            }
            catch(error){
                favData = [];
            }
            
        }
        var index = this.checkContains(key)
        if (index < 0) //If contin -->0 1 2  i.e index not -1
        {
            key = key.substr(0, key.length - 4);
            favData.push(key); //Added to array if -1
        } else {
            favData.splice(index, 1); //Remove from array
        }
        localStorage.setItem('ifscFav', JSON.stringify(favData)); //Updage array in localstorage
        this.setState({ favorites: favData }); //Update in State
        this.props.favMethod(key); //CAll Parent Method to reflect other table to update local storage

    }


    checkContains(key) {
        var favData = localStorage.getItem('ifscFav') || 'no';
        key = key.substr(0, key.length - 4);
        if (favData === 'no') {
            return -1;
        }
        else {
            try {
                favData = JSON.parse(favData);
            }
            catch(error){
                favData = [];
            }
            
        }
        for (var i = 0; i < favData.length; i++) {
            if (favData[i] === key) {
                return i;
            }
        }
        return -1;
    }


    handleSingleCheckboxChange = (index, item) => {
        // console.log(index);
        var checkedCopy = this.state.checked;
        checkedCopy[index] = !this.state.checked[index];
        if (checkedCopy[index] === false) {
            this.setState({ selectAll: false });
        }
        this.setState({
            checked: checkedCopy,
            singleCheck: checkedCopy[index],
        }, () => {
            this.state.callback(this.state.singleCheck);
            // console.log("bbbbbbbbbbbb", this.state.checked)
        }
        );
        this.props.addToDownload(item.fileurl, checkedCopy[index]);
    };

    render() {
        const TableRow = ({ item, i, isSelected, tabkey, isChecked }) => {
            const tablerow =
                <tr key={`${item.file}${i}${tabkey}`}>
                    <td className="MuiTableCell-body text-align-left" >
                        <Checkbox inputProps={{ 'aria-label': 'Checkbox A' }}
                            className="selectCheckbox" 
                            //defaultChecked={this.state.checked[`${i}`]}
                            checked={this.state.checked[`${i}`]}
                            onChange={() => this.handleSingleCheckboxChange(`${i}`, item)}
                        />
                    </td>
                    <td className="MuiTableCell-body text-align-left">
                        {parseAsHtml(item.file)}
                    </td>
                    <td className="MuiTableCell-body text-center">
                        <a target="_blank" rel="noopener noreferrer" aria-label="Download" href={(item.fileurl)}>{getDownloadIcon((item.fileurl),`${item.file}${i}${tabkey}`)}</a>
                    </td>
                    <td className="MuiTableCell-body text-center">
                        {this.checkContains(item.key) >= 0 && <StarIcon className="favorite-icon" onClick={() => { this.addToFav(item.key); }} />}
                        {this.checkContains(item.key) < 0 && <StarBorderIcon className="favorite-icon" onClick={() => { this.addToFav(item.key); }} />}
                    </td>
                </tr>;
            return tablerow;
        }

        return (
            // <div className="col-md-12 m-0">
                <div className="table-responsive">
                    <TableContainer>
                        <Table className="table customize-table reports-checkbox">
                            <TableHead>
                                <tr>
                                    <th className="MuiTableCell-head preline-span text-align-left" ><span className="hide">Sr</span> </th>
                                    <th className="MuiTableCell-head preline-span text-align-left" >{this.props.heading || "File"}</th>
                                    <th className="MuiTableCell-head preline-span text-center" >Download</th>
                                    <th className="MuiTableCell-head preline-span text-center" >Favorites</th>
                                </tr>
                            </TableHead>
                            <tbody>
                                {this.state.data.length > 0 ?
                                    this.state.data.map((item, i) => {

                                        if (item.file.toLowerCase().includes(this.state.searchKey.toLowerCase())) {
                                            return <TableRow tabkey={this.state.tab} item={item} i={i} isChecked={this.state.isChecked} key={i} />
                                        }
                                         else {
                                            return "";
                                        }

                                        // return <TableRow tabkey={this.state.tab} item={item} i={i} isChecked={this.state.isChecked} key={i} />
                                    }) : <tr><td className="p-2" colSpan="4">No Records</td></tr>}
                            </tbody>
                        </Table>
                    </TableContainer>
                </div>
            // </div>
        );
    }
}

export default DailyReportsTable;