import React, {useState,useE} from 'react'
import { useEffect } from 'react'

const SelectLanguage = () => {
  
    const [selectLanguage, setSelectLanguage ] = useState("");

    // useEffect ( ()=>{
    //     localStorage.setItem("selectedLanguage",setSelectLanguage)
    //     alert(localStorage.getItem("ChangeLanguage"))
    // }, [])

    const handleChange = (e) => {
        setSelectLanguage(e.target.value)
        localStorage.setItem("selectedLanguage", selectLanguage)
        //alert(selectLanguage);            
    }
  
  
    return (
        
        <select value={selectLanguage} onChange={handleChange}>
            <option value="English">English</option>
            <option value="Hindi">Hindi</option>
            <option value="Gujrati">Gujrati</option>    
        </select>
  )
}



export default SelectLanguage;