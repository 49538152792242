import React from "react";
import HighChartOc from "./highchart-option-chain";

class OptionChart extends React.Component {
  constructor(props) {
    super(props);
    const queryparams = new URLSearchParams(props.location.search);
    this.state = {
      queryparams,
      headerRef: props.headerRef,
      chartApi: `/api/option-chart` + props.location.search,
      symbol: `${queryparams.get("symbol") || "-"}`,
      strikeprice: `${queryparams.get("strikeprice") || "-"}`,
      expiry: `${queryparams.get("expiry") || "-"}`,
      pageURL: props.location,
    };
  }

 
  componentDidMount() {
    try {
      this._isMounted = true;
      this.state.headerRef.current.updateTitle("Option Chain", "Option Chart", [
        {
          field_breadcrumb: [
            { uri: "markets/derivatives-watch", title: "Markets" },
            { uri: "markets/option-chain", title: "Option Chain" },
            { uri: this.props.location.search, title: "Option Chart" },
          ],
        },
      ]);
    } catch (e) {
      console.log(e);
    }
  }

  render() {
    return (
      <div id="optionChart" className="container-fluid">
        <div className="row mt-3">
          <div className="col-md-12 mt-0 ">
            <h2 className="page-title">Chart</h2>
            <HighChartOc
              api={this.state.chartApi}
            />
            <div className="row mt-3 mb-3">
              <div className="col-md-3 ">
              <div className="optionDetailBox">
              <div className="row">
                  <div className="col-md-12 pb-1">
                    <span>Symbol</span>
                  </div>
                  <div className="col-md-12">
                    <b>{this.state.symbol}</b>
                  </div>
                </div>
              </div>
                
              </div>
              <div className="col-md-3 ">
              <div className="optionDetailBox">
                <div className="row">
                  <div className="col-md-12 pb-1">
                    <span>Strike Price</span>
                  </div>
                  <div className="col-md-12">
                    <b>{this.state.strikeprice}</b>
                  </div>
                </div>
                </div>
              </div>
              <div className="col-md-3 ">
              <div className="optionDetailBox">
                <div className="row">
                  <div className="col-md-12 pb-1">
                    <span>Expiry Date</span>
                  </div>
                  <div className="col-md-12">
                    <b>{this.state.expiry}</b>
                  </div>
                </div>
                </div>
              </div>
              <div className="col-md-3 ">
              <div className="optionDetailBox">
                <div className="row">
                  <div className="col-md-12 pb-1">
                    <b><span>Legend</span></b>
                  </div>
                    <div className="col-md-6">
                      <div className="chartLegendCall"></div><span className="ml-2">Calls</span></div>
                    <div className="col-md-6"><div className="chartLegendPut"></div><span className="ml-2">Puts</span></div>
                </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default OptionChart;
