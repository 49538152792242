const drawerWidth = 300;
const styles = theme => ({

    root: {
        display: "flex"
    },
    appBar: {
        transition: theme.transitions.create(["margin", "width"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen
        }),
        boxShadow: `none`,
        backgroundColor: `white`,
        zIndex: 0,
        marginTop: `-10px`,

        '& h2': {
            // fontSize: `1rem`,
            // color: `#3a2d7d`,
            // fontWeight: `600`
        },
        '& .MuiToolbar-gutters': {
            paddingLeft: `8px`,
            paddingRight: `8px`,
        },
        '& .MuiToolbar-root h3': {
            margin: `0px`,
        }
    },
    appBarShift: {
        [theme.breakpoints.down('xs')]: {
            width: `100%`,
            // marginLeft: `160px`,
            marginTop: `0px`
        },
        // width: `calc(100% - ${drawerWidth}px)`,
        // marginLeft: drawerWidth,
        transition: theme.transitions.create(["margin", "width"], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen
        }),
        zIndex: 0,
        '& .MuiToolbar-gutters': {
            paddingLeft: `8px`,
            paddingRight: `8px`,
        },
        '& .MuiToolbar-root h3': {
            margin: `0px`,
        }
    },
    menuButton: {
        marginRight: 0,
        color: `#fff`,
        backgroundColor: `#E86E25`,
        border: `1px solid transparent`,
        padding: `.375rem .75rem`,
        fontSize: `1rem`,
        lineHeight: `1.5`,
        borderRadius: `.25rem`,
        '&:hover': {
            color: `#fff`,
            backgroundColor: `#E86E25`,
        }
    },
    hide: {
        display: "none"
    },
    drawer: {
        [theme.breakpoints.down('xs')]: {
            // width: `160px`,
            width: `auto`,
        },
        width: drawerWidth,
        flexShrink: 0
    },
    drawerPaper: {
        [theme.breakpoints.down('xs')]: {
            // width: `160px`,
            width: `auto`,
        },
        width: drawerWidth,
        position: `relative`,
        height: `auto`,
        border: 0,
        overflowX: 'hidden',
        zIndex: 9
    },
    drawerHeader: {
        display: "flex",
        alignItems: "center",
        padding: theme.spacing(0, 1),
        ...theme.mixins.toolbar,
        justifyContent: "flex-end",

    },
    content: {
        flexGrow: 1,
        //padding: `0px 30px 0px 8px!important`,
        transition: theme.transitions.create("margin", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen
        }),
    },
    contentShift: {
        [theme.breakpoints.down('xs')]: {
            width: `100%`,
            paddingRight:`0`,
            // marginLeft: `160px`
        },
        // width: `calc(100% - ${drawerWidth}px)`,
        // marginLeft: drawerWidth,
        //padding: `0px 30px 0px 4px!important`,
        //minHeight:`350px`,
        paddingRight:`2rem`,
        transition: theme.transitions.create(["margin", "width"], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen
        }),
    }
});

export default styles;