import React, { Component } from "react";
import { Select, MenuItem } from "@material-ui/core";

class OptionChainDataFilter extends Component {
  constructor(props) {
    super(props);
    this.wrapper = React.createRef();
    this.state = {
      instrument: "",
      symbolApi: '/api/option-chain-symbols',
      selectIdx: [],
      selectSymbol: [],
      symbol: "",
      expiry: "",
      strikeprice: "",
      expirylist: props.expirylist || [],
      filterCallback: props.filterCallback,
      skpricelist: props.skpricelist || [],
      indiceSymbol: "",
      setSymbol: props.setSymbol,
      symbolsApi1: props.symbolsApi1 || "",
      symbolsApi2: props.symbolsApi2 || "",
      symbolCallback:props.symbolCallback
    };
    this.setExpiry = this.setExpiry.bind(this);
    this.setStrikePrice = this.setStrikePrice.bind(this);
    this.setIndiceSymbol = this.setIndiceSymbol.bind(this);
    this.setOtherSymbol = this.setOtherSymbol.bind(this);
  }

  componentDidMount() {
    try {
      this._isMounted = true;
    } catch (e) {
      console.log("error in mounting option chain filter",e);
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.expirylist !== this.props.expirylist) {
      this.setState({
        expirylist: this.props.expirylist,
        //expiry:this.props.expirylist[0] 
      });
    }
    if (prevProps.expiry !== this.props.expiry) {
      this.setState({ expiry: this.props.expiry });
    }
    if (prevProps.strikeprice !== this.props.strikeprice) {
      this.setState({ strikeprice: this.props.strikeprice });
    }
    if (prevProps.skpricelist !== this.props.skpricelist) {
      this.setState({ skpricelist: this.props.skpricelist });
    }
    if (prevProps.symbolsApi1 !== this.props.symbolsApi1) {
      this.setState({ symbolsApi1: this.props.symbolsApi1 });
      this.loadSymbolIdx(this.props.symbolsApi1);
    }
    if (prevProps.symbolsApi2 !== this.props.symbolsApi2) {
      this.setState({ symbolsApi2: this.props.symbolsApi2 });
      this.loadSymbols(this.props.symbolsApi2);
    }
  }


  loadSymbolIdx(api) {
    if (api) {
      fetch(api)
        .then(resp => resp.json())
        .then((resp) => {
          this.setState({ selectIdx: resp.data, indiceSymbol: resp.data[0] });
          this.state.setSymbol(resp.data[0]);
        })
        .catch((e) => { console.log("error in loading list of index in option chain", e) })
    }
  }

  loadSymbols(api) {
    if (api) {
      fetch(api)
        .then(resp => resp.json())
        .then((resp) => {
          this.setState({ selectSymbol: resp.data });
        })
        .catch((e) => { console.log("error in loading list of symbols in option chain", e) })
    }
  }

  setExpiry(e) {
    this.setState({ expiry: e.target.value, strikeprice: "" });
    this.state.filterCallback(e.target.value, "");
  }

  setStrikePrice(e) {
    this.setState({ expiry: "", strikeprice: e.target.value });
    this.state.filterCallback("", e.target.value);
  }
  setIndiceSymbol(e) {
    this.setState({ indiceSymbol: e.target.value, symbol: "", strikeprice: "" })
    this.state.setSymbol(e.target.value);
  }
  setOtherSymbol(e) {
    this.setState({ symbol: e.target.value, indiceSymbol: "", strikeprice: "" })
    this.state.setSymbol(e.target.value);
  }
  render() {
    return (
      <div className="container-fluid">

        <div className="row option-filters">
          <div className=" option-filter-inputs">
            <Select
              className="customize-input-group quotes-select-filter custom-input-group-width"
              onChange={this.setIndiceSymbol}
              value={this.state.indiceSymbol}
              displayEmpty
            >
              <MenuItem value="" disabled>
                View Options Contracts For
                </MenuItem>
              {this.state.selectIdx.map((x, idx) => {
                return (
                  <MenuItem key={x + idx} value={x}>
                    {x}
                  </MenuItem>
                );
              })}
            </Select>
          </div>
          {this.state.selectSymbol.length > 0 ?
          <><div className="optionChainOr">Or</div>

          <div className=" option-filter-inputs">
            <Select
              className="customize-input-group quotes-select-filter custom-input-group-width"
              onChange={this.setOtherSymbol}
              value={this.state.symbol}
              displayEmpty
            >
              <MenuItem value="" disabled>
                Select Symbol
                </MenuItem>

              {this.state.selectSymbol.map((x, idx) => {
                return (
                  <MenuItem key={x + idx} value={x}>
                    {x}
                  </MenuItem>
                );
              })}
            </Select>
          </div></>
          : ""}
          <div className="option-filter-inputs">
            <Select
              className="customize-input-group quotes-select-filter custom-input-group-width"
              onChange={this.setExpiry}
              value={this.state.expiry}
              displayEmpty
            >
              <MenuItem value="" disabled>
                Select Expiry
                </MenuItem>
              {this.state.expirylist.map((x, idx) => {
                return (
                  <MenuItem key={x + idx} value={x}>
                    {x}
                  </MenuItem>
                );
              })}
            </Select>
          </div>
          <div className="optionChainOr">Or</div>
          <div className="option-filter-inputs">
            <Select
              className="customize-input-group quotes-select-filter custom-input-group-width"
              onChange={this.setStrikePrice}
              value={this.state.strikeprice}
              // disabled={false}
              displayEmpty
            >
              <MenuItem value="" disabled>
                Select Strike Price
                </MenuItem>
              {
                this.state.skpricelist.map((x, idx) => {
                  return (
                    <MenuItem key={x + idx} value={x}>
                      {x}
                    </MenuItem>
                  );
                })}
            </Select>
          </div>
        </div>

      </div>
    );
  }
}
export default OptionChainDataFilter;
