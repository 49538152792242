import React from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableHead from "@material-ui/core/TableHead";
import { Refresh } from "./custom-toolbar";
// import CSVDownloader from "./download-csv";
import { convertPrice, convertNumberPrecision } from "./util";
import LinearProgress from "@material-ui/core/LinearProgress";
import ScrollTerms from "./scroll-terms";
// import BestView from "./best-view";
import lineGraph from "../../public/images/line-graph.svg";
import { TableContainer } from "@material-ui/core";
import columns from "../option-chain-columns";
import datecolumns from "../option-chain-date-columns";
import OptionChainDataFilter from "./optionChain-data-filter";
import Notes from "./notes";
import * as XLSX from "xlsx";
import { ReactComponent as CsvIcon } from '../../public/images/csv-icon.svg';

class OptionChainTable extends React.Component {
  constructor(props) {
    super(props);
    this.wrapper = React.createRef();
    this.state = {
      param: props.param,
      columns: columns,
      records: "",
      csvdata: [],
      inProgress: true,
      symbolDetails: props.symbol,
      rows: [],
      type: props.type,
      cols: [],
      datecolumns: datecolumns,
      spcolumns: columns,
      expiry: props.expiry || "",
      strikeprice: props.strikeprice || "",
      dataapi: `/api/option-chain`,
      notesApi: `/api/contents/notes?url=/option-chain`,
      notesData: [],
      filtered: {},
      data: [],
      strikepricelist: [],
      selectedexpiry: "",
      tableselectedexpiry: "",
      tableselectedsp: "",
      selectedsp: "",
      timestamp: "",
      ltp: "",
      api1: "",
      api2: "",
      skpricelist: [],
      expirylist: [],
      rowData: [],
      allData: [],
      underlyingApi: "",
      total: {},
      date: "",
      totalrow: [],

    };
    this.updateBySP = this.updateBySP.bind(this);
    this.updateByDate = this.updateByDate.bind(this);
    this.setSymbol = this.setSymbol.bind(this);
    this.setFilters = this.setFilters.bind(this);
    this.refresh = this.refresh.bind(this);
    this.exportFile = this.exportFile.bind(this);

  }

  exportFile() {
    let filename = 'option-chain-' + this.state.symbol + '-' + this.state.date
    let wb = XLSX.utils.table_to_book(document.getElementById('OCTbl'));
    XLSX.writeFile(wb, filename + '.xls');
    return false;
  }

  loadOptionChain() {

    let cols = []
    let csvdata = [];
    let colcsv = [];
    let totalrow = [];
    cols.push(<tr>
      <th colSpan={Math.floor(this.state.columns.length / 2)} className="text-center">CALLS</th>
      <th></th>
      <th colSpan={Math.floor(this.state.columns.length / 2)} className="text-center">PUTS</th>
    </tr>);
    let total = this.state.total;
    let coldata = this.state.columns.map((col, index) => {
      colcsv.push(col.heading);

      if (col.OPTIONTYPE === "CE" && col.name === "chart") {
        totalrow.push(<td>Total:</td>)
      }
      else
        if (col.name === "OI") {

          let totaloi = 0;
          if (col.OPTIONTYPE === "CE") {
            totaloi = total.CE.totOI
          }
          if (col.OPTIONTYPE === "PE") {
            totaloi = total.PE.totOI
          }
          totalrow.push(<td>{totaloi}</td>)

        }
        else if (col.name === "CONTRACTSTRADED") {

          let totalvol = 0;
          if (col.OPTIONTYPE === "CE") {
            totalvol = total.CE.totVol
          }
          if (col.OPTIONTYPE === "PE") {
            totalvol = total.PE.totVol
          }
          totalrow.push(<td>{totalvol}</td>)

        }
        else {
          totalrow.push(<td></td>)
        }
      return <th width={col.width}>{col.heading || ''} <span>{col.subHead}</span></th>
    });
    cols.push(<tr>{coldata}</tr>);
    csvdata.push(colcsv);
    this.setState({ cols });
    let rows = [];
    

    if (this.state.rowData.length > 0) {


      this.state.rowData.forEach((row, t) => {
        let rowcsv = [];
        let rowDomObj = this.state.columns.map((col, index) => {

          let cellVal = col.OPTIONTYPE !== "" ? col.OPTIONTYPE === "CE" ? typeof row.CE === "undefined" ? null : row.CE[col.name] : typeof row.PE === "undefined" ? null : row.PE[col.name] : row[col.name];
          let rowVal = col.OPTIONTYPE !== "" ? col.OPTIONTYPE === "CE" ? typeof row.CE === "undefined" ? null : row.CE : typeof row.PE === "undefined" ? null : row.PE : row;
          let bgColor = col.OPTIONTYPE === "CE" && this.state.ltp >= row["STRIKEPRICE"] ? "addCEBg" : "";
          let bgColor_1 = col.OPTIONTYPE === "PE" && this.state.ltp < row["STRIKEPRICE"] ? "addPEBg" : "";
          let chkClass = bgColor === "addCEBg" || bgColor_1 === "addPEBg" ? 'bg-yellow' : '';
          if ((col.name !== "chart" && cellVal === undefined) || cellVal === null) {
            let tdClass = chkClass + " text-center";
            rowcsv.push("-");
            return <td width={col.width} className={tdClass}>-</td>
          }
          let fcellVal = void 0;
          if (col.type) {
            if (col.type === "number") {
              let precision = 2;
              if ((col.heading && col.heading.toLowerCase().indexOf('price') >= 0) 
              || (col.legend && col.legend.toLowerCase().indexOf('price') >= 0)) {
                if (this.state.type.includes("cur") && this.state.symbol !== "INRUSD") {
                  precision = 2;
                }
                else {
                  precision = 2;
                }
              }
              else {
                precision = 2;
              }
              fcellVal = convertNumberPrecision(cellVal, precision);

            }
            if (col.type === "quantity") {
              fcellVal = convertNumberPrecision(cellVal, 2);
            }
          } else {
            fcellVal = cellVal ? cellVal.toString() : '-';
          }
          if (col.chkVal) {
            let chkValClass = "";
            if (cellVal < 0) {
              chkValClass += 'red';
            } else if (cellVal > 0) {
              chkValClass += 'green';
            }
            let _tdClass = chkClass + " " + chkValClass;
            rowcsv.push(fcellVal);
            return <td width={col.width} className={_tdClass}>{fcellVal}</td>

          } else if (col.name === "chart") {
            // var ceIdentifier = row.CE && row.CE["identifier"];
            // var peIdentifier = row.PE && row.PE["identifier"];
            //console.log(row.CE,row.PE, "!!!!!!")
            var ocSP = row.STRIKEPRICE.toString();
            var ocED = row.EXPIRY;
            var ocInstrument = (row.CE && row.CE["INSTRUMENTTYPE"]) || (row.PE && row.PE["INSTRUMENTTYPE"]);
            var ocSymbol = (row.CE && row.CE["UNDERLYING"]) || (row.PE && row.PE["UNDERLYING"])
            //rowcsv.push("");
            return <td width={col.width}>
              <a target="_blank" rel="noopener noreferrer" href={'/option-chain-chart?instrument=' + ocInstrument +'&symbol='+ ocSymbol+'&expiry='+ocED+'&strikeprice='+ocSP}><img src={lineGraph} alt="chart"/></a></td>
            // return td({ width: col.width }, a({ "href": "javascript:;", 'onclick': 'genChainGraph(\'' + ceIdentifier + '\',\'' + peIdentifier + '\', \'' + ocSP + '\', \'' + ocED + '\', \'' + ocUnderlying + '\')', 'data-toggle': 'modal', 'data-target': '#modal_oc_graph' }, img({
            //     "src": "/assets/images/grficon.gif",
            //     "alt": "Graph"
            // }, '')));
          } else if (col.name === "STRIKEPRICE") {
            rowcsv.push(fcellVal);
            return <td className="filter-column" onClick={(e) => {
              this.updateBySP(this.state.allData, fcellVal, this.state.type);
            }} width={col.width}>{fcellVal}</td>

          } else if (col.name === "EXPIRY") {
            rowcsv.push(fcellVal);
            return <td className="filter-column" onClick={(e) => {
              this.updateByDate(this.state.allData, fcellVal, this.state.type);
              
            }} width={col.width}>{fcellVal}</td>

          } else if (col.heading === 'LTP') {
            rowcsv.push(fcellVal);
            let url = void 0;
            let identifier = '&instrumenttype=' + rowVal.INSTRUMENT.substr(0, 3) + '&expiry=' + rowVal.EXPIRY 
            + '&optiontype=' + col.OPTIONTYPE + '&strikeprice=' + rowVal.STRIKEPRICE + '&inst=' + rowVal.INSTRUMENT;

            switch (this.state.type) {
              case "currency":
                url = "/currency-getquote?symbol=" + rowVal.UNDERLYING + identifier;
                break;
              // case "commodity":
              //   url = "/commodity-getquote?symbol=" + rowVal.UNDERLYING + identifier;
              //   break;
              default:
                url = "/get-quotes/derivatives?symbol=" + rowVal.UNDERLYING + identifier;
                break;
            }
            
            return <td width={col.width} className={chkClass}><a href={url} target="_blank" rel="noreferrer">{fcellVal}</a></td>
          } 
          else {
            rowcsv.push(fcellVal)

            return <td width={col.width} className={chkClass}>{fcellVal}</td>

          }
        });
        csvdata.push(rowcsv);
        rows.push(<tr>{rowDomObj}</tr>);
      });
    } else {
      csvdata.push("No Record Found");
      rows.push(<tr><td colSpan={this.state.columns.length}>No Record Found</td></tr>);

    }

    this.setState({
      rows: rows,inProgress: false,
      csvdata: csvdata,
      totalrow: <tr>{totalrow}</tr>
    })
  }

  filterData = function (data, condition) {
    let total = {
      CE: {
        totOI: 0,
        totVol: 0
      },
      PE: {
        totOI: 0,
        totVol: 0
      }
    };

    let filteredData = data.filter(function (e) {
      if (condition(e)) {
        if (e.CE) {
          total.CE.totOI += e.CE.OI;
          total.CE.totVol += e.CE.CONTRACTSTRADED;
        }
        if (e.PE) {
          total.PE.totOI += e.PE.OI;
          total.PE.totVol += e.PE.CONTRACTSTRADED;
        }
        return true;
      }
      return false;
    });

    return { filteredData: filteredData, total: total };
  };

  updateBySP = (allData, val, type) => {
    
    this.setState({ selectedsp: val, selectedexpiry: "", tableselectedsp: val })
    
    let this$filterData = this.filterData(allData, function (e) {
      let sp = ["currency"].includes(type) ? convertPrice(e.STRIKEPRICE) : convertNumberPrecision(e.STRIKEPRICE);
      return sp === convertNumberPrecision(val);
    });
    let filteredData = this$filterData.filteredData;
    let total = this$filterData.total;
    filteredData = filteredData.sort(function (a, b) {
      return new Date(a.EXPIRY) - new Date(b.EXPIRY);
    });
    
    
    let respJSONObj = this.state.datecolumns;

    this.setState({
      columns: respJSONObj,
      rowData: filteredData,
      total: total,
    }, () => {
      this.loadOptionChain()
    })
   
  };

  updateByDate = function (allData, val, type) {
    this.setState({ selectedexpiry: val, selectedsp: "", tableselectedexpiry: val })
    let this$filterData2 = this.filterData(allData, function (e) {
      return new Date(e.EXPIRY).getTime() === new Date(val).getTime();
    });

    let filteredData = this$filterData2.filteredData;
    let total = this$filterData2.total;
   
    let respJSONObj = this.state.spcolumns;
    
    this.setState({
      columns: respJSONObj,
      rowData: filteredData,
      total: total,
    }, () => {
      this.loadOptionChain()
    })
   
  };
  componentDidMount() {
    try {
      // this.loadData(this.props.dataapi);
      this.loadSymbolsApi();
      // this.loadOptionChain();
      this.loadNotes(this.state.notesApi);

    } catch (e) {
      console.log("error in mounting option chain",e);
    }
  }
  loadSymbolsApi() {
    let api1 = "";
    let api2 = "";
    switch (this.state.type) {
      case "equity":
        api1 = `/api/option-chain-symbols?type=index`;
        api2 = `/api/option-chain-symbols?type=symbol`;
        break;
      case "currency":
        api1 = `/api/option-chain-symbols?type=currency`;
        break;
      case "commodity":
        api1 = `/api/option-chain-symbols?type=commodity`;
        break;
      default:
        break;
    }
    this.setState({
      api1, api2
    })
  }

  componentDidUpdate(prevProps) {

    // if (this.props.symbol !== prevProps.symbol) {
    //   this.setState({ symbol: this.props.symbol })
    // }
    // if (this.props.dataapi !== prevProps.dataapi) {
    //   this.setState({ dataapi: this.props.dataapi })
    // }
  }

  loadNotes(api) {
    fetch(api)
      .then(resp => resp.json())
      .then((cards) => {
        this.setState({
          notesData: cards.content.field_note_components,
        })
      })
      .catch((e) => { console.log("error in option chain notes", e) })
  }

  loadData(api) {
    fetch(api)
      .then(resp => resp.json())
      .then((response) => {
        let splist = response.records.strikePrices.map((x, idx) => {
          return convertNumberPrecision(x)
        })
        this.setState({
          rowData: response.filtered.data,
          allData: response.records.data,
          expirylist: response.records.expirydates,
          skpricelist: splist,
          selectedexpiry: response.records.expirydates[0] ? response.records.expirydates[0] : "",
          total: { "CE": response.filtered.CE, "PE": response.filtered.PE },
        }, () => {
          this.loadOptionChain();
        })
      })
      .catch((e) => { console.log("error in loading option chain data", e) })
  }

  setExpiry = (expval, sp) => {
    if (expval !== "") {
      this.setState({ selectedexpiry: expval, selectedsp: "", tableselectedexpiry: expval })
    }
    else if (sp !== "") {
      this.setState({ selectedsp: sp, selectedexpiry: "", tableselectedsp: sp })
    }
  }

  setFilters = (expval, sp) => {
    this.setState({
      selectedexpiry:expval,
      selectedsp:sp
    })
    if (expval !== "") {
      this.updateByDate(this.state.allData, expval, this.state.type);

    }
    else if (sp !== "") {
      this.updateBySP(this.state.allData, sp, this.state.type);
    }

  }
  setSymbol(symbol) {
    let dataapi = `/api/option-chain?symbol=` + symbol;
    this.setState({ dataapi: dataapi, symbol: symbol,columns:columns })
    this.loadData(dataapi);
    let symbolName = "";
    switch (symbol) {
      case "NIFTY":
        symbolName = "NIFTY 50";
        break;
      case "NIFTYIT":
        symbolName = "NIFTY IT";
        break;
      case "BANKNIFTY":
        symbolName = "NIFTY BANK";
        break;
      case "FINNIFTY":
        symbolName = "NIFTY FIN SERVICE";
        break;
      default:
        symbolName = symbol;
        break
    }
    let underlyingApi = `/api/option-chain/underlying?symbol=` + symbolName;
    this.setState({ underlyingApi });
    this.loadUnderlyingDetails(underlyingApi);
  }

  loadUnderlyingDetails = (underlyingApi) => {

    fetch(underlyingApi)
      .then(resp => resp.json())
      .then((response) => {
        if (response.data[0]) {
          this.setState({
            ltp: response.data[0].CURRVALUE,
            timestamp: response.data[0].FULLTIMESTAMP,
            date: response.data[0].DATEVAL
          })
        }
        else {
          this.setState({
            ltp: "",
            timestamp: "",
            date: ""
          })
        }
      })
      .catch((e) => { console.log("error in loading option chain underlying details", e) })

  }

  refresh() {

    this.setState({
      data: [],
      timestamp: '',
      ltp: "",
      selectedexpiry: "",
      selectedsp: "",
      rowData: [],
      allData: [],
      expirylist: [],
      skpricelist: [],
      total: {},
      columns: columns
    }, () => {
      this.loadData(this.state.dataapi);
      this.loadUnderlyingDetails(this.state.underlyingApi);
    });
  }

  render() {

    return (
      <>
        <OptionChainDataFilter symbolsApi2={this.state.api2} symbolsApi1={this.state.api1}
          setSymbol={this.setSymbol} skpricelist={this.state.skpricelist} strikeprice={this.state.selectedsp}
          expiry={this.state.selectedexpiry} filterCallback={this.setFilters} expirylist={this.state.expirylist}/>
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <div className="row mt-3 mb-1">
                <div className="col-md-6">
                  <div className="">
                    <h3 className="timestamp-heading">
                      Underlying Index: <strong>{this.state.symbol} {" "}
                        {this.state.ltp}</strong> As on {this.state.timestamp}{" "}  IST
                      <Refresh refreshcallback={this.refresh} />
                    </h3>
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="mr-0 row justify-content-end align-items-center">
                    <span>
                      <ScrollTerms></ScrollTerms>
                    </span>
                    <button onClick={() => { this.exportFile() }}
                      className="btn btn-white btn-csv-download d-flex align-items-center">
                      <CsvIcon className="icon-color mr-1" />Download (.CSV)
                    </button>

                    {/* <span>
                      <BestView></BestView>
                    </span> */}
                    {/* <span>
                    <DownloadExcel data={this.state.csvdata}
                      csvname={"option-chain"} />
                      
                    </span> */}
                    {/* <span>
                      <CSVDownloader
                        data={this.state.rowData}
                        csvname={"option-chain"}
                      />
                    </span> */}
                  </div>
                </div>
              </div>
              {this.state.inProgress ? (
                <LinearProgress />
              ) : (
                  <>
                    {/* <div className="col-md-12 OCtbl" > */}

                      <TableContainer className="OCtbl"
                      // style={{overflow:`visible`}}
                      >

                        <Table stickyHeader id="OCTbl" className="lefthd-table" >
                          <TableHead >
                            {this.state.cols}

                          </TableHead>
                          <TableBody >
                            {this.state.rows}
                            {this.state.totalrow}

                          </TableBody>
                        </Table>
                      </TableContainer>

                    {/* </div> */}
                  </>
                )}
            </div>
            <div id='terms' className="col-md-12 mt-2">
              <Notes notesData={this.state.notesData} />
            </div>
          </div>

        </div>

      </>
    );
  }
}

export default OptionChainTable;
