import * as React from "react";
import Slider from "@mui/material/Slider";
import { withStyles } from "@material-ui/core/styles";

const AntSlider = withStyles((theme) => ({
  root: {
    height: "5px !important",
  },
  thumb: {
    backgroundColor: "white !important",
  },
  rail: {
    backgroundImage: "linear-gradient(to right, #e70000 0, #f1b51c 50%, #0b9601 100%)",
    opacity: "1 !important",
    borderRadius: "0px !important",
    // "&:hover":{opacity:"1 !important"}
  },
}))(Slider);

class RangeSlider extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      minVal: 0,
      maxVal: 0,
      value: 0,
      usContainerInfo: props.usContainerInfo,
    };
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.usContainerInfo !== this.props.usContainerInfo &&
      this.props.usContainerInfo.DayPriceInfo &&
      this.props.usContainerInfo.DayPriceInfo[0] !== []
    ) {
      this.setState(
        {
          minVal: Number(this.props.usContainerInfo.DayPriceInfo[0].dayLow),
          maxVal: Number(this.props.usContainerInfo.DayPriceInfo[0].dayHigh),
          value: Number(this.props.usContainerInfo.DayPriceInfo[0].atp),
        });
    }
  }

  render() {
    return (
      <div className="no-margin">
        <div className="rangebar">
          <ul>
            <li>Low</li>
            <li>Day High/Low Range</li>
            <li>High</li>
          </ul>
        </div>
        <div>
          <AntSlider
            value={this.state.value}
            track={false}
            disabled={true}
            min={this.state.minVal}
            max={this.state.maxVal}
            step={0.05}
          />
        </div>
        <div className="rangevalue">
          <ul>
            <li>{this.state.minVal}</li>
            <li>{this.state.maxVal}</li>
          </ul>
        </div>
      </div>
    );
  }
}

export default RangeSlider;
