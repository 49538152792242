import React from 'react';
import ModalVideo from 'react-modal-video'
import 'react-modal-video/scss/modal-video.scss';
import {removePtag } from './util';
class VCard extends React.Component {

    _isMounted = false;
    constructor(props) {
        super(props);
        this.state = {

            mydata: [],
            cardsize: 'l',
            isOpen: false,
            videoId: '',
        }
        this.openModal = this.openModal.bind(this)
    }

    componentDidMount() {
        try {
            this._isMounted = true;
            this.setState({ mydata: this.props.characterData, cardsize: this.props.cardsize || 'l' });
        } catch (e) {
            console.log(e);
        }
    }
    openModal() {
        this.setState({ isOpen: true })
    }
    render() {
        const rows = this.state.mydata.map((row, index) => {
            return (
                <React.Fragment key={index}>
                    <div className="video_box">
                        <div className="row justify-content-center align-items-center">
                            <div className="col-md-4">
                                <div className="video_img" id={row.vid} onClick={(e) => {

                                    this.setState({ videoId: e.currentTarget.id });
                                    this.openModal()
                                }}>
                                    <a target="_blank" rel="noopener noreferrer" className="posrel">
                                        <img rel={row.vid} src={row.vImage} className="img-fluid youTubeImg" alt="Investor Awareness | Password Tips" title="Investor Awareness | Password Tips" />
                                        <span className="round-play-button"></span>
                                    </a>
                                </div>
                            </div>
                            <div className="col-md-8">
                                <div className="video_desc" id={row.vid} onClick={(e) => {

                                    this.setState({ videoId: e.currentTarget.id });
                                    this.openModal()
                                }}>
                                    {((child) => {
                                        return this.state.cardsize === 's' ? <h3 className="vid_desc">{child}</h3> : <h4>{child}</h4>
                                    })(<a target="_blank" rel="noopener noreferrer">
                                        {removePtag(row.vcaption)}
                                    </a>)}
                                    <p className={this.state.cardsize === 's' ? "circular-card-content" : ""}>{removePtag(row.vDesc)}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </React.Fragment>
            );
        })
        return (
            <section className="corporate_events_videos common-tabs">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="video_wrap">
                                <div className="tab-content py-3 ">
                                    <div className="tab-pane fade show active" id="corporate-videos" role="tabpanel">
                                        <div className="video_container">
                                            <ModalVideo channel='youtube' isOpen={this.state.isOpen}
                                                videoId={this.state.videoId} onClose={() => this.setState({ isOpen: false })} />
                                            {rows}
                                        </div>
                                    </div>
                                </div>
                                {/* <div className="link-view-all text-right">
                                    <a href="https://www.youtube.com/user/NSEIL1india" target="_blank">View All <img src="assets/images/Read_more_CTA.svg" width="14" height="10" alt="" /></a>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}
// class VideoCard extends React.Component {
//     render() {
//         const videofields = [
//             
//         ]
//         return (
//             <div>
//                 <VCard characterData={videofields} />
//             </div>
//         );
//     }
// }

export default VCard;