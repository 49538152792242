import ReactExport from "react-export-excel";
import React from "react";
import { ReactComponent as CsvIcon } from '../../public/images/csv-icon.svg';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;


class DownloadExcel extends React.Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      data: props.data || [],
      csvname: props.csvname,
      columns: props.columns || [],
      multiDataSet: []
    };
    this.downloadCSV = this.downloadCSV.bind(this);
  }
  componentDidMount() {
    try {
      this._isMounted = true;
      this.downloadCSV();
    } catch (e) {
      console.log(e);
    }

  }
  componentDidUpdate(prevProps) {
    if (prevProps.data !== this.props.data) {
      this.setState({
        data: this.props.data,
      }, () => {
        this.downloadCSV();
      })
    }
    if (prevProps.csvname !== this.props.csvname) {
      this.setState({
        csvname: this.props.csvname,
      })
    }
  }
  downloadCSV() {
    let rowArray = [];
    if(this.state.data.length === 0){
      let emptyrow = [];
      this.state.columns.forEach((item)=>{
        emptyrow.push("")
      });
      rowArray.push(emptyrow)
    }
    else {
      this.state.data.forEach(row => {
        let rowObj = []
        Object.values(row).forEach((item,idx) => {
          rowObj.push({ 
            value: item, 
            style: idx !== Object.values(row).length -1 ?
             { alignment: { wrapText: true } } : ""} )
        });
        rowArray.push(rowObj)
      })
    }
    let multiDataSet = [{
      columns: this.state.columns,
      data: rowArray
    }]
    this.setState({ multiDataSet: multiDataSet })
  };
  render() {

    return (<>

      <ExcelFile filename={this.state.csvname} element={<button className="btn btn-white btn-csv-download d-flex align-items-center">
        <CsvIcon className="icon-color mr-1" />Download (.CSV)
                </button>}>
        <ExcelSheet dataSet={this.state.multiDataSet} name={this.state.csvname} />
      </ExcelFile>
    </>
    );
  }
}

export default DownloadExcel;