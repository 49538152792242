import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import Pagination from "@material-ui/lab/Pagination";

const styles = theme => ({
    paginationContainer: {
        float: "right"
    }
});
class PaginationContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            cards: props["pagination-component"],
            itemsPerPage: props["itemsPerPage"],
            page: 1,
            noOfPages: 0,
            selectValue: 6,
        }
        this.handleChange = this.handleChange.bind(this);
        this.handleChangeSelect = this.handleChangeSelect.bind(this);
    }
    componentDidUpdate(prevProps){
        if(this.props["pagination-component"] !== prevProps["pagination-component"]){
            this.setState({ cards: this.props["pagination-component"],
            noOfPages: Math.ceil(this.props["pagination-component"].length / this.state.itemsPerPage),
            page:1
        })
        }
    }
    componentDidMount() {
        try {
            this._isMounted = true;
            
            this.setState({ noOfPages: Math.ceil(this.state.cards.length / this.state.itemsPerPage) })
        } catch (e) {
            console.log(e);
        }
    }
    handleChange = (event, value) => {
        this.setState({ page: value });
    };
    handleChangeSelect = (event) => {
        this.setState({
            page:1,
            selectValue: event.target.value,
            itemsPerPage: event.target.value,
            noOfPages: Math.ceil(this.state.cards.length / event.target.value),
        });
    };
    render() {
        const { classes } = this.props;
        return (
            <div className="pagination-container">
                <div className="row">
                    {this.state.cards ? this.state.cards
                        .slice((this.state.page - 1) * this.state.itemsPerPage, this.state.page * this.state.itemsPerPage)
                        .map((projectItem) => {
                            return projectItem
                        })
                        : ""}
                </div>
                <div className="row">
                    <div className="col-lg-6 pagination-entries">
                    <span>Showing {this.state.cards.length === 0 ? 0 : 1} to {this.state.itemsPerPage < this.state.cards.length ? this.state.itemsPerPage + " " : this.state.cards.length + " "} 
                     of {this.state.cards.length} entries</span> 
                        <select className="numselect" aria-label="select-number" value={this.state.selectValue} onChange={this.handleChangeSelect}>
                            <option value="6">6</option>
                            <option value="12">12</option>
                            <option value="15">15</option>
                            <option value="20">20</option>
                        </select>
                    </div>
                    <div className="col-lg-6">
                        <Pagination
                            variant="outlined"
                            count={this.state.noOfPages}
                            page={this.state.page}
                            onChange={this.handleChange}
                            defaultPage={1}
                            color="primary"
                            size="small"
                            
                            className={classes.paginationContainer} />
                    </div>
                </div>
            </div>
        );
    }
}

export default withStyles(styles, { withTheme: true })(PaginationContainer);

