import React, { Component } from 'react';
import moment from "moment";
import { MuiPickersUtilsProvider, KeyboardDatePicker, } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { Select, MenuItem } from "@material-ui/core";

const ButtonHeight = {
    height: '87%',
}
const dateFormat = "DD-MMM-YYYY";
class HistoricalDataFilter extends Component {
    constructor(props) {
        super(props);
        this.wrapper = React.createRef();
        this.state = {
            // todate: moment().utc().utcOffset("+05:30").format(dateFormat),
            // fromdate: moment(new Date()).subtract(7, 'days').format(dateFormat),
            todate: null,
            fromdate: null,
            linksarray: [
                {
                    id: "1D",
                },
                {
                    id: "1W",
                },
                {
                    id: "1M",
                },
                {
                    id: "3M",
                },
                {
                    id: "6M",
                },
                {
                    id: "1Y",
                },
            ],
            activeLink: "",
            fromDateOpen: false,
            toDateOpen: false,
            callback: props.callback,
            api1: `/api/historical-derivative`,
            instrument: '',
            selectInstrument: [],
            symbol: '',
            selectSymbolIdx: [],
            expiry: '',
            selectExpiry: [],
            optionType: '',
            selectOptionType: [],
            yearSelected: null,
            yearSelectedOpen: false,
            currentYear: '',
            strikeprice: '',
            loadInstType: '',
            strikepriceList: [],
            resetcallback: props.resetcallback,
            maxFromDate: moment().utc().utcOffset("+05:30").format(dateFormat),
            validationfrom: '',
            // quoteType:props.quoteType || false,

        }
        this.handleClick = this.handleClick.bind(this);
        this.handleFromDateChange = this.handleFromDateChange.bind(this);
        this.handleToDateChange = this.handleToDateChange.bind(this);
        this.filter = this.filter.bind(this);
        this.reset = this.reset.bind(this);
        this.validateFromToDate = this.validateFromToDate.bind(this);
        // this.state.callback(
        //     this.state.instrument,
        //     this.state.symbol,
        //     this.state.expiry,
        //     this.state.optionType,
        //     this.state.strikeprice,
        //     moment(this.state.fromdate,dateFormat).format(dateFormat),
        //     moment(this.state.todate,dateFormat).format(dateFormat)
        // );
    };

    componentDidMount() {
        try {
            this._isMounted = true;
            this.loadInstrument(this.state.api1 + '/instrument');
            // this.loadSymbolIdx(this.state.api1 + '/symbolidx');
            // this.loadSymbolStk(this.state.api1 + '/symbolstk');
            // this.loadExpiry(this.state.api1 + '/expiry');
            this.loadOptionType(this.state.api1 + '/option-type');
            this.loadSymbols(this.state.api1 + '/symbols');
        } catch (e) {
            console.log(e);
        }

    }

    loadInstrument(api) {
        fetch(api)
            .then(resp => resp.json())
            .then((resp) => {
                this.setState({ selectInstrument: resp.data });
                // console.log("ajsdnfdd", this.state.instrument)
            })
            .catch((e) => { console.log("error in loading list of instruments in historical data", e) })
    }

    loadSymbolIdx(api) {
        fetch(api)
            .then(resp => resp.json())
            .then((resp) => {
                this.setState({ selectSymbolIdx: resp.data });

            })
            .catch((e) => { console.log("error in loading list of index symbols in historical data", e) })
    }
    loadSymbols(api) {
        fetch(api)
            .then(resp => resp.json())
            .then((resp) => {
                this.setState({ selectSymbolIdx: resp.data });

            })
            .catch((e) => { console.log("error in loading list of symbols in historical data", e) })
    }
    loadSymbolStk(api) {
        fetch(api)
            .then(resp => resp.json())
            .then((resp) => {
                this.setState({ selectSymbolIdx: resp.data });

            })
            .catch((e) => { console.log("error in loading list of stock symbols in historical data", e) })
    }

    loadExpiry(api) {
        fetch(api)
            .then(resp => resp.json())
            .then((resp) => {
                this.setState({ selectExpiry: resp.data });

            })
            .catch((e) => { console.log("error in loading list of expiry dates in historical data", e) })
    }

    loadOptionType(api) {
        fetch(api)
            .then(resp => resp.json())
            .then((resp) => {
                this.setState({ selectOptionType: resp.data });

            })
            .catch((e) => { console.log("error in loading list of option types in historical data", e) })
    }

    handleClick(id) {
        this.setState({
            activeLink: id,
            todate: moment().utc().utcOffset("+05:30").format(dateFormat),
        }, function () {
            this.setState({
                maxFromDate: moment().utc().utcOffset("+05:30").format(dateFormat),
            })
            switch (id) {
                case "1D":
                    this.setState({ fromdate: moment(new Date()).subtract(1, 'days').format(dateFormat) });
                    break;
                case "1W":
                    this.setState({ fromdate: moment(new Date()).subtract(7, 'days').format(dateFormat) });
                    break;
                case "1M":
                    this.setState({ fromdate: moment(new Date()).subtract(1, 'months').format(dateFormat) });
                    break;
                case "3M":
                    this.setState({ fromdate: moment(new Date()).subtract(3, 'months').format(dateFormat) });
                    break;
                case "6M":
                    this.setState({ fromdate: moment(new Date()).subtract(6, 'months').format(dateFormat) });
                    break;
                case "1Y":
                    this.setState({ fromdate: moment(new Date()).subtract(1, 'year').format(dateFormat) });
                    break;
                default:
                    break;
            }
        })
    }

    handleFromDateChange = (date) => {
        this.setState({
            fromdate: moment(date, dateFormat).format(dateFormat),
            fromDateOpen: false
        },()=>{
            this.validateFromToDate();
        });

    }
    validateFromToDate = () => {
        if(moment(this.state.fromdate,dateFormat) > moment(this.state.todate,dateFormat)){
            this.setState({validationfrom:"From date should not be after to date"})
          }
          else {
              this.setState({validationfrom:""}) 
        }
    }
    handleToDateChange = (date) => {
        this.setState({
            todate: moment(date, dateFormat).format(dateFormat),
            toDateOpen: false
        }, () => {
            this.validateFromToDate();
            this.setState({ maxFromDate: this.state.todate })
        });
    }

    handleyearSelectedChange = (date) => {
        this.setState({
            yearSelected: date,
            yearSelectedOpen: false,
        }
            , () => {
                this.setState({
                    maxMonth: moment(this.state.yearSelected).format("yyyy") === moment(new Date()).format("yyyy") ?
                        new Date() : moment(this.state.yearSelected).format("MMM"),
                })
            }
        );
        this.loadExpiry(this.state.api1 + '/expiry?year=' + moment(date).format("yyyy")
            + '&symbol=' + this.state.symbol + '&instrumenttype=' + this.state.loadInstType);

    }

    filter() {
        // if(!this.state.quoteType) {

        if (this.state.instrument === '') {
            alert('Please Select Instrument');
            return;
        }
        if (this.state.symbol === '') {
            alert(' Please Select Symbol');
            return;
        }
        
        if (this.state.instrument.includes('Options')) {

            if (this.state.yearSelected === ''
                //|| typeof this.state.yearSelected === null 
                || this.state.yearSelected === null) {
                alert('Please Select Year');
                return;
            }


            if (this.state.expiry === ''
                //|| typeof this.state.expiry === null 
                || this.state.expiry === null) {
                alert('Please Select Expiry Date');
                return;
            }
            // if (this.state.optionType === '' || typeof this.state.optionType === null || this.state.optionType === null) {
            //     alert('Please Select Option Type');
            //     return;
            // }


        }
    // }
        //    if (this.state.instrument || this.state.symbol || this.state.expiry || this.state.optionType || (this.state.fromdate && this.state.todate && moment(this.state.todate) >= moment(this.state.fromdate))) {

        //{
            if (this.state.fromdate === null &&
                this.state.todate === null) {
                alert('Please Select Date Range');
                return;
            }
            if (this.state.todate === '' || this.state.todate === null) {
                alert('Please Select To Date');
                return;
            }
            if (this.state.fromdate === '' || this.state.fromdate === null) {
                alert('Please Select From Date');
                return;
            }
        if(this.state.validationfrom === ""){
            this.state.callback(
                this.state.instrument,
                this.state.symbol,
                this.state.expiry,
                this.state.optionType,
                this.state.strikeprice,
                moment(this.state.fromdate, dateFormat).format(dateFormat),
                moment(this.state.todate, dateFormat).format(dateFormat)
            );
        
    }

        //}

    }

    reset() {
        this.loadSymbols(this.state.api1 + '/symbols');
        this.loadInstrument(this.state.api1 + '/instrument');
        this.setState({
            validationfrom:"",
            symbol: '',
            instrument: '',
            expiry: '',
            optionType: '',
            currentYear: '',
            yearSelected: null,
            todate: null,
            fromdate: null,
            strikeprice: '',
            activeLink: "",
            maxFromDate: moment().utc().utcOffset("+05:30").format(dateFormat),
        }, () => {
            this.state.resetcallback();
        })


        // this.state.resetcallback(this.state.api)
    }

    selectInstType = (instrument) => {
        let type = ""
        if (instrument.includes('Com')) {
            type = "COM";
            //this.loadSymbols(this.state.api1 + '/symbols?type=COM');
        } else if (instrument.includes('Cur')) {
            type = "CUR";
            //this.loadSymbols(this.state.api1 + '/symbols?type=CUR');
        } else if (instrument.includes('Stock')) {
            type = "STK";
            //this.loadSymbols(this.state.api1 + '/symbols?type=STK');
        } else {
            type = "IDX";
            //this.loadSymbols(this.state.api1 + '/symbols?type=IDX');
        }
        this.loadSymbols(this.state.api1 + '/symbols?type=' + type);
        this.setState({ loadInstType: instrument })
    }
    selectSymbols = (symbols) => {
        let type = ""
        if (symbols.includes('NIFTY')) {
            type = "IDX";
            //this.loadSymbols(this.state.api1 + '/symbols?type=COM');
        } else if (symbols.includes('USD') || symbols.includes('INR')) {
            type = "CUR";
            //this.loadSymbols(this.state.api1 + '/symbols?type=CUR');
        } else if (symbols === "GOLD" || symbols === "SILVER") {
            type = "COM";
            //this.loadSymbols(this.state.api1 + '/symbols?type=STK');
        } else {
            type = "STK";
            //this.loadSymbols(this.state.api1 + '/symbols?type=IDX');
        }
        this.loadInstrument(this.state.api1 + '/instrument?inst=' + type);
    }

    loadStrikePrice = (opttype) => {
        fetch(this.state.api1 + '/strike-price?expiry=' + this.state.expiry
            + '&symbol=' + this.state.symbol + '&instrumenttype=' + this.state.loadInstType
            + '&optiontype=' + opttype)
            .then(resp => resp.json())
            .then((resp) => {
                this.setState({ strikepriceList: resp.data });

            })
            .catch((e) => { console.log("error in loading list of strike prices in historical data", e) })
    }

    render() {


        return (
            <div className="container-fluid">
                {/* {this.state.quoteType ? <></> :  */}
                <div className="row ml-2 historical-filters">
                    <div className="form-group historical-filter-inputs">
                        <Select className="customize-input-group quotes-select-filter"
                            onChange={(e) => {
                                this.setState({
                                    instrument: e.target.value, yearSelected: null, expiry: '', selectExpiry: [],
                                    selectSymbolIdx: [],
                                    // symbol: '',
                                    optionType: '',
                                    strikepriceList: [], strikeprice: ''
                                });
                                this.selectInstType(e.target.value);
                                // let abc = e.target.value;
                                // if (abc.includes('Com')) {
                                //     this.loadSymbols(this.state.api1 + '/symbols?type=COM');
                                // } else if(abc.includes('Cur')) {
                                //     this.loadSymbols(this.state.api1 + '/symbols?type=CUR');
                                // } else if(abc.includes('Stock')) {
                                //     this.loadSymbols(this.state.api1 + '/symbols?type=STK');
                                // }else {
                                //     this.loadSymbols(this.state.api1 + '/symbols?type=IDX');
                                // }
                            }} value={this.state.instrument}
                            displayEmpty>
                            <MenuItem value="" disabled>Select Instrument</MenuItem>
                            {this.state.selectInstrument.map((x, idx) => {
                                return <MenuItem key={x + idx} value={x}>
                                    {x}
                                </MenuItem>
                            })}
                        </Select>

                    </div>

                    <div className="form-group historical-filter-inputs">
                        <Select className="customize-input-group quotes-select-filter"
                            onChange={(e) => {
                                this.setState({
                                    symbol: e.target.value, yearSelected: null, expiry: '',
                                    selectExpiry: [], optionType: '',
                                    strikepriceList: [], strikeprice: ''
                                });
                                this.selectSymbols(e.target.value);
                            }} value={this.state.symbol}
                            displayEmpty>

                            <MenuItem value="" disabled>Select Symbol</MenuItem>

                            {this.state.selectSymbolIdx.map((x, idx) => {
                                return <MenuItem key={x + idx} value={x}>
                                    {x}
                                </MenuItem>
                            })}
                        </Select>
                    </div>
                    <div className="form-group mr-1 ml-1 historical-filter-inputs customize-input-group year ">

                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardDatePicker placeholder="Select Year" disableToolbar variant="inline" format="yyyy" views={["year"]} margin="normal"
                                id="yearSelected"
                                //minDate="2005" maxDate="2030" 
                                value={this.state.yearSelected}
                                onChange={this.handleyearSelectedChange}
                                disabled={this.state.instrument !== '' ? false : true}
                                KeyboardButtonProps={{
                                    "aria-label": "calender_year_button",
                                    onFocus: (e) => {
                                        this.setState({
                                            yearSelectedOpen: true
                                        });
                                    }
                                }}
                                PopoverProps={{
                                    disableRestoreFocus: true,
                                    onClose: () => {
                                        this.setState({
                                            yearSelectedOpen: false
                                        });
                                    }
                                }}
                                InputProps={{
                                    onFocus: () => {
                                        this.setState({
                                            yearSelectedOpen: true
                                        });
                                    }
                                }}
                                open={this.state.yearSelectedOpen} />
                        </MuiPickersUtilsProvider>
                    </div>
                    <div className="form-group historical-filter-inputs">
                        <Select className="customize-input-group quotes-select-filter"
                            onChange={(e) => { this.setState({ expiry: e.target.value }) }} value={this.state.expiry}
                            displayEmpty>
                            <MenuItem value="" disabled>Select Expiry</MenuItem>
                            {this.state.selectExpiry.map((x, idx) => {
                                return <MenuItem key={x + idx} value={x}>
                                    {x}
                                </MenuItem>
                            })}
                        </Select>
                    </div>
                    <div className="form-group historical-filter-inputs">
                        <Select className="customize-input-group quotes-select-filter"
                            onChange={(e) => {
                                this.setState({ optionType: e.target.value, strikepriceList: [], strikeprice: '' });
                                this.loadStrikePrice(e.target.value)
                            }} value={this.state.optionType}
                            disabled={!this.state.instrument.includes('Options')}
                            displayEmpty>
                            <MenuItem value="" disabled>Select Option Type</MenuItem>
                            {this.state.instrument.includes('Options') && this.state.selectOptionType.map((x, idx) => {
                                return <MenuItem key={x + idx} value={x}>
                                    {x}
                                </MenuItem>
                            })
                            }
                        </Select>
                    </div>
                    <div className="form-group historical-filter-inputs">
                        <Select className="customize-input-group quotes-select-filter"
                            onChange={(e) => { this.setState({ strikeprice: e.target.value }) }} value={this.state.strikeprice}
                            disabled={!this.state.instrument.includes('Options')}
                            displayEmpty>
                            <MenuItem value="" disabled>Select Strike Price</MenuItem>
                            {this.state.instrument.includes('Options') && this.state.strikepriceList.map((x, idx) => {
                                return <MenuItem key={x + idx} value={x}>
                                    {x}
                                </MenuItem>
                            })
                            }
                        </Select>
                    </div>
                    {/* <div className="form-group historical-filter-inputs">
                        <div className="customize-input-group quotes-select-filter">
                            <input type="text" className="historical-input-form-control" placeholder="Enter Strike Price"

                                disabled={!this.state.instrument.includes('Options')}
                                onChange={(e) => { this.setState({ strikeprice: e.target.value }) }} value={this.state.strikeprice}

                            />
                        </div>
                    </div> */}
                </div>
                {/* } */}
                <div className="col-12 form-inline">
                    <div className="historical-dates filterdata form-group mr-2">
                        {this.state.linksarray.map((links, index) => {
                            return <div key={index} data-val={links.id} onClick={() => this.handleClick(links.id)} className={(links.id === this.state.activeLink ? "days active" : "days")}>
                                {links.id}</div>
                        })}
                    </div>
                    {/* search inputs*/}
                    {/* <div className="col-lg-3">
                        <div className="customize-input-group" style={InputBoxHeight}>
                            <div className="input-group-prepend">
                                <Horizontalinput />
                            </div>
                            <input type="text" className="input-form-control" />
                            <div className="input-group-append">
                                <SearchIcon />
                            </div>
                        </div>
                    </div> */}
                    {/* 2 */}
                    <div className="row col-7 align-items-center ml-2 historical-inputs">
                        <div className="form-group mr-2 historical-date-input respWidth">
                            <div className="customize-input-group">
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <KeyboardDatePicker variant="inline" format="dd-MMM-yyyy" views={["year", "month", "date"]} openTo="year"
                                        margin="normal" id="fromDate"
                                        value={this.state.fromdate} maxDate={this.state.maxFromDate} onChange={this.handleFromDateChange}
                                        autoOk={true}
                                        KeyboardButtonProps={{

                                            "aria-label": "from_date_button"
                                        }}
                                        placeholder="From"
                                        disableFuture={true}
                                        helperText={
                                            this.state.validationfrom !== "" ?
                                            this.state.validationfrom  : null
                                        }
                                    // KeyboardButtonProps={{
                                    //     onFocus: (e) => {
                                    //         this.setState({
                                    //             fromDateOpen: true
                                    //         });
                                    //     }
                                    // }}
                                    // PopoverProps={{
                                    //     disableRestoreFocus: true,
                                    //     onClose: () => {
                                    //         this.setState({
                                    //             fromDateOpen: false
                                    //         });
                                    //     }
                                    // }}
                                    // InputProps={{
                                    //     onFocus: () => {
                                    //         this.setState({
                                    //             fromDateOpen: true
                                    //         });
                                    //     }
                                    // }}
                                    // open={this.state.fromDateOpen} 
                                    />
                                </MuiPickersUtilsProvider>
                            </div>
                        </div>
                        {/* 3 */}
                        <div className="form-group mr-2 historical-date-input respWidth">
                            <div className="customize-input-group">
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <KeyboardDatePicker variant="inline" format="dd-MMM-yyyy" margin="normal" views={["year", "month", "date"]} openTo="year"
                                        id="toDate" maxDate={new Date()} value={this.state.todate} onChange={this.handleToDateChange}
                                        autoOk={true} disableFuture={true}
                                        KeyboardButtonProps={{
                                            "aria-label": "to_date_button"
                                        }}
                                        placeholder="To"
                                    // KeyboardButtonProps={{
                                    //     onFocus: (e) => {
                                    //         this.setState({
                                    //             toDateOpen: true
                                    //         });
                                    //     }
                                    // }}
                                    // PopoverProps={{
                                    //     disableRestoreFocus: true,
                                    //     onClose: () => {
                                    //         this.setState({
                                    //             toDateOpen: false
                                    //         });
                                    //     }
                                    // }}
                                    // InputProps={{
                                    //     onFocus: () => {
                                    //         this.setState({
                                    //             toDateOpen: true
                                    //         });
                                    //     }
                                    // }}
                                    // open={this.state.toDateOpen} 
                                    />
                                </MuiPickersUtilsProvider>
                            </div>
                        </div>
                        {/* 4 */}
                        <div className="form-group mr-2 historical-date-input">
                            <button className="btn btn-orange full-width" style={ButtonHeight} onClick={this.filter}>Go</button>
                        </div>
                        <div className="form-group mr-2 historical-date-input">
                            <button className="btn btn-orange full-width" style={ButtonHeight} onClick={this.reset}>Reset</button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
export default HistoricalDataFilter;