import React from 'react';
import { CarouselProvider, Slider, Slide, ButtonBack, ButtonNext, DotGroup } from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';
import MediaCoverageCard from './media-coverage-card';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';

class HomeMediaCoverage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      api:props["api-mediacov"],
      cards: [],
      pages: [],
      perpage: window.innerWidth <=576 ? 1: 2,
      sliceCards: window.innerWidth <=576 ? 1: 2,
      ariaproperties:""
    }
  };
  componentDidMount() {
    try {
      //console.log("home media-coverage", this.state);
      this._isMounted = true;
      // window.addEventListener("resize", this.resize.bind(this));
      this.MediaReleaseData(this.state.api);
    } catch (e) {
      console.log(e);
    }
  }
//   resize() {
//     let currentHideNav = (window.innerWidth <= 500);
//     if (currentHideNav !== this.state.sliceCards) {
//         this.setState({sliceCards: currentHideNav},()=>{
//             this.MediaReleaseData(this.state.api);
//         });
//     }
// }
  MediaReleaseData(api) {
    fetch(api)
            .then(resp => resp.json())
            .then((cards) => {
              let pages = [];
              let mediaCovcards = cards;
              while (mediaCovcards.length > 0) {
                pages.push(mediaCovcards.splice(0, this.state.sliceCards));
              }
              this.setState({ pages },()=>{
                let ariaproperties= "";
                    this.state.pages.map((row, index) => {
                        return ariaproperties += "mediacov-slider-"+index+" "
                    })
                    this.setState({
                        ariaproperties
                    })
              });
            })
        .catch((e) => { console.log("error in loading home media coverage", e) })
    // Fetch mediaRelease data.
    
  }
  render() {
    return (
      <div className="col-lg-6">
        <div className="title-row">
                    <span className="title"><h2>Media Coverage
                        </h2></span>
                    <span className="btn-row">
                    <a target="_blank" rel="noopener noreferrer" className="btn btn-orange" aria-label="View All Media Coverage"
             href="/resources/media-coverage">View all</a>
                    </span>
                    <div className="clear"></div>
                </div>
        {/* <div className="row">
          <div className="col-lg-12">
            <h2>Media Release<a target="_blank" rel="noopener noreferrer" className="btn btn-orange pr-4 pl-4 float-right" aria-label="View All Media Coverage"
             href="/resources/media-coverage">View all</a>
            </h2>
          </div>
        </div> */}
        <div className="row">
        <div className="col-lg-12">
        <section id="slider5">
          <div className="d-flex">
            <div className="large-12 columns mt-4 p-0 medcovghgt">
              {
                <CarouselProvider
                  naturalSlideWidth={100}
                  naturalSlideHeight={50}
                  totalSlides={this.state.pages.length || 1}
                >
                  <Slider aria-label="latest-media-coverage" aria-owns={this.state.ariaproperties}>
                    {
                      this.state.pages.map((page, idx) => {
                        return <Slide id={"mediacov-slider-"+idx} key={`slide${idx}`} index={idx}>
                          {
                            page.map((card, jdx) => {
                              return <MediaCoverageCard alignCard="listview" cardkey={`card${idx}${jdx}`} key={`card${idx}${jdx}`} card={card}></MediaCoverageCard>
                            })
                          }
                        </Slide>
                      })
                    }
                  </Slider>
                  <ButtonBack className="arrow-btn"><ArrowBackIosIcon /></ButtonBack>
                  <ButtonNext className="arrow-btn"><ArrowForwardIosIcon /></ButtonNext>
                  <DotGroup />
                </CarouselProvider>
              }
            </div>
          </div>
        </section>
      </div>
      </div>
      </div>
    )
  }
}

export default HomeMediaCoverage;