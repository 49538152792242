import React from 'react';
import HistDataTable from './components/historical-data-table';
//import env from '../config';;
import HistoricalDataFilter from './components/historical-data-filter';
import Notes from './components/notes';
import moment from "moment";

class HistoricalDerivative extends React.Component {
    _isMounted = false;
    constructor(props) {
        super(props);
        this.state = {
            headerRef: props.headerRef,
            fromdate: null,
            todate: null,
            expiry: null,
            strikeprice: null,
            optiontype: null,
            symbol:null,
            instrumenttype:null,
            api: `/api/historical-derivative`,
            notesApi: `/api/contents/notes?url=/resources-historical-data`,
            notesData: [],
            filename:'Historical_Data'
        }
        this.recieveDateParams = this.recieveDateParams.bind(this);
        this.reset = this.reset.bind(this)
    }

    componentDidMount() {
        try {
            this._isMounted = true;
            this.loadNotes(this.state.notesApi);
            //this.state.headerRef.current.updateTitle("Historical Derivative", "Historical Derivative");
        } catch (e) {
            console.log(e);
        }

    }

    loadNotes(api) {
        fetch(api)
            .then(resp => resp.json())
            .then((cards) => {
                this.setState({
                    notesData: cards.content.field_note_components,
                })
            })
            .catch((e) => { console.log("error in notes", e) })
}

   
    reset(){
        let api = `/api/historical-derivative?`;
        this.setState({ api });
    }
    recieveDateParams = (instrumenttype,symbol,expiry,optiontype,strikeprice,fromdate, todate) => {
        let api = `/api/historical-derivative?`;
        // alert(instrumenttype);
        if (fromdate && todate) {
            api += `&fromdate=${fromdate}&todate=${todate}`;
        }
        if (expiry) {
            api += `&expiry=${expiry}`;
        }
        if (strikeprice) {
            api += `&strikeprice=${strikeprice}`;
        }
        if (optiontype) {
            api += `&optiontype=${optiontype}`;
        }
        if (symbol) {
            api += `&symbol=${symbol}`;
        }
        if (instrumenttype) {
            // alert('fff');
            api += `&instrumenttype=${instrumenttype}`;
        }
       
        this.setState({ 
            api, instrumenttype,symbol,expiry,
            optiontype,strikeprice,fromdate, todate, 
            filename:'Historical_Data_'+instrumenttype+'_'+symbol+'_'
            +moment(fromdate, "DD-MMM-YYYY").format("DD-MM-YYYY")+'_'+moment(todate, "DD-MMM-YYYY").format("DD-MM-YYYY")
        });
    }
    render() {
        return (
            <>
                <HistoricalDataFilter resetcallback={this.reset}  callback={this.recieveDateParams} />
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12">
                            <HistDataTable
                            fromDate={this.state.fromdate} toDate={this.state.todate}
                            filename={this.state.filename} 
                            api={this.state.api}/>
                        </div>
                        <div className="col-md-12">
                        <Notes notesData={this.state.notesData} />
                        </div>
                    </div>
                   
                </div>
            
            </>
        );
    }
}

export default HistoricalDerivative;