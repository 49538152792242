import React, { Component } from 'react';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
// import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import PaginationContainer from './pagination-container';
import { parseAsHtml } from './util';

class EventGallery extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: props["data-gallery"] || [],
        }
    };

    componentDidMount() {
        try {
            // console.log("state", this.state);
        } catch (e) {
            console.log(e);
        }
    }

    render() {
        const eventGalleryComponent = [];
        return (
            <>
                {
                    this.state.data.forEach((event, idx) => {
                        eventGalleryComponent.push(<div className="col-lg-4 mb-4 overlayeffect" key={idx}>
                            <div className="box-shadow">
                                <Card className="event-gallery">
                                    <CardActionArea>
                                        <CardMedia className="event-gallerybg"
                                            component="img"
                                            alt={event[0].alt || ""}
                                            image={event[0].url} key={`card${idx}`}
                                            title={event[0].title || ""} />
                                        <CardContent>
                                           
                                            <div className="content-bx">
                                                <div role="button" className="btn btn-secondary">{event[4] && event[4].field_event_dates}</div>
                                            </div>
                                            <div>{parseAsHtml(event[3] && event[3].field_event_description)}</div>
                                        </CardContent>
                                    </CardActionArea>
                                </Card>
                            </div>
                            <div className="box-shadow-effect">
                                <div className="content-bx">
                                    <div>{parseAsHtml(event[1].field_para)}</div>
                                    <a target="_blank" rel="noopener noreferrer" href={event[2].uri} className="btn btn-white"> {event[2].title}</a>
                                </div>
                            </div>
                        </div>)
                    })
                }

                <div className={"container-fluid"}>
                <PaginationContainer pagination-component={eventGalleryComponent} itemsPerPage={6} />
                </div>

            </>
        );
    }
}

export default EventGallery;