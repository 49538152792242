import React from 'react';
// import env from '../../config';
import { Select, MenuItem } from "@material-ui/core";
// import moment from "moment";
// import { MuiPickersUtilsProvider, KeyboardDatePicker, } from '@material-ui/pickers';
// import DateFnsUtils from '@date-io/date-fns';
// import env from '../../config';

const ButtonHeight = {
    height: '87%',
    paddingBottom: '10px',
}
class QuoteFilters extends React.Component {
    _isMounted = false;
    _footerToFocus = React.createRef()
    constructor(props) {
        super(props);
        const queryparams = new URLSearchParams(window.location.search);
        this.state = {
            //queryparams,
            expiryDate: null,
            expiryDateOpen: false,
            columns: props.columns,
            data: props.data,
            //instrument: "All",
            //optionType: "All",
            //strikePrice: "All",
            // expiryDate: "All",
            api: `/api/getquotes`,
            symbol: props.symbol,
            selectInstrument: [],
            tempInst: `${queryparams.get("inst")}` === "null" ? '' : `${queryparams.get("inst")}`,
            instrument: `${queryparams.get("inst")}` === "null" ? 'Active Contracts' : `${queryparams.get("inst")}`,
            selectExpiry: [],
            expiry: `${queryparams.get("expiry")}` === "null" ?  '' : `${queryparams.get("expiry")}`,
            selectOptionType: [],
            optionType: `${queryparams.get("optiontype")}` === "null" ?  '' : `${queryparams.get("optiontype")}`,
            selectStrikePrice: [],
            strikePrice: `${queryparams.get("strikeprice")}` === "null" ?  '' : `${queryparams.get("strikeprice")}`,
            disableList: [(`${queryparams.get("inst")}` === "null" ? '' : `${queryparams.get("inst")}`)+`Active Contracts`, 'Index Futures', 'Commodity Futures', 'Currency Futures'],
            ddOptionType: false,
            refresh: props.refresh
        }
        this.handleExpiryDate = this.handleExpiryDate.bind(this);
        this.instrumentChange = this.instrumentChange.bind(this);
        this.optionTypeChange = this.optionTypeChange.bind(this);
        this.strikePriceChange = this.strikePriceChange.bind(this);
        this.reset = this.reset.bind(this);
        this.disableControl = this.disableControl.bind(this);
        this.updateApiOnChange = this.updateApiOnChange.bind(this);
    }

    componentDidUpdate(prevProps) {
        if (this.props.columns !== prevProps.columns) {
            this.setState({ columns: this.props.columns, data: this.props.data })
        }

        if (this.props.data !== prevProps.data) {
            this.setState({ data: this.props.data });
            let instParam = "Ind";
            if(this.props.symbol.includes("NIFTY")){
                instParam = "Ind";
            }
            else if(this.props.symbol.includes("USD") || this.props.symbol.includes("INR")){
                instParam = "Cur";
            }
            else if(this.props.symbol.includes("GOLD") || this.props.symbol.includes("SILVER")){
                instParam = "Com";
            }
            else {
                instParam = "Sto";
            }
            this.loadInstrument(this.state.api + `/instrument?instrument=${instParam}`);
            if (this.props.data.length > 0) {
                
                this.loadExpiry(this.state.api + `/expiry?symbol=${this.props.symbol}&inst=${this.props.data[0].INSTR_DESC.substr(0, 3)}`);
                this.loadOptionType(this.state.api + `/optiontype`);
                this.loadStrikePrice(this.state.api + `/strikeprice?symbol=${this.props.symbol}&inst=${this.props.data[0].INSTR_DESC.substr(0, 3)}`);
            } else {
                this.loadOptionType(this.state.api + `/optiontype`);
            }
            this.setState({ ddOptionType: this.disableControl(this.state.instrument) });
        }

    }
    componentDidMount() {
        try {
            this._isMounted = true;
            if (this.state.data.length > 0) {
            this.loadInstrument(this.state.api + `/instrument?instrument=${this.state.data[0].INSTR_DESC.substr(0, 3)}`);
            this.loadExpiry(this.state.api + `/expiry?symbol=${this.state.symbol}&inst=${this.state.data[0].INSTR_DESC.substr(0, 3)}`);
            this.loadStrikePrice(this.state.api + `/strikeprice?symbol=${this.state.symbol}&inst=${this.state.data[0].INSTR_DESC.substr(0, 3)}`);
            }
            this.loadOptionType(this.state.api + `/optiontype`);
            this.setState({ ddOptionType: this.disableControl(this.state.instrument) });
        } catch (e) {
            console.log(e);
        }
    }
    handleExpiryDate = (date) => {
        this.setState({
            expiryDate: date,
            expiryDateOpen: false
        });
    }
    loadInstrument(api) {
        fetch(api)
            .then(resp => resp.json())
            .then((data) => {
                let tempinst = [];

                for (var i = 0; i < data.data.length; i++) {
                    tempinst.push(data.data[i].IM_DESC);

                }
                this.setState({ selectInstrument: tempinst });

            })
            .catch((e) => { console.log("error in list of getquotes instrument filter", e) })
    }
    loadExpiry(api) {
        fetch(api)
            .then(resp => resp.json())
            .then((resp) => {
                this.setState({ selectExpiry: resp.data });

            })
            .catch((e) => { console.log("error in list of get-quotes expiry filter", e) })
    }
    loadOptionType(api) {
        fetch(api)
            .then(resp => resp.json())
            .then((resp) => {
                this.setState({ selectOptionType: resp.data });

            })
            .catch((e) => { console.log("error in list of get quotes option type filter", e) })
    }
    loadStrikePrice(api) {
        fetch(api)
            .then(resp => resp.json())
            .then((resp) => {
                this.setState({ selectStrikePrice: resp.data });
            })
            .catch((e) => { console.log("error in list of get quotes filters", e) })
    }
    instrumentChange = ({ target: { value } }) => {
        this.setState({
            instrument: value
        })
    };
    optionTypeChange = ({ target: { value } }) => {
        this.setState({
            optionType: value
        })
    }
    ExpiryDateChange = ({ target: { value } }) => {
        this.setState({
            expiry: value
        })
    }
    strikePriceChange = ({ target: { value } }) => {
        this.setState({
            strikePrice: value
        })
    }

    disableControl(x) {
        let temp = this.state.disableList;
        for (var i = 0; i < temp.length; i++) {
            // if(temp[i].toLowerCase().includes(x.toLowerCase()))
            if (temp[i] === x) {
                return true;
            }
        }
        return false;
    }

    updateApiOnChange(x, y, z, a) {
        this.state.refresh(x, y, z, a);
    }

    reset() {
        //const queryparams = new URLSearchParams(window.location.search);
        this.setState({
            instrument: this.state.tempInst + 'Active Contracts',
            expiry: '',
            optionType: '',
            strikePrice: '',
        }, () => {
            this.state.refresh(
                this.state.instrument,
                this.state.expiry,
                this.state.optionType,
                this.state.strikeprice,

            );
        })
    }

    render() {
        return (
            <div className="container-fluid px-0">
                <div className="row">

                    <div className="col-lg-2">
                        <Select defaultValue={this.state.instrument}
                            className="customize-input-group quotes-select-filter" onChange={(e) => {
                                this.setState({ instrument: e.target.value, ddOptionType: this.disableControl(e.target.value) })
                                this.updateApiOnChange(e.target.value, this.state.expiry, this.state.optionType, this.state.strikePrice);
                            }} value={this.state.instrument} displayEmpty

                        >
                            <MenuItem value="" disabled>Select Instrument</MenuItem>
                            <MenuItem value={this.state.tempInst + 'Active Contracts'} >Active Contracts</MenuItem>
                            {this.state.selectInstrument.map((x, idx) => {
                                return <MenuItem key={x + idx} value={x} >
                                    {x}
                                </MenuItem>
                            })}
                        </Select>
                    </div>

                    <div className="col-lg-2">
                        <Select defaultValue={this.state.expiry} className="customize-input-group quotes-select-filter" onChange={(e) => {
                            this.setState({ expiry: e.target.value })
                            this.updateApiOnChange(this.state.instrument, e.target.value, this.state.optionType, this.state.strikePrice);
                        }} value={this.state.expiry} displayEmpty
                        >
                            <MenuItem value="" disabled>Expiry Date</MenuItem>
                            {this.state.selectExpiry.map((x, idx) => {
                                return <MenuItem key={x + idx} value={x}>
                                    {x}
                                </MenuItem>
                            })}
                        </Select>
                    </div>

                    <div className="col-lg-2">
                        <Select defaultValue={this.state.optionType} className="customize-input-group quotes-select-filter" onChange={(e) => {
                            this.setState({ optionType: e.target.value })
                            this.updateApiOnChange(this.state.instrument, this.state.expiry, e.target.value, this.state.strikePrice);
                        }} value={this.state.optionType} displayEmpty
                            disabled={this.state.ddOptionType}
                        >
                            <MenuItem value="" disabled>Option Type</MenuItem>
                            {this.state.selectOptionType.map((x, idx) => {
                                return <MenuItem key={x + idx} value={x}>
                                    {x}
                                </MenuItem>
                            })}
                        </Select>
                    </div>



                    {/* <div className="col-lg-3">
                        <div className="customize-input-group">
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>

                                <KeyboardDatePicker placeholder="Select Expiry date" disableToolbar variant="inline" format="dd-MM-yyyy" margin="normal"
                                    id="toDate" maxDate={new Date()} value={this.state.expiryDate} onChange={this.handleExpiryDate}
                                    KeyboardButtonProps={{ onFocus: (e) => { this.setState({ expiryDateOpen: true }); } }}
                                    PopoverProps={{ disableRestoreFocus: true, onClose: () => { this.setState({ expiryDateOpen: false }); } }}
                                    InputProps={{ onFocus: () => { this.setState({ expiryDateOpen: true }); } }} open={this.state.expiryDateOpen} />

                            </MuiPickersUtilsProvider>
                        </div>
                    </div> */}

                    <div className="col-lg-2">
                        <Select defaultValue={this.state.strikePrice}
                            className="customize-input-group quotes-select-filter"
                            disabled={this.state.ddOptionType}
                            onChange={(e) => {
                                this.setState({ strikePrice: e.target.value })
                                this.updateApiOnChange(this.state.instrument, this.state.expiry, this.state.optionType, e.target.value);
                            }} value={this.state.strikePrice} displayEmpty>
                            <MenuItem value="" disabled >Strike Price</MenuItem>
                            {this.state.selectStrikePrice.map((x, idx) => {
                                return <MenuItem key={x + idx} value={x}>
                                    {x}
                                </MenuItem>
                            })}
                        </Select>
                    </div>

                    <div className="col-lg-1 quotes-filter">
                        <button className="btn btn-orange full-width" style={ButtonHeight} onClick={this.reset}>Clear</button>
                    </div>
                </div>
            </div>
        );
    }
}

export default QuoteFilters;