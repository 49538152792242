import React from 'react'
import { Helmet } from 'react-helmet';
//import env from '../config';; 

class MetaData extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            //   headerRef: props.headerRef,
            metadetails: props.metadetails[0] || [],
        }
    }
    componentDidUpdate(prevProps) {
        if (prevProps.metadetails !== this.props.metadetails) {
            this.setState({ metadetails: this.props.metadetails[0] || [] })
        }
    }
    render() {
        return (
            <Helmet>
                <title>{this.state.metadetails.field_page_title}</title>
                <meta name="description" content={this.state.metadetails.field_description} />
                <meta name="keywords" content={this.state.metadetails.field_keywords} />
                <meta property="og:title" content={this.state.metadetails.field_page_title} />
                <meta property="og:description" content={this.state.metadetails.field_description}/>
                <meta property="og:url" content={this.state.metadetails.field_description} />
                <meta name="twitter:title" content={this.state.metadetails.field_page_title} />
                <meta name="twitter:description" content={this.state.metadetails.field_description}/>
                <meta itemprop="name" content={this.state.metadetails.field_page_title} />
                <meta itemprop="description" content={this.state.metadetails.field_description}/>
                <link rel="alternate" hreflang="en" href={this.state.metadetails.field_url} />
                <link rel="canonical" href={this.state.metadetails.field_url} />
                {/* <link rel="alternate" href="android-app:// XXX" /> */}
            </Helmet>
        );
    }
}

export default MetaData;