import React, { Component } from 'react';
import TbgTableRevised from './components/tgb-table-revised';
//import env from '../config';;
import moment from "moment";
// import NavTabs from './components/nav-tabs';
import Notes from './components/notes';

class BusinessGrowthRevised extends Component {
    constructor(props) {
        super(props);
        this.state = {
            currentMonth: moment().format("MMM"),
            currentYear: moment().format("YYYY"),
            // currentMonth: "Sep",
            // currentYear: "2019",
            api: `/api/tbgr/all`,
            notesApi: `/api/contents/notes?url=/resources-business-growth`,
            notesData: [],
            // api: `/api/tbg/monthly?month=Sep&year=2019`,
            // currentMonth: 'Sep',
            // currentYear: '2019',
            
           
        };
        
    }
   
    componentDidMount() {
        try {
            if (this.state.api && this.state.api !== "") {
                //this.loadData(this.state.api);
                this.loadNotes(this.state.notesApi);
            }
        } catch (e) {
            console.log(e);
        }
    }
   
loadNotes(api) {
fetch(api)
    .then(resp => resp.json())
    .then((cards) => {
        this.setState({
            notesData: cards.content.field_note_components,
        })
    })
    .catch((e) => { console.log("error in header", e) })
}
    loadData(api) {
        console.log("business growth", api)
        fetch(api)
            .then(resp => resp.json())
            .then((data) => {
                this.setState({
                    columns: data.columns,
                    data: data.data
                });
                console.log("business growth data", data, this.state)
            })
            .catch((e) => { console.log("error in header", e) })
    }
    render() {
        return <div className="container-fluid">
             <TbgTableRevised data-api={this.state.api}  current-month={this.state.currentMonth}
                            current-year={this.state.currentYear} />
 <div className="row mt-4">
                    <div className="col-md-12">
                        <Notes notesData={this.state.notesData} />
                    </div>
                </div>
        </div>

    }
}

export default BusinessGrowthRevised;