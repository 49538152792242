import React from "react";
import "../../public/css/styles.scss";
import RangeSlider from "./range-slider";
import DoubleArrowIcon from "@material-ui/icons/DoubleArrow";

class USContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      usContainerInfo: props.usContainerInfo || [],
      symbol: props.symbol,
      symbolDescription: "",
      symbolInfo: [],
      priceInfo: [],
      DayPriceInfo: [],
      timeStamp: "",
    };
  }

  loadPriceInfo() {
    if (this.state.usContainerInfo !== []) {
      this.setState({
        symbolDescription: this.state.usContainerInfo.symbolDescription || "",
        symbolInfo: this.state.usContainerInfo.symbolData || [],
        priceInfo: this.state.usContainerInfo.priceData || [],
        DayPriceInfo: this.state.usContainerInfo.DayPriceInfo || [],
      });
    }
  }
  componentDidMount() {
    try {
      this._isMounted = true;
      this.loadPriceInfo();
    } catch (e) {
      console.log(e);
    }
  }
  componentDidUpdate(prevProps) {
    if (
      prevProps.usContainerInfo !== this.props.usContainerInfo &&
      this.props.usContainerInfo !== []
    ) {
      this.setState({ usContainerInfo: this.props.usContainerInfo }, () => {
        this.loadPriceInfo();
      });
    }
  }

  render() {
    return (
      <div id="depositorycontainer">
        <div className="spdr-block">
          <div className="row">
          {this.state.symbolDescription !== "" ? 
            <div className="col-md-5">
            
                  <h2 className="title-h2">
                    {this.state.symbolDescription} <span className="spytxt"></span>
                  </h2>
                  <h3 className="tdh3">{this.state.symbol}</h3>
                
            </div>
            : (
              ""
            )}
            {/* Underlying Section Commented - Data unavailable */}
            {/* <div className="col-md-7">
              <div className="row">
                <div className="col-md-6 rightfloattitle">
                  Underlying US stock */}
            {/* <br /> */}
            {/* <div className="rightfloatvalue">
                    ${this.state.symbolInfo[0].price || ""}
                  </div> */}
            {/* <div className="rightfloattime">
                    Date as of Sep 16,2021 02:08 PM ET
                  </div> */}
            {/* </div>
                <div className="col-md-3 rightfloattitle">
                  Change */}
            {/* <br /> */}
            {/* <div className={"rightfloatvalue "+(this.state.symbolInfo[0].change > 0 ? "green" : "red")}>
                    ${this.state.symbolInfo[0].change}
                  </div> */}
            {/* <div className="rightfloattime">Sep 16,2021 21:54 IST</div> */}
            {/* </div>
                <div className="col-md-3 rightfloattitle">
                  Change %<br /> */}
            {/* <div className={"rightfloatvalue "+(this.state.symbolInfo[0].perchange > 0 ? "green" : "red")}>
                    {this.state.symbolInfo[0].perchange}%
                  </div> */}
            {/* <div className="rightfloattime">Sep 16,2021 21:54 IST</div> */}
            {/* </div>
              </div>
            </div> */}
          </div>
        </div>

        <div className="price-info-block">
          <h2 className="pricetitle">Price Information</h2>
          <div className="firstcontent">
            <div className="row">
              <div className="col-md-3">
                {this.state.symbolInfo.map((item, jdx) => (
                  <div
                    key={jdx}
                    className={
                      "opencloseright pl-1 " + (item.change > 0 ? "green" : "red")
                    }
                  >
                    <span className="usstockprice">${item.price}</span>
                    <span>
                      <DoubleArrowIcon
                        className={
                          "stock-arrow mt-2 " +
                          (item.change > 0 ? "chng-up" : "chng-down")
                        }
                      />
                    </span>
                    <div className="allamount">
                      <span className="amountleft">
                        {item.change > 0 ? "+" + item.change : item.change}
                      </span>
                      <span className="amountright">
                        ({item.change > 0 ? "+" + item.perchange : item.perchange}
                        %)
                      </span>
                    </div>
                  </div>
                ))}
              </div>
              <div className="col-md-9 align-self-center">
                <div className="price-info-row">
                  {this.state.priceInfo.map((col, idx) => {
                    return (
                      <div key={idx} className="price-info-col">
                        {col.colname}
                        <div className="amount">{col.val}</div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <div className="no-margin">
                <RangeSlider
                  symbol={this.state.symbol}
                  usContainerInfo={this.state.usContainerInfo}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default USContainer;
