import React from 'react';
import { withRouter} from 'react-router-dom';
import TextField from '@material-ui/core/TextField';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
//import env from '../../config';
import { IconButton } from '@material-ui/core';
import { parseAsHtml } from './util';

let currentUrlParams = new URLSearchParams(window.location.search);
class AllResultsTab extends React.Component {

    constructor(props) {
        super(props);
        const queryparams = new URLSearchParams(props.location.search);
        this.state = {
            api: props.api,
            totalResults: 0,
            type: props.type,
            highlighted: `${queryparams.get("q") || ''}`,
            timeTook: 0,
            results: [],
            page: props.page,
        };
        this.changePageNo = this.changePageNo.bind(this);
        this.nextPage = this.nextPage.bind(this);
        this.previousPage = this.previousPage.bind(this);
    };

    componentDidMount() {
        try {
            this._isMounted = true;
            this.changePath();
        } catch (e) {
            console.log(e);
        }
    }

    changePath() {
        currentUrlParams.set('type', this.state.type);
        currentUrlParams.set('page', this.state.page);
        this.props.history.push(`/search?` + currentUrlParams.toString());
        // window.scrollTo(0, 0);
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
        this.loadResults(`/api/search?` + currentUrlParams);
    }

    loadResults(api) {
        fetch(api)
            .then(resp => resp.json())
            .then((response) => {
                this.setState({
                    totalResults: response.totalResults,
                    timeTook: response.timeTook,
                    results: response.results,
                    page: response.currentPage,
                })
            })
            .catch((e) => { console.log("error in search Results", e) })
    }

    changePageNo = (e) => {
        this.setState({
            page: e.target.value,
        }, () => {
            this.changePath();
        })
    }

    nextPage = (e) => {
        this.setState({
            page: this.state.page + 1 > Math.ceil(this.state.totalResults / 10) ?
                Math.ceil(this.state.totalResults / 10) : this.state.page + 1,
        }, () => {
            this.changePath();
        })
    }

    previousPage = (e) => {
        this.setState({
            page: this.state.page - 1 <= 0 ? 1 : this.state.page - 1
        }, () => {
            this.changePath();
        })
    }

    render() {
        return (
            <div className="container-fluid">
                {this.state.results.length > 0 ?
                    <>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="tag">
                                    <p>About {this.state.totalResults} Results ({this.state.timeTook} seconds)
                                   </p>
                                </div>
                                <div className="tag">
                                    <p>Showing results for <strong>{this.state.highlighted}</strong>
                                    </p>
                                </div>
                                {this.state.results.map((item,idx) => {
                                    return <div key={idx} className="all-search listing-block-wrap mt-4">
                                        <div className="row align-items-center">
                                            <div className="col-lg-9">
                                                <div className="listing-block listing-font align-txt">
                                                    <a className="download-link" 
                                                    href={item.result_sub_type === "content" ?
                                                     item.url+"?q="+this.state.highlighted : item.url}>
                                                        <h6>{parseAsHtml(item.symbol || item.title || item.site_sub_module)}</h6>
                                                    </a>
                                                    <p className="grey-color">
                                                        {parseAsHtml(item.symbol_info || item.site_module)}
                                                    </p>
                                                    <p className="grey-color">
                                                        { item.content ? parseAsHtml(item.content) : ""}
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="col-lg-3">
                                                {/* <div className="d-flex"> */}
                                                    <button className="btn btn-secondary full-width">{item.result_sub_type}</button>
                                                {/* </div> */}
                                            </div>
                                        </div>
                                    </div>
                                })}
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-12">
                                <ul className="custompagination">
                                    <li>
                                        <TextField className="pagination-shrink"
                                            id="outlined-number"
                                            type="number" onChange={this.changePageNo}
                                            inputProps={{
                                                max: Math.ceil(this.state.totalResults / 10),
                                                min: 1
                                            }}
                                            value={this.state.page}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            variant="outlined"
                                        />
                                    </li>
                                    <li>
                                        <p className="noof-records"> of {Math.ceil(this.state.totalResults / 10)}</p>
                                    </li>
                                    <li>
                                        <div className="transparent first">
                                            <IconButton onClick={this.previousPage} ><NavigateBeforeIcon /></IconButton>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="transparent last">
                                            <IconButton onClick={this.nextPage} ><NavigateNextIcon /></IconButton>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </>
                    :
                    <div className="row mt-3">
                        <div className="col-md-12">
                            <h3 className="no-searchresults-txt">No results found for {this.state.highlighted}</h3>
                        </div>
                    </div>
                }
            </div>
        )
    }
}
export default withRouter(AllResultsTab);