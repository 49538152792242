import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Checkbox from '@material-ui/core/Checkbox';
import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(
  theme => ({
    root: {
      padding: '16px 24px 16px 24px',
      fontFamily: 'Roboto',
      "&$checked": {
        color: "rgba(0, 0, 0, 0.54)"
      }
    },
    title: {
      marginLeft: '-7px',
      marginRight: '24px',
      fontSize: '14px',
      color: theme.palette.text.secondary,
      textAlign: 'left',
      fontWeight: 500,
    },
    formGroup: {
      marginTop: '8px',
    },
    formControl: {},
    selectallBorder:{
        borderBottom:'1px solid #E2E2E2'
    },
    checkbox: {
      padding: '0px',
      width: '32px',
      height: '32px',
      "&$checked": {
        color: "#e86e25"
      }
    },
    checkboxRoot: {},
    checked: {
        
    },
    label: {
      fontSize: '16px',
      marginLeft: '8px',
      color: '#333333',
      fontFamily:'Roboto-Regular',
    },
    selectAlllabel: {
        fontSize: '16px',
        marginLeft: '8px',
        color: '#333333',
        fontFamily:'Roboto-Bold',
      },
  }),
  { name: 'MUIDataTableViewCol' },
);

const CustomizeColumns = ({ columns, options, components = {}, onColumnUpdate, updateColumns }) => {
// console.log("columns are", columns)
  const classes = useStyles();
  const textLabels = options.textLabels.viewColumns;
  const CheckboxComponent = components.Checkbox || Checkbox;
  const [isAllSelected, setisAllSelected] = useState(() => {
    let displayArray2 = [];
    columns.forEach(col => {
        displayArray2.push(col.display)
    });
    
    //const initialState = someExpensiveComputation(props);
    return displayArray2.every(v => v === "true");
  });
  //const [arraylist, setarraylist] = useState(() => {
    // let displayArray2 = [];
    // columns.map(col => {
    //     displayArray2.push(col.display)
    // });
    //console.log("initianl",columns)
  //   return columns
  //   ;
  // });


  const handleColChange = (index,event) => {
    onColumnUpdate(index);
    let displayArray1 = [];
    columns.forEach(col => {
        displayArray1.push(col.display)
    });
    // console.log("tur false arrays", event.target.checked)
    if(event.target.checked === false){
        setisAllSelected(false)
    }
    //setisAllSelected(displayArray1.every(v => v === "true"))
    //setisAllSelected(columns.every(v => v === "true"))
  };

  const selectAll = () => {
   
    var newColumns = columns.map(col => {
      var newCol = Object.assign({}, col);
      newCol.display = "true";
      return newCol;
    });
    updateColumns(newColumns);
    let displayArray = [];
    newColumns.forEach(col => {
        displayArray.push(col.display)
    });
    setisAllSelected(displayArray.every(v => v === "true"))
    // console.log("columns are", displayArray.every(v => v === "true"))
  };

  return (
    <FormControl component={'fieldset'} className={classes.root} aria-label={textLabels.titleAria}>
      <Typography variant="caption" className={classes.title}>
        {textLabels.title}
      </Typography>
      <FormGroup className={classes.formGroup}>
      {/* <Button onClick={selectAll}>Show All</Button> */}
      <FormControlLabel className={classes.selectallBorder}
                classes={{
                  root: classes.formControl,
                  label: classes.selectAlllabel,
                }}
                control={<CheckboxComponent
                    color="primary"
                    className={classes.checkbox}
                    classes={{
                      root: classes.checkboxRoot,
                      checked: classes.checked,
                    }}
                    checked={isAllSelected}
                    onChange={selectAll} />
                    // checked={column.display === 'true'}
                }
                label={"Select All"}
              />
        
        {columns.map((column, index) => {
        // //arraylist.push(column.display)
        // console.log("--------------",column.display)
          return (
            column.display !== 'excluded' &&
            column.viewColumns !== false && (
              <FormControlLabel
                key={index}
                classes={{
                  root: classes.formControl,
                  label: classes.label,
                }}
                control={
                  <CheckboxComponent
                    color="primary"
                    className={classes.checkbox}
                    classes={{
                      root: classes.checkboxRoot,
                      checked: classes.checked,
                    }}
                    onChange={(event) => handleColChange(index,event)}
                    checked={column.display === 'true'}
                    value={column.name}
                  />
                }
                label={column.label}
              />
            )
          );
        })}
      </FormGroup>
    </FormControl>
  );
};

CustomizeColumns.propTypes = {
  /** Columns used to describe table */
  columns: PropTypes.array.isRequired,
  /** Options used to describe table */
  options: PropTypes.object.isRequired,
  /** Callback to trigger View column update */
  onColumnUpdate: PropTypes.func,
  /** Extend the style applied to components */
  classes: PropTypes.object,
};

export default CustomizeColumns;
