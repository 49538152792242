import React from 'react';
import ReorderIcon from '@material-ui/icons/Reorder';
import AppsIcon from '@material-ui/icons/Apps';
import MediaCoverageCard from './components/media-coverage-card';
import PaginationContainer from './components/pagination-container';
import moment from "moment";
import { Refresh } from './components/custom-toolbar';
import MediaFilters from './components/media-filters';
// import Notes from './components/notes';
import { parseAsHtml } from './components/util';

const dateformat = "DD-MMM-YYYY";
class MediaCoverage extends React.Component {
    _isMounted = false;
    constructor(props) {
        super(props);
        this.state = {
            fromdate: moment(new Date()).subtract(1, 'year').format(dateformat),
            todate: moment().utc().utcOffset("+05:30").format(dateformat),
            api: ``,
            cardData: [],
            currentView: 'listview',
            isGridview: false,
            cardpara: '',
            keyword: '',
            wholeCardData: [],
            notesApi: `/api/contents/notes?url=/resources-media-coverage`,
            notesData: [],
            drawerOpen: props.drawerOpen || true,
            baseApi: `/api/contents/media-coverage?`,
        };

        this.refresh = this.refresh.bind(this);
        this.setParameters = this.setParameters.bind(this);
        this.filterMediaCoverage = this.filterMediaCoverage.bind(this);
        this.resetFilters = this.resetFilters.bind(this);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.drawerOpen !== this.props.drawerOpen) {
            this.setState({
                drawerOpen: this.props.drawerOpen,
            })
        }
    }
    componentDidMount() {
        try {
            this._isMounted = true;
            this.setState({ api: `${this.state.baseApi}fromDate=${moment(this.state.fromdate, dateformat).format("YYYY-MM-DD")}&toDate=${moment(this.state.todate, dateformat).format("YYYY-MM-DD")}` }
                , () => {
                    this.loadMediaCovData(this.state.api);
                })
            this.loadNotes(this.state.notesApi);
        } catch (e) {
            console.log(e);
        }

    }

    loadMediaCovData(api) {
        fetch(api)
            .then(resp => resp.json())
            .then((cards) => {
                this.setState({ wholeCardData: cards })
                this.filterMediaCoverage(cards);
            })
            .catch((e) => { console.log("error in loading media coverage", e) })
    }
    refresh() {
        if (this._isMounted) {
            this.setState({ cardData: [] });
            this.loadMediaCovData(`${this.state.api}`);
        }
    }
    setParameters(fromdate, todate, keyword) {

        let api = `/api/contents/media-coverage?fromDate=${moment(fromdate, dateformat).format("YYYY-MM-DD")}&toDate=${moment(todate, dateformat).format("YYYY-MM-DD")}`;
        if (fromdate !== this.state.fromdate || todate !== this.state.todate) {

            this.setState({
                fromdate: fromdate,
                todate: todate,
                api: api,
                keyword: keyword
            }, () => {
                this.loadMediaCovData(api);
            })
        }
        else {

            this.setState({ keyword }, () => {
                this.filterMediaCoverage(this.state.wholeCardData)
            })

        }

    }
    filterMediaCoverage(carddata) {
        if (this.state.keyword !== "") {
            const filteredContent = carddata.filter(item => {
                return item.title.toLowerCase().search(this.state.keyword.toLowerCase()) !== -1;
            })

            this.setState({ cardData: filteredContent })
        }

        else {
            this.setState({ cardData: carddata })
        }
    }


    resetFilters() {
        this.setState({
            fromdate: moment(new Date()).subtract(1, 'year').format(dateformat),
            todate: moment().utc().utcOffset("+05:30").format(dateformat),
            keyword: "",
            cardData: [],
            wholeCardData: [],
        }, () => {
            let api = `/api/contents/media-coverage?fromDate=${moment(this.state.fromdate, dateformat).format("YYYY-MM-DD")}&toDate=${moment(this.state.todate, dateformat).format("YYYY-MM-DD")}`;
            this.loadMediaCovData(api);
            this.setState({
                api
            })

        })
    }
    loadNotes(api) {

        fetch(api)
            .then(resp => resp.json())
            .then((cards) => {
                // let notedata = [];
                let cardpara = '';
                cardpara = cards.content.field_note_components[0].field_para;
                this.setState({
                    // notesData: notedata,
                    cardpara: cardpara
                })


            })
            .catch((e) => { console.log("error in notes in media coverage", e) })
    }

    render() {

        const mediaComp = [];
        return (
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-12">
                        <div className="static-para circulars-para">{parseAsHtml(this.state.cardpara)}
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <MediaFilters resetcallback={this.resetFilters} callback={this.setParameters} />
                    </div>
                </div>
                <div className="row mb-2 mt-2">
                    <div className="col-lg-7">
                        <div className="d-flex align-items-center">
                            <div className="tag">
                                <p>Media Release for period {moment(this.state.fromdate, dateformat).format("DD-MM-YYYY")} to {moment(this.state.todate, dateformat).format("DD-MM-YYYY")}
                                </p>
                            </div>
                            <Refresh refreshcallback={this.refresh} />
                        </div>
                    </div>
                    <div className="col-lg-5">
                        <div className="d-flex justify-content-end">
                            <div className="mr-2">
                                <div className="circle-icon"
                                    onClick={() => {
                                        if (this.state.currentView === 'gridview') {

                                            this.setState({ currentView: 'listview', isGridview: false })
                                        }
                                        else {
                                            this.setState({ currentView: 'gridview', isGridview: true })
                                        }

                                    }}
                                >
                                    {this.state.isGridview && <ReorderIcon />}
                                    {!this.state.isGridview && <AppsIcon />}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* cards */}
                <div className={this.state.isGridview ? "row media-coverage-grid" : " "}>
                    {this.state.cardData.length > 0 && this.state.cardData.forEach((event, index) => {
                        mediaComp.push(<div className={this.state.isGridview ? (this.state.drawerOpen ? "col-sm-4 mb-4" : "col-sm-3 mb-4") : "col-lg-12 mb-2"} key={index}>
                            <MediaCoverageCard alignCard={this.state.currentView} card={event} cardkey={"medc" + index} />
                        </div>)

                    })
                    }
                    {this.state.cardData.length > 0 ?
                     <div className={this.state.isGridview ? "container-fluid" : ""}>
                        <PaginationContainer pagination-component={mediaComp} itemsPerPage={this.state.drawerOpen ? 6 : 8} />
                        </div>
                        : <p className="text-center mb-0">No records</p>}
                    
                </div>

            </div>
        )
    }
}

export default MediaCoverage;