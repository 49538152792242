import React, { Component } from 'react';
import HomeBanner from './components/home-banner';
import MarketBanner from './components/home-market-banner';
import NavTabs from './components/nav-tabs';
import Circulars from './components/home-circular-card';
import AdBanner from './components/home-ad-banner';
import NewsCards from './components/home-news-cards';
import HomePressRelease from './components/home-press-release';
import HomeMediaCoverage from './components/home-media-coverage';
import ContactUs from './components/contact-us';
import '../public/css/home.scss';
import MarketTable from './components/market-table';
import UnderLyingInfo from './components/under-lying-info';
import ScrollTop from './scroll-top';
import DerivativesDataTable from './components/derivatives-data-table';
import { ReactComponent as LiveWatchIcon } from '../public/images/statistics.svg';
import { ReactComponent as UnderlyingInfo } from '../public/images/underlying-info.svg';
import { ReactComponent as MarketIcon } from '../public/images/market-turnover.svg';
import MetaData from './meta-data';
import Notes from './components/notes';
import UsStockDataTable from './components/us-stocks-data-table';
import AlertDialog from './components/alertDialog';
import SettlementStatistics from './settlement-statistics';
import HighChartHome from './components/highchart-homepage';
import FlashButton from './components/flash-buttons';

class Home extends Component {
    constructor(props) {
        super(props);
        this.wrapper = React.createRef();
        this.state = {
            api: `/api/contents/page?url=/homepage-ifsc`, // add "/hindi" before /homepage-ifsc
            //   liveWatchnotesApi: `/api/contents/notes?url=/derivative-watch`,
            //mktNotesApi: `/api/contents/notes?url=/resources-market-turnover`,
            //   alertApi: 'api/contents/alert-message-notification',
            banner: [],
            news: [],
            clearance: [],
            contactTitle: '',
            contactuscard: [],
            contactusview: [],
            AddressViewData: [],
            PhoneNMailViewData: [],
            metadetails: [],
            eqnotesData: [],
            curnotesData: [],
         
            //mktNotes: [],
            //  alertData:'',
            //  alertWarning:false,
            //  showAlert: false
        };
    };




    componentDidMount() {
        try {
            this.props.headerRef.current.hideBreadCrum();
            this.props.headerRef.current.activeMenu("Home");
            this.loadHomeData(this.state.api);
            //  this.loadLiveWatchNotes(this.state.liveWatchnotesApi);
            //this.loadMktTurnoverNotes(this.state.mktNotesApi);
            //  this.loadAlertData(this.state.alertApi);

            //this.props.headerRef.current.showAlert();
        } catch (e) {
            console.log(e);
        }
    }

    // loadAlertData(api){
    //     fetch(api).then(response=>response.json()).then(data=>{
    //         let fieldComponent = data.content.field_alert_component.slice(-1)
    //         let fieldText = fieldComponent[0].field_alert_message_text;
    //         let Type = fieldComponent[0].field_alert_severity;
    //         if(Type === "High"){
    //                 this.setState({alertWarning: true})
    //         }
    //         let alertStat = data.content.field_alert_button_notification[0][0]
    //         if(alertStat === "ON"){
    //             this.setState({showAlert: true})
    //         }
    //         this.setState({alertData:fieldText})
    //     }).catch((e) => { console.log("error in loading alert notification", e) })
    // }
    // loadLiveWatchNotes(api) {
    //     fetch(api)
    //         .then(resp => resp.json())
    //         .then((cards) => {
    //             let eqnotesData = [];
    //             //let comnotesData = [];
    //             let curnotesData = [];
    //             let title = cards.content.field_note_components[0] || "";
    //             eqnotesData.push(title, cards.content.field_note_components[1] || "");
    //             curnotesData.push(title, cards.content.field_note_components[2] || "");
    //             //comnotesData.push(title, cards.content.field_note_components[3]);
    //             this.setState({
    //                 eqnotesData,
    //                 //comnotesData, 
    //                 curnotesData
    //             })
    //         })
    //         .catch((e) => { console.log("error in loading live watch notes", e) })
    // }
    // loadMktTurnoverNotes(api) {
    //     fetch(api)
    //         .then(resp => resp.json())
    //         .then((cards) => {
    //             let mktNotes = [];
    //             let title = cards.content.field_note_components[0] || "";
    //             mktNotes.push(title, cards.content.field_note_components[1] || "");
    //             this.setState({ mktNotes: mktNotes })
    //         })
    //         .catch((e) => { console.log("error in loading market turnover notes", e) })
    // }
    loadHomeData(api) {

        console.log(localStorage.getItem("websiteLanguage"));

        const websiteLanguage = localStorage.getItem("websiteLanguage");


        fetch(api)
            .then(resp => resp.json())
            .then((response => {
                let news = [];
                let banner = [];
                let contactuscard = [];
                let contactusview = [];
                let contactTitle = '';
                let clearance = [];
                let AddressViewData = [];
                let PhoneNMailViewData = [];
                let metadetails = [];
                response.content.field_components.forEach((cont, idx) => {
                    Object.keys(cont).forEach((ke, jdx) => {
                        switch (ke) {
                            case "field_homepage_banner":
                                banner = cont[ke];
                                break;
                            case "field_whats_new_banner":
                                news = cont[ke];
                                break;
                            case "field_contact_us":

                                Object.keys(cont[ke]).forEach(item => {

                                    if (cont[ke][item].field_contact_us_card) {
                                        if (cont[ke][item].field_contact_us_card[0].field_subtitle) {
                                            contactTitle = cont[ke][item].field_contact_us_card[0].field_subtitle;
                                        }
                                        else {
                                            contactuscard.push(cont[ke][item]);
                                        }
                                    }
                                    else {
                                        contactusview.push(cont[ke][item]);

                                    }
                                })
                                contactusview.forEach((item, idx) => {
                                    if (idx === 0) {
                                        AddressViewData.push(item)
                                    }
                                    else {
                                        PhoneNMailViewData.push(item)
                                    }
                                })
                                break;
                            case "field_homepage_footer":
                                clearance = cont[ke];
                                break;
                            case "field_seo_components":
                                metadetails = cont[ke];
                                break;
                            default:
                                break;
                        };
                    });

                })
                this.setState({
                    contactuscard, contactusview,
                    news, banner, clearance, contactTitle,
                    AddressViewData, PhoneNMailViewData,
                    metadetails
                }
                )
            }))
            .catch((e) => { console.log("error in loading home page data", e) })

    }



    //  websiteLanguage = localStorage.getItem("websiteLanguage")
    //  console.log("websiteLanguage")

    render() {
        return (
            <><MetaData metadetails={this.state.metadetails} />
                <div className="home-row">
                    <div className='row'>
                   
                    </div>
                    <div className='row'>
                        <div className='col-md-6'>
                            <HomeBanner hbData={this.state.banner} />
                        </div>
                   <HighChartHome />
                    </div>

                    <div className='row'><span style={{ height: "185px" }}></span></div>
                    <div className='row'>
                    <div className='col-md-12'>
                            <FlashButton />
                    </div>
                    </div>
                 

                    <div className='row'>
                        <div className='col-md-6'>
                            <SettlementStatistics />
                        </div>
                       
                   
                        <div className='col-md-6'>
                            <AdBanner
                                AdBannerCard={this.state.clearance}
                            />
                        </div>

                    </div>
                    {/* <MarketBanner api-marketrate={`/api/market-rate?type=derivatives`}/> */}
                    {/* {this.state.showAlert ? <AlertDialog data= {this.state.alertData} warning={this.state.alertWarning} type={"home"}/> : null} */}
                    {/* <div className="container-fluid ">
                        <NavTabs tabStyleBlock={true} tabs={[
                            {
                                title: "Live Watch", icon: <span className="hometabs-icons">
                                    <LiveWatchIcon id="livewatch_icon" /></span>, component:
                                    <NavTabs tabStyleBlock={false} tabs={[{
                                        title: "Equity Derivatives",
                                        component: <><DerivativesDataTable tabname={"equityderivatives"} insttype={"idx"} viewcols={false} csv={false} viewbutton={true} api={`/api/derivatives-watch?inst_type1=IDX&inst_type2=STK&type=live`} />
                                            <div className="col-md-12">
                                                <Notes notesData={this.state.eqnotesData} />
                                            </div>
                                        </>,
                                    },
                                    {
                                        title: "Currency Derivatives",
                                        component: <><DerivativesDataTable tabname={"currencyderivatives"} insttype={"cur"} viewcols={false} csv={false} viewbutton={true} api={`/api/derivatives-watch?inst_type1=CUR&type=live`} />
                                            <div className="col-md-12">
                                                <Notes notesData={this.state.curnotesData} />
                                            </div>
                                        </>,
                                    },
                                    {
                                        title: "Commodity Derivatives",
                                        component: <DerivativesDataTable tabname={"commodityderivatives"} insttype={"com"} viewcols={false} csv={false} viewbutton={true} api={`/api/derivatives-watch?inst_type1=COM&type=live`} />,
                                    },
                                    {
                                        title: "US Stocks",
                                        component: <UsStockDataTable filename="US Stocks" muitable={true} page={"home"} search={true} insttype={"stocks"} csv={false} viewbutton={true} api={`/api/us-stocks?type=stocks&page=live`} />,
                                    },
                                    ]}
                                    />

                            },

                            {
                                title: "Underlying Info",
                                icon: <span className="hometabs-icons"><UnderlyingInfo id="underlyinginfo_icon" /></span>,
                                component: <UnderLyingInfo data-api={`/api/broad-market-indice`} />
                            },
                            {
                                title: "Market Turnover",
                                icon: <span className="hometabs-icons"><MarketIcon id="mktturnover_icon" /></span>,
                                component:
                                    <><MarketTable
                                        data-api={`/api/market-turnover`}
                                        pageName="marketTurnover"
                                        pageTitle="Market Turnover on "
                                        notesApi="/api/contents/notes?url=/resources-market-turnover" />
                                    </>
                            },
                            {
                                title: "NLT",
                                component:
                                    <><MarketTable
                                        data-api={`/api/nlt`}
                                        pageName="NLT"
                                        pageTitle="NLT for Trading Date:"
                                        notesApi="/api/contents/notes?url=/market/nlt"
                                    />
                                    </>
                            },

                        ]} />
                    </div> */}
                    <NewsCards newsData={this.state.news} />

                    <Circulars />
                    <AdBanner
                        AdBannerCard={this.state.clearance}
                    />
                    {/* <div className="container-fluid mt-4 mb-4">
                        <div className="row">
                            <HomePressRelease api-pr={`/api/contents/press-release`} />
                            <HomeMediaCoverage api-mediacov={`/api/contents/media-coverage`} />
                        </div>
                    </div> */}
                    <ContactUs heading={this.state.contactTitle}
                        ContactUsCard={this.state.contactuscard}
                        AddressViewData={this.state.AddressViewData}
                        PhoneNMailViewData={this.state.PhoneNMailViewData}
                    />
                    <ScrollTop />
                </div>
            </>
        );
    }
}

export default Home;
