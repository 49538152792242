import React, { Component } from 'react'
import SearchCard from './SearchCard'


class SearchResult extends Component {
    render() {
        const results = this.props.results;
        
        return (
            <div role="listbox" className="tt-menu" style={{ position: "absolute", top: "100%", left: "0px", zIndex: "100", width: "-webkit-fill-available" }} aria-expanded="false">
                <div role="presentation" className="tt-dataset tt-dataset-search">
                    {this.props.results !== {} && ((results.symbols && results.symbols.length > 0 )|| 
                   (results.sitemap && results.sitemap.length > 0 ) ||
                   (results.contents && results.contents.length > 0 )) ?
                        <div>
                            {
                                this.props.results.symbols && this.props.results.symbols.map(
                                    (item,idx) => <SearchCard key={idx}
                                        search={item}
                                        type="symbol"
                                        highlight={this.props.highlightword}
                                    />
                                )
                            }
                            {
                                this.props.results.sitemap && this.props.results.sitemap.map(
                                    (item,jdx) => <SearchCard key={jdx}
                                        search={item}
                                        type="sitemap"
                                        highlight={this.props.highlightword}
                                    />
                                )
                            }
                             {
                                this.props.results.contents && this.props.results.contents.map(
                                    (item,kdx) => <SearchCard key={kdx}
                                        search={item}
                                        type="content"
                                        highlight={this.props.highlightword}
                                    />
                                )
                            }
                            <div className="footerNote">Press Enter for more results</div>
                        </div> :
                        <div className="no-records-list">
                            <p className="line1">
                                <span>No Record Found</span>
                            </p>
                        </div>}
                </div>
            </div>)
    }
}

export default SearchResult