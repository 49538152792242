import * as React from "react";
import Badge from "@mui/material/Badge";
import NotificationsOutlinedIcon from "@mui/icons-material/NotificationsOutlined";

class BellBadge extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      TotContents : props.contents
    };
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.contents !== this.props.contents 
    ) {
      this.setState({ TotContents: this.props.contents });
    }
  }

  render() {
    return (
          <div className="Badge">
            <Badge badgeContent={this.state.TotContents} >
              <NotificationsOutlinedIcon style={{ fontSize: "25px", color: "white" }} />
            </Badge>
          </div> 
    );
  }
}

export default BellBadge;
